import { Component, Input, OnInit, Output, EventEmitter, ElementRef, SimpleChanges, Inject, ViewChild } from "@angular/core";
import { UsuarioServiceProvider } from "src/app/service/index.providers";
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController, Platform } from "@ionic/angular";
import { ApiService } from 'src/app/api.service';
import { findIndex } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { ThemeColorsService } from "src/app/service/theme-colors/theme-colors.service";
import { InduccionService } from "src/app/service/induccion-service/induccion.service";
@Component({
	selector: "app-basic-map",
	templateUrl: "./basic-map.component.html",
	styleUrls: ["./basic-map.component.scss"],
})
export class BasicMapComponent implements OnInit {
	@Input() finish: boolean = false;
	@Input() dataProcess: any = {};
	@Input() reloadMap: boolean = false;
	@Input() activity_selected: any = {id: null, stage_id: null};
	@Input() direfentStageSelected: null | number = null;
	@Input() updateStageOnly: null | boolean = null;
	@Input() brandLogo: string = "";

	@Output() onShowModal = new EventEmitter();
	@Output() onHoverActivityMap = new EventEmitter();
	@Output() to = new EventEmitter();
	@Output() changeReloadMap = new EventEmitter();
	startHeightHtml:number;
	ruta_imagen: string = "";
	tab_selected: number = 0;
	activity_map: any = {id: null, stage_id: null};
  stageSelected: null | number = null;
  isIphoneDevice: boolean = false;
	isIpadDevice: boolean = false;
  pendingActivityId: null | number = null;
	activeGrayLayer: boolean = false;

  industry_icons:string = "default"; // default  medical_pharma  retail  banking  clothing_store  restaurants
  brandColors:any = {};

	constructor(public up: UsuarioServiceProvider,
		private el: ElementRef,
    private router: Router,
    private alertCtrl: AlertController,
    private api: ApiService,
		private navCtrl: NavController,
    @Inject(DOCUMENT) private document: any,
    public platform: Platform,
    private themeColorsService: ThemeColorsService,
    private induccionService : InduccionService,
  ){
    if (this.platform.is("iphone")) {
			this.isIphoneDevice = true;
		};
		if (this.platform.is("ipad")) {
			this.isIpadDevice = true;
		}
  }

	ngOnChanges(changes: SimpleChanges) {
    // console.log("direfentStageSelected:: ",this.direfentStageSelected, this.dataProcess)
    if(this.dataProcess?.stages ) {
      this.findPendingActivity(this.dataProcess?.stages);
    };


		if(this.reloadMap) {
			setTimeout(() => {
				this.draw();
				this.reloadMap = false
				this.changeReloadMap.emit(false);
			}, 100);
		};

    if(this.direfentStageSelected !== null) { // &&  this.direfentStageSelected !== this.stageSelected
      if(!this.updateStageOnly) {
        setTimeout(() => {
          this.scrollToCard(this.direfentStageSelected);
        }, 500);
      };
      this.stageSelected = this.direfentStageSelected;
    };

		// if(this.activity_selected.stage_id !== this.activity_map.stage_id){
    //   console.log("kheeee", this.activity_selected.stage_id, this.activity_map.stage_id);

		// 	const index = this.dataProcess.stages.findIndex((e) => (e.id === this.activity_selected.stage_id));
    //   console.log("index kheeee", index);
		// 	const el = document.getElementById('title-'+index);
    //   console.log("index el kheeee", el);
		// 	if(el){
		// 		setTimeout(() => {
		// 			let content_c = document.getElementById("div4");
		// 			content_c.scroll({
		// 				top: el.offsetTop-700,
		// 				left: 0,
		// 				behavior: "smooth",
		// 			  });
		// 			  this.activity_map = this.activity_selected;
		// 		}, 100);
		// 	}
		// }
	}

  scrollToCard(positionDataIdx) {
		try {
      let allMapsContainer = document.getElementById("div4");
      const onlyOneMap = document.getElementById('stage-'+positionDataIdx);
      allMapsContainer.scroll({
        top: this.dataProcess.certificate?.enabled ? onlyOneMap.offsetTop - (allMapsContainer.offsetHeight - onlyOneMap.offsetHeight) + 160 : onlyOneMap.offsetTop - (allMapsContainer.offsetHeight - onlyOneMap.offsetHeight) + 20,  // + 20 para margin bottom y 160 por distancia a botón de certficado
        // top: onlyOneMap.offsetTop - (allMapsContainer.offsetHeight - onlyOneMap.offsetHeight) + 20,  // + 20 para margin bottom
        left: 0,
        behavior: "smooth",
      });

		} catch (error) {
			console.log(error);
		};
	}

	ngOnInit() {
		this.ruta_imagen = this.up.getBucketBaseUrl;
    this.brandColors = this.themeColorsService.getBrandColors();
    // console.log("colores recibidos en mapa: ", this.brandColors)
    if(this.dataProcess?.extra_attributes?.industry_icons) {
      this.industry_icons = this.dataProcess?.extra_attributes?.industry_icons;
    };

		setTimeout(() => {
			this.draw();
		}, 100);

    this.setMapPathColorToDocument();

    setTimeout(() => {
      this.activeGrayLayer = true;
		}, 290);
	}



  setMapPathColorToDocument() {
    let rootelement = this.document.documentElement;
    rootelement.style.setProperty('--mapPathColor', this.dataProcess.color);
  }

	onMouseEnterTooltip(i, activity) {
		const tooltipElement = this.el.nativeElement.querySelector(`#tooltip-${i}`);
		const tooltipRect = tooltipElement.getBoundingClientRect();
		const bodyRect = document.body.getBoundingClientRect();

		// Verificar y ajustar la posición del tooltip
		if (tooltipRect.right > (bodyRect.width / 1.4) ) {
			tooltipElement.classList.add('tooltip-visible');
			return;
		}
		if (tooltipRect.left < 20 ) {
			tooltipElement.classList.add('custom-margin-tooltip');
		}
		this.onHoverActivityMap.emit(activity);
	}

	iconActivity(type){
		switch (type) {
			case 'checklist':
				return 'mdi-list-box';
			case 'temas':
				return 'mdi-bookmark-box';
			case 'encuesta':
				return 'mdi-medal';
			case 'evaluacion':
				return 'mdi-note-text';
			case 'tareas':
				return 'mdi-cloud-upload';
			case 'sesion_online':
				return 'mdi-video';
      case 'pasantia':
        return 'mdi-calendar-multiple-check';
      case 'firmas':
        return 'mdi-draw';
			default:
        return '';
				break;
		}
	}

	mostrarModal(activity){
		this.onShowModal.emit(activity);
	}

	toRoute(route){
		this.to.emit(route);
	}

	redirectCertificate(url, downloadUrl){
		let extras: NavigationExtras = {
			queryParams: {
				url: url,
        desc: downloadUrl
			},
		};
		this.router.navigate(["certificado"], extras);
	}

	async confirmarSalir() {
		let alert = await this.alertCtrl.create({
			header: "Cerrar Sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();
	}

	async cerrarSesion() {
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.navCtrl.navigateRoot("login");
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
	}

	draw() {
		let numbersActivities = 0;
		this.dataProcess.stages.forEach(stage => {
			// if(stage.status !== 'locked') numbersActivities += stage.activities.length;
			numbersActivities += stage.activities.length;
		});
		const canvas = document.getElementById("canvas") as HTMLCanvasElement;
		if (canvas.getContext) {
			const ctx = canvas.getContext("2d");
			let width = document.documentElement.clientWidth;
			width = width <= 1024 ? width : width <= 1680 ?  width * 0.34 : width <= 1540 ? width * 0.29 : 520
			let height = 40 + (this.dataProcess.certificate && this.dataProcess.certificate.enabled ? 320 : 0);
			// let countStageLocked = 0;
			this.dataProcess.stages.forEach((stage, idx) => {
				// if(stage.status !== 'locked'){
					const additional = Math.ceil((stage.activities.length-8)/6) > 0 ? Math.ceil((stage.activities.length-8)/6) : 0
					const additional1 = Math.ceil(additional/2);
					const additional2 = Math.floor(additional/2);
					height += 830 + (200  * additional1) + (340  * additional2);
          // console.log("height:: ",height);
          // console.log("additional, additional1, additional2:: ",additional, additional1, additional2);

				// } else {
				// 	countStageLocked += 1;
				// }
			});

			// if(this.dataProcess.stages.length == countStageLocked) height += 600;

			// if(numbersActivities > 8) height = height + (Math.ceil((numbersActivities-8)/3)*130) //200
			const midWidth = width / 2;
			let startHeight = height - 140;
			canvas.width = width;
			canvas.height = height;

      // console.log("canvas.height: " , height)
			const elementHtml = document.getElementById("elementHtml");
			elementHtml.style.width = `${width}px`;
			elementHtml.style.height = `${height}px`;

			const elementHtml2 = document.getElementById("elementHtml2");
			elementHtml2.style.width = `${width}px`;
			let heightRoad = 0;
			this.dataProcess.stages.forEach((stage, idx) => {
				// if(stage.status !== 'locked'){
					if(idx % 2 === 0) {
						this.drawRoad1(ctx,midWidth,startHeight,stage.activities.length, this.dataProcess, idx);
						this.drawElementHtml(startHeight, 'activity'+(idx+1), 1, 'title-'+(idx),ctx, midWidth)
					}
					else {
						this.drawRoad2(ctx,midWidth,startHeight,stage.activities.length, this.dataProcess, idx);
						this.drawElementHtml(startHeight, 'activity'+(idx+1), -1, 'title-'+(idx),ctx, midWidth)
					}
					// const additional = Math.ceil((stage.activities.length-8)/6) > 0 ? Math.ceil((stage.activities.length-8)/6) : 0
					const additional = Math.ceil((stage.activities.length-8)/6)  > 0 ? Math.ceil((stage.activities.length-8)/6) : 0
					const additional1 = Math.ceil(additional/2);
					const additional2 = Math.floor(additional/2);
					heightRoad = 830;
					heightRoad +=(200  * additional1) + (340  * additional2);
					startHeight = startHeight - heightRoad;
					heightRoad = 0;
				// }
			});

			// this.drawRoad1(ctx,midWidth,startHeight,numberActivity);
			// this.drawElementHtml(startHeight, 'activity1', 1)

			// startHeight = startHeight - 780;
			// this.drawRoad2(ctx,midWidth,startHeight,numberActivity);
			// this.drawElementHtml(startHeight, 'activity2', -1)


			const activities = Array.from(document.getElementsByClassName('elementHtml') as HTMLCollectionOf<HTMLElement>)
      if(this.updateStageOnly !== false) {
        setTimeout(() => {
          let content_c = document.getElementById("div4");
          content_c.scroll({
            top: height,
            left: 0,
            behavior: "smooth",
            });
        }, 100);
      }



		}
	}

	drawRoad1(ctx,midWidth,startHeight,numberActivity, stage, stageIndex){
    // console.log("stage:", stage, stageIndex)
		const additional = Math.ceil((numberActivity-8)/3)  > 0 ? Math.ceil((numberActivity-8)/3) : 0
		const additional1 = Math.ceil(additional/2);
		const additional2 = Math.floor(additional/2);
		const startH = startHeight;

    let colorFondo = stage.color_map_odd && stage.stages[stageIndex].status !== "locked" ? stage.color_map_odd : '#8BFC89'
		let colorEmpresa = stage.color ? stage.color : '#2e36ce'

		this.drawElementRoad1(ctx,midWidth,startH,numberActivity, colorFondo);

		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(1,1);
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.lineWidth=12;
		ctx.miterLimit=10;
		ctx.beginPath();
		ctx.moveTo(midWidth,startHeight);
		ctx.lineTo(midWidth,startHeight-125.49);
		ctx.bezierCurveTo(midWidth,startHeight-158.77,midWidth+26.97,startHeight-185.74,midWidth+60.25,startHeight-185.74);
		ctx.bezierCurveTo(midWidth+93.53,startHeight-185.74,midWidth+120.5,startHeight-212.71,midWidth+120.5,startHeight-245.99);
		ctx.lineTo(midWidth+120.5,startHeight-264.1);
		ctx.bezierCurveTo(midWidth+120.5,startHeight-297.38,midWidth+93.53,startHeight-324.35,midWidth+60.25,startHeight-324.35); // aqui va al otro
		ctx.bezierCurveTo(midWidth+13.192,startHeight-324.35,midWidth-13.192,startHeight-324.35,midWidth-60.25,startHeight-324.35);
		ctx.bezierCurveTo(midWidth-93.53,startHeight-324.35,midWidth-120.5,startHeight-351.32,midWidth-120.5,startHeight-384.6);
		ctx.lineTo(midWidth-120.5,startHeight-401.6);
		ctx.bezierCurveTo(midWidth-120.5,startHeight-434.88,midWidth-93.53,startHeight-461.85,midWidth-60.25,startHeight-461.85);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth-26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
			ctx.lineTo(midWidth,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth+93.53,startHeight-185.74,midWidth+120.5,startHeight-212.71,midWidth+120.5,startHeight-245.99);
				ctx.lineTo(midWidth+120.5,startHeight-264.1);
				ctx.bezierCurveTo(midWidth+120.5,startHeight-297.38,midWidth+93.53,startHeight-324.35,midWidth+60.25,startHeight-324.35);
				if (index === additional1 && additional1 !== additional2) {
					//Fin de recorrido por por derecha
					ctx.bezierCurveTo(midWidth+26.97,startHeight-324.35,midWidth,startHeight-351.32,midWidth,startHeight-384.59999);
					ctx.lineTo(midWidth,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.bezierCurveTo(midWidth+13.192,startHeight-324.35,midWidth-13.192,startHeight-324.35,midWidth-60.25,startHeight-324.35);
					ctx.bezierCurveTo(midWidth-93.53,startHeight-324.35,midWidth-120.5,startHeight-351.32,midWidth-120.5,startHeight-384.6);
					ctx.lineTo(midWidth-120.5,startHeight-401.6);
					ctx.bezierCurveTo(midWidth-120.5,startHeight-434.88,midWidth-93.53,startHeight-461.85,midWidth-60.25,startHeight-461.85);
					if (index === additional2 && additional1 === additional2) {
						//Fin de recorrido por izquierda
						ctx.bezierCurveTo(midWidth-26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
						ctx.lineTo(midWidth,startHeight-824.95);
					}
				}
			}
		}
		startHeight = startH;
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.miterLimit=10;
		ctx.beginPath();
		ctx.moveTo(midWidth+11.5,startHeight);
		ctx.lineTo(midWidth-11.5,startHeight);
		ctx.lineTo(midWidth-11.5,startHeight-125.49);
		ctx.bezierCurveTo(midWidth-11.5,startHeight-165.05,midWidth+20.69,startHeight-197.24,midWidth+60.25,startHeight-197.24);
		ctx.bezierCurveTo(midWidth+87.13,startHeight-197.24,midWidth+109,startHeight-219.11,midWidth+109,startHeight-245.99);
		ctx.lineTo(midWidth+109,startHeight-264.11);
		ctx.bezierCurveTo(midWidth+109,startHeight-290.99,midWidth+87.13,startHeight-312.86,midWidth+60.25,startHeight-312.86);
		ctx.lineTo(midWidth-60.25,startHeight-312.86);
		ctx.bezierCurveTo(midWidth-99.81,startHeight-312.86,midWidth-132,startHeight-345.05,midWidth-132,startHeight-384.61);
		ctx.lineTo(midWidth-132,startHeight-401.61);
		ctx.bezierCurveTo(midWidth-132,startHeight-441.17,midWidth-99.81,startHeight-473.36,midWidth-60.25,startHeight-473.36);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth-33.37,startHeight-473.36,midWidth-11.5,startHeight-495.23,midWidth-11.5,startHeight-522.11);
			ctx.lineTo(midWidth-11.5,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth+87.13,startHeight-197.24,midWidth+109,startHeight-219.11,midWidth+109,startHeight-245.99);
				ctx.lineTo(midWidth+109,startHeight-264.11);
				ctx.bezierCurveTo(midWidth+109,startHeight-290.99,midWidth+87.13,startHeight-312.86,midWidth+60.25,startHeight-312.86);

				if (index === additional1 && additional1 !== additional2) {
					// Fin de recorrido derecha
					ctx.bezierCurveTo(midWidth+33.37,startHeight-312.86,midWidth-11.5,startHeight-334.73,midWidth-11.5,startHeight-381.61);
					ctx.lineTo(midWidth-11.5,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.lineTo(midWidth-60.25,startHeight-312.86);
					ctx.bezierCurveTo(midWidth-99.81,startHeight-312.86,midWidth-132,startHeight-345.05,midWidth-132,startHeight-384.61);
					ctx.lineTo(midWidth-132,startHeight-401.61);
					ctx.bezierCurveTo(midWidth-132,startHeight-441.17,midWidth-99.81,startHeight-473.36,midWidth-60.25,startHeight-473.36);
					if (index === additional2 && additional1 === additional2) {
						// Fin de recorrido izquierda
						ctx.bezierCurveTo(midWidth-33.37,startHeight-473.36,midWidth-11.5,startHeight-495.23,midWidth-11.5,startHeight-522.11);
						ctx.lineTo(midWidth-11.5,startHeight-824.95);
					}

				}
			}
		}
		ctx.lineTo(midWidth+11.5,startHeight-824.95);
		ctx.lineTo(midWidth+11.5,startHeight-522.11);
		if (additional1 !== additional2) {
			// Fin de recorrido derecha
			ctx.lineTo(midWidth+11.5,startHeight-382.11);
			ctx.bezierCurveTo(midWidth+11.5,startHeight-372.55,midWidth+20.69,startHeight-335.86,midWidth+60.25,startHeight-335.86);
		}

		if (additional1 === additional2) {
			// Fin de recorrido izquierda
			ctx.bezierCurveTo(midWidth+11.5,startHeight-482.55,midWidth-20.69,startHeight-450.36,midWidth-60.25,startHeight-450.36);
			ctx.bezierCurveTo(midWidth-87.13,startHeight-450.36,midWidth-109,startHeight-428.49,midWidth-109,startHeight-401.61);
			ctx.lineTo(midWidth-109,startHeight-384.61);
			ctx.bezierCurveTo(midWidth-109,startHeight-357.73,midWidth-87.13,startHeight-335.86,midWidth-60.25,startHeight-335.86);
		}

		ctx.lineTo(midWidth+60.25,startHeight-335.86);
		ctx.bezierCurveTo(midWidth+99.81,startHeight-335.86,midWidth+132,startHeight-303.67,midWidth+132,startHeight-264.11);
		ctx.lineTo(midWidth+132,startHeight-245.99);

		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth+132,startHeight-206.43,midWidth+99.81,startHeight-174.24,midWidth+60.25,startHeight-174.24);
			ctx.bezierCurveTo(midWidth+33.37,startHeight-174.24,midWidth+11.5,startHeight-152.37,midWidth+11.5,startHeight-125.49);
			ctx.lineTo(midWidth+11.5,startHeight);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				ctx.bezierCurveTo(midWidth+132,startHeight-206.43,midWidth+99.81,startHeight-174.24 - 18,midWidth+60.25,startHeight-174.24 - 12);
				startHeight= startHeight + 266.11;
				ctx.bezierCurveTo(midWidth+11.5,startHeight-482.55 + 38,midWidth-20.69,startHeight-450.36,midWidth-60.25,startHeight-450.36);
				ctx.bezierCurveTo(midWidth-87.13,startHeight-450.36,midWidth-109,startHeight-428.49,midWidth-109,startHeight-401.61);
				ctx.lineTo(midWidth-109,startHeight-384.61);
				ctx.bezierCurveTo(midWidth-109,startHeight-357.73,midWidth-87.13,startHeight-335.86,midWidth-60.25,startHeight-335.86);
				ctx.lineTo(midWidth+60.25,startHeight-335.86);
				ctx.bezierCurveTo(midWidth+99.81,startHeight-335.86,midWidth+132,startHeight-303.67,midWidth+132,startHeight-264.11);
				ctx.lineTo(midWidth+132,startHeight-245.99);
				if (index === additional1) {
					ctx.bezierCurveTo(midWidth+132,startHeight-206.43,midWidth+99.81,startHeight-174.24,midWidth+60.25,startHeight-174.24);
					ctx.bezierCurveTo(midWidth+33.37,startHeight-174.24,midWidth+11.5,startHeight-152.37,midWidth+11.5,startHeight-125.49);
					ctx.lineTo(midWidth+11.5,startHeight);
				}
			}
		}
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawRoad2(ctx,midWidth,startHeight,numberActivity, stage, stageIndex){
		const additional = Math.ceil((numberActivity-8)/3) > 0 ? Math.ceil((numberActivity-8)/3) : 0
		const additional1 = Math.ceil(additional/2);
		const additional2 = Math.floor(additional/2);
		const startH = startHeight;
		this.drawElementRoad2(ctx,midWidth,startH,numberActivity, stage, stageIndex);

		let colorEmpresa = stage.color ? stage.color : '#2e36ce'


		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(1,1);
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.lineWidth=12;
		ctx.miterLimit=10;
		ctx.beginPath();
		ctx.moveTo(midWidth,startHeight+80);
		ctx.lineTo(midWidth,startHeight-125.49);
		ctx.bezierCurveTo(midWidth,startHeight-158.77,midWidth-26.97,startHeight-185.74,midWidth-60.25,startHeight-185.74);
		ctx.bezierCurveTo(midWidth-93.53,startHeight-185.74,midWidth-120.5,startHeight-212.71,midWidth-120.5,startHeight-245.99);
		ctx.lineTo(midWidth-120.5,startHeight-264.1);
		ctx.bezierCurveTo(midWidth-120.5,startHeight-297.38,midWidth-93.53,startHeight-324.35,midWidth-60.25,startHeight-324.35); // aqui va al otro
		ctx.bezierCurveTo(midWidth-13.192,startHeight-324.35,midWidth+13.192,startHeight-324.35,midWidth+60.25,startHeight-324.35);
		ctx.bezierCurveTo(midWidth+93.53,startHeight-324.35,midWidth+120.5,startHeight-351.32,midWidth+120.5,startHeight-384.6);
		ctx.lineTo(midWidth+120.5,startHeight-401.6);
		ctx.bezierCurveTo(midWidth+120.5,startHeight-434.88,midWidth+93.53,startHeight-461.85,midWidth+60.25,startHeight-461.85);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth+26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
			ctx.lineTo(midWidth,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth-93.53,startHeight-185.74,midWidth-120.5,startHeight-212.71,midWidth-120.5,startHeight-245.99);
				ctx.lineTo(midWidth-120.5,startHeight-264.1);
				ctx.bezierCurveTo(midWidth-120.5,startHeight-297.38,midWidth-93.53,startHeight-324.35,midWidth-60.25,startHeight-324.35);
				if (index === additional1 && additional1 !== additional2) {
					//Fin de recorrido por por derecha
					ctx.bezierCurveTo(midWidth-26.97,startHeight-324.35,midWidth,startHeight-351.32,midWidth,startHeight-384.59999);
					ctx.lineTo(midWidth,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.bezierCurveTo(midWidth-13.192,startHeight-324.35,midWidth+13.192,startHeight-324.35,midWidth+60.25,startHeight-324.35);
					ctx.bezierCurveTo(midWidth+93.53,startHeight-324.35,midWidth+120.5,startHeight-351.32,midWidth+120.5,startHeight-384.6);
					ctx.lineTo(midWidth+120.5,startHeight-401.6);
					ctx.bezierCurveTo(midWidth+120.5,startHeight-434.88,midWidth+93.53,startHeight-461.85,midWidth+60.25,startHeight-461.85);
					if (index === additional2 && additional1 === additional2) {
						//Fin de recorrido por izquierda
						ctx.bezierCurveTo(midWidth+26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
						ctx.lineTo(midWidth,startHeight-824.95);
					}
				}
			}
		}
		startHeight = startH;
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.miterLimit=10;
		ctx.beginPath();
		// ctx.moveTo(midWidth-11.5,startHeight);
		// ctx.lineTo(midWidth+11.5,startHeight);

		ctx.moveTo(midWidth+11.5,startHeight+66);
		ctx.lineTo(midWidth+11.5,startHeight-125.49);
		ctx.bezierCurveTo(midWidth+11.5,startHeight-165.05,midWidth-20.69,startHeight-197.24,midWidth-60.25,startHeight-197.24);
		ctx.bezierCurveTo(midWidth-87.13,startHeight-197.24,midWidth-109,startHeight-219.11,midWidth-109,startHeight-245.99);
		ctx.lineTo(midWidth-109,startHeight-264.11);
		ctx.bezierCurveTo(midWidth-109,startHeight-290.99,midWidth-87.13,startHeight-312.86,midWidth-60.25,startHeight-312.86);
		ctx.lineTo(midWidth+60.25,startHeight-312.86);
		ctx.bezierCurveTo(midWidth+99.81,startHeight-312.86,midWidth+132,startHeight-345.05,midWidth+132,startHeight-384.61);
		ctx.lineTo(midWidth+132,startHeight-401.61);
		ctx.bezierCurveTo(midWidth+132,startHeight-441.17,midWidth+99.81,startHeight-473.36,midWidth+60.25,startHeight-473.36);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth+33.37,startHeight-473.36,midWidth+11.5,startHeight-495.23,midWidth+11.5,startHeight-522.11);
			ctx.lineTo(midWidth+11.5,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth-87.13,startHeight-197.24,midWidth-109,startHeight-219.11,midWidth-109,startHeight-245.99);
				ctx.lineTo(midWidth-109,startHeight-264.11);
				ctx.bezierCurveTo(midWidth-109,startHeight-290.99,midWidth-87.13,startHeight-312.86,midWidth-60.25,startHeight-312.86);

				if (index === additional1 && additional1 !== additional2) {
					// Fin de recorrido derecha
					ctx.bezierCurveTo(midWidth-33.37,startHeight-312.86,midWidth+11.5,startHeight-334.73,midWidth+11.5,startHeight-381.61);
					ctx.lineTo(midWidth+11.5,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.lineTo(midWidth+60.25,startHeight-312.86);
					ctx.bezierCurveTo(midWidth+99.81,startHeight-312.86,midWidth+132,startHeight-345.05,midWidth+132,startHeight-384.61);
					ctx.lineTo(midWidth+132,startHeight-401.61);
					ctx.bezierCurveTo(midWidth+132,startHeight-441.17,midWidth+99.81,startHeight-473.36,midWidth+60.25,startHeight-473.36);
					if (index === additional2 && additional1 === additional2) {
						// Fin de recorrido izquierda
						ctx.bezierCurveTo(midWidth+33.37,startHeight-473.36,midWidth+11.5,startHeight-495.23,midWidth+11.5,startHeight-522.11);
						ctx.lineTo(midWidth+11.5,startHeight-824.95);
					}

				}
			}
		}
		ctx.lineTo(midWidth-11.5,startHeight-824.95);
		ctx.lineTo(midWidth-11.5,startHeight-522.11);
		if (additional1 !== additional2) {
			// Fin de recorrido derecha
			ctx.lineTo(midWidth-11.5,startHeight-382.11);
			ctx.bezierCurveTo(midWidth-11.5,startHeight-372.55,midWidth-20.69,startHeight-335.86,midWidth-60.25,startHeight-335.86);
		}

		if (additional1 === additional2) {
			// Fin de recorrido izquierda
			ctx.bezierCurveTo(midWidth-11.5,startHeight-482.55,midWidth+20.69,startHeight-450.36,midWidth+60.25,startHeight-450.36);
			ctx.bezierCurveTo(midWidth+87.13,startHeight-450.36,midWidth+109,startHeight-428.49,midWidth+109,startHeight-401.61);
			ctx.lineTo(midWidth+109,startHeight-384.61);
			ctx.bezierCurveTo(midWidth+109,startHeight-357.73,midWidth+87.13,startHeight-335.86,midWidth+60.25,startHeight-335.86);
		}

		ctx.lineTo(midWidth-60.25,startHeight-335.86);
		ctx.bezierCurveTo(midWidth-99.81,startHeight-335.86,midWidth-132,startHeight-303.67,midWidth-132,startHeight-264.11);
		ctx.lineTo(midWidth-132,startHeight-245.99);

		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth-132,startHeight-206.43,midWidth-99.81,startHeight-174.24,midWidth-60.25,startHeight-174.24);
			ctx.bezierCurveTo(midWidth-33.37,startHeight-174.24,midWidth-11.5,startHeight-152.37,midWidth-11.5,startHeight-125.49);
			ctx.lineTo(midWidth-11.5,startHeight+66);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				ctx.bezierCurveTo(midWidth-132,startHeight-206.43,midWidth-99.81,startHeight-174.24 - 18,midWidth-60.25,startHeight-174.24 - 12);
				startHeight= startHeight + 266.11;
				ctx.bezierCurveTo(midWidth-11.5,startHeight-482.55 + 38,midWidth+20.69,startHeight-450.36,midWidth+60.25,startHeight-450.36);
				ctx.bezierCurveTo(midWidth+87.13,startHeight-450.36,midWidth+109,startHeight-428.49,midWidth+109,startHeight-401.61);
				ctx.lineTo(midWidth+109,startHeight-384.61);
				ctx.bezierCurveTo(midWidth+109,startHeight-357.73,midWidth+87.13,startHeight-335.86,midWidth+60.25,startHeight-335.86);
				ctx.lineTo(midWidth-60.25,startHeight-335.86);
				ctx.bezierCurveTo(midWidth-99.81,startHeight-335.86,midWidth-132,startHeight-303.67,midWidth-132,startHeight-264.11);
				ctx.lineTo(midWidth-132,startHeight-245.99);
				if (index === additional1) {
					ctx.bezierCurveTo(midWidth-132,startHeight-206.43,midWidth-99.81,startHeight-174.24,midWidth-60.25,startHeight-174.24);
					ctx.bezierCurveTo(midWidth-33.37,startHeight-174.24,midWidth-11.5,startHeight-152.37,midWidth-11.5,startHeight-125.49);
					ctx.lineTo(midWidth-11.5,startHeight);
				}
			}
		}
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawElementHtml(startHeight, activity, type, title, ctx,midWidth) {

		const activities = Array.from(document.getElementsByClassName(activity) as HTMLCollectionOf<HTMLElement>)
		this.startHeightHtml = startHeight;
		const card = document.getElementById(title);
		let constTree = 0;
		card.style.top = `${this.startHeightHtml}px`
		activities.forEach((activity,index) => {
			switch ((index > 7 ? index + 3 : index + 1) % 8) {
				case 1:
					this.startHeightHtml= this.startHeightHtml - 125;
					activity.style.top = `${this.startHeightHtml}px`
					constTree = 10;
					break;
				case 2:
					this.startHeightHtml= this.startHeightHtml - 70;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginLeft = "4rem": activity.style.marginRight = "4rem"
					constTree = 28;
					break;
				case 3:
					this.startHeightHtml= this.startHeightHtml - (index > 7 ? 24 :  40);
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginLeft = "14rem": activity.style.marginRight = "14rem"
					constTree = 0;
					break;
				case 4:
					this.startHeightHtml= this.startHeightHtml - 90;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginLeft = "13rem" : activity.style.marginRight = "13rem"
					constTree = 60;
					break;
				case 5:
					this.startHeightHtml= this.startHeightHtml - 30;
					// this.startHeightHtml= this.startHeightHtml - 18;
					activity.style.top = `${this.startHeightHtml}px`
					constTree = 60;
					break;
				case 6:
					this.startHeightHtml= this.startHeightHtml - 18;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginRight = "13rem" : activity.style.marginLeft = "13rem"
					constTree = 10;
					break;
				case 7:
					this.startHeightHtml= this.startHeightHtml - 100;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginRight = "13rem" : activity.style.marginLeft = "13rem"
					constTree = 50;
					break;
				case 0:
					if (activities.length > 8) {
						this.startHeightHtml= this.startHeightHtml - 18;
					} else {
						this.startHeightHtml= this.startHeightHtml - 40;
						(type === 1) ? activity.style.marginRight = "2rem" :  activity.style.marginLeft = "2rem"
					}
					activity.style.top = `${this.startHeightHtml}px`
					break;
				default:
					break;
			}
		});

		if(activities.length > 8){

		} else if(activities.length < 8) {
			midWidth = type === 1 ? midWidth : midWidth - 192;
			midWidth  = ((activities.length % 6) > 3 || activities.length == 6) ? midWidth +(-190*type) : activities.length == 7 ? midWidth+(-92*type): midWidth;
			// midWidth = type === 1 ? midWidth : (midWidth*type) + 412 - 200;
			const startTree = this.startHeightHtml + constTree;

      this.drawRoadBlockByIndustryTheme(this.industry_icons, ctx, midWidth, startTree );

			// this.drawElementTree(ctx,1,midWidth+60,startTree-130);
			// this.drawElementTree(ctx,1,midWidth+80,startTree-135);
			// this.drawElementTree(ctx,1,midWidth+100,startTree-128);
			// this.drawElementTree(ctx,1,midWidth+50,startTree-108);
			// this.drawElementTree(ctx,1,midWidth+71,startTree-114);
			// this.drawElementTree(ctx,1,midWidth+90,startTree-120);
			// this.drawElementTree(ctx,1,midWidth+110,startTree-112);
			// this.drawElementTree(ctx,1,midWidth+61,startTree-96);
			// this.drawElementTree(ctx,1,midWidth+96,startTree-100);
			// this.drawElementTree(ctx,1,midWidth+80,startTree-98);
		}
	}

	drawElementRoad1(ctx,midWidth,startHeight,numberActivity, bgmap1){

		let colorFondoOpacity = bgmap1 ? bgmap1+'54' : '#8BFC8954'
    // si las act son mayores a 8 es 1 sino 0
    const additional = numberActivity > 8 ? 1 : 0;
    // const additional = Math.ceil((numberActivity-8)/6) > 0 ? Math.ceil((numberActivity-8)/6) : 0
		// Fondo
		let heightBase = 144;
		let heightBackground = 115;
		for (let index = 0; index < additional + 2; index++) {
			if(index % 2 === 0) {
				heightBase = heightBase + 130;
				this.drawBaseBackground1(ctx,1, midWidth-210, startHeight-heightBase, colorFondoOpacity)
			} else {
				heightBase = heightBase + 400;
				this.drawBaseBackground2(ctx,1, midWidth-210, startHeight-heightBase, colorFondoOpacity)
			}
		}
		for (let index = 0; index < additional + 2; index++) {
			if(index % 2 === 0) {
				heightBackground = heightBackground + 195;
				this.drawBackground1(ctx,1, midWidth-198, startHeight-heightBackground, bgmap1)
			} else {
				heightBackground = heightBackground + 330;
				this.drawBackground2(ctx,1, midWidth-186, startHeight-heightBackground, bgmap1)
			}
		}

		// Elementos
		let heigthElement = 0;
		for (let index = 0; index < additional + 1; index++) {
      // console.log("index % 4 ::",index, numberActivity, index % 4);
			switch (index % 4) {
        // mapa base hasta max 8 actividades
				case 0:
          // console.log("eje x: ",midWidth, "eje y: ",startHeight);
          this.drawElementsByIndustryTheme(this.industry_icons, "road1", 0, ctx, midWidth, startHeight, additional, heigthElement);
					break;
        // mapa adicional si hay más de 8 actividades
				case 1:
					heigthElement += 580;
          this.drawElementsByIndustryTheme(this.industry_icons, "road1", 1, ctx, midWidth, startHeight, additional, heigthElement);
					break;
				case 2:
					this.drawElementRock(ctx,0.8,midWidth-94,startHeight-heigthElement-252);
					this.drawElementRock(ctx,0.42,midWidth-78,startHeight-heigthElement-194);

					this.drawElementRock(ctx,0.42,midWidth+(numberActivity%8>1?50:0)-22,startHeight-heigthElement-278);
					this.drawElementRock(ctx,0.42,midWidth+(numberActivity%8>1?50:0)+18,startHeight-heigthElement-250);
					this.drawElementRock(ctx,0.42,midWidth+(numberActivity%8>1?50:0)-4,startHeight-heigthElement-232);

					break;
				case 3:
					break;
				default:
					break;
			}
		}

	}

	drawElementRoad2(ctx,midWidth,startHeight,numberActivity, stage, stageIndex){
    // console.log("*** drawElementRoad2 numberActivity", numberActivity)
    const isLargeRoad = numberActivity > 8;
		// Fondo
    let colorFondo = stage.color_map_even && stage.stages[stageIndex].status !== "locked" ? stage.color_map_even : '#8BFC89'
		let colorFondoOpacity = colorFondo ? colorFondo+'54' : '#8BFC8954';

		this.drawBaseBackground1(ctx,1, midWidth-210, startHeight-274, colorFondoOpacity) //#F7EBC0
		this.drawBaseBackground2(ctx,1, midWidth-198, startHeight - (isLargeRoad ? 690 : 640), colorFondoOpacity) //#F7EBC0
		this.drawBackground1(ctx,1, midWidth-198, startHeight-(isLargeRoad ? 320 : 310), colorFondo) //#8BFC89
		this.drawBackground2(ctx,1, midWidth- (isLargeRoad ? 186 : 182), startHeight- (isLargeRoad ? 680 : 630), colorFondo) //#8BFC89
		// this.drawBaseBackground1(ctx,1, midWidth-210, startHeight-274, colorFondoOpacity) //#F7EBC0
		// this.drawBaseBackground2(ctx,1, midWidth-198, startHeight - (isLargeRoad ? 678 : 640), colorFondoOpacity) //#F7EBC0
		// this.drawBackground1(ctx,1, midWidth-198, startHeight-310, colorFondo) //#8BFC89
		// this.drawBackground2(ctx,1, midWidth-182, startHeight- (isLargeRoad ? 668 : 630), colorFondo) //#8BFC89

		// Elementos
    this.drawElementsByIndustryTheme(this.industry_icons, "road2", null, ctx, midWidth, startHeight, null, null, isLargeRoad);

	}

	drawBackground1(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.strokeStyle=color;
		ctx.lineWidth=4;
		ctx.beginPath();
		ctx.moveTo(x+336.59,y+150.361);
		ctx.lineTo(x+336.59,y+267.34);
		ctx.bezierCurveTo(x+336.59,y+274.847,x+342.981,y+280.75,x+350.635,y+280.75);
		ctx.lineTo(x+366.955,y+280.75);
		ctx.bezierCurveTo(x+372.606,y+280.75,x+377,y+285.063,x+377,y+290.16);
		ctx.lineTo(x+377,y+402.922);
		ctx.bezierCurveTo(x+377,y+408.019,x+372.606,y+412.332,x+366.955,y+412.332);
		ctx.lineTo(x+272.605,y+412.332);
		ctx.bezierCurveTo(x+266.953,y+412.332,x+262.56,y+408.019,x+262.56,y+402.922);
		ctx.lineTo(x+262.56,y+386.058);
		ctx.bezierCurveTo(x+262.56,y+378.551,x+256.168,y+372.648,x+248.514,y+372.648);
		ctx.lineTo(x+187.333,y+372.648);
		ctx.bezierCurveTo(x+179.68,y+372.648,x+173.288,y+378.551,x+173.288,y+386.058);
		ctx.lineTo(x+173.288,y+423.59);
		ctx.bezierCurveTo(x+173.288,y+428.686,x+168.894,y+433,x+163.243,y+433);
		ctx.lineTo(x+120.725,y+433);
		ctx.bezierCurveTo(x+115.073,y+433,x+110.679,y+428.686,x+110.679,y+423.59);
		ctx.lineTo(x+110.679,y+386.058);
		ctx.bezierCurveTo(x+110.679,y+378.551,x+104.288,y+372.648,x+96.6339,y+372.648);
		ctx.lineTo(x+63.5945,y+372.648);
		ctx.bezierCurveTo(x+57.9429,y+372.648,x+53.5491,y+368.334,x+53.5491,y+363.238);
		ctx.bezierCurveTo(x+53.5491,y+355.731,x+47.1574,y+349.828,x+39.5037,y+349.828);
		ctx.lineTo(x+24.5853,y+349.828);
		ctx.bezierCurveTo(x+18.9338,y+349.828,x+14.5399,y+345.514,x+14.5399,y+340.418);
		ctx.lineTo(x+14.5399,y+290.171);
		ctx.bezierCurveTo(x+14.5399,y+285.075,x+18.9338,y+280.761,x+24.5853,y+280.761);
		ctx.lineTo(x+39.5037,y+280.761);
		ctx.bezierCurveTo(x+47.1574,y+280.761,x+53.5491,y+274.858,x+53.5491,y+267.351);
		ctx.lineTo(x+53.5491,y+216.256);
		ctx.bezierCurveTo(x+53.5491,y+208.749,x+47.1574,y+202.846,x+39.5037,y+202.846);
		ctx.lineTo(x+12.0454,y+202.846);
		ctx.bezierCurveTo(x+6.39385,y+202.846,x+2,y+198.533,x+2,y+193.436);
		ctx.lineTo(x+2,y+150.372);
		ctx.bezierCurveTo(x+2,y+145.276,x+6.39384,y+140.962,x+12.0454,y+140.962);
		ctx.lineTo(x+39.5037,y+140.962);
		ctx.bezierCurveTo(x+47.1574,y+140.962,x+53.5491,y+135.059,x+53.5491,y+127.552);
		ctx.lineTo(x+53.5491,y+41.4578);
		ctx.bezierCurveTo(x+53.5491,y+36.3613,x+57.9429,y+32.0477,x+63.5945,y+32.0477);
		ctx.lineTo(x+82.9283,y+32.0477);
		ctx.bezierCurveTo(x+90.582,y+32.0477,x+96.9737,y+26.1447,x+96.9737,y+18.6376);
		ctx.lineTo(x+96.9737,y+11.4101);
		ctx.bezierCurveTo(x+96.9737,y+6.31363,x+101.368,y+2,x+107.019,y+2);
		ctx.lineTo(x+221.303,y+2);
		ctx.bezierCurveTo(x+226.955,y+2,x+231.349,y+6.31363,x+231.349,y+11.4101);
		ctx.lineTo(x+231.349,y+18.6376);
		ctx.bezierCurveTo(x+231.349,y+26.1447,x+237.74,y+32.0477,x+245.394,y+32.0477);
		ctx.lineTo(x+326.544,y+32.0477);
		ctx.bezierCurveTo(x+332.196,y+32.0477,x+336.59,y+36.3613,x+336.59,y+41.4578);
		ctx.lineTo(x+336.59,y+66.0291);
		ctx.bezierCurveTo(x+336.59,y+73.5361,x+342.981,y+79.4392,x+350.635,y+79.4392);
		ctx.lineTo(x+354.415,y+79.4392);
		ctx.bezierCurveTo(x+360.066,y+79.4392,x+364.46,y+83.7528,x+364.46,y+88.8493);
		ctx.lineTo(x+364.46,y+127.541);
		ctx.bezierCurveTo(x+364.46,y+132.637,x+360.066,y+136.951,x+354.415,y+136.951);
		ctx.lineTo(x+350.635,y+136.951);
		ctx.bezierCurveTo(x+342.981,y+136.951,x+336.59,y+142.854,x+336.59,y+150.361);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawBackground2(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.strokeStyle=color;
		ctx.lineWidth=4;
		ctx.beginPath();
		ctx.moveTo(x+42.3998,y+284.639);
		ctx.lineTo(x+42.3998,y+169.66);
		ctx.lineTo(x+42.4116,y+169.66);
		ctx.lineTo(x+42.4116,y+167.66);
		ctx.bezierCurveTo(x+42.4116,y+160.153,x+36.0197,y+154.25,x+28.3658,y+154.25);
		ctx.lineTo(x+12.0458,y+154.25);
		ctx.bezierCurveTo(x+6.39394,y+154.25,x+2,y+149.937,x+2,y+144.84);
		ctx.lineTo(x+2,y+32.0776);
		ctx.bezierCurveTo(x+2,y+26.9812,x+6.39394,y+22.6675,x+12.0458,y+22.6675);
		ctx.lineTo(x+106.387,y+22.6675);
		ctx.bezierCurveTo(x+112.038,y+22.6675,x+116.432,y+26.9812,x+116.432,y+32.0776);
		ctx.lineTo(x+116.432,y+48.9418);
		ctx.bezierCurveTo(x+116.432,y+56.4489,x+122.824,y+62.3519,x+130.478,y+62.3519);
		ctx.lineTo(x+191.661,y+62.3519);
		ctx.bezierCurveTo(x+199.315,y+62.3519,x+205.706,y+56.4489,x+205.706,y+48.9418);
		ctx.lineTo(x+205.706,y+11.4101);
		ctx.bezierCurveTo(x+205.706,y+6.31368,x+210.1,y+2,x+215.752,y+2);
		ctx.lineTo(x+258.272,y+2);
		ctx.bezierCurveTo(x+263.923,y+2,x+268.317,y+6.31368,x+268.317,y+11.4101);
		ctx.lineTo(x+268.317,y+48.9418);
		ctx.bezierCurveTo(x+268.317,y+56.4489,x+274.709,y+62.3519,x+282.363,y+62.3519);
		ctx.lineTo(x+315.404,y+62.3519);
		ctx.bezierCurveTo(x+321.055,y+62.3519,x+325.449,y+66.6656,x+325.449,y+71.762);
		ctx.bezierCurveTo(x+325.449,y+79.2691,x+331.841,y+85.1721,x+339.495,y+85.1721);
		ctx.lineTo(x+354.414,y+85.1721);
		ctx.bezierCurveTo(x+360.066,y+85.1721,x+364.46,y+89.4858,x+364.46,y+94.5822);
		ctx.lineTo(x+364.46,y+144.829);
		ctx.bezierCurveTo(x+364.46,y+149.925,x+360.066,y+154.239,x+354.414,y+154.239);
		ctx.lineTo(x+339.495,y+154.239);
		ctx.bezierCurveTo(x+331.841,y+154.239,x+325.449,y+160.142,x+325.449,y+167.649);
		ctx.lineTo(x+325.449,y+218.744);
		ctx.bezierCurveTo(x+325.449,y+226.251,x+331.841,y+232.154,x+339.495,y+232.154);
		ctx.lineTo(x+366.954,y+232.154);
		ctx.bezierCurveTo(x+372.606,y+232.154,x+377,y+236.467,x+377,y+241.564);
		ctx.lineTo(x+377,y+284.628);
		ctx.bezierCurveTo(x+377,y+289.724,x+372.606,y+294.038,x+366.954,y+294.038);
		ctx.lineTo(x+339.495,y+294.038);
		ctx.bezierCurveTo(x+331.841,y+294.038,x+325.449,y+299.941,x+325.449,y+307.448);
		ctx.lineTo(x+325.449,y+393.542);
		ctx.bezierCurveTo(x+325.449,y+398.639,x+321.055,y+402.952,x+315.404,y+402.952);
		ctx.lineTo(x+296.069,y+402.952);
		ctx.bezierCurveTo(x+288.415,y+402.952,x+282.023,y+408.855,x+282.023,y+416.362);
		ctx.lineTo(x+282.023,y+423.59);
		ctx.bezierCurveTo(x+282.023,y+428.686,x+277.629,y+433,x+271.978,y+433);
		ctx.lineTo(x+157.69,y+433);
		ctx.bezierCurveTo(x+152.038,y+433,x+147.644,y+428.686,x+147.644,y+423.59);
		ctx.lineTo(x+147.644,y+416.362);
		ctx.bezierCurveTo(x+147.644,y+408.855,x+141.252,y+402.952,x+133.598,y+402.952);
		ctx.lineTo(x+52.4456,y+402.952);
		ctx.bezierCurveTo(x+46.7938,y+402.952,x+42.3998,y+398.639,x+42.3998,y+393.542);
		ctx.lineTo(x+42.3998,y+368.971);
		ctx.bezierCurveTo(x+42.3998,y+361.464,x+36.0079,y+355.561,x+28.3541,y+355.561);
		ctx.lineTo(x+24.5743,y+355.561);
		ctx.bezierCurveTo(x+18.9225,y+355.561,x+14.5285,y+351.247,x+14.5285,y+346.151);
		ctx.lineTo(x+14.5285,y+307.459);
		ctx.bezierCurveTo(x+14.5285,y+302.363,x+18.9225,y+298.049,x+24.5743,y+298.049);
		ctx.lineTo(x+28.3541,y+298.049);
		ctx.bezierCurveTo(x+36.0079,y+298.049,x+42.3998,y+292.146,x+42.3998,y+284.639);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawBaseBackground1(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.beginPath();
		ctx.moveTo(x+164.469,y+38.2807);
		ctx.lineTo(x+279.221,y+38.2807);
		ctx.bezierCurveTo(x+285.403,y+38.2807,x+290.414,y+33.1721,x+290.414,y+26.8701);
		ctx.lineTo(x+290.414,y+23.2896);
		ctx.bezierCurveTo(x+290.414,y+16.9876,x+295.425,y+11.8791,x+301.607,y+11.8791);
		ctx.lineTo(x+339.562,y+11.8791);
		ctx.bezierCurveTo(x+345.744,y+11.8791,x+350.755,y+16.9876,x+350.755,y+23.2896);
		ctx.lineTo(x+350.755,y+26.8701);
		ctx.bezierCurveTo(x+350.755,y+33.1721,x+355.766,y+38.2807,x+361.948,y+38.2807);
		ctx.lineTo(x+386.052,y+38.2807);
		ctx.bezierCurveTo(x+392.233,y+38.2807,x+397.244,y+43.3892,x+397.244,y+49.6912);
		ctx.lineTo(x+397.244,y+126.565);
		ctx.bezierCurveTo(x+397.244,y+132.867,x+402.256,y+137.975,x+408.437,y+137.975);
		ctx.lineTo(x+415.527,y+137.975);
		ctx.bezierCurveTo(x+421.709,y+137.975,x+426.72,y+143.084,x+426.72,y+149.386);
		ctx.lineTo(x+426.72,y+257.647);
		ctx.bezierCurveTo(x+426.72,y+263.949,x+421.709,y+269.058,x+415.527,y+269.058);
		ctx.lineTo(x+408.437,y+269.058);
		ctx.bezierCurveTo(x+402.256,y+269.058,x+397.244,y+274.166,x+397.244,y+280.468);
		ctx.lineTo(x+397.244,y+298.783);
		ctx.bezierCurveTo(x+397.244,y+305.085,x+392.233,y+310.194,x+386.052,y+310.194);
		ctx.lineTo(x+301.596,y+310.194);
		ctx.bezierCurveTo(x+295.414,y+310.194,x+290.403,y+315.302,x+290.403,y+321.604);
		ctx.lineTo(x+290.403,y+347.616);
		ctx.bezierCurveTo(x+290.403,y+353.918,x+285.392,y+359.026,x+279.21,y+359.026);
		ctx.lineTo(x+236.966,y+359.026);
		ctx.bezierCurveTo(x+230.784,y+359.026,x+225.773,y+353.918,x+225.773,y+347.616);
		ctx.lineTo(x+225.773,y+321.604);
		ctx.bezierCurveTo(x+225.773,y+315.302,x+220.762,y+310.194,x+214.58,y+310.194);
		ctx.lineTo(x+164.458,y+310.194);
		ctx.bezierCurveTo(x+158.276,y+310.194,x+153.265,y+315.302,x+153.265,y+321.604);
		ctx.lineTo(x+153.265,y+335.736);
		ctx.bezierCurveTo(x+153.265,y+342.038,x+148.254,y+347.147,x+142.072,y+347.147);
		ctx.lineTo(x+92.7819,y+347.147);
		ctx.bezierCurveTo(x+86.6001,y+347.147,x+81.589,y+342.038,x+81.589,y+335.736);
		ctx.lineTo(x+81.589,y+321.604);
		ctx.bezierCurveTo(x+81.589,y+315.302,x+76.5779,y+310.194,x+70.3961,y+310.194);
		ctx.bezierCurveTo(x+64.2143,y+310.194,x+59.2032,y+305.085,x+59.2032,y+298.783);
		ctx.lineTo(x+59.2032,y+267.485);
		ctx.bezierCurveTo(x+59.2032,y+261.183,x+54.1921,y+256.074,x+48.0103,y+256.074);
		ctx.lineTo(x+11.1929,y+256.074);
		ctx.bezierCurveTo(x+5.0111,y+256.074,x+0,y+250.966,x+0,y+244.664);
		ctx.lineTo(x+0,y+204.387);
		ctx.bezierCurveTo(x+0,y+198.085,x+5.0111,y+192.976,x+11.1929,y+192.976);
		ctx.lineTo(x+48.0103,y+192.976);
		ctx.bezierCurveTo(x+54.1921,y+192.976,x+59.2032,y+187.867,x+59.2032,y+181.565);
		ctx.lineTo(x+59.2032,y+123.609);
		ctx.bezierCurveTo(x+59.2032,y+117.307,x+54.1921,y+112.198,x+48.0103,y+112.198);
		ctx.lineTo(x+31.4671,y+112.198);
		ctx.bezierCurveTo(x+25.2853,y+112.198,x+20.2742,y+107.09,x+20.2742,y+100.788);
		ctx.lineTo(x+20.2742,y+11.4106);
		ctx.bezierCurveTo(x+20.2742,y+5.10856,x+25.2853,y+0,x+31.4671,y+0);
		ctx.lineTo(x+142.083,y+0);
		ctx.bezierCurveTo(x+148.265,y+0,x+153.276,y+5.10856,x+153.276,y+11.4106);
		ctx.lineTo(x+153.276,y+26.8701);
		ctx.bezierCurveTo(x+153.276,y+33.1721,x+158.287,y+38.2807,x+164.469,y+38.2807);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawBaseBackground2(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.beginPath();
		ctx.moveTo(x+262.251,y+320.757);
		ctx.lineTo(x+147.499,y+320.757);
		ctx.bezierCurveTo(x+141.317,y+320.757,x+136.306,y+325.865,x+136.306,y+332.167);
		ctx.lineTo(x+136.306,y+335.748);
		ctx.bezierCurveTo(x+136.306,y+342.05,x+131.295,y+347.158,x+125.113,y+347.158);
		ctx.lineTo(x+87.1581,y+347.158);
		ctx.bezierCurveTo(x+80.9763,y+347.158,x+75.9652,y+342.05,x+75.9652,y+335.748);
		ctx.lineTo(x+75.9652,y+332.167);
		ctx.bezierCurveTo(x+75.9652,y+325.865,x+70.9541,y+320.757,x+64.7723,y+320.757);
		ctx.lineTo(x+40.6687,y+320.757);
		ctx.bezierCurveTo(x+34.4869,y+320.757,x+29.4758,y+315.648,x+29.4758,y+309.346);
		ctx.lineTo(x+29.4758,y+232.472);
		ctx.bezierCurveTo(x+29.4758,y+226.17,x+24.4647,y+221.062,x+18.2829,y+221.062);
		ctx.lineTo(x+11.1929,y+221.062);
		ctx.bezierCurveTo(x+5.0111,y+221.062,x+0,y+215.953,x+0,y+209.651);
		ctx.lineTo(x+0,y+101.39);
		ctx.bezierCurveTo(x+0,y+95.0882,x+5.0111,y+89.9796,x+11.1929,y+89.9796);
		ctx.lineTo(x+18.2829,y+89.9796);
		ctx.bezierCurveTo(x+24.4647,y+89.9796,x+29.4758,y+84.8711,x+29.4758,y+78.569);
		ctx.lineTo(x+29.4758,y+60.2541);
		ctx.bezierCurveTo(x+29.4758,y+53.9521,x+34.4869,y+48.8435,x+40.6687,y+48.8435);
		ctx.lineTo(x+125.124,y+48.8435);
		ctx.bezierCurveTo(x+131.306,y+48.8435,x+136.317,y+43.735,x+136.317,y+37.4329);
		ctx.lineTo(x+136.317,y+11.4106);
		ctx.bezierCurveTo(x+136.317,y+5.10855,x+141.328,y+0,x+147.51,y+0);
		ctx.lineTo(x+189.754,y+0);
		ctx.bezierCurveTo(x+195.936,y+0,x+200.947,y+5.10855,x+200.947,y+11.4106);
		ctx.lineTo(x+200.947,y+37.4218);
		ctx.bezierCurveTo(x+200.947,y+43.7238,x+205.958,y+48.8324,x+212.14,y+48.8324);
		ctx.lineTo(x+262.262,y+48.8324);
		ctx.bezierCurveTo(x+268.444,y+48.8324,x+273.455,y+43.7238,x+273.455,y+37.4218);
		ctx.lineTo(x+273.455,y+23.2896);
		ctx.bezierCurveTo(x+273.455,y+16.9876,x+278.466,y+11.879,x+284.648,y+11.879);
		ctx.lineTo(x+333.938,y+11.879);
		ctx.bezierCurveTo(x+340.12,y+11.879,x+345.131,y+16.9876,x+345.131,y+23.2896);
		ctx.lineTo(x+345.131,y+37.4218);
		ctx.bezierCurveTo(x+345.131,y+43.7238,x+350.142,y+48.8324,x+356.324,y+48.8324);
		ctx.bezierCurveTo(x+362.506,y+48.8324,x+367.517,y+53.9409,x+367.517,y+60.2429);
		ctx.lineTo(x+367.517,y+91.5412);
		ctx.bezierCurveTo(x+367.517,y+97.8432,x+372.528,y+102.952,x+378.71,y+102.952);
		ctx.lineTo(x+415.527,y+102.952);
		ctx.bezierCurveTo(x+421.709,y+102.952,x+426.72,y+108.06,x+426.72,y+114.362);
		ctx.lineTo(x+426.72,y+154.64);
		ctx.bezierCurveTo(x+426.72,y+160.942,x+421.709,y+166.05,x+415.527,y+166.05);
		ctx.lineTo(x+378.71,y+166.05);
		ctx.bezierCurveTo(x+372.528,y+166.05,x+367.517,y+171.159,x+367.517,y+177.461);
		ctx.lineTo(x+367.517,y+235.417);
		ctx.bezierCurveTo(x+367.517,y+241.719,x+372.528,y+246.828,x+378.71,y+246.828);
		ctx.lineTo(x+395.253,y+246.828);
		ctx.bezierCurveTo(x+401.435,y+246.828,x+406.446,y+251.936,x+406.446,y+258.238);
		ctx.lineTo(x+406.446,y+347.616);
		ctx.bezierCurveTo(x+406.446,y+353.918,x+401.435,y+359.026,x+395.253,y+359.026);
		ctx.lineTo(x+284.637,y+359.026);
		ctx.bezierCurveTo(x+278.455,y+359.026,x+273.444,y+353.918,x+273.444,y+347.616);
		ctx.lineTo(x+273.444,y+332.156);
		ctx.bezierCurveTo(x+273.444,y+325.854,x+268.433,y+320.745,x+262.251,y+320.745);
		ctx.lineTo(x+262.251,y+320.757);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawElementBush(ctx, scale, x, y){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#3BB753";
		ctx.beginPath();
		ctx.moveTo(x+146.835,y+37.8301);
		ctx.bezierCurveTo(x+144.806,y+37.8301,x+142.849,y+38.1165,x+140.987,y+38.6178);
		ctx.bezierCurveTo(x+141.083,y+37.9017,x+141.154,y+37.1618,x+141.154,y+36.422);
		ctx.bezierCurveTo(x+141.154,y+27.2329,x+133.708,y+19.8101,x+124.542,y+19.8101);
		ctx.bezierCurveTo(x+122.418,y+19.8101,x+120.39,y+20.2158,x+118.528,y+20.9557);
		ctx.bezierCurveTo(x+113.874,y+8.71166,x+102.059,y+0,x+88.1922,y+0);
		ctx.bezierCurveTo(x+88.1683,y+0,x+88.1444,y+0,x+88.0967,y+0);
		ctx.lineTo(x+88.0967,y+60.5998);
		ctx.lineTo(x+169.103,y+60.5998);
		ctx.bezierCurveTo(x+169.103,y+60.4327,x+169.103,y+60.2895,x+169.103,y+60.1224);
		ctx.bezierCurveTo(x+169.103,y+47.8068,x+159.127,y+37.8301,x+146.811,y+37.8301);
		ctx.lineTo(x+146.835,y+37.8301);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#239B48";
		ctx.beginPath();
		ctx.moveTo(x+88.0953,y+0.03125);
		ctx.bezierCurveTo(x+76.3047,y+0.0551176,x+66.0416,y+6.38,x+60.3611,y+15.7838);
		ctx.bezierCurveTo(x+58.9052,y+15.2587,x+57.33,y+14.9485,x+55.6831,y+14.9485);
		ctx.bezierCurveTo(x+48.6899,y+14.9485,x+42.9378,y+20.2232,x+42.174,y+27.0016);
		ctx.bezierCurveTo(x+41.6728,y+26.9539,x+41.1955,y+26.93,x+40.6704,y+26.93);
		ctx.bezierCurveTo(x+33.9159,y+26.93,x+28.116,y+30.9636,x+25.5145,y+36.7396);
		ctx.bezierCurveTo(x+24.4643,y+36.5964,x+23.3902,y+36.477,x+22.2923,y+36.477);
		ctx.bezierCurveTo(x+9.97664,y+36.477,x+0,y+46.4537,x+0,y+58.7693);
		ctx.bezierCurveTo(x+0,y+59.3899,x+0.0477556,y+60.0105,x+0.0954907,y+60.631);
		ctx.lineTo(x+88.0953,y+60.631);
		ctx.lineTo(x+88.0953,y+0.03125);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawElementTree(ctx, scale, x, y){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#684B1C";
		ctx.beginPath();
		ctx.moveTo(x+12.8621,y+50.6531);
		ctx.lineTo(x+10.9609,y+50.6531);
		ctx.lineTo(x+10.9609,y+32.043);
		ctx.lineTo(x+12.8621,y+32.043);
		ctx.lineTo(x+13.3887,y+43.2046);
		ctx.lineTo(x+12.8621,y+50.6531);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#845F29";
		ctx.beginPath();
		ctx.moveTo(x+14.7625,y+32.043);
		ctx.lineTo(x+12.8613,y+32.043);
		ctx.lineTo(x+12.8613,y+50.6531);
		ctx.lineTo(x+14.7625,y+50.6531);
		ctx.lineTo(x+14.7625,y+32.043);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#239B48";
		ctx.beginPath();
		ctx.moveTo(x+12.8621,y+0.453332);
		ctx.bezierCurveTo(x+8.53307,y+0.422092,x+5.01187,y+3.92545,x+5.01187,y+8.24997);
		ctx.bezierCurveTo(x+5.01187,y+8.54006,x+5.02973,y+8.83014,x+5.06097,y+9.1113);
		ctx.bezierCurveTo(x+5.32428,y+11.4766,x+4.74857,y+13.8821,x+3.20888,y+15.6985);
		ctx.bezierCurveTo(x+1.3434,y+17.8942,x+0.21875,y+20.7326,x+0.21875,y+23.8388);
		ctx.bezierCurveTo(x+0.21875,y+30.8098,x+5.8866,y+36.4598,x+12.8665,y+36.4285);
		ctx.lineTo(x+15.9057,y+20.9915);
		ctx.lineTo(x+12.8665,y+0.457793);
		ctx.lineTo(x+12.8621,y+0.453332);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#3BB753";
		ctx.beginPath();
		ctx.moveTo(x+22.4043,y+15.6938);
		ctx.bezierCurveTo(x+20.8647,y+13.8774,x+20.289,y+11.472,x+20.5523,y+9.10663);
		ctx.bezierCurveTo(x+20.5835,y+8.82547,x+20.6014,y+8.53539,x+20.6014,y+8.2453);
		ctx.bezierCurveTo(x+20.6014,y+3.96094,x+17.1426,y+0.484365,x+12.8672,y+0.453125);
		ctx.lineTo(x+12.8672,y+36.4239);
		ctx.bezierCurveTo(x+19.7936,y+36.3926,x+25.3945,y+30.7694,x+25.3945,y+23.8386);
		ctx.bezierCurveTo(x+25.3945,y+20.7324,x+24.2698,y+17.894,x+22.4043,y+15.6983);
		ctx.lineTo(x+22.4043,y+15.6938);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawElementRock (ctx, scale, x, y) {
		x = x / scale;
		y = y / scale;

		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#3C5160";
		ctx.beginPath();
		ctx.moveTo(x+40.7558,y+54.377);
		ctx.lineTo(x+0.491211,y+54.377);
		ctx.lineTo(x+8.03345,y+12.2029);
		ctx.bezierCurveTo(x+9.25069,y+5.44839,x+15.1221,y+0.507812,x+21.9959,y+0.507812);
		ctx.lineTo(x+40.7558,y+0.507812);
		ctx.lineTo(x+47.3911,y+18.4324);
		ctx.lineTo(x+40.7558,y+54.3531);
		ctx.lineTo(x+40.7558,y+54.377);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#475D6B";
		ctx.beginPath();
		ctx.moveTo(x+40.7549,y+54.377);
		ctx.lineTo(x+81.0195,y+54.377);
		ctx.lineTo(x+73.4774,y+12.2029);
		ctx.bezierCurveTo(x+72.2601,y+5.44839,x+66.3888,y+0.507812,x+59.5149,y+0.507812);
		ctx.lineTo(x+40.7549,y+0.507812);
		ctx.lineTo(x+40.7549,y+54.377);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawElementMountain (ctx, scale, x, y) {
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#19737C";
		ctx.beginPath();
		ctx.moveTo(x+0.448242,y+60.6716);
		ctx.lineTo(x+32.3065,y+12.5682);
		ctx.lineTo(x+40.4797,y+5.05078);
		ctx.lineTo(x+45.1058,y+30.6137);
		ctx.lineTo(x+40.4797,y+60.6716);
		ctx.lineTo(x+0.448242,y+60.6716);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#35A2A5";
		ctx.beginPath();
		ctx.moveTo(x+80.505,y+60.6726);
		ctx.lineTo(x+48.8494,y+12.8674);
		ctx.lineTo(x+40.4795,y+7.04297);
		ctx.lineTo(x+40.4795,y+60.6726);
		ctx.lineTo(x+80.505,y+60.6726);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#F4F4F4";
		ctx.beginPath();
		ctx.moveTo(x+40.4796,y+0.230469);
		ctx.lineTo(x+32.1097,y+12.8689);
		ctx.bezierCurveTo(x+31.4837,y+13.8167,x+32.366,y+15.0329,x+33.4629,y+14.7288);
		ctx.lineTo(x+35.3408,y+14.2042);
		ctx.lineTo(x+34.9175,y+16.4696);
		ctx.bezierCurveTo(x+34.7089,y+17.5963,x+35.9787,y+18.4011,x+36.9027,y+17.7275);
		ctx.bezierCurveTo(x+38.5839,y+16.5113,x+40.4677,y+15.1342,x+40.4677,y+15.0985);
		ctx.bezierCurveTo(x+40.4677,y+15.0806,x+42.6377,y+15.6827,x+42.6377,y+15.6827);
		ctx.lineTo(x+40.4796,y+0.230469);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="white";
		ctx.beginPath();
		ctx.moveTo(x+40.4795,y+0.228614);
		ctx.lineTo(x+48.8494,y+12.867);
		ctx.bezierCurveTo(x+49.4754,y+13.8149,x+48.5931,y+15.031,x+47.4962,y+14.727);
		ctx.lineTo(x+45.6183,y+14.2024);
		ctx.lineTo(x+46.0416,y+16.4677);
		ctx.bezierCurveTo(x+46.2502,y+17.5945,x+44.9804,y+18.3993,x+44.0564,y+17.7256);
		ctx.bezierCurveTo(x+42.3752,y+16.5095,x+40.4914,y+15.1324,x+40.4914,y+15.0966);
		ctx.bezierCurveTo(x+40.4914,y+15.0787,x+40.4795,y+0.222656,x+40.4795,y+0.222656);
		ctx.lineTo(x+40.4795,y+0.228614);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

  findPendingActivity(stages) {
    this.pendingActivityId = this.induccionService.findPendingActivity(stages).pendigActivityID;
  }

  drawElementBandAid(ctx, scale, x, y, rotationAngle) {
    x = x / scale;
    y = y / scale;

    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.scale(scale, scale);
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    // ctx.translate(0.188477,12.129);
    // ctx.rotate(-0.14427921341243807);
    // Ajustar el inicio de la figura según x e y
    ctx.beginPath();
    ctx.moveTo(x + 0, y + 0);
    ctx.lineTo(x + 83.0476, y + 0);
    ctx.bezierCurveTo(x + 83.0476, y + 0, x + 83.0476, y + 0, x + 83.0476, y + 0);
    ctx.lineTo(x + 83.0476, y + 83.0476);
    ctx.bezierCurveTo(x + 83.0476, y + 83.0476, x + 83.0476, y + 83.0476, x + 83.0476, y + 83.0476);
    ctx.lineTo(x + 0, y + 83.0476);
    ctx.bezierCurveTo(x + 0, y + 83.0476, x + 0, y + 83.0476, x + 0, y + 83.0476);
    ctx.lineTo(x + 0, y + 0);
    ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
    ctx.closePath();
    // ctx.rotate(0.14427921341243807);
    // ctx.translate(-0.188477, -12.129);
    // ctx.clip();
    ctx.translate(x + 41.5238, y + 41.5238); // trasladar el origen al centro del dibujo

    ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

    ctx.translate(-x - 41.5238, -y - 41.5238); // regresar el origen a su posición

    // Primer dibujo
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryColor}`;
    ctx.beginPath();
    ctx.moveTo(x + 60.2643, y + 4.45708);
    ctx.lineTo(x + 9.92985, y + 71.9039);
    ctx.bezierCurveTo(x + 7.66961, y + 74.9325, x + 11.278, y + 81.4524, x + 17.9937, y + 86.4642);
    ctx.bezierCurveTo(x + 24.7094, y + 91.4761, x + 31.9866, y + 93.0799, x + 34.2469, y + 90.0513);
    ctx.lineTo(x + 84.5726, y + 22.5979);
    ctx.bezierCurveTo(x + 86.8328, y + 19.5693, x + 83.2244, y + 13.0494, x + 76.5087, y + 8.03754);
    ctx.bezierCurveTo(x + 69.793, y + 3.02572, x + 62.5158, y + 1.42188, x + 60.2556, y + 4.45053);
    ctx.lineTo(x + 60.2643, y + 4.45708);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();

    // Segundo dibujo
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryLightFirstVariant}`;
    // ctx.fillStyle = " #3A7DFF";
    ctx.beginPath();
    ctx.moveTo(x + 90.0448, y + 60.2641);
    ctx.lineTo(x + 22.598, y + 9.92963);
    ctx.bezierCurveTo(x + 19.5694, y + 7.66939, x + 13.0494, y + 11.2778, x + 8.03761, y + 17.9935);
    ctx.bezierCurveTo(x + 3.03234, y + 24.7004, x + 1.4285, y + 31.9776, x + 4.45715, y + 34.2379);
    ctx.lineTo(x + 71.9039, y + 84.5723);
    ctx.bezierCurveTo(x + 74.9326, y + 86.8326, x + 81.4525, y + 83.2242, x + 86.4643, y + 76.5085);
    ctx.bezierCurveTo(x + 91.4762, y + 69.7928, x + 93.08, y + 62.5156, x + 90.0513, y + 60.2553);
    ctx.lineTo(x + 90.0448, y + 60.2641);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();

    // Tercer dibujo
    ctx.save();
    ctx.fillStyle = "white";
    ctx.beginPath();
    ctx.moveTo(x + 38.9009, y + 33.0752);
    ctx.lineTo(x + 31.2942, y + 43.2679);
    ctx.bezierCurveTo(x + 30.0918, y + 44.8792, x + 30.4232, y + 47.1602, x + 32.0345, y + 48.3627);
    ctx.lineTo(x + 51.9729, y + 63.2424);
    ctx.bezierCurveTo(x + 53.5842, y + 64.4449, x + 55.8652, y + 64.1135, x + 57.0677, y + 62.5022);
    ctx.lineTo(x + 64.6743, y + 52.3095);
    ctx.bezierCurveTo(x + 65.8768, y + 50.6982, x + 65.5454, y + 48.4172, x + 63.9341, y + 47.2147);
    ctx.lineTo(x + 43.9957, y + 32.335);
    ctx.bezierCurveTo(x + 42.3844, y + 31.1325, x + 40.1034, y + 31.4639, x + 38.9009, y + 33.0752);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();

    // Último dibujo
    ctx.save();
    ctx.fillStyle = `${this.brandColors.secondaryLightFirstVariant}`;
    ctx.beginPath();
    ctx.moveTo(x + 37.4807, y + 34.9848);
    ctx.lineTo(x + 38.8983, y + 33.0854);
    ctx.bezierCurveTo(x + 40.0981, y + 31.4776, x + 42.3791, y + 31.1407, x + 43.9931, y + 32.3451);
    ctx.lineTo(x + 63.9377, y + 47.2295);
    ctx.bezierCurveTo(x + 65.5455, y + 48.4293, x + 65.8824, y + 50.7104, x + 64.6779, y + 52.3243);
    ctx.lineTo(x + 63.2604, y + 54.2238);
    ctx.lineTo(x + 37.4807, y + 34.9848);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();

    ctx.restore();
    ctx.restore();

  }

  drawElementSprayBottle(ctx, scale, x, y, rotationAngle) {
    // desactivado porque no hay personalizacion de la escala, no hay necesidad de ajustar las coordenadas en función de la escala
    // x = x / scale;
    // y = y / scale;

    ctx.save();
    ctx.strokeStyle="rgba(0,0,0,0)";
    ctx.miterLimit=4;
    ctx.fillStyle="rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle="rgba(0,0,0,0)";

    // ctx.translate(x + 0, y + 7.46875);
    // ctx.rotate(-0.21617124516426167);

    ctx.beginPath();
    ctx.moveTo(x + 0, y + 0);
    ctx.lineTo(x + 34.8142, y + 0);
    ctx.bezierCurveTo(x + 34.8142, y + 0, x + 34.8142, y + 0, x + 34.8142, y + 0);
    ctx.lineTo(x + 34.8142, y + 57.7686);
    ctx.bezierCurveTo(x + 34.8142, y + 57.7686, x + 34.8142, y + 57.7686, x + 34.8142, y + 57.7686);
    ctx.lineTo(x + 0, y + 57.7686);
    ctx.bezierCurveTo(x + 0, y + 57.7686, x + 0, y + 57.7686, x + 0, y + 57.7686);
    ctx.lineTo(x + 0, y + 0);
    ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
    ctx.closePath();

    ctx.translate(x + 0, y + 7.46875);
    ctx.rotate(rotationAngle * Math.PI / 180);
    ctx.translate(-x - 0, -y - 7.46875);
    // ctx.clip();

    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightThirdVariant}`;
    ctx.beginPath();
    ctx.moveTo(x + 21.2627, y + 5.46535);
    ctx.lineTo(x + 13.8652, y + 7.08984);
    ctx.lineTo(x + 15.4897, y + 14.4873);
    ctx.lineTo(x + 22.8872, y + 12.8628);
    ctx.lineTo(x + 21.2627, y + 5.46535);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 14.8829, y + 14.3302);
    ctx.lineTo(x + 23.3666, y + 12.4672);
    ctx.bezierCurveTo(x + 25.0888, y + 12.089, x + 26.7897, y + 13.1819, x + 27.1667, y + 14.8988);
    ctx.lineTo(x + 27.8777, y + 18.1365);
    ctx.lineTo(x + 13.157, y + 21.3692);
    ctx.lineTo(x + 12.446, y + 18.1315);
    ctx.bezierCurveTo(x + 12.0678, y + 16.4093, x + 13.1607, y + 14.7084, x + 14.8776, y + 14.3314);
    ctx.lineTo(x + 14.8829, y + 14.3302);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightFifthVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 13.371, y + 21.0013);
    ctx.lineTo(x + 27.5406, y + 17.8896);
    ctx.bezierCurveTo(x + 33.0145, y + 16.6875, x + 38.4367, y + 20.1571, x + 39.6388, y + 25.631);
    ctx.lineTo(x + 45.8924, y + 54.108);
    ctx.bezierCurveTo(x + 46.1729, y + 55.3851, x + 45.3623, y + 56.6518, x + 44.0853, y + 56.9322);
    ctx.lineTo(x + 14.7074, y + 63.3837);
    ctx.bezierCurveTo(x + 13.4303, y + 63.6641, x + 12.1636, y + 62.8536, x + 11.8832, y + 61.5765);
    ctx.lineTo(x + 5.62958, y + 33.0995);
    ctx.bezierCurveTo(x + 4.4275, y + 27.6256, x + 7.89706, y + 22.2034, x + 13.371, y + 21.0013);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 20.4551, y + 19.4453);
    ctx.lineTo(x + 27.5399, y + 17.8895);
    ctx.bezierCurveTo(x + 33.0191, y + 16.6862, x + 38.4348, y + 20.1517, x + 39.6381, y + 25.6309);
    ctx.lineTo(x + 45.8917, y + 54.1079);
    ctx.bezierCurveTo(x + 46.1722, y + 55.3849, x + 45.3616, y + 56.6516, x + 44.0846, y + 56.9321);
    ctx.lineTo(x + 29.3956, y + 60.1578);
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 17.1236, y + 25.4922);
    ctx.lineTo(x + 26.0101, y + 23.5407);
    ctx.bezierCurveTo(x + 30.6044, y + 22.5318, x + 35.1541, y + 25.4431, x + 36.163, y + 30.0373);
    ctx.lineTo(x + 40.5408, y + 49.9723);
    ctx.bezierCurveTo(x + 40.8212, y + 51.2494, x + 40.0107, y + 52.5161, x + 38.7336, y + 52.7965);
    ctx.lineTo(x + 17.8289, y + 57.3872);
    ctx.bezierCurveTo(x + 16.5519, y + 57.6677, x + 15.2852, y + 56.8571, x + 15.0047, y + 55.5801);
    ctx.lineTo(x + 10.627, y + 35.6451);
    ctx.bezierCurveTo(x + 9.61806, y + 31.0508, x + 12.5293, y + 26.5011, x + 17.1236, y + 25.4922);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightThirdVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 33.2605, y + 7.81787);
    ctx.bezierCurveTo(x + 32.9319, y + 7.89001, x + 32.5848, y + 7.87738, x + 32.242, y + 7.75824);
    ctx.lineTo(x + 26.0108, y + 5.63835);
    ctx.lineTo(x + 12.636, y + 8.57547);
    ctx.bezierCurveTo(x + 11.6133, y + 8.80006, x + 10.5987, y + 8.15081, x + 10.3741, y + 7.1281);
    ctx.bezierCurveTo(x + 10.1495, y + 6.10539, x + 10.7987, y + 5.09074, x + 11.8215, y + 4.86615);
    ctx.lineTo(x + 25.7155, y + 1.81498);
    ctx.bezierCurveTo(x + 26.0547, y + 1.7405, x + 26.403, y + 1.75844, x + 26.7328, y + 1.86931);
    ctx.lineTo(x + 33.4673, y + 4.1564);
    ctx.bezierCurveTo(x + 34.458, y + 4.4943, x + 34.9891, y + 5.57192, x + 34.6524, y + 6.56792);
    ctx.bezierCurveTo(x + 34.4295, y + 7.22232, x + 33.8899, y + 7.67409, x + 33.2593, y + 7.81257);
    ctx.lineTo(x + 33.2605, y + 7.81787);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 28.2816, y + 55.0858);
    ctx.lineTo(x + 39.3142, y + 52.663);
    ctx.bezierCurveTo(x + 40.2733, y + 52.4524, x + 40.8812, y + 51.5023, x + 40.6706, y + 50.5432);
    ctx.lineTo(x + 36.2161, y + 30.2585);
    ctx.bezierCurveTo(x + 35.1792, y + 25.5371, x + 30.5131, y + 22.5513, x + 25.7917, y + 23.5882);
    ctx.lineTo(x + 21.5737, y + 24.5145);
    ctx.lineTo(x + 28.2881, y + 55.0899);
    ctx.lineTo(x + 28.2816, y + 55.0858);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 24.5544, y + 17.9075);
    ctx.lineTo(x + 25.6248, y + 17.6724);
    ctx.bezierCurveTo(x + 25.9586, y + 17.5991, x + 26.173, y + 17.2854, x + 26.1044, y + 16.9728);
    ctx.lineTo(x + 25.583, y + 14.5988);
    ctx.bezierCurveTo(x + 25.3922, y + 13.7297, x + 24.4902, y + 13.1891, x + 23.5629, y + 13.3927);
    ctx.bezierCurveTo(x + 23.3775, y + 13.4334, x + 23.2646, y + 13.6026, x + 23.303, y + 13.7775);
    ctx.lineTo(x + 24.1583, y + 17.6723);
    ctx.bezierCurveTo(x + 24.1956, y + 17.8418, x + 24.3754, y + 17.9523, x + 24.5555, y + 17.9128);
    ctx.lineTo(x + 24.5544, y + 17.9075);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();

  }

  drawElementFolder(ctx, scale, x, y, rotationAngle) {
    const scaleFactor = scale;
    // Ajustar las coordenadas x y en función del factor de escala
    x = x / scaleFactor;
    y = y / scaleFactor;

    // console.log("drawElementFolder xx :",x)
    // console.log("drawElementFolder yy :",y)

    ctx.save();
    ctx.strokeStyle="rgba(0,0,0,0)";
    ctx.miterLimit=4;
    ctx.fillStyle="rgba(0,0,0,0)";
    ctx.scale(scaleFactor, scaleFactor);
    ctx.save();
    ctx.fillStyle="rgba(0,0,0,0)";

    ctx.beginPath();
    ctx.moveTo(x + 0, y + 0);
    ctx.lineTo(x + 55.2253, y + 0);
    ctx.bezierCurveTo(x + 55.2253, y + 0, x + 55.2253, y + 0, x + 55.2253, y + 0);
    ctx.lineTo(x + 55.2253, y + 41.3651);
    ctx.bezierCurveTo(x + 55.2253, y + 41.3651, x + 55.2253, y + 41.3651, x + 55.2253, y + 41.3651);
    ctx.lineTo(x + 0, y + 41.3651);
    ctx.bezierCurveTo(x + 0, y + 41.3651, x + 0, y + 41.3651, x + 0, y + 41.3651);
    ctx.lineTo(x + 0, y + 0);
    ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
    ctx.closePath();

    ctx.translate(x + 0, y + 11.2969);
    ctx.rotate(rotationAngle * Math.PI / 180);
    ctx.translate(-x - 0, -y - 11.2969);


    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;
    ctx.beginPath();
    ctx.moveTo(x + 14.725, y + 49.5865);
    ctx.bezierCurveTo(x + 14.8227, y + 50.0542, x + 15.2831, y + 50.3554, x + 15.7508, y + 50.2577);
    ctx.lineTo(x + 61.6628, y + 40.6618);
    ctx.bezierCurveTo(x + 62.1305, y + 40.5641, x + 62.4317, y + 40.1037, x + 62.3339, y + 39.636);
    ctx.lineTo(x + 54.8868, y + 4.00487);
    ctx.bezierCurveTo(x + 54.7891, y + 3.53719, x + 54.3287, y + 3.236, x + 53.861, y + 3.33375);
    ctx.lineTo(x + 20.262, y + 10.3561);
    ctx.bezierCurveTo(x + 19.7943, y + 10.4538, x + 19.334, y + 10.1527, x + 19.2362, y + 9.68497);
    ctx.lineTo(x + 18.9318, y + 8.22811);
    ctx.bezierCurveTo(x + 18.834, y + 7.76042, x + 18.3736, y + 7.45923, x + 17.906, y + 7.55698);
    ctx.lineTo(x + 7.29814, y + 9.77406);
    ctx.bezierCurveTo(x + 6.83046, y + 9.87181, x + 6.52927, y + 10.3322, x + 6.62702, y + 10.7999);
    ctx.lineTo(x + 14.7341, y + 49.5889);
    ctx.lineTo(x + 14.725, y + 49.5865);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle="white";

    ctx.beginPath();
    ctx.moveTo(x + 52.1249, y + 6.59983);
    ctx.lineTo(x + 11.0719, y + 15.1801);
    ctx.bezierCurveTo(x + 10.4227, y + 15.3158, x + 10.0065, y + 15.952, x + 10.1422, y + 16.6012);
    ctx.lineTo(x + 16.4716, y + 46.885);
    ctx.bezierCurveTo(x + 16.6073, y + 47.5342, x + 17.2435, y + 47.9504, x + 17.8927, y + 47.8148);
    ctx.lineTo(x + 58.9457, y + 39.2345);
    ctx.bezierCurveTo(x + 59.5949, y + 39.0988, x + 60.0112, y + 38.4626, x + 59.8755, y + 37.8134);
    ctx.lineTo(x + 53.546, y + 7.52959);
    ctx.bezierCurveTo(x + 53.4104, y + 6.88042, x + 52.7741, y + 6.46415, x + 52.1249, y + 6.59983);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 11.0708, y + 15.1803);
    ctx.lineTo(x + 16.2112, y + 14.106);
    ctx.lineTo(x + 23.032, y + 46.7406);
    ctx.lineTo(x + 17.8916, y + 47.815);
    ctx.bezierCurveTo(x + 17.2418, y + 47.9508, x + 16.6063, y + 47.535, x + 16.4705, y + 46.8852);
    ctx.lineTo(x + 10.1402, y + 16.5973);
    ctx.bezierCurveTo(x + 10.0044, y + 15.9475, x + 10.4202, y + 15.312, x + 11.07, y + 15.1762);
    ctx.lineTo(x + 11.0708, y + 15.1803);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 14.6779, y + 49.8431);
    ctx.lineTo(x + 3.79704, y + 29.301);
    ctx.bezierCurveTo(x + 3.52644, y + 28.7917, x + 3.82286, y + 28.1639, x + 4.38575, y + 28.0463);
    ctx.lineTo(x + 38.2041, y + 20.9781);
    ctx.bezierCurveTo(x + 38.7794, y + 20.8578, x + 39.0715, y + 20.2093, x + 38.7826, y + 19.6952);
    ctx.lineTo(x + 37.2956, y + 17.0859);
    ctx.bezierCurveTo(x + 37.0026, y + 16.5726, x + 37.2947, y + 15.9241, x + 37.8741, y + 15.803);
    ctx.lineTo(x + 47.8073, y + 13.7269);
    ctx.bezierCurveTo(x + 48.1881, y + 13.6473, x + 48.5761, y + 13.8297, x + 48.7557, y + 14.1723);
    ctx.lineTo(x + 61.9904, y + 39.4665);
    ctx.bezierCurveTo(x + 62.2568, y + 39.9767, x + 61.9637, y + 40.5995, x + 61.3967, y + 40.718);
    ctx.lineTo(x + 15.6212, y + 50.2853);
    ctx.bezierCurveTo(x + 15.2405, y + 50.3649, x + 14.8566, y + 50.1816, x + 14.6779, y + 49.8431);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();

  }

  drawElementFirstAidKitScale(ctx, scale, x, y) {
    const scaleFactor = scale; // El factor de escala que estás utilizando

    // Ajustar las coordenadas x e y en función del factor de escala
    const scaledX = x / scaleFactor;
    const scaledY = y / scaleFactor;

    // console.log("scaledX", scaledX)
    // console.log("scaledY", scaledY)

    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";

    ctx.scale(scaleFactor, scaleFactor);
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";

    ctx.beginPath();
    ctx.moveTo(scaledX + 0, scaledY + 0);
    ctx.lineTo(scaledX + 123, scaledY + 0);
    ctx.bezierCurveTo(scaledX + 123, scaledY + 0, scaledX + 123, scaledY + 0, scaledX + 123, scaledY + 0);
    ctx.lineTo(scaledX + 123, scaledY + 78);
    ctx.bezierCurveTo(scaledX + 123, scaledY + 78, scaledX + 123, scaledY + 78, scaledX + 123, scaledY + 78);
    ctx.lineTo(scaledX + 0, scaledY + 78);
    ctx.bezierCurveTo(scaledX + 0, scaledY + 78, scaledX + 0, scaledY + 78, scaledX + 0, scaledY + 78);
    ctx.lineTo(scaledX + 0, scaledY + 0);
    ctx.bezierCurveTo(scaledX + 0, scaledY + 0, scaledX + 0, scaledY + 0, scaledX + 0, scaledY + 0);
    ctx.closePath();
    ctx.clip();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 87.0206, scaledY + 5.08095);
    ctx.lineTo(scaledX + 102.273, scaledY + 10.5993);
    ctx.bezierCurveTo(scaledX + 103.251, scaledY + 10.9508, scaledX + 103.763, scaledY + 12.032, scaledX + 103.404, scaledY + 13.0136);
    ctx.lineTo(scaledX + 102.453, scaledY + 15.6335);
    ctx.lineTo(scaledX + 98.076, scaledY + 27.6718);
    ctx.bezierCurveTo(scaledX + 97.7235, scaledY + 28.6468, scaledX + 96.6392, scaledY + 29.1575, scaledX + 95.6547, scaledY + 28.7993);
    ctx.lineTo(scaledX + 94.8033, scaledY + 28.4876);
    ctx.bezierCurveTo(scaledX + 93.8255, scaledY + 28.136, scaledX + 93.3133, scaledY + 27.0549, scaledX + 93.6725, scaledY + 26.0733);
    ctx.lineTo(scaledX + 97.4042, scaledY + 15.8059);
    ctx.bezierCurveTo(scaledX + 97.7567, scaledY + 14.8309, scaledX + 97.2512, scaledY + 13.7498, scaledX + 96.2734, scaledY + 13.3917);
    ctx.lineTo(scaledX + 89.8277, scaledY + 11.057);
    ctx.bezierCurveTo(scaledX + 88.8498, scaledY + 10.7054, scaledX + 87.7656, scaledY + 11.2095, scaledX + 87.4064, scaledY + 12.1845);
    ctx.lineTo(scaledX + 83.6747, scaledY + 22.4519);
    ctx.bezierCurveTo(scaledX + 83.3221, scaledY + 23.4269, scaledX + 82.2379, scaledY + 23.9376, scaledX + 81.2534, scaledY + 23.5794);
    ctx.lineTo(scaledX + 80.402, scaledY + 23.2677);
    ctx.bezierCurveTo(scaledX + 79.4241, scaledY + 22.9161, scaledX + 78.9119, scaledY + 21.835, scaledX + 79.2711, scaledY + 20.8534);
    ctx.lineTo(scaledX + 84.606, scaledY + 6.19523);
    ctx.bezierCurveTo(scaledX + 84.9585, scaledY + 5.22023, scaledX + 86.0428, scaledY + 4.70952, scaledX + 87.0272, scaledY + 5.06768);
    ctx.lineTo(scaledX + 87.0206, scaledY + 5.08095);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "white";

    ctx.beginPath();
    ctx.moveTo(scaledX + 121.193, scaledY + 30.1289);
    ctx.lineTo(scaledX + 59.781, scaledY + 7.91405);
    ctx.bezierCurveTo(scaledX + 58.3616, scaledY + 7.4006, scaledX + 56.7935, scaledY + 8.13169, scaledX + 56.2786, scaledY + 9.54697);
    ctx.lineTo(scaledX + 40.7868, scaledY + 52.126);
    ctx.bezierCurveTo(scaledX + 40.2718, scaledY + 53.5413, scaledX + 41.005, scaledY + 55.1048, scaledX + 42.4244, scaledY + 55.6182);
    ctx.lineTo(scaledX + 103.836, scaledY + 77.8331);
    ctx.bezierCurveTo(scaledX + 105.256, scaledY + 78.3465, scaledX + 106.824, scaledY + 77.6154, scaledX + 107.339, scaledY + 76.2001);
    ctx.lineTo(scaledX + 122.83, scaledY + 33.6211);
    ctx.bezierCurveTo(scaledX + 123.345, scaledY + 32.2059, scaledX + 122.612, scaledY + 30.6423, scaledX + 121.193, scaledY + 30.1289);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 118.308, scaledY + 32.4392);
    ctx.lineTo(scaledX + 60.5235, scaledY + 11.5363);
    ctx.bezierCurveTo(scaledX + 59.6152, scaledY + 11.2078, scaledX + 58.6118, scaledY + 11.6756, scaledX + 58.2823, scaledY + 12.5812);
    ctx.lineTo(scaledX + 44.2741, scaledY + 51.0825);
    ctx.bezierCurveTo(scaledX + 43.9446, scaledY + 51.9882, scaledX + 44.4138, scaledY + 52.9887, scaledX + 45.322, scaledY + 53.3172);
    ctx.lineTo(scaledX + 103.107, scaledY + 74.2201);
    ctx.bezierCurveTo(scaledX + 104.015, scaledY + 74.5487, scaledX + 105.019, scaledY + 74.0808, scaledX + 105.348, scaledY + 73.1752);
    ctx.lineTo(scaledX + 119.356, scaledY + 34.6739);
    ctx.bezierCurveTo(scaledX + 119.686, scaledY + 33.7683, scaledX + 119.217, scaledY + 32.7677, scaledX + 118.308, scaledY + 32.4392);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 119.946, scaledY + 33.0335);
    ctx.lineTo(scaledX + 89.4121, scaledY + 21.9883);
    ctx.lineTo(scaledX + 74.2107, scaledY + 63.7692);
    ctx.lineTo(scaledX + 104.745, scaledY + 74.8144);
    ctx.lineTo(scaledX + 119.946, scaledY + 33.0335);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.secondaryLightFirstVariant}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 76.2235, scaledY + 58.2467);
    ctx.lineTo(scaledX + 87.4053, scaledY + 27.511);
    ctx.bezierCurveTo(scaledX + 78.8909, scaledY + 24.4335, scaledX + 69.4918, scaledY + 28.8177, scaledX + 66.3986, scaledY + 37.3075);
    ctx.bezierCurveTo(scaledX + 63.3122, scaledY + 45.7973, scaledX + 67.7091, scaledY + 55.1692, scaledX + 76.2235, scaledY + 58.2534);
    ctx.lineTo(scaledX + 76.2235, scaledY + 58.2467);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.secondaryColor}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 87.4064, scaledY + 27.5066);
    ctx.lineTo(scaledX + 76.2246, scaledY + 58.2423);
    ctx.bezierCurveTo(scaledX + 84.739, scaledY + 61.3199, scaledX + 94.1381, scaledY + 56.9357, scaledX + 97.2313, scaledY + 48.4459);
    ctx.bezierCurveTo(scaledX + 100.318, scaledY + 39.9561, scaledX + 95.9208, scaledY + 30.5842, scaledX + 87.4064, scaledY + 27.5);
    ctx.lineTo(scaledX + 87.4064, scaledY + 27.5066);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "white";

    ctx.beginPath();
    ctx.moveTo(scaledX + 90.0523, scaledY + 43.0072);
    ctx.lineTo(scaledX + 85.7152, scaledY + 41.4419);
    ctx.bezierCurveTo(scaledX + 85.4558, scaledY + 41.349, scaledX + 85.3228, scaledY + 41.0638, scaledX + 85.4159, scaledY + 40.8052);
    ctx.lineTo(scaledX + 87.0789, scaledY + 36.2419);
    ctx.bezierCurveTo(scaledX + 87.172, scaledY + 35.9832, scaledX + 87.039, scaledY + 35.698, scaledX + 86.7795, scaledY + 35.6052);
    ctx.lineTo(scaledX + 82.662, scaledY + 34.1195);
    ctx.bezierCurveTo(scaledX + 82.4026, scaledY + 34.0266, scaledX + 82.1166, scaledY + 34.1592, scaledX + 82.0234, scaledY + 34.4179);
    ctx.lineTo(scaledX + 80.3605, scaledY + 38.9812);
    ctx.bezierCurveTo(scaledX + 80.2673, scaledY + 39.2399, scaledX + 79.9813, scaledY + 39.3725, scaledX + 79.7219, scaledY + 39.2797);
    ctx.lineTo(scaledX + 75.3849, scaledY + 37.7144);
    ctx.bezierCurveTo(scaledX + 75.1254, scaledY + 37.6215, scaledX + 74.8394, scaledY + 37.7541, scaledX + 74.7463, scaledY + 38.0128);
    ctx.lineTo(scaledX + 73.2563, scaledY + 42.1184);
    ctx.bezierCurveTo(scaledX + 73.1631, scaledY + 42.3771, scaledX + 73.2962, scaledY + 42.6623, scaledX + 73.5556, scaledY + 42.7552);
    ctx.lineTo(scaledX + 77.8926, scaledY + 44.3205);
    ctx.bezierCurveTo(scaledX + 78.152, scaledY + 44.4133, scaledX + 78.2851, scaledY + 44.6985, scaledX + 78.192, scaledY + 44.9572);
    ctx.lineTo(scaledX + 76.529, scaledY + 49.5205);
    ctx.bezierCurveTo(scaledX + 76.4359, scaledY + 49.7792, scaledX + 76.5689, scaledY + 50.0644, scaledX + 76.8283, scaledY + 50.1572);
    ctx.lineTo(scaledX + 80.9458, scaledY + 51.6429);
    ctx.bezierCurveTo(scaledX + 81.2053, scaledY + 51.7358, scaledX + 81.4913, scaledY + 51.6031, scaledX + 81.5844, scaledY + 51.3445);
    ctx.lineTo(scaledX + 83.2474, scaledY + 46.7812);
    ctx.bezierCurveTo(scaledX + 83.3405, scaledY + 46.5225, scaledX + 83.6265, scaledY + 46.3899, scaledX + 83.886, scaledY + 46.4827);
    ctx.lineTo(scaledX + 88.223, scaledY + 48.048);
    ctx.bezierCurveTo(scaledX + 88.4824, scaledY + 48.1409, scaledX + 88.7685, scaledY + 48.0082, scaledX + 88.8616, scaledY + 47.7496);
    ctx.lineTo(scaledX + 90.3516, scaledY + 43.6439);
    ctx.bezierCurveTo(scaledX + 90.4447, scaledY + 43.3853, scaledX + 90.3117, scaledY + 43.1001, scaledX + 90.0523, scaledY + 43.0072);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 60.9839, scaledY + 12.6077);
    ctx.lineTo(scaledX + 49.7422, scaledY + 3.24241);
    ctx.bezierCurveTo(scaledX + 49.1302, scaledY + 2.7317, scaledX + 48.3254, scaledY + 2.45312, scaledX + 47.4939, scaledY + 2.45312);
    ctx.lineTo(scaledX + 17.128, scaledY + 2.45312);
    ctx.bezierCurveTo(scaledX + 16.2965, scaledY + 2.45312, scaledX + 15.4917, scaledY + 2.73833, scaledX + 14.8797, scaledY + 3.24241);
    ctx.lineTo(scaledX + 3.6114, scaledY + 12.6077);
    ctx.bezierCurveTo(scaledX + 2.98613, scaledY + 13.1251, scaledX + 2.61362, scaledY + 13.8414, scaledX + 2.56041, scaledY + 14.5975);
    ctx.bezierCurveTo(scaledX + 2.11473, scaledY + 21.1572, scaledX + 1.65575, scaledY + 52.8679, scaledX + 32.281, scaledY + 70.796);
    ctx.lineTo(scaledX + 32.2943, scaledY + 70.796);
    ctx.lineTo(scaledX + 32.3076, scaledY + 70.796);
    ctx.bezierCurveTo(scaledX + 62.9396, scaledY + 52.8679, scaledX + 62.4739, scaledY + 21.1506, scaledX + 62.0283, scaledY + 14.5975);
    ctx.bezierCurveTo(scaledX + 61.975, scaledY + 13.8348, scaledX + 61.6025, scaledY + 13.1251, scaledX + 60.9773, scaledY + 12.6077);
    ctx.lineTo(scaledX + 60.9839, scaledY + 12.6077);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "white";

    ctx.beginPath();
    ctx.moveTo(scaledX + 47.4938, scaledY + 2.45408);
    ctx.bezierCurveTo(scaledX + 48.3253, scaledY + 2.45408, scaledX + 49.1302, scaledY + 2.73929, scaledX + 49.7421, scaledY + 3.24337);
    ctx.lineTo(scaledX + 60.9838, scaledY + 12.6087);
    ctx.bezierCurveTo(scaledX + 61.6091, scaledY + 13.126, scaledX + 61.9816, scaledY + 13.8357, scaledX + 62.0348, scaledY + 14.5985);
    ctx.bezierCurveTo(scaledX + 62.4805, scaledY + 21.1515, scaledX + 62.9395, scaledY + 52.8689, scaledX + 32.3142, scaledY + 70.7969);
    ctx.lineTo(scaledX + 32.3009, scaledY + 70.7969);
    ctx.lineTo(scaledX + 32.2876, scaledY + 70.7969);
    ctx.bezierCurveTo(scaledX + 1.65566, scaledY + 52.8689, scaledX + 2.11464, scaledY + 21.1582, scaledX + 2.56032, scaledY + 14.6051);
    ctx.bezierCurveTo(scaledX + 2.61353, scaledY + 13.8423, scaledX + 2.98604, scaledY + 13.1327, scaledX + 3.61132, scaledY + 12.6153);
    ctx.lineTo(scaledX + 14.8796, scaledY + 3.24337);
    ctx.bezierCurveTo(scaledX + 15.4916, scaledY + 2.73265, scaledX + 16.2965, scaledY + 2.45408, scaledX + 17.1279, scaledY + 2.45408);
    ctx.lineTo(scaledX + 47.4938, scaledY + 2.45408);
    ctx.closePath();
    ctx.moveTo(scaledX + 47.4938, scaledY + 0);
    ctx.lineTo(scaledX + 17.1279, scaledY + 0);
    ctx.bezierCurveTo(scaledX + 15.7177, scaledY + 0, scaledX + 14.3608, scaledY + 0.484184, scaledX + 13.3098, scaledY + 1.35969);
    ctx.lineTo(scaledX + 2.03482, scaledY + 10.725);
    ctx.bezierCurveTo(scaledX + 0.890697, scaledY + 11.6801, scaledX + 0.205553, scaledY + 12.9934, scaledX + 0.105775, scaledY + 14.4327);
    ctx.bezierCurveTo(scaledX + -0.33325, scaledY + 20.9128, scaledX + -0.911964, scaledY + 54.1689, scaledX + 30.9838, scaledY + 72.8796);
    ctx.lineTo(scaledX + 32.2809, scaledY + 73.7286);
    ctx.lineTo(scaledX + 33.598, scaledY + 72.8862);
    ctx.bezierCurveTo(scaledX + 65.5071, scaledY + 54.1755, scaledX + 64.9217, scaledY + 20.9128, scaledX + 64.4827, scaledY + 14.4327);
    ctx.bezierCurveTo(scaledX + 64.3829, scaledY + 12.9934, scaledX + 63.7044, scaledY + 11.6801, scaledX + 62.5603, scaledY + 10.725);
    ctx.lineTo(scaledX + 51.3186, scaledY + 1.35969);
    ctx.bezierCurveTo(scaledX + 50.261, scaledY + 0.484184, scaledX + 48.904, scaledY + 0, scaledX + 47.4938, scaledY + 0);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 62.0347, scaledY + 14.5975);
    ctx.bezierCurveTo(scaledX + 61.9815, scaledY + 13.8348, scaledX + 61.609, scaledY + 13.1251, scaledX + 60.9837, scaledY + 12.6077);
    ctx.lineTo(scaledX + 49.742, scaledY + 3.24241);
    ctx.bezierCurveTo(scaledX + 49.13, scaledY + 2.7317, scaledX + 48.3252, scaledY + 2.45312, scaledX + 47.4937, scaledY + 2.45312);
    ctx.lineTo(scaledX + 32.3008, scaledY + 2.45312);
    ctx.lineTo(scaledX + 32.3008, scaledY + 70.8093);
    ctx.lineTo(scaledX + 32.3141, scaledY + 70.8093);
    ctx.bezierCurveTo(scaledX + 62.946, scaledY + 52.8679, scaledX + 62.4804, scaledY + 21.1572, scaledX + 62.0347, scaledY + 14.6041);
    ctx.lineTo(scaledX + 62.0347, scaledY + 14.5975);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.secondaryLightFirstVariant}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 32.2938, scaledY + 52.8829);
    ctx.lineTo(scaledX + 32.2938, scaledY + 10.9844);
    ctx.bezierCurveTo(scaledX + 20.6929, scaledY + 10.9844, scaledX + 11.2871, scaledY + 20.3629, scaledX + 11.2871, scaledY + 31.9303);
    ctx.bezierCurveTo(scaledX + 11.2871, scaledY + 43.4976, scaledX + 20.6929, scaledY + 52.8762, scaledX + 32.2938, scaledY + 52.8762);
    ctx.lineTo(scaledX + 32.2938, scaledY + 52.8829);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = `${this.brandColors.secondaryColor}`;

    ctx.beginPath();
    ctx.moveTo(scaledX + 32.2949, scaledY + 10.9844);
    ctx.lineTo(scaledX + 32.2949, scaledY + 52.8829);
    ctx.bezierCurveTo(scaledX + 43.8958, scaledY + 52.8829, scaledX + 53.3016, scaledY + 43.5043, scaledX + 53.3016, scaledY + 31.9369);
    ctx.bezierCurveTo(scaledX + 53.3016, scaledY + 20.3696, scaledX + 43.8958, scaledY + 10.991, scaledX + 32.2949, scaledY + 10.991);
    ctx.lineTo(scaledX + 32.2949, scaledY + 10.9844);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "white";

    ctx.beginPath();
    ctx.moveTo(scaledX + 42.2861, scaledY + 28.4988);
    ctx.lineTo(scaledX + 36.3792, scaledY + 28.4988);
    ctx.bezierCurveTo(scaledX + 36.0266, scaledY + 28.4988, scaledX + 35.7406, scaledY + 28.2136, scaledX + 35.7406, scaledY + 27.8621);
    ctx.lineTo(scaledX + 35.7406, scaledY + 21.6406);
    ctx.bezierCurveTo(scaledX + 35.7406, scaledY + 21.2891, scaledX + 35.4546, scaledY + 21.0039, scaledX + 35.102, scaledY + 21.0039);
    ctx.lineTo(scaledX + 29.4945, scaledY + 21.0039);
    ctx.bezierCurveTo(scaledX + 29.1419, scaledY + 21.0039, scaledX + 28.8559, scaledY + 21.2891, scaledX + 28.8559, scaledY + 21.6406);
    ctx.lineTo(scaledX + 28.8559, scaledY + 27.8621);
    ctx.bezierCurveTo(scaledX + 28.8559, scaledY + 28.2136, scaledX + 28.5699, scaledY + 28.4988, scaledX + 28.2173, scaledY + 28.4988);
    ctx.lineTo(scaledX + 22.3105, scaledY + 28.4988);
    ctx.bezierCurveTo(scaledX + 21.9579, scaledY + 28.4988, scaledX + 21.6719, scaledY + 28.784, scaledX + 21.6719, scaledY + 29.1355);
    ctx.lineTo(scaledX + 21.6719, scaledY + 34.7269);
    ctx.bezierCurveTo(scaledX + 21.6719, scaledY + 35.0784, scaledX + 21.9579, scaledY + 35.3636, scaledX + 22.3105, scaledY + 35.3636);
    ctx.lineTo(scaledX + 28.2173, scaledY + 35.3636);
    ctx.bezierCurveTo(scaledX + 28.5699, scaledY + 35.3636, scaledX + 28.8559, scaledY + 35.6488, scaledX + 28.8559, scaledY + 36.0003);
    ctx.lineTo(scaledX + 28.8559, scaledY + 42.2218);
    ctx.bezierCurveTo(scaledX + 28.8559, scaledY + 42.5733, scaledX + 29.1419, scaledY + 42.8585, scaledX + 29.4945, scaledY + 42.8585);
    ctx.lineTo(scaledX + 35.102, scaledY + 42.8585);
    ctx.bezierCurveTo(scaledX + 35.4546, scaledY + 42.8585, scaledX + 35.7406, scaledY + 42.5733, scaledX + 35.7406, scaledY + 42.2218);
    ctx.lineTo(scaledX + 35.7406, scaledY + 36.0003);
    ctx.bezierCurveTo(scaledX + 35.7406, scaledY + 35.6488, scaledX + 36.0266, scaledY + 35.3636, scaledX + 36.3792, scaledY + 35.3636);
    ctx.lineTo(scaledX + 42.2861, scaledY + 35.3636);
    ctx.bezierCurveTo(scaledX + 42.6386, scaledY + 35.3636, scaledX + 42.9246, scaledY + 35.0784, scaledX + 42.9246, scaledY + 34.7269);
    ctx.lineTo(scaledX + 42.9246, scaledY + 29.1355);
    ctx.bezierCurveTo(scaledX + 42.9246, scaledY + 28.784, scaledX + 42.6386, scaledY + 28.4988, scaledX + 42.2861, scaledY + 28.4988);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();
  }

  drawElementNote(ctx, scale, x, y) {
    x = x / scale;
    y = y / scale;

    ctx.save();
    ctx.strokeStyle="rgba(0,0,0,0)";
    ctx.miterLimit=4;

    ctx.fillStyle="rgba(0,0,0,0)";
    ctx.scale(scale, scale);
    ctx.save();
    ctx.fillStyle="rgba(0,0,0,0)";

    // ctx.translate(x + 0, y + 7.37891);
    // ctx.rotate(-0.13707728678700865);
    ctx.beginPath();
    ctx.moveTo(x + 0, y + 0);
    ctx.lineTo(x + 54.0058, y + 0);
    ctx.bezierCurveTo(x + 54.0058, y + 0, x + 54.0058, y + 0, x + 54.0058, y + 0);
    ctx.lineTo(x + 54.0058, y + 65.8013);
    ctx.bezierCurveTo(x + 54.0058, y + 65.8013, x + 54.0058, y + 65.8013, x + 54.0058, y + 65.8013);
    ctx.lineTo(x + 0, y + 65.8013);
    ctx.bezierCurveTo(x + 0, y + 65.8013, x + 0, y + 65.8013, x + 0, y + 65.8013);
    ctx.lineTo(x + 0, y + 0);
    ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
    ctx.closePath();
    // ctx.rotate(0.13707728678700865);
    // ctx.translate(x + 0, y + -7.37891);
    // ctx.clip();
    ctx.translate(x + 0, y + 7.37891);
    ctx.rotate(-0.13707728678700865);
    // ctx.rotate(rotationAngle * Math.PI / 180);
    ctx.translate(-x - 0, -y - 7.37891);


    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 46.6589, y + 0.942671);
    ctx.lineTo(x + 2.6771, y + 7.00962);
    ctx.bezierCurveTo(x + 1.19858, y + 7.21357, x + 0.165335, y + 8.57749, x + 0.369286, y + 10.056);
    ctx.lineTo(x + 8.62235, y + 69.8858);
    ctx.bezierCurveTo(x + 8.8263, y + 71.3643, x + 10.1902, y + 72.3976, x + 11.6687, y + 72.1936);
    ctx.lineTo(x + 55.6505, y + 66.1267);
    ctx.bezierCurveTo(x + 57.129, y + 65.9227, x + 58.1623, y + 64.5588, x + 57.9583, y + 63.0803);
    ctx.lineTo(x + 49.7053, y + 3.25049);
    ctx.bezierCurveTo(x + 49.5013, y + 1.77197, x + 48.1374, y + 0.738719, x + 46.6589, y + 0.942671);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 2.6771, y + 7.00962);
    ctx.lineTo(x + 46.6589, y + 0.942671);
    ctx.bezierCurveTo(x + 48.1342, y + 0.739156, x + 49.5018, y + 1.77513, x + 49.7053, y + 3.25049);
    ctx.lineTo(x + 50.523, y + 9.17875);
    ctx.lineTo(x + 1.18704, y + 15.9843);
    ctx.lineTo(x + 0.369286, y + 10.056);
    ctx.bezierCurveTo(x + 0.165772, y + 8.58065, x + 1.20175, y + 7.21313, x + 2.6771, y + 7.00962);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle="#EAF6FF";

    ctx.beginPath();
    ctx.moveTo(x + 1.12891, y + 15.5664);
    ctx.lineTo(x + 5.94739, y + 50.4975);
    ctx.bezierCurveTo(x + 5.95997, y + 50.5887, x + 5.98402, y + 50.6838, x + 6.01269, y + 50.7728);
    ctx.lineTo(x + 7.1958, y + 54.3162);
    ctx.bezierCurveTo(x + 8.46345, y + 58.1159, x + 10.1477, y + 61.7652, x + 12.2175, y + 65.1972);
    ctx.bezierCurveTo(x + 12.5359, y + 65.7219, x + 13.1327, y + 66.0059, x + 13.7336, y + 65.923);
    ctx.lineTo(x + 60.1671, y + 59.5178);
    ctx.bezierCurveTo(x + 61.1811, y + 59.3779, x + 61.7753, y + 58.2955, x + 61.3463, y + 57.3652);
    ctx.lineTo(x + 56.115, y + 45.9554);
    ctx.bezierCurveTo(x + 56.0528, y + 45.8219, x + 56.0121, y + 45.6854, x + 55.9921, y + 45.5405);
    ctx.lineTo(x + 50.5944, y + 8.74849);
    ctx.lineTo(x + 1.12965, y + 15.5718);
    ctx.lineTo(x + 1.12891, y + 15.5664);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 9.26707, y + 41.7013);
    ctx.lineTo(x + 10.181, y + 48.327);
    ctx.bezierCurveTo(x + 10.1914, y + 48.4021, x + 10.2079, y + 48.4818, x + 10.2343, y + 48.5547);
    ctx.lineTo(x + 11.2158, y + 51.5082);
    ctx.bezierCurveTo(x + 12.2705, y + 54.6757, x + 13.6743, y + 57.7186, x + 15.4029, y + 60.5799);
    ctx.bezierCurveTo(x + 15.6651, y + 61.0139, x + 16.1625, y + 61.2514, x + 16.6668, y + 61.1819);
    ctx.lineTo(x + 55.3803, y + 55.8417);
    ctx.bezierCurveTo(x + 56.2279, y + 55.7247, x + 56.7217, y + 54.8256, x + 56.3631, y + 54.0496);
    ctx.lineTo(x + 52.0058, y + 44.5366);
    ctx.bezierCurveTo(x + 51.958, y + 44.4284, x + 51.9195, y + 44.308, x + 51.9033, y + 44.19);
    ctx.lineTo(x + 50.5351, y + 35.9759);
    ctx.bezierCurveTo(x + 50.4302, y + 35.3343, x + 49.8341, y + 34.8971, x + 49.1903, y + 34.986);
    ctx.lineTo(x + 10.2892, y + 40.3521);
    ctx.bezierCurveTo(x + 9.63464, y + 40.4424, x + 9.17678, y + 41.0468, x + 9.26707, y + 41.7013);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 46.4123, y + 12.8691);
    ctx.lineTo(x + 28.6007, y + 15.3261);
    ctx.bezierCurveTo(x + 28.1859, y + 15.3833, x + 27.896, y + 15.766, x + 27.9532, y + 16.1808);
    ctx.lineTo(x + 30.5234, y + 34.8132);
    ctx.bezierCurveTo(x + 30.5806, y + 35.228, x + 30.9633, y + 35.5179, x + 31.3781, y + 35.4607);
    ctx.lineTo(x + 49.1897, y + 33.0037);
    ctx.bezierCurveTo(x + 49.6045, y + 32.9465, x + 49.8944, y + 32.5638, x + 49.8372, y + 32.149);
    ctx.lineTo(x + 47.267, y + 13.5166);
    ctx.bezierCurveTo(x + 47.2098, y + 13.1018, x + 46.8271, y + 12.8119, x + 46.4123, y + 12.8691);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 27.8756, y + 32.7831);
    ctx.lineTo(x + 9.15734, y + 35.3651);
    ctx.bezierCurveTo(x + 8.74252, y + 35.4224, x + 8.45264, y + 35.805, x + 8.50986, y + 36.2198);
    ctx.lineTo(x + 8.72965, y + 37.8132);
    ctx.bezierCurveTo(x + 8.78687, y + 38.228, x + 9.16954, y + 38.5179, x + 9.58435, y + 38.4607);
    ctx.lineTo(x + 28.3026, y + 35.8787);
    ctx.bezierCurveTo(x + 28.7174, y + 35.8214, x + 29.0073, y + 35.4388, x + 28.9501, y + 35.024);
    ctx.lineTo(x + 28.7303, y + 33.4306);
    ctx.bezierCurveTo(x + 28.6731, y + 33.0158, x + 28.2904, y + 32.7259, x + 27.8756, y + 32.7831);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 27.0924, y + 27.1073);
    ctx.lineTo(x + 8.37414, y + 29.6894);
    ctx.bezierCurveTo(x + 7.95932, y + 29.7466, x + 7.66943, y + 30.1292, x + 7.72665, y + 30.5441);
    ctx.lineTo(x + 7.94645, y + 32.1374);
    ctx.bezierCurveTo(x + 8.00367, y + 32.5523, x + 8.38633, y + 32.8422, x + 8.80115, y + 32.7849);
    ctx.lineTo(x + 27.5194, y + 30.2029);
    ctx.bezierCurveTo(x + 27.9342, y + 30.1457, x + 28.2241, y + 29.763, x + 28.1669, y + 29.3482);
    ctx.lineTo(x + 27.9471, y + 27.7548);
    ctx.bezierCurveTo(x + 27.8899, y + 27.34, x + 27.5072, y + 27.0501, x + 27.0924, y + 27.1073);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 26.3092, y + 21.4237);
    ctx.lineTo(x + 7.59094, y + 24.0058);
    ctx.bezierCurveTo(x + 7.17612, y + 24.063, x + 6.88623, y + 24.4456, x + 6.94345, y + 24.8605);
    ctx.lineTo(x + 7.16325, y + 26.4539);
    ctx.bezierCurveTo(x + 7.22047, y + 26.8687, x + 7.60313, y + 27.1586, x + 8.01795, y + 27.1013);
    ctx.lineTo(x + 26.7362, y + 24.5193);
    ctx.bezierCurveTo(x + 27.151, y + 24.4621, x + 27.4409, y + 24.0794, x + 27.3837, y + 23.6646);
    ctx.lineTo(x + 27.1639, y + 22.0712);
    ctx.bezierCurveTo(x + 27.1067, y + 21.6564, x + 26.724, y + 21.3665, x + 26.3092, y + 21.4237);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 25.526, y + 15.7479);
    ctx.lineTo(x + 6.80773, y + 18.33);
    ctx.bezierCurveTo(x + 6.39292, y + 18.3872, x + 6.10303, y + 18.7699, x + 6.16025, y + 19.1847);
    ctx.lineTo(x + 6.38004, y + 20.7781);
    ctx.bezierCurveTo(x + 6.43726, y + 21.1929, x + 6.81993, y + 21.4828, x + 7.23474, y + 21.4256);
    ctx.lineTo(x + 25.953, y + 18.8435);
    ctx.bezierCurveTo(x + 26.3678, y + 18.7863, x + 26.6577, y + 18.4036, x + 26.6005, y + 17.9888);
    ctx.lineTo(x + 26.3807, y + 16.3954);
    ctx.bezierCurveTo(x + 26.3235, y + 15.9806, x + 25.9408, y + 15.6907, x + 25.526, y + 15.7479);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 50.1016, y + 6.12026);
    ctx.lineTo(x + 0.765625, y + 12.9258);
    ctx.lineTo(x + 1.12973, y + 15.5653);
    ctx.lineTo(x + 50.4657, y + 8.75981);
    ctx.lineTo(x + 50.1016, y + 6.12026);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();

  }

  drawElementVitalSignsMonitor(ctx,scale, x, y) {
    x = x / scale;
    y = y / scale;

    ctx.save();
    ctx.strokeStyle="rgba(0,0,0,0)";
    ctx.miterLimit=4;
    ctx.fillStyle="rgba(0,0,0,0)";
    ctx.scale(scale, scale);
    ctx.save();
    ctx.fillStyle="rgba(0,0,0,0)";

    // ctx.translate(x + 0.1875, y + 8.70703);
    // ctx.rotate(-0.17881596318382703);
    ctx.beginPath();
    ctx.moveTo(x + 0, y + 0);
    ctx.lineTo(x + 48.9553, y + 0);
    ctx.bezierCurveTo(x + 48.9553, y + 0, x + 48.9553, y + 0, x + 48.9553, y + 0);
    ctx.lineTo(x + 48.9553, y + 41.9617);
    ctx.bezierCurveTo(x + 48.9553, y + 41.9617, x + 48.9553, y + 41.9617, x + 48.9553, y + 41.9617);
    ctx.lineTo(x + 0, y + 41.9617);
    ctx.bezierCurveTo(x + 0, y + 41.9617, x + 0, y + 41.9617, x + 0, y + 41.9617);
    ctx.lineTo(x + 0, y + 0);
    ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
    ctx.closePath();

    // ctx.rotate(0.17881596318382703);
    // ctx.translate(x + -0.1875, y + -8.70703);
    // ctx.clip();

    ctx.translate(x + 0.1875, y + 8.70703);
    ctx.rotate(-0.17881596318382703);
    ctx.translate(-x -0.1875, -y -8.70703);

    ctx.save();
    ctx.fillStyle="white";

    ctx.beginPath();
    ctx.moveTo(x + 45.7537, y + 0.471084);
    ctx.lineTo(x + 2.796, y + 8.23555);
    ctx.bezierCurveTo(x + 1.35536, y + 8.49594, x + 0.401365, y + 9.89026, x + 0.66518, y + 11.3498);
    ctx.lineTo(x + 7.17333, y + 47.3568);
    ctx.bezierCurveTo(x + 7.43714, y + 48.8164, x + 8.81887, y + 49.7885, x + 10.2595, y + 49.5281);
    ctx.lineTo(x + 53.2172, y + 41.7637);
    ctx.bezierCurveTo(x + 54.6578, y + 41.5033, x + 55.6118, y + 40.109, x + 55.348, y + 38.6494);
    ctx.lineTo(x + 48.8398, y + 2.64242);
    ctx.bezierCurveTo(x + 48.576, y + 1.18283, x + 47.1943, y + 0.210694, x + 45.7537, y + 0.471084);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 44.7226, y + 2.76779);
    ctx.lineTo(x + 4.56298, y + 10.0265);
    ctx.bezierCurveTo(x + 3.66525, y + 10.1888, x + 3.07077, y + 11.0576, x + 3.23517, y + 11.9672);
    ctx.lineTo(x + 7.70466, y + 36.6951);
    ctx.bezierCurveTo(x + 7.86905, y + 37.6046, x + 8.73007, y + 38.2104, x + 9.6278, y + 38.0481);
    ctx.lineTo(x + 49.7874, y + 30.7894);
    ctx.bezierCurveTo(x + 50.6851, y + 30.6271, x + 51.2796, y + 29.7583, x + 51.1152, y + 28.8487);
    ctx.lineTo(x + 46.6457, y + 4.12085);
    ctx.bezierCurveTo(x + 46.4813, y + 3.21131, x + 45.6203, y + 2.60552, x + 44.7226, y + 2.76779);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 43.476, y + 11.4567);
    ctx.bezierCurveTo(x + 41.5196, y + 10.0803, x + 38.8374, y + 10.5651, x + 37.4832, y + 12.5399);
    ctx.lineTo(x + 36.8895, y + 13.4075);
    ctx.lineTo(x + 36.0422, y + 12.8114);
    ctx.bezierCurveTo(x + 34.0858, y + 11.4349, x + 31.4036, y + 11.9197, x + 30.0493, y + 13.8946);
    ctx.bezierCurveTo(x + 28.6985, y + 15.8688, x + 29.1897, y + 18.5863, x + 31.1467, y + 19.9663);
    ctx.lineTo(x + 39.0927, y + 25.5568);
    ctx.lineTo(x + 43.9881, y + 18.4019);
    ctx.lineTo(x + 43.9846, y + 18.4025);
    ctx.lineTo(x + 44.577, y + 17.5278);
    ctx.bezierCurveTo(x + 45.9277, y + 15.5536, x + 45.4366, y + 12.8361, x + 43.4795, y + 11.4561);
    ctx.lineTo(x + 43.476, y + 11.4567);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 39.0892, y + 25.5567);
    ctx.lineTo(x + 43.9846, y + 18.4018);
    ctx.lineTo(x + 43.9811, y + 18.4025);
    ctx.lineTo(x + 44.5734, y + 17.5277);
    ctx.bezierCurveTo(x + 45.9242, y + 15.5535, x + 45.433, y + 12.836, x + 43.476, y + 11.456);
    ctx.bezierCurveTo(x + 41.5196, y + 10.0796, x + 38.8374, y + 10.5644, x + 37.4831, y + 12.5392);
    ctx.lineTo(x + 36.8895, y + 13.4068);
    ctx.lineTo(x + 39.085, y + 25.5538);
    ctx.lineTo(x + 39.0892, y + 25.5567);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 20.1, y + 29.4544);
    ctx.bezierCurveTo(x + 19.7243, y + 29.5223, x + 19.3394, y + 29.3385, x + 19.1573, y + 28.993);
    ctx.lineTo(x + 13.5331, y + 18.5163);
    ctx.lineTo(x + 12.2296, y + 23.6005);
    ctx.bezierCurveTo(x + 12.1423, y + 23.9395, x + 11.87, y + 24.1981, x + 11.5259, y + 24.2603);
    ctx.lineTo(x + 9.272, y + 24.6677);
    ctx.bezierCurveTo(x + 8.78752, y + 24.7552, x + 8.32231, y + 24.4279, x + 8.23359, y + 23.9371);
    ctx.bezierCurveTo(x + 8.14486, y + 23.4462, x + 8.46606, y + 22.9768, x + 8.95055, y + 22.8892);
    ctx.lineTo(x + 10.6462, y + 22.5827);
    ctx.lineTo(x + 12.3725, y + 15.8463);
    ctx.bezierCurveTo(x + 12.4637, y + 15.4883, x + 12.7564, y + 15.2223, x + 13.1261, y + 15.1812);
    ctx.bezierCurveTo(x + 13.488, y + 15.1378, x + 13.8442, y + 15.3232, x + 14.0189, y + 15.6479);
    ctx.lineTo(x + 19.1353, y + 25.1806);
    ctx.lineTo(x + 19.227, y + 21.6562);
    ctx.bezierCurveTo(x + 19.2367, y + 21.2283, x + 19.5414, y + 20.8684, x + 19.9592, y + 20.7929);
    ctx.lineTo(x + 25.3131, y + 19.8252);
    ctx.bezierCurveTo(x + 25.7976, y + 19.7376, x + 26.2628, y + 20.0649, x + 26.3515, y + 20.5558);
    ctx.bezierCurveTo(x + 26.4403, y + 21.0466, x + 26.1191, y + 21.5161, x + 25.6346, y + 21.6037);
    ctx.lineTo(x + 20.9933, y + 22.4425);
    ctx.lineTo(x + 20.8328, y + 28.5947);
    ctx.bezierCurveTo(x + 20.8234, y + 29.0041, x + 20.542, y + 29.3525, x + 20.1491, y + 29.4456);
    ctx.bezierCurveTo(x + 20.1315, y + 29.4487, x + 20.1175, y + 29.4513, x + 20.1, y + 29.4544);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 48.5829, y + 39.9717);
    ctx.bezierCurveTo(x + 50.4307, y + 39.6377, x + 51.6543, y + 37.8493, x + 51.3159, y + 35.9772);
    ctx.bezierCurveTo(x + 50.9775, y + 34.1051, x + 49.2053, y + 32.8582, x + 47.3575, y + 33.1921);
    ctx.bezierCurveTo(x + 45.5097, y + 33.5261, x + 44.286, y + 35.3145, x + 44.6244, y + 37.1866);
    ctx.bezierCurveTo(x + 44.9628, y + 39.0588, x + 46.735, y + 40.3057, x + 48.5829, y + 39.9717);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 39.0333, y + 34.6955);
    ctx.lineTo(x + 11.8635, y + 39.6064);
    ctx.bezierCurveTo(x + 9.92067, y + 39.9575, x + 8.63412, y + 41.8379, x + 8.9899, y + 43.8062);
    ctx.lineTo(x + 8.99054, y + 43.8098);
    ctx.bezierCurveTo(x + 9.34632, y + 45.7782, x + 11.2097, y + 47.0892, x + 13.1525, y + 46.738);
    ctx.lineTo(x + 40.3223, y + 41.8272);
    ctx.bezierCurveTo(x + 42.2651, y + 41.476, x + 43.5517, y + 39.5957, x + 43.1959, y + 37.6273);
    ctx.lineTo(x + 43.1952, y + 37.6237);
    ctx.bezierCurveTo(x + 42.8395, y + 35.6554, x + 40.9761, y + 34.3443, x + 39.0333, y + 34.6955);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();

  }

  drawElementSimpleAidKit(ctx, x, y) {

    ctx.save();
    ctx.strokeStyle="rgba(0,0,0,0)";
    ctx.miterLimit=4;
    ctx.fillStyle="rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle="rgba(0,0,0,0)";

    ctx.beginPath();
    ctx.moveTo(x + 0, y + 0);
    ctx.lineTo(x + 52, y + 0);
    ctx.bezierCurveTo(x + 52, y + 0, x + 52, y + 0, x + 52, y + 0);
    ctx.lineTo(x + 52, y + 46);
    ctx.bezierCurveTo(x + 52, y + 46, x + 52, y + 46, x + 52, y + 46);
    ctx.lineTo(x + 0, y + 46);
    ctx.bezierCurveTo(x + 0, y + 46, x + 0, y + 46, x + 0, y + 46);
    ctx.lineTo(x + 0, y + 0);
    ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
    ctx.closePath();
    ctx.clip();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 20.1033, y + 0);
    ctx.lineTo(x + 32.0236, y + 0);
    ctx.bezierCurveTo(x + 32.7906, y + 0, x + 33.411, y + 0.618266, x + 33.411, y + 1.38258);
    ctx.lineTo(x + 33.411, y + 3.42724);
    ctx.lineTo(x + 33.411, y + 12.8229);
    ctx.bezierCurveTo(x + 33.411, y + 13.5873, x + 32.7906, y + 14.2055, x + 32.0236, y + 14.2055);
    ctx.lineTo(x + 31.3592, y + 14.2055);
    ctx.bezierCurveTo(x + 30.5922, y + 14.2055, x + 29.9717, y + 13.5873, x + 29.9717, y + 12.8229);
    ctx.lineTo(x + 29.9717, y + 4.80982);
    ctx.bezierCurveTo(x + 29.9717, y + 4.04551, x + 29.3513, y + 3.42724, x + 28.5843, y + 3.42724);
    ctx.lineTo(x + 23.5475, y + 3.42724);
    ctx.bezierCurveTo(x + 22.7805, y + 3.42724, x + 22.16, y + 4.04551, x + 22.16, y + 4.80982);
    ctx.lineTo(x + 22.16, y + 12.8229);
    ctx.bezierCurveTo(x + 22.16, y + 13.5873, x + 21.5396, y + 14.2055, x + 20.7726, y + 14.2055);
    ctx.lineTo(x + 20.1082, y + 14.2055);
    ctx.bezierCurveTo(x + 19.3411, y + 14.2055, x + 18.7207, y + 13.5873, x + 18.7207, y + 12.8229);
    ctx.lineTo(x + 18.7207, y + 1.38258);
    ctx.bezierCurveTo(x + 18.7207, y + 0.618266, x + 19.3411, y + 0, x + 20.1082, y + 0);
    ctx.lineTo(x + 20.1033, y + 0);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle="white";

    ctx.beginPath();
    ctx.moveTo(x + 49.9872, y + 8.75391);
    ctx.lineTo(x + 2.00789, y + 8.75391);
    ctx.bezierCurveTo(x + 0.898964, y + 8.75391, x + 0, y + 9.64972, x + 0, y + 10.7548);
    ctx.lineTo(x + 0, y + 44);
    ctx.bezierCurveTo(x + 0, y + 45.105, x + 0.898964, y + 46.0008, x + 2.00789, y + 46.0008);
    ctx.lineTo(x + 49.9872, y + 46.0008);
    ctx.bezierCurveTo(x + 51.0961, y + 46.0008, x + 51.9951, y + 45.105, x + 51.9951, y + 44);
    ctx.lineTo(x + 51.9951, y + 10.7548);
    ctx.bezierCurveTo(x + 51.9951, y + 9.64972, x + 51.0961, y + 8.75391, x + 49.9872, y + 8.75391);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 48.5713, y + 11.0664);
    ctx.lineTo(x + 3.42548, y + 11.0664);
    ctx.bezierCurveTo(x + 2.71587, y + 11.0664, x + 2.14062, y + 11.6396, x + 2.14062, y + 12.3468);
    ctx.lineTo(x + 2.14062, y + 42.4081);
    ctx.bezierCurveTo(x + 2.14062, y + 43.1153, x + 2.71587, y + 43.6885, x + 3.42548, y + 43.6885);
    ctx.lineTo(x + 48.5713, y + 43.6885);
    ctx.bezierCurveTo(x + 49.2809, y + 43.6885, x + 49.8561, y + 43.1153, x + 49.8561, y + 42.4081);
    ctx.lineTo(x + 49.8561, y + 12.3468);
    ctx.bezierCurveTo(x + 49.8561, y + 11.6396, x + 49.2809, y + 11.0664, x + 48.5713, y + 11.0664);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.primaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 49.8553, y + 11.0664);
    ctx.lineTo(x + 26, y + 11.0664);
    ctx.lineTo(x + 26, y + 43.6885);
    ctx.lineTo(x + 49.8553, y + 43.6885);
    ctx.lineTo(x + 49.8553, y + 11.0664);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

    ctx.beginPath();
    ctx.moveTo(x + 25.9995, y + 39.3745);
    ctx.lineTo(x + 25.9995, y + 15.3789);
    ctx.bezierCurveTo(x + 19.3505, y + 15.3789, x + 13.957, y + 20.7534, x + 13.957, y + 27.3791);
    ctx.bezierCurveTo(x + 13.957, y + 34.0048, x + 19.3505, y + 39.3793, x + 25.9995, y + 39.3793);
    ctx.lineTo(x + 25.9995, y + 39.3745);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle=`${this.brandColors.secondaryColor}`;

    ctx.beginPath();
    ctx.moveTo(x + 26, y + 15.3789);
    ctx.lineTo(x + 26, y + 39.3793);
    ctx.bezierCurveTo(x + 32.649, y + 39.3793, x + 38.0425, y + 34.0048, x + 38.0425, y + 27.3791);
    ctx.bezierCurveTo(x + 38.0425, y + 20.7534, x + 32.649, y + 15.3789, x + 26, y + 15.3789);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle="white";

    ctx.beginPath();
    ctx.moveTo(x + 31.7259, y + 25.411);
    ctx.lineTo(x + 28.3404, y + 25.411);
    ctx.bezierCurveTo(x + 28.1401, y + 25.411, x + 27.974, y + 25.2455, x + 27.974, y + 25.0459);
    ctx.lineTo(x + 27.974, y + 21.4823);
    ctx.bezierCurveTo(x + 27.974, y + 21.2827, x + 27.8127, y + 21.1172, x + 27.6076, y + 21.1172);
    ctx.lineTo(x + 24.393, y + 21.1172);
    ctx.bezierCurveTo(x + 24.1927, y + 21.1172, x + 24.0266, y + 21.2778, x + 24.0266, y + 21.4823);
    ctx.lineTo(x + 24.0266, y + 25.0459);
    ctx.bezierCurveTo(x + 24.0266, y + 25.2455, x + 23.8605, y + 25.411, x + 23.6602, y + 25.411);
    ctx.lineTo(x + 20.2746, y + 25.411);
    ctx.bezierCurveTo(x + 20.0743, y + 25.411, x + 19.9082, y + 25.5716, x + 19.9082, y + 25.7761);
    ctx.lineTo(x + 19.9082, y + 28.9794);
    ctx.bezierCurveTo(x + 19.9082, y + 29.179, x + 20.0694, y + 29.3445, x + 20.2746, y + 29.3445);
    ctx.lineTo(x + 23.6602, y + 29.3445);
    ctx.bezierCurveTo(x + 23.8605, y + 29.3445, x + 24.0266, y + 29.51, x + 24.0266, y + 29.7096);
    ctx.lineTo(x + 24.0266, y + 33.2732);
    ctx.bezierCurveTo(x + 24.0266, y + 33.4728, x + 24.1878, y + 33.6383, x + 24.393, y + 33.6383);
    ctx.lineTo(x + 27.6076, y + 33.6383);
    ctx.bezierCurveTo(x + 27.8079, y + 33.6383, x + 27.974, y + 33.4776, x + 27.974, y + 33.2732);
    ctx.lineTo(x + 27.974, y + 29.7096);
    ctx.bezierCurveTo(x + 27.974, y + 29.51, x + 28.1401, y + 29.3445, x + 28.3404, y + 29.3445);
    ctx.lineTo(x + 31.7259, y + 29.3445);
    ctx.bezierCurveTo(x + 31.9262, y + 29.3445, x + 32.0923, y + 29.1839, x + 32.0923, y + 28.9794);
    ctx.lineTo(x + 32.0923, y + 25.7761);
    ctx.bezierCurveTo(x + 32.0923, y + 25.5765, x + 31.9311, y + 25.411, x + 31.7259, y + 25.411);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();
  }


 drawElementMarket(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 77, y + 0);
  ctx.bezierCurveTo(x + 77, y + 0, x + 77, y + 0, x + 77, y + 0);
  ctx.lineTo(x + 77, y + 69);
  ctx.bezierCurveTo(x + 77, y + 69, x + 77, y + 69, x + 77, y + 69);
  ctx.lineTo(x + 0, y + 69);
  ctx.bezierCurveTo(x + 0, y + 69, x + 0, y + 69, x + 0, y + 69);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 70.6375, y + 17.1836);
  ctx.lineTo(x + 6.36328, y + 17.1836);
  ctx.lineTo(x + 6.36328, y + 69.0077);
  ctx.lineTo(x + 70.6375, y + 69.0077);
  ctx.lineTo(x + 70.6375, y + 17.1836);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 73.0085, y + 62.5625);
  ctx.lineTo(x + 3.99264, y + 62.5625);
  ctx.bezierCurveTo(x + 3.37646, y + 62.5625, x + 2.87695, y + 63.0618, x + 2.87695, y + 63.6777);
  ctx.lineTo(x + 2.87695, y + 67.889);
  ctx.bezierCurveTo(x + 2.87695, y + 68.5049, x + 3.37646, y + 69.0042, x + 3.99264, y + 69.0042);
  ctx.lineTo(x + 73.0085, y + 69.0042);
  ctx.bezierCurveTo(x + 73.6247, y + 69.0042, x + 74.1242, y + 68.5049, x + 74.1242, y + 67.889);
  ctx.lineTo(x + 74.1242, y + 63.6777);
  ctx.bezierCurveTo(x + 74.1242, y + 63.0618, x + 73.6247, y + 62.5625, x + 73.0085, y + 62.5625);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 68.7712, y + 1.16171);
  ctx.lineTo(x + 76.8077, y + 18.4132);
  ctx.bezierCurveTo(x + 77.4294, y + 19.7433, x + 76.4532, y + 21.271, x + 74.9889, y + 21.271);
  ctx.lineTo(x + 2.00999, y + 21.271);
  ctx.bezierCurveTo(x + 0.539837, y + 21.271, x + -0.430581, y + 19.7433, x + 0.191184, y + 18.4132);
  ctx.lineTo(x + 8.22763, y + 1.16171);
  ctx.bezierCurveTo(x + 8.55885, y + 0.453068, x + 9.26778, y + 0, x + 10.0464, y + 0);
  ctx.lineTo(x + 66.9466, y + 0);
  ctx.bezierCurveTo(x + 67.7253, y + 0, x + 68.44, y + 0.453068, x + 68.7654, y + 1.16171);
  ctx.lineTo(x + 68.7712, y + 1.16171);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 63.1336, y + 18.1726);
  ctx.lineTo(x + 70.9144, y + 18.1726);
  ctx.bezierCurveTo(x + 71.7744, y + 18.1726, x + 72.3729, y + 17.4407, x + 72.0766, y + 16.7553);
  ctx.lineTo(x + 66.2831, y + 3.52916);
  ctx.bezierCurveTo(x + 66.103, y + 3.11675, x + 65.6381, y + 2.84375, x + 65.1209, y + 2.84375);
  ctx.lineTo(x + 62.4654, y + 2.84375);
  ctx.bezierCurveTo(x + 61.7622, y + 2.84375, x + 61.1986, y + 3.34329, x + 61.2276, y + 3.94157);
  ctx.lineTo(x + 61.8901, y + 17.1677);
  ctx.bezierCurveTo(x + 61.9191, y + 17.7311, x + 62.4654, y + 18.1726, x + 63.1278, y + 18.1726);
  ctx.lineTo(x + 63.1336, y + 18.1726);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 46.9571, y + 2.83984);
  ctx.lineTo(x + 40.9428, y + 2.83984);
  ctx.bezierCurveTo(x + 40.2571, y + 2.83984, x + 39.7051, y + 3.31034, x + 39.7051, y + 3.89119);
  ctx.lineTo(x + 39.7051, y + 17.1173);
  ctx.bezierCurveTo(x + 39.7051, y + 17.6982, x + 40.2571, y + 18.1687, x + 40.9428, y + 18.1687);
  ctx.lineTo(x + 47.2244, y + 18.1687);
  ctx.bezierCurveTo(x + 47.9159, y + 18.1687, x + 48.4737, y + 17.6865, x + 48.4621, y + 17.0999);
  ctx.lineTo(x + 48.1948, y + 3.87377);
  ctx.bezierCurveTo(x + 48.1832, y + 3.29872, x + 47.6311, y + 2.83984, x + 46.9571, y + 2.83984);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 57.5845, y + 2.83984);
  ctx.lineTo(x + 51.8375, y + 2.83984);
  ctx.bezierCurveTo(x + 51.146, y + 2.83984, x + 50.5882, y + 3.32196, x + 50.5998, y + 3.90862);
  ctx.lineTo(x + 50.8671, y + 17.1347);
  ctx.bezierCurveTo(x + 50.8787, y + 17.7098, x + 51.4307, y + 18.1687, x + 52.1048, y + 18.1687);
  ctx.lineTo(x + 58.2469, y + 18.1687);
  ctx.bezierCurveTo(x + 58.95, y + 18.1687, x + 59.5137, y + 17.6691, x + 59.4846, y + 17.0708);
  ctx.lineTo(x + 58.8222, y + 3.84473);
  ctx.bezierCurveTo(x + 58.7931, y + 3.2813, x + 58.2469, y + 2.83984, x + 57.5845, y + 2.83984);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 40.7868, y + 25.5117);
  ctx.lineTo(x + 14.5565, y + 25.5117);
  ctx.bezierCurveTo(x + 12.7368, y + 25.5117, x + 11.2617, y + 26.9863, x + 11.2617, y + 28.8052);
  ctx.lineTo(x + 11.2617, y + 55.0251);
  ctx.bezierCurveTo(x + 11.2617, y + 56.844, x + 12.7368, y + 58.3185, x + 14.5565, y + 58.3185);
  ctx.lineTo(x + 40.7868, y + 58.3185);
  ctx.bezierCurveTo(x + 42.6065, y + 58.3185, x + 44.0816, y + 56.844, x + 44.0816, y + 55.0251);
  ctx.lineTo(x + 44.0816, y + 28.8052);
  ctx.bezierCurveTo(x + 44.0816, y + 26.9863, x + 42.6065, y + 25.5117, x + 40.7868, y + 25.5117);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 50.2991, y + 36.6523);
  ctx.lineTo(x + 63.3039, y + 36.6523);
  ctx.bezierCurveTo(x + 64.315, y + 36.6523, x + 65.1401, y + 37.4772, x + 65.1401, y + 38.4879);
  ctx.lineTo(x + 65.1401, y + 62.5644);
  ctx.lineTo(x + 48.4629, y + 62.5644);
  ctx.lineTo(x + 48.4629, y + 38.4879);
  ctx.bezierCurveTo(x + 48.4629, y + 37.4772, x + 49.288, y + 36.6523, x + 50.2991, y + 36.6523);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 56.8008, y + 62.5644);
  ctx.lineTo(x + 65.1394, y + 62.5644);
  ctx.lineTo(x + 65.1394, y + 38.4879);
  ctx.bezierCurveTo(x + 65.1394, y + 37.4714, x + 64.3143, y + 36.6523, x + 63.3032, y + 36.6523);
  ctx.lineTo(x + 56.8008, y + 36.6523);
  ctx.lineTo(x + 56.8008, y + 62.5644);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 62.6937, y + 24.4531);
  ctx.lineTo(x + 50.9093, y + 24.4531);
  ctx.bezierCurveTo(x + 49.5582, y + 24.4531, x + 48.4629, y + 25.548, x + 48.4629, y + 26.8985);
  ctx.lineTo(x + 48.4629, y + 29.5821);
  ctx.bezierCurveTo(x + 48.4629, y + 30.9327, x + 49.5582, y + 32.0275, x + 50.9093, y + 32.0275);
  ctx.lineTo(x + 62.6937, y + 32.0275);
  ctx.bezierCurveTo(x + 64.0448, y + 32.0275, x + 65.1401, y + 30.9327, x + 65.1401, y + 29.5821);
  ctx.lineTo(x + 65.1401, y + 26.8985);
  ctx.bezierCurveTo(x + 65.1401, y + 25.548, x + 64.0448, y + 24.4531, x + 62.6937, y + 24.4531);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

 }

 drawElementPaperBag(ctx, scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);

  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x + 0, y + 6.36328);
  // ctx.rotate(-0.14566605203607275);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 43.8492, y + 0);
  ctx.bezierCurveTo(x + 43.8492, y + 0, x + 43.8492, y + 0, x + 43.8492, y + 0);
  ctx.lineTo(x + 43.8492, y + 68.9911);
  ctx.bezierCurveTo(x + 43.8492, y + 68.9911, x + 43.8492, y + 68.9911, x + 43.8492, y + 68.9911);
  ctx.lineTo(x + 0, y + 68.9911);
  ctx.bezierCurveTo(x + 0, y + 68.9911, x + 0, y + 68.9911, x + 0, y + 68.9911);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.14566605203607275);
  // ctx.translate(x + 0, y + -6.36328);
  // ctx.clip();


  ctx.translate(x + 0, y + 6.36328); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x -0, -y - 6.36328); // regresar el origen a su posición






  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 19.6779, y + 3.47795);
  ctx.lineTo(x + 8.6273, y + 5.09914);
  ctx.bezierCurveTo(x + 6.32412, y + 5.43703, x + 4.73094, y + 7.57803, x + 5.06883, y + 9.88121);
  ctx.lineTo(x + 7.89648, y + 29.1556);
  ctx.bezierCurveTo(x + 8.23437, y + 31.4587, x + 10.3754, y + 33.0519, x + 12.6785, y + 32.714);
  ctx.lineTo(x + 23.7292, y + 31.0929);
  ctx.bezierCurveTo(x + 26.0324, y + 30.755, x + 27.6255, y + 28.614, x + 27.2877, y + 26.3108);
  ctx.lineTo(x + 24.46, y + 7.03642);
  ctx.bezierCurveTo(x + 24.1221, y + 4.73325, x + 21.9811, y + 3.14007, x + 19.6779, y + 3.47795);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 37.6994, y + 12.8178);
  ctx.lineTo(x + 26.6488, y + 14.439);
  ctx.bezierCurveTo(x + 24.3456, y + 14.7769, x + 22.7524, y + 16.9179, x + 23.0903, y + 19.2211);
  ctx.lineTo(x + 25.918, y + 38.4954);
  ctx.bezierCurveTo(x + 26.2559, y + 40.7986, x + 28.3969, y + 42.3918, x + 30.7, y + 42.0539);
  ctx.lineTo(x + 41.7507, y + 40.4327);
  ctx.bezierCurveTo(x + 44.0538, y + 40.0948, x + 45.647, y + 37.9538, x + 45.3091, y + 35.6506);
  ctx.lineTo(x + 42.4815, y + 16.3763);
  ctx.bezierCurveTo(x + 42.1436, y + 14.0731, x + 40.0026, y + 12.4799, x + 37.6994, y + 12.8178);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightFourthVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 12.7552, y + 22.7445);
  ctx.lineTo(x + 12.7493, y + 22.7454);
  ctx.bezierCurveTo(x + 8.58492, y + 23.3563, x + 5.70425, y + 27.2275, x + 6.31519, y + 31.3919);
  ctx.lineTo(x + 7.25432, y + 37.7933);
  ctx.bezierCurveTo(x + 7.86526, y + 41.9578, x + 11.7365, y + 44.8384, x + 15.9009, y + 44.2275);
  ctx.lineTo(x + 15.9067, y + 44.2266);
  ctx.bezierCurveTo(x + 20.0712, y + 43.6157, x + 22.9518, y + 39.7445, x + 22.3409, y + 35.5801);
  ctx.lineTo(x + 21.4018, y + 29.1786);
  ctx.bezierCurveTo(x + 20.7908, y + 25.0142, x + 16.9196, y + 22.1336, x + 12.7552, y + 22.7445);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 28.2397, y + 11.2111);
  ctx.bezierCurveTo(x + 25.2067, y + 9.00096, x + 20.9615, y + 9.67148, x + 18.7572, y + 12.7036);
  ctx.bezierCurveTo(x + 16.5471, y + 15.7366, x + 17.2177, y + 19.9818, x + 20.2498, y + 22.1861);
  ctx.bezierCurveTo(x + 20.2498, y + 22.1861, x + 20.4251, y + 22.405, x + 20.6756, y + 22.8515);
  ctx.bezierCurveTo(x + 19.1772, y + 22.8864, x + 17.7686, y + 23.7374, x + 17.0676, y + 25.1409);
  ctx.bezierCurveTo(x + 17.0121, y + 25.2505, x + 16.9624, y + 25.3592, x + 16.9195, y + 25.4729);
  ctx.bezierCurveTo(x + 16.0859, y + 27.5999, x + 17.1293, y + 29.9945, x + 19.2563, y + 30.828);
  ctx.bezierCurveTo(x + 21.2379, y + 31.6053, x + 22.2468, y + 32.8295, x + 22.7012, y + 33.5683);
  ctx.bezierCurveTo(x + 22.7055, y + 33.5975, x + 22.704, y + 33.6276, x + 22.7024, y + 33.6577);
  ctx.bezierCurveTo(x + 22.6498, y + 37.4063, x + 25.6493, y + 40.4865, x + 29.398, y + 40.5391);
  ctx.bezierCurveTo(x + 29.7686, y + 40.5444, x + 30.1292, y + 40.5213, x + 30.4855, y + 40.4691);
  ctx.bezierCurveTo(x + 33.7271, y + 39.9935, x + 36.2329, y + 37.2274, x + 36.2794, y + 33.8436);
  ctx.bezierCurveTo(x + 36.4953, y + 18.0307, x + 29.6227, y + 12.2194, x + 28.2455, y + 11.2102);
  ctx.lineTo(x + 28.2397, y + 11.2111);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 18.7588, y + 12.7035);
  ctx.bezierCurveTo(x + 20.9689, y + 9.67051, x + 25.2149, y + 9.00583, x + 28.2412, y + 11.211);
  ctx.bezierCurveTo(x + 29.6233, y + 12.2134, x + 36.491, y + 18.0315, x + 36.2751, y + 33.8443);
  ctx.bezierCurveTo(x + 36.2277, y + 37.2223, x + 33.7228, y + 39.9943, x + 30.4812, y + 40.4698);
  ctx.bezierCurveTo(x + 30.1249, y + 40.5221, x + 29.7643, y + 40.5451, x + 29.3936, y + 40.5399);
  ctx.lineTo(x + 29.3306, y + 38.8904);
  ctx.bezierCurveTo(x + 28.9771, y + 29.4855, x + 25.4418, y + 20.4758, x + 19.2996, y + 13.3401);
  ctx.lineTo(x + 18.7529, y + 12.7044);
  ctx.lineTo(x + 18.7588, y + 12.7035);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 43.2109, y + 27.8919);
  ctx.lineTo(x + 40.5452, y + 25.9083);
  ctx.bezierCurveTo(x + 40.1635, y + 25.6242, x + 39.6261, y + 25.703, x + 39.3479, y + 26.0839);
  ctx.lineTo(x + 37.2497, y + 28.9036);
  ctx.bezierCurveTo(x + 36.9656, y + 29.2854, x + 36.4283, y + 29.3642, x + 36.0523, y + 29.0793);
  ctx.lineTo(x + 33.0384, y + 26.8365);
  ctx.bezierCurveTo(x + 32.6567, y + 26.5525, x + 32.1193, y + 26.6313, x + 31.8411, y + 27.0122);
  ctx.lineTo(x + 29.7429, y + 29.8319);
  ctx.bezierCurveTo(x + 29.4588, y + 30.2136, x + 28.9215, y + 30.2925, x + 28.5455, y + 30.0075);
  ctx.lineTo(x + 26.0139, y + 28.1236);
  ctx.bezierCurveTo(x + 25.6321, y + 27.8396, x + 25.0947, y + 27.9184, x + 24.8165, y + 28.2993);
  ctx.lineTo(x + 22.8828, y + 30.898);
  ctx.bezierCurveTo(x + 22.5987, y + 31.2797, x + 22.0613, y + 31.3586, x + 21.6854, y + 31.0736);
  ctx.lineTo(x + 18.8256, y + 28.9455);
  ctx.bezierCurveTo(x + 18.4438, y + 28.6614, x + 17.9064, y + 28.7403, x + 17.6282, y + 29.1212);
  ctx.lineTo(x + 15.6297, y + 31.8069);
  ctx.bezierCurveTo(x + 15.3456, y + 32.1887, x + 14.8083, y + 32.2675, x + 14.4323, y + 31.9826);
  ctx.lineTo(x + 11.5524, y + 29.8395);
  ctx.bezierCurveTo(x + 11.1706, y + 29.5554, x + 10.6333, y + 29.6343, x + 10.355, y + 30.0152);
  ctx.lineTo(x + 7.96777, y + 33.2233);
  ctx.bezierCurveTo(x + 7.68369, y + 33.6051, x + 7.14634, y + 33.6839, x + 6.77042, y + 33.399);
  ctx.lineTo(x + 5.31705, y + 32.3175);
  ctx.bezierCurveTo(x + 4.70087, y + 31.8589, x + 3.84542, y + 32.3723, x + 3.95681, y + 33.1316);
  ctx.lineTo(x + 9.23337, y + 69.0987);
  ctx.bezierCurveTo(x + 9.68065, y + 72.1475, x + 12.5146, y + 74.2615, x + 15.5693, y + 73.8134);
  ctx.lineTo(x + 47.9093, y + 69.0689);
  ctx.bezierCurveTo(x + 50.9582, y + 68.6217, x + 53.0722, y + 65.7877, x + 52.624, y + 62.733);
  ctx.lineTo(x + 47.2858, y + 26.3454);
  ctx.bezierCurveTo(x + 47.1752, y + 25.5919, x + 46.2084, y + 25.346, x + 45.749, y + 25.9563);
  ctx.lineTo(x + 44.4433, y + 27.7111);
  ctx.bezierCurveTo(x + 44.1592, y + 28.0928, x + 43.6218, y + 28.1717, x + 43.2459, y + 27.8867);
  ctx.lineTo(x + 43.2109, y + 27.8919);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 45.7151, y + 25.9617);
  ctx.lineTo(x + 44.4093, y + 27.7164);
  ctx.bezierCurveTo(x + 44.1252, y + 28.0982, x + 43.5879, y + 28.177, x + 43.212, y + 27.8921);
  ctx.lineTo(x + 40.5463, y + 25.9085);
  ctx.bezierCurveTo(x + 40.1646, y + 25.6244, x + 39.6272, y + 25.7033, x + 39.349, y + 26.0842);
  ctx.lineTo(x + 37.2508, y + 28.9039);
  ctx.bezierCurveTo(x + 36.9667, y + 29.2856, x + 36.4294, y + 29.3645, x + 36.0534, y + 29.0795);
  ctx.lineTo(x + 33.0395, y + 26.8368);
  ctx.bezierCurveTo(x + 32.6979, y + 26.5826, x + 32.2281, y + 26.6336, x + 31.9312, y + 26.9278);
  ctx.lineTo(x + 38.3191, y + 70.4703);
  ctx.lineTo(x + 47.8803, y + 69.0676);
  ctx.bezierCurveTo(x + 50.9292, y + 68.6203, x + 53.0432, y + 65.7864, x + 52.5951, y + 62.7317);
  ctx.lineTo(x + 47.2568, y + 26.3441);
  ctx.bezierCurveTo(x + 47.1463, y + 25.5906, x + 46.1794, y + 25.3446, x + 45.7201, y + 25.955);
  ctx.lineTo(x + 45.7151, y + 25.9617);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
 }

 drawElementWetWipes(ctx, scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x + 0, y + 12.9922);
  // ctx.rotate(-0.24711069681436515);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 53.1104, y + 0);
  ctx.bezierCurveTo(x + 53.1104, y + 0, x + 53.1104, y + 0, x + 53.1104, y + 0);
  ctx.lineTo(x + 53.1104, y + 70.6936);
  ctx.bezierCurveTo(x + 53.1104, y + 70.6936, x + 53.1104, y + 70.6936, x + 53.1104, y + 70.6936);
  ctx.lineTo(x + 0, y + 70.6936);
  ctx.bezierCurveTo(x + 0, y + 70.6936, x + 0, y + 70.6936, x + 0, y + 70.6936);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.24711069681436515);
  // ctx.translate(x + 0, y + -12.9922);
  // ctx.clip();

  ctx.translate(x + 0, y + 12.9922); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x -0, -y - 12.9922); // regresar el origen a su posición

  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 7.54088, y + 18.3678);
  ctx.lineTo(x + 4.05965, y + 14.3909);
  ctx.bezierCurveTo(x + 3.35062, y + 13.5798, x + 3.44983, y + 12.1235, x + 4.22641, y + 11.9276);
  ctx.lineTo(x + 47.4301, y + 1.02874);
  ctx.bezierCurveTo(x + 48.2186, y + 0.829846, x + 49.0027, y + 2.11377, x + 48.7366, y + 3.15824);
  ctx.lineTo(x + 47.413, y + 8.30939);
  ctx.lineTo(x + 7.06071, y + 18.4889);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 61.4023, y + 63.1331);
  ctx.lineTo(x + 64.8835, y + 67.11);
  ctx.bezierCurveTo(x + 65.5925, y + 67.921, x + 65.4933, y + 69.3774, x + 64.7167, y + 69.5733);
  ctx.lineTo(x + 21.513, y + 80.4721);
  ctx.bezierCurveTo(x + 20.7246, y + 80.671, x + 19.9404, y + 79.3871, x + 20.2066, y + 78.3426);
  ctx.lineTo(x + 21.5301, y + 73.1915);
  ctx.lineTo(x + 61.8765, y + 63.0134);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 47.4082, y + 8.30807);
  ctx.lineTo(x + 7.54199, y + 18.365);
  ctx.bezierCurveTo(x + 4.32898, y + 19.1755, x + 4.82514, y + 32.115, x + 8.64752, y + 47.2671);
  ctx.bezierCurveTo(x + 12.4699, y + 62.4192, x + 18.1694, y + 74.0397, x + 21.3824, y + 73.2292);
  ctx.lineTo(x + 61.2486, y + 63.1723);
  ctx.bezierCurveTo(x + 64.4616, y + 62.3617, x + 63.9655, y + 49.4223, x + 60.1446, y + 34.2761);
  ctx.bezierCurveTo(x + 56.3237, y + 19.1299, x + 50.6227, y + 7.50346, x + 47.4097, y + 8.314);
  ctx.lineTo(x + 47.4082, y + 8.30807);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 41.3897, y + 68.1825);
  ctx.lineTo(x + 61.2487, y + 63.1727);
  ctx.bezierCurveTo(x + 64.4617, y + 62.3622, x + 63.9656, y + 49.4227, x + 60.1447, y + 34.2766);
  ctx.bezierCurveTo(x + 56.3238, y + 19.1304, x + 50.6228, y + 7.50392, x + 47.4098, y + 8.31445);
  ctx.lineTo(x + 27.5508, y + 13.3242);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 38.9712, y + 58.6053);
  ctx.bezierCurveTo(x + 48.8783, y + 56.1061, x + 54.8835, y + 46.0488, x + 52.3843, y + 36.1417);
  ctx.bezierCurveTo(x + 49.8851, y + 26.2347, x + 39.8278, y + 20.2294, x + 29.9207, y + 22.7287);
  ctx.bezierCurveTo(x + 20.0137, y + 25.2279, x + 14.0084, y + 35.2852, x + 16.5077, y + 45.1922);
  ctx.bezierCurveTo(x + 19.0069, y + 55.0993, x + 29.0642, y + 61.1045, x + 38.9712, y + 58.6053);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 29.9219, y + 22.7266);
  ctx.bezierCurveTo(x + 39.8277, y + 20.2277, x + 49.8865, y + 26.2338, x + 52.3854, y + 36.1396);
  ctx.bezierCurveTo(x + 54.8843, y + 46.0454, x + 48.8781, y + 56.1043, x + 38.9724, y + 58.6032);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

 }

 drawElementShoppingCart(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 81, y + 0);
  ctx.bezierCurveTo(x + 81, y + 0, x + 81, y + 0, x + 81, y + 0);
  ctx.lineTo(x + 81, y + 85);
  ctx.bezierCurveTo(x + 81, y + 85, x + 81, y + 85, x + 81, y + 85);
  ctx.lineTo(x + 0, y + 85);
  ctx.bezierCurveTo(x + 0, y + 85, x + 0, y + 85, x + 0, y + 85);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 57.0221, y + 26.8408);
  ctx.bezierCurveTo(x + 62.7815, y + 26.8408, x + 67.4504, y + 22.1571, x + 67.4504, y + 16.3794);
  ctx.bezierCurveTo(x + 67.4504, y + 10.6017, x + 62.7815, y + 5.91797, x + 57.0221, y + 5.91797);
  ctx.bezierCurveTo(x + 51.2627, y + 5.91797, x + 46.5938, y + 10.6017, x + 46.5938, y + 16.3794);
  ctx.bezierCurveTo(x + 46.5938, y + 22.1571, x + 51.2627, y + 26.8408, x + 57.0221, y + 26.8408);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 52.8776, y + 0);
  ctx.lineTo(x + 34.5527, y + 0);
  ctx.bezierCurveTo(x + 32.8872, y + 0, x + 31.5371, y + 1.35441, x + 31.5371, y + 3.02516);
  ctx.lineTo(x + 31.5371, y + 25.1933);
  ctx.bezierCurveTo(x + 31.5371, y + 26.864, x + 32.8872, y + 28.2184, x + 34.5527, y + 28.2184);
  ctx.lineTo(x + 52.8776, y + 28.2184);
  ctx.bezierCurveTo(x + 54.5431, y + 28.2184, x + 55.8932, y + 26.864, x + 55.8932, y + 25.1933);
  ctx.lineTo(x + 55.8932, y + 3.02516);
  ctx.bezierCurveTo(x + 55.8932, y + 1.35441, x + 54.5431, y + 0, x + 52.8776, y + 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 68.5866, y + 13.1406);
  ctx.lineTo(x + 50.2617, y + 13.1406);
  ctx.bezierCurveTo(x + 48.5962, y + 13.1406, x + 47.2461, y + 14.495, x + 47.2461, y + 16.1658);
  ctx.lineTo(x + 47.2461, y + 38.3339);
  ctx.bezierCurveTo(x + 47.2461, y + 40.0046, x + 48.5962, y + 41.359, x + 50.2617, y + 41.359);
  ctx.lineTo(x + 68.5866, y + 41.359);
  ctx.bezierCurveTo(x + 70.2521, y + 41.359, x + 71.6022, y + 40.0046, x + 71.6022, y + 38.3339);
  ctx.lineTo(x + 71.6022, y + 16.1658);
  ctx.bezierCurveTo(x + 71.6022, y + 14.495, x + 70.2521, y + 13.1406, x + 68.5866, y + 13.1406);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 74.0923, y + 83.0078);
  ctx.bezierCurveTo(x + 73.1876, y + 83.0078, x + 72.29, y + 82.6631, x + 71.6027, y + 81.9666);
  ctx.lineTo(x + 50.6058, y + 60.7131);
  ctx.lineTo(x + 28.1783, y + 81.6148);
  ctx.bezierCurveTo(x + 26.7617, y + 82.9375, x + 24.5386, y + 82.853, x + 23.2201, y + 81.4319);
  ctx.bezierCurveTo(x + 21.9017, y + 80.0108, x + 21.9858, y + 77.7806, x + 23.4025, y + 76.458);
  ctx.lineTo(x + 48.3196, y + 53.2416);
  ctx.bezierCurveTo(x + 49.7082, y + 51.9472, x + 51.8612, y + 51.9964, x + 53.1936, y + 53.3401);
  ctx.lineTo(x + 76.5749, y + 77.0138);
  ctx.bezierCurveTo(x + 77.9354, y + 78.3927, x + 77.9284, y + 80.6229, x + 76.5539, y + 81.9877);
  ctx.bezierCurveTo(x + 75.8736, y + 82.6701, x + 74.976, y + 83.0078, x + 74.0853, y + 83.0078);
  ctx.lineTo(x + 74.0923, y + 83.0078);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 19.2513, y + 20.2634);
  ctx.lineTo(x + 29.8479, y + 56.9734);
  ctx.bezierCurveTo(x + 30.0934, y + 57.8247, x + 30.942, y + 58.3453, x + 31.8116, y + 58.1835);
  ctx.lineTo(x + 72.1643, y + 50.3954);
  ctx.bezierCurveTo(x + 72.9708, y + 50.2407, x + 73.5529, y + 49.5371, x + 73.5529, y + 48.714);
  ctx.lineTo(x + 73.5529, y + 31.0133);
  ctx.bezierCurveTo(x + 73.5529, y + 30.0636, x + 74.3173, y + 29.2967, x + 75.264, y + 29.2967);
  ctx.lineTo(x + 77.2417, y + 29.2967);
  ctx.bezierCurveTo(x + 78.1885, y + 29.2967, x + 78.9529, y + 28.5299, x + 78.9529, y + 27.5801);
  ctx.lineTo(x + 78.9529, y + 25.4132);
  ctx.bezierCurveTo(x + 78.9529, y + 24.4635, x + 78.1885, y + 23.6966, x + 77.2417, y + 23.6966);
  ctx.lineTo(x + 28.9292, y + 23.6966);
  ctx.bezierCurveTo(x + 28.1999, y + 23.6966, x + 27.5547, y + 23.2323, x + 27.3162, y + 22.5429);
  ctx.lineTo(x + 24.4549, y + 14.2905);
  ctx.bezierCurveTo(x + 24.2165, y + 13.601, x + 23.5713, y + 13.1367, x + 22.842, y + 13.1367);
  ctx.lineTo(x + 3.4791, y + 13.1367);
  ctx.bezierCurveTo(x + 1.8591, y + 13.1367, x + 0.554688, y + 14.4523, x + 0.554688, y + 16.0704);
  ctx.bezierCurveTo(x + 0.554688, y + 17.6885, x + 1.86612, y + 19.0041, x + 3.4791, y + 19.0041);
  ctx.lineTo(x + 17.6103, y + 19.0041);
  ctx.bezierCurveTo(x + 18.3677, y + 19.0041, x + 19.0409, y + 19.5177, x + 19.2513, y + 20.2494);
  ctx.lineTo(x + 19.2513, y + 20.2634);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 68.4745, y + 28.2891);
  ctx.lineTo(x + 66.4197, y + 28.2891);
  ctx.bezierCurveTo(x + 65.7187, y + 28.2891, x + 65.1504, y + 28.8592, x + 65.1504, y + 29.5624);
  ctx.lineTo(x + 65.1504, y + 45.8702);
  ctx.bezierCurveTo(x + 65.1504, y + 46.5734, x + 65.7187, y + 47.1436, x + 66.4197, y + 47.1436);
  ctx.lineTo(x + 68.4745, y + 47.1436);
  ctx.bezierCurveTo(x + 69.1756, y + 47.1436, x + 69.7439, y + 46.5734, x + 69.7439, y + 45.8702);
  ctx.lineTo(x + 69.7439, y + 29.5624);
  ctx.bezierCurveTo(x + 69.7439, y + 28.8592, x + 69.1756, y + 28.2891, x + 68.4745, y + 28.2891);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 60.1074, y + 28.2188);
  ctx.lineTo(x + 58.0526, y + 28.2188);
  ctx.bezierCurveTo(x + 57.3515, y + 28.2188, x + 56.7832, y + 28.7889, x + 56.7832, y + 29.4921);
  ctx.lineTo(x + 56.7832, y + 48.0723);
  ctx.bezierCurveTo(x + 56.7832, y + 48.7755, x + 57.3515, y + 49.3456, x + 58.0526, y + 49.3456);
  ctx.lineTo(x + 60.1074, y + 49.3456);
  ctx.bezierCurveTo(x + 60.8084, y + 49.3456, x + 61.3767, y + 48.7755, x + 61.3767, y + 48.0723);
  ctx.lineTo(x + 61.3767, y + 29.4921);
  ctx.bezierCurveTo(x + 61.3767, y + 28.7889, x + 60.8084, y + 28.2188, x + 60.1074, y + 28.2188);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 51.7343, y + 28.2891);
  ctx.lineTo(x + 49.6795, y + 28.2891);
  ctx.bezierCurveTo(x + 48.9785, y + 28.2891, x + 48.4102, y + 28.8592, x + 48.4102, y + 29.5624);
  ctx.lineTo(x + 48.4102, y + 49.9999);
  ctx.bezierCurveTo(x + 48.4102, y + 50.7031, x + 48.9785, y + 51.2733, x + 49.6795, y + 51.2733);
  ctx.lineTo(x + 51.7343, y + 51.2733);
  ctx.bezierCurveTo(x + 52.4354, y + 51.2733, x + 53.0037, y + 50.7031, x + 53.0037, y + 49.9999);
  ctx.lineTo(x + 53.0037, y + 29.5624);
  ctx.bezierCurveTo(x + 53.0037, y + 28.8592, x + 52.4354, y + 28.2891, x + 51.7343, y + 28.2891);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 13.9497, y + 10.7148);
  ctx.lineTo(x + 12.533, y + 10.7148);
  ctx.bezierCurveTo(x + 12.0024, y + 10.7148, x + 11.5723, y + 11.1464, x + 11.5723, y + 11.6787);
  ctx.lineTo(x + 11.5723, y + 20.4446);
  ctx.bezierCurveTo(x + 11.5723, y + 20.9769, x + 12.0024, y + 21.4084, x + 12.533, y + 21.4084);
  ctx.lineTo(x + 13.9497, y + 21.4084);
  ctx.bezierCurveTo(x + 14.4803, y + 21.4084, x + 14.9104, y + 20.9769, x + 14.9104, y + 20.4446);
  ctx.lineTo(x + 14.9104, y + 11.6787);
  ctx.bezierCurveTo(x + 14.9104, y + 11.1464, x + 14.4803, y + 10.7148, x + 13.9497, y + 10.7148);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 0.960779, y + 13.1016);
  ctx.lineTo(x + 11.5714, y + 13.1016);
  ctx.lineTo(x + 11.5714, y + 19.0675);
  ctx.lineTo(x + 0.960779, y + 19.0675);
  ctx.bezierCurveTo(x + 0.427792, y + 19.0675, x + 0, y + 18.6383, x + 0, y + 18.1036);
  ctx.lineTo(x + 0, y + 14.0654);
  ctx.bezierCurveTo(x + 0, y + 13.5307, x + 0.427792, y + 13.1016, x + 0.960779, y + 13.1016);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 25.1552, y + 85.0015);
  ctx.bezierCurveTo(x + 28.5287, y + 85.0015, x + 31.2635, y + 82.258, x + 31.2635, y + 78.8738);
  ctx.bezierCurveTo(x + 31.2635, y + 75.4896, x + 28.5287, y + 72.7461, x + 25.1552, y + 72.7461);
  ctx.bezierCurveTo(x + 21.7817, y + 72.7461, x + 19.0469, y + 75.4896, x + 19.0469, y + 78.8738);
  ctx.bezierCurveTo(x + 19.0469, y + 82.258, x + 21.7817, y + 85.0015, x + 25.1552, y + 85.0015);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 74.8915, y + 85.0015);
  ctx.bezierCurveTo(x + 78.265, y + 85.0015, x + 80.9998, y + 82.258, x + 80.9998, y + 78.8738);
  ctx.bezierCurveTo(x + 80.9998, y + 75.4896, x + 78.265, y + 72.7461, x + 74.8915, y + 72.7461);
  ctx.bezierCurveTo(x + 71.518, y + 72.7461, x + 68.7832, y + 75.4896, x + 68.7832, y + 78.8738);
  ctx.bezierCurveTo(x + 68.7832, y + 82.258, x + 71.518, y + 85.0015, x + 74.8915, y + 85.0015);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

 }

drawElementMoney(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;
  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale,scale);
  // ctx.scale(0.8247422680412372,0.8247422680412372);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(0,42.2148);
  // ctx.rotate(-0.5522466099405338);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 80.4732, y + 0);
  ctx.bezierCurveTo(x + 80.4732, y + 0, x + 80.4732, y + 0, x + 80.4732, y + 0);
  ctx.lineTo(x + 80.4732, y + 53.1807);
  ctx.bezierCurveTo(x + 80.4732, y + 53.1807, x + 80.4732, y + 53.1807, x + 80.4732, y + 53.1807);
  ctx.lineTo(x + 0, y + 53.1807);
  ctx.bezierCurveTo(x + 0, y + 53.1807, x + 0, y + 53.1807, x + 0, y + 53.1807);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.5522466099405338);
  // ctx.translate(0,-42.2148);
  // ctx.clip();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 56.9199, y + 7.14075);
  ctx.lineTo(x + 2.02645, y + 40.9661);
  ctx.bezierCurveTo(x + 0.907273, y + 41.6558, x + 0.559062, y + 43.1221, x + 1.2487, y + 44.2413);
  ctx.lineTo(x + 20.4691, y + 75.4331);
  ctx.bezierCurveTo(x + 21.1587, y + 76.5523, x + 22.6251, y + 76.9005, x + 23.7442, y + 76.2108);
  ctx.lineTo(x + 78.6377, y + 42.3854);
  ctx.bezierCurveTo(x + 79.7569, y + 41.6958, x + 80.1051, y + 40.2295, x + 79.4155, y + 39.1103);
  ctx.lineTo(x + 60.1951, y + 7.9185);
  ctx.bezierCurveTo(x + 59.5054, y + 6.79932, x + 58.0391, y + 6.45111, x + 56.9199, y + 7.14075);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 58.5671, y + 30.4383);
  ctx.bezierCurveTo(x + 56.7475, y + 27.4853, x + 57.3929, y + 23.7144, x + 59.9308, y + 21.5155);
  ctx.bezierCurveTo(x + 60.4374, y + 21.0796, x + 60.5175, y + 20.3127, x + 60.1675, y + 19.7448);
  ctx.lineTo(x + 58.2116, y + 16.5707);
  ctx.bezierCurveTo(x + 57.1102, y + 14.7833, x + 54.7722, y + 14.2281, x + 52.9849, y + 15.3295);
  ctx.lineTo(x + 11.1109, y + 41.1322);
  ctx.bezierCurveTo(x + 9.32356, y + 42.2336, x + 8.76836, y + 44.5716, x + 9.86972, y + 46.359);
  ctx.lineTo(x + 11.8256, y + 49.5331);
  ctx.bezierCurveTo(x + 12.1793, y + 50.107, x + 12.8965, y + 50.3743, x + 13.5137, y + 50.1177);
  ctx.bezierCurveTo(x + 16.6188, y + 48.8394, x + 20.2773, y + 49.9582, x + 22.097, y + 52.9112);
  ctx.bezierCurveTo(x + 23.9166, y + 55.8642, x + 23.2712, y + 59.6352, x + 20.7333, y + 61.834);
  ctx.bezierCurveTo(x + 20.2267, y + 62.27, x + 20.1466, y + 63.0368, x + 20.4966, y + 63.6047);
  ctx.lineTo(x + 22.4525, y + 66.7789);
  ctx.bezierCurveTo(x + 23.5538, y + 68.5662, x + 25.8919, y + 69.1214, x + 27.6792, y + 68.0201);
  ctx.lineTo(x + 69.5592, y + 42.2136);
  ctx.bezierCurveTo(x + 71.3465, y + 41.1122, x + 71.9017, y + 38.7742, x + 70.8003, y + 36.9869);
  ctx.lineTo(x + 68.8444, y + 33.8127);
  ctx.bezierCurveTo(x + 68.4908, y + 33.2389, x + 67.7735, y + 32.9716, x + 67.1564, y + 33.2281);
  ctx.bezierCurveTo(x + 64.0512, y + 34.5065, x + 60.3927, y + 33.3876, x + 58.5731, y + 30.4346);
  ctx.lineTo(x + 58.5671, y + 30.4383);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 45.81, y + 50.5743);
  ctx.bezierCurveTo(x + 50.7225, y + 47.5472, x + 52.2509, y + 41.1109, x + 49.2239, y + 36.1984);
  ctx.bezierCurveTo(x + 46.1968, y + 31.286, x + 39.7605, y + 29.7575, x + 34.848, y + 32.7846);
  ctx.bezierCurveTo(x + 29.9355, y + 35.8117, x + 28.4071, y + 42.248, x + 31.4341, y + 47.1605);
  ctx.bezierCurveTo(x + 34.4612, y + 52.073, x + 40.8975, y + 53.6014, x + 45.81, y + 50.5743);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 72.3927, y + 56.7159);
  ctx.bezierCurveTo(x + 76.077, y + 54.4456, x + 77.2234, y + 49.6184, x + 74.9531, y + 45.934);
  ctx.bezierCurveTo(x + 72.6827, y + 42.2496, x + 67.8555, y + 41.1033, x + 64.1711, y + 43.3736);
  ctx.bezierCurveTo(x + 60.4868, y + 45.6439, x + 59.3405, y + 50.4711, x + 61.6108, y + 54.1555);
  ctx.bezierCurveTo(x + 63.8811, y + 57.8399, x + 68.7083, y + 58.9862, x + 72.3927, y + 56.7159);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 71.4491, y + 55.1842);
  ctx.bezierCurveTo(x + 74.2883, y + 53.4347, x + 75.1717, y + 49.7148, x + 73.4221, y + 46.8756);
  ctx.bezierCurveTo(x + 71.6726, y + 44.0364, x + 67.9527, y + 43.153, x + 65.1135, y + 44.9025);
  ctx.bezierCurveTo(x + 62.2743, y + 46.652, x + 61.3909, y + 50.3719, x + 63.1404, y + 53.2112);
  ctx.bezierCurveTo(x + 64.89, y + 56.0504, x + 68.6099, y + 56.9337, x + 71.4491, y + 55.1842);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 78.4903, y + 34.5597);
  ctx.bezierCurveTo(x + 82.1747, y + 32.2893, x + 83.321, y + 27.4621, x + 81.0507, y + 23.7777);
  ctx.bezierCurveTo(x + 78.7804, y + 20.0934, x + 73.9532, y + 18.9471, x + 70.2688, y + 21.2174);
  ctx.bezierCurveTo(x + 66.5844, y + 23.4877, x + 65.4381, y + 28.3149, x + 67.7084, y + 31.9993);
  ctx.bezierCurveTo(x + 69.9787, y + 35.6836, x + 74.806, y + 36.83, x + 78.4903, y + 34.5597);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 77.5467, y + 33.028);
  ctx.bezierCurveTo(x + 80.386, y + 31.2784, x + 81.2693, y + 27.5585, x + 79.5198, y + 24.7193);
  ctx.bezierCurveTo(x + 77.7703, y + 21.8801, x + 74.0504, y + 20.9967, x + 71.2112, y + 22.7463);
  ctx.bezierCurveTo(x + 68.3719, y + 24.4958, x + 67.4886, y + 28.2157, x + 69.2381, y + 31.0549);
  ctx.bezierCurveTo(x + 70.9876, y + 33.8941, x + 74.7075, y + 34.7775, x + 77.5467, y + 33.028);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 89.7384, y + 49.3839);
  ctx.bezierCurveTo(x + 93.4227, y + 47.1136, x + 94.5691, y + 42.2863, x + 92.2988, y + 38.602);
  ctx.bezierCurveTo(x + 90.0284, y + 34.9176, x + 85.2012, y + 33.7713, x + 81.5168, y + 36.0416);
  ctx.bezierCurveTo(x + 77.8325, y + 38.3119, x + 76.6862, y + 43.1391, x + 78.9565, y + 46.8235);
  ctx.bezierCurveTo(x + 81.2268, y + 50.5079, x + 86.054, y + 51.6542, x + 89.7384, y + 49.3839);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 88.7948, y + 47.8522);
  ctx.bezierCurveTo(x + 91.634, y + 46.1027, x + 92.5174, y + 42.3828, x + 90.7678, y + 39.5435);
  ctx.bezierCurveTo(x + 89.0183, y + 36.7043, x + 85.2984, y + 35.821, x + 82.4592, y + 37.5705);
  ctx.bezierCurveTo(x + 79.62, y + 39.32, x + 78.7366, y + 43.0399, x + 80.4862, y + 45.8791);
  ctx.bezierCurveTo(x + 82.2357, y + 48.7183, x + 85.9556, y + 49.6017, x + 88.7948, y + 47.8522);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementPlasticBag(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale,scale);

  // ctx.scale(0.9756097560975611,0.9756097560975611);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(17.4238,0);
  // ctx.rotate(0.23052134227415905);

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 65.8988, y + 0);
  ctx.bezierCurveTo(x + 65.8988, y + 0, x + 65.8988, y + 0, x + 65.8988, y + 0);
  ctx.lineTo(x + 65.8988, y + 76.2575);
  ctx.bezierCurveTo(x + 65.8988, y + 76.2575, x + 65.8988, y + 76.2575, x + 65.8988, y + 76.2575);
  ctx.lineTo(x + 0, y + 76.2575);
  ctx.bezierCurveTo(x + 0, y + 76.2575, x + 0, y + 76.2575, x + 0, y + 76.2575);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();

  // ctx.rotate(-0.23052134227415905);
  // ctx.translate(-17.4238,0);
  // ctx.clip();

  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 67.518, y + 11.7568);
  ctx.lineTo(x + 62.7832, y + 10.6455);
  ctx.bezierCurveTo(x + 61.0124, y + 10.2299, x + 59.1977, y + 11.1742, x + 58.5255, y + 12.8652);
  ctx.lineTo(x + 53.3481, y + 25.9026);
  ctx.bezierCurveTo(x + 52.6759, y + 27.5936, x + 50.8674, y + 28.5393, x + 49.0903, y + 28.1222);
  ctx.lineTo(x + 40.702, y + 26.1535);
  ctx.bezierCurveTo(x + 38.9311, y + 25.7379, x + 37.726, y + 24.0849, x + 37.8762, y + 22.2714);
  ctx.lineTo(x + 39.0396, y + 8.292);
  ctx.bezierCurveTo(x + 39.1898, y + 6.47851, x + 37.9909, y + 4.82695, x + 36.2138, y + 4.40988);
  ctx.lineTo(x + 31.479, y + 3.29866);
  ctx.bezierCurveTo(x + 29.8759, y + 2.92242, x + 28.2146, y + 3.66013, x + 27.4227, y + 5.1067);
  ctx.lineTo(x + 8.15415, y + 40.0836);
  ctx.bezierCurveTo(x + 7.99279, y + 40.3801, x + 7.87346, y + 40.693, x + 7.79763, y + 41.0161);
  ctx.lineTo(x + 0.840107, y + 70.6613);
  ctx.bezierCurveTo(x + 0.37637, y + 72.6372, x + 1.60324, y + 74.6166, x + 3.57917, y + 75.0803);
  ctx.lineTo(x + 60.5829, y + 88.4587);
  ctx.bezierCurveTo(x + 62.5588, y + 88.9224, x + 64.5382, y + 87.6956, x + 65.0019, y + 85.7196);
  ctx.lineTo(x + 71.9595, y + 56.0744);
  ctx.bezierCurveTo(x + 72.0353, y + 55.7513, x + 72.0691, y + 55.4118, x + 72.055, y + 55.0807);
  ctx.lineTo(x + 70.3526, y + 15.1821);
  ctx.bezierCurveTo(x + 70.2808, y + 13.5328, x + 69.1273, y + 12.1345, x + 67.5242, y + 11.7582);
  ctx.lineTo(x + 67.518, y + 11.7568);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 34.2507, y + 72.5245);
  ctx.bezierCurveTo(x + 43.3344, y + 74.6564, x + 52.4264, y + 69.0209, x + 54.5582, y + 59.9372);
  ctx.bezierCurveTo(x + 56.6901, y + 50.8535, x + 51.0546, y + 41.7615, x + 41.9709, y + 39.6296);
  ctx.bezierCurveTo(x + 32.8872, y + 37.4978, x + 23.7952, y + 43.1333, x + 21.6633, y + 52.217);
  ctx.bezierCurveTo(x + 19.5315, y + 61.3007, x + 25.167, y + 70.3927, x + 34.2507, y + 72.5245);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 41.9707, y + 39.6289);
  ctx.bezierCurveTo(x + 51.055, y + 41.7609, x + 56.6901, y + 50.8521, x + 54.558, y + 59.9365);
  ctx.bezierCurveTo(x + 52.426, y + 69.0208, x + 43.3348, y + 74.6558, x + 34.2505, y + 72.5238);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 36.1797, y + 64.3024);
  ctx.bezierCurveTo(x + 40.7233, y + 65.3688, x + 45.271, y + 62.5499, x + 46.3373, y + 58.0064);
  ctx.bezierCurveTo(x + 47.4037, y + 53.4628, x + 44.5848, y + 48.9151, x + 40.0413, y + 47.8488);
  ctx.bezierCurveTo(x + 35.4977, y + 46.7824, x + 30.95, y + 49.6012, x + 29.8837, y + 54.1448);
  ctx.bezierCurveTo(x + 28.8173, y + 58.6884, x + 31.6362, y + 63.2361, x + 36.1797, y + 64.3024);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}


drawElementBinder(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale,scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.translate(0,0.00390625);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 66, y + 0);
  ctx.bezierCurveTo(x + 66, y + 0, x + 66, y + 0, x + 66, y + 0);
  ctx.lineTo(x + 66, y + 64);
  ctx.bezierCurveTo(x + 66, y + 64, x + 66, y + 64, x + 66, y + 64);
  ctx.lineTo(x + 0, y + 64);
  ctx.bezierCurveTo(x + 0, y + 64, x + 0, y + 64, x + 0, y + 64);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.translate(0,-0.00390625);
  ctx.clip();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 12.226, y + 0.00390625);
  ctx.lineTo(x + 27.8078, y + 0.00390625);
  ctx.bezierCurveTo(x + 29.0321, y + 0.00390625, x + 30.024, y + 0.988435, x + 30.024, y + 2.20362);
  ctx.lineTo(x + 30.024, y + 63.9983);
  ctx.lineTo(x + 10.0098, y + 63.9983);
  ctx.lineTo(x + 10.0098, y + 2.20362);
  ctx.bezierCurveTo(x + 10.0098, y + 0.988435, x + 11.0017, y + 0.00390625, x + 12.226, y + 0.00390625);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 26.1414, y + 3.11328);
  ctx.lineTo(x + 13.8925, y + 3.11328);
  ctx.bezierCurveTo(x + 13.2194, y + 3.11328, x + 12.6738, y + 3.65482, x + 12.6738, y + 4.32285);
  ctx.lineTo(x + 12.6738, y + 55.4621);
  ctx.bezierCurveTo(x + 12.6738, y + 56.1301, x + 13.2194, y + 56.6717, x + 13.8925, y + 56.6717);
  ctx.lineTo(x + 26.1414, y + 56.6717);
  ctx.bezierCurveTo(x + 26.8144, y + 56.6717, x + 27.36, y + 56.1301, x + 27.36, y + 55.4621);
  ctx.lineTo(x + 27.36, y + 4.32285);
  ctx.bezierCurveTo(x + 27.36, y + 3.65482, x + 26.8144, y + 3.11328, x + 26.1414, y + 3.11328);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 36.6733, y + 7.42578);
  ctx.lineTo(x + 57.3224, y + 7.42578);
  ctx.bezierCurveTo(x + 58.5467, y + 7.42578, x + 59.5386, y + 8.41031, x + 59.5386, y + 9.6255);
  ctx.lineTo(x + 59.5386, y + 64.0052);
  ctx.lineTo(x + 34.457, y + 64.0052);
  ctx.lineTo(x + 34.457, y + 9.6255);
  ctx.bezierCurveTo(x + 34.457, y + 8.41031, x + 35.449, y + 7.42578, x + 36.6733, y + 7.42578);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 55.848, y + 10.7148);
  ctx.lineTo(x + 38.2597, y + 10.7148);
  ctx.bezierCurveTo(x + 37.5866, y + 10.7148, x + 37.041, y + 11.2564, x + 37.041, y + 11.9244);
  ctx.lineTo(x + 37.041, y + 17.1677);
  ctx.bezierCurveTo(x + 37.041, y + 17.8358, x + 37.5866, y + 18.3773, x + 38.2597, y + 18.3773);
  ctx.lineTo(x + 55.848, y + 18.3773);
  ctx.bezierCurveTo(x + 56.521, y + 18.3773, x + 57.0666, y + 17.8358, x + 57.0666, y + 17.1677);
  ctx.lineTo(x + 57.0666, y + 11.9244);
  ctx.bezierCurveTo(x + 57.0666, y + 11.2564, x + 56.521, y + 10.7148, x + 55.848, y + 10.7148);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 55.848, y + 19.9648);
  ctx.lineTo(x + 38.2597, y + 19.9648);
  ctx.bezierCurveTo(x + 37.5866, y + 19.9648, x + 37.041, y + 20.5064, x + 37.041, y + 21.1744);
  ctx.lineTo(x + 37.041, y + 26.4177);
  ctx.bezierCurveTo(x + 37.041, y + 27.0858, x + 37.5866, y + 27.6273, x + 38.2597, y + 27.6273);
  ctx.lineTo(x + 55.848, y + 27.6273);
  ctx.bezierCurveTo(x + 56.521, y + 27.6273, x + 57.0666, y + 27.0858, x + 57.0666, y + 26.4177);
  ctx.lineTo(x + 57.0666, y + 21.1744);
  ctx.bezierCurveTo(x + 57.0666, y + 20.5064, x + 56.521, y + 19.9648, x + 55.848, y + 19.9648);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 2.21625, y + 26.0273);
  ctx.lineTo(x + 14.6295, y + 26.0273);
  ctx.bezierCurveTo(x + 15.8538, y + 26.0273, x + 16.8458, y + 27.0119, x + 16.8458, y + 28.2271);
  ctx.lineTo(x + 16.8458, y + 63.9964);
  ctx.lineTo(x + 0, y + 63.9964);
  ctx.lineTo(x + 0, y + 28.2271);
  ctx.bezierCurveTo(x + 0, y + 27.0119, x + 0.991927, y + 26.0273, x + 2.21625, y + 26.0273);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 26.5385, y + 17.4844);
  ctx.lineTo(x + 39.9664, y + 17.4844);
  ctx.bezierCurveTo(x + 41.1907, y + 17.4844, x + 42.1826, y + 18.4689, x + 42.1826, y + 19.6841);
  ctx.lineTo(x + 42.1826, y + 64.0048);
  ctx.lineTo(x + 24.3223, y + 64.0048);
  ctx.lineTo(x + 24.3223, y + 19.6841);
  ctx.bezierCurveTo(x + 24.3223, y + 18.4689, x + 25.3142, y + 17.4844, x + 26.5385, y + 17.4844);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 50.3549, y + 31.3125);
  ctx.lineTo(x + 63.7828, y + 31.3125);
  ctx.bezierCurveTo(x + 65.0071, y + 31.3125, x + 65.999, y + 32.297, x + 65.999, y + 33.5122);
  ctx.lineTo(x + 65.999, y + 64.0045);
  ctx.lineTo(x + 48.1387, y + 64.0045);
  ctx.lineTo(x + 48.1387, y + 33.5122);
  ctx.bezierCurveTo(x + 48.1387, y + 32.297, x + 49.1306, y + 31.3125, x + 50.3549, y + 31.3125);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.1387, y + 35.4236);
  ctx.lineTo(x + 62.1617, y + 35.4236);
  ctx.bezierCurveTo(x + 62.6662, y + 35.4236, x + 63.0743, y + 35.8287, x + 63.0743, y + 36.3294);
  ctx.lineTo(x + 63.0743, y + 38.9116);
  ctx.bezierCurveTo(x + 63.0743, y + 39.4123, x + 62.6662, y + 39.8174, x + 62.1617, y + 39.8174);
  ctx.lineTo(x + 48.1387, y + 39.8174);
  ctx.lineTo(x + 48.1387, y + 35.418);
  ctx.lineTo(x + 48.1387, y + 35.4236);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.1387, y + 41.7752);
  ctx.lineTo(x + 62.1617, y + 41.7752);
  ctx.bezierCurveTo(x + 62.6662, y + 41.7752, x + 63.0743, y + 42.1802, x + 63.0743, y + 42.6809);
  ctx.lineTo(x + 63.0743, y + 45.2632);
  ctx.bezierCurveTo(x + 63.0743, y + 45.7639, x + 62.6662, y + 46.169, x + 62.1617, y + 46.169);
  ctx.lineTo(x + 48.1387, y + 46.169);
  ctx.lineTo(x + 48.1387, y + 41.7695);
  ctx.lineTo(x + 48.1387, y + 41.7752);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.1387, y + 48.1228);
  ctx.lineTo(x + 62.1617, y + 48.1228);
  ctx.bezierCurveTo(x + 62.6662, y + 48.1228, x + 63.0743, y + 48.5279, x + 63.0743, y + 49.0286);
  ctx.lineTo(x + 63.0743, y + 51.6109);
  ctx.bezierCurveTo(x + 63.0743, y + 52.1116, x + 62.6662, y + 52.5166, x + 62.1617, y + 52.5166);
  ctx.lineTo(x + 48.1387, y + 52.5166);
  ctx.lineTo(x + 48.1387, y + 48.1172);
  ctx.lineTo(x + 48.1387, y + 48.1228);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.1387, y + 54.4744);
  ctx.lineTo(x + 62.1617, y + 54.4744);
  ctx.bezierCurveTo(x + 62.6662, y + 54.4744, x + 63.0743, y + 54.8794, x + 63.0743, y + 55.3801);
  ctx.lineTo(x + 63.0743, y + 57.9624);
  ctx.bezierCurveTo(x + 63.0743, y + 58.4631, x + 62.6662, y + 58.8682, x + 62.1617, y + 58.8682);
  ctx.lineTo(x + 48.1387, y + 58.8682);
  ctx.lineTo(x + 48.1387, y + 54.4688);
  ctx.lineTo(x + 48.1387, y + 54.4744);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 35.2111, y + 19.9648);
  ctx.lineTo(x + 27.8085, y + 19.9648);
  ctx.bezierCurveTo(x + 27.1355, y + 19.9648, x + 26.5898, y + 20.5064, x + 26.5898, y + 21.1744);
  ctx.lineTo(x + 26.5898, y + 23.4248);
  ctx.bezierCurveTo(x + 26.5898, y + 24.0928, x + 27.1355, y + 24.6343, x + 27.8085, y + 24.6343);
  ctx.lineTo(x + 35.2111, y + 24.6343);
  ctx.bezierCurveTo(x + 35.8842, y + 24.6343, x + 36.4298, y + 24.0928, x + 36.4298, y + 23.4248);
  ctx.lineTo(x + 36.4298, y + 21.1744);
  ctx.bezierCurveTo(x + 36.4298, y + 20.5064, x + 35.8842, y + 19.9648, x + 35.2111, y + 19.9648);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 38.6916, y + 26.0586);
  ctx.lineTo(x + 31.289, y + 26.0586);
  ctx.bezierCurveTo(x + 30.6159, y + 26.0586, x + 30.0703, y + 26.6001, x + 30.0703, y + 27.2682);
  ctx.lineTo(x + 30.0703, y + 29.5185);
  ctx.bezierCurveTo(x + 30.0703, y + 30.1865, x + 30.6159, y + 30.7281, x + 31.289, y + 30.7281);
  ctx.lineTo(x + 38.6916, y + 30.7281);
  ctx.bezierCurveTo(x + 39.3646, y + 30.7281, x + 39.9102, y + 30.1865, x + 39.9102, y + 29.5185);
  ctx.lineTo(x + 39.9102, y + 27.2682);
  ctx.bezierCurveTo(x + 39.9102, y + 26.6001, x + 39.3646, y + 26.0586, x + 38.6916, y + 26.0586);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 6.46186, y + 28.3516);
  ctx.lineTo(x + 3.42373, y + 28.3516);
  ctx.bezierCurveTo(x + 2.75069, y + 28.3516, x + 2.20508, y + 28.8931, x + 2.20508, y + 29.5611);
  ctx.lineTo(x + 2.20508, y + 44.7229);
  ctx.bezierCurveTo(x + 2.20508, y + 45.3909, x + 2.75069, y + 45.9324, x + 3.42373, y + 45.9324);
  ctx.lineTo(x + 6.46186, y + 45.9324);
  ctx.bezierCurveTo(x + 7.13491, y + 45.9324, x + 7.68052, y + 45.3909, x + 7.68052, y + 44.7229);
  ctx.lineTo(x + 7.68052, y + 29.5611);
  ctx.bezierCurveTo(x + 7.68052, y + 28.8931, x + 7.13491, y + 28.3516, x + 6.46186, y + 28.3516);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 13.4287, y + 44.0977);
  ctx.lineTo(x + 10.3905, y + 44.0977);
  ctx.bezierCurveTo(x + 9.71748, y + 44.0977, x + 9.17188, y + 44.6392, x + 9.17188, y + 45.3072);
  ctx.lineTo(x + 9.17188, y + 60.469);
  ctx.bezierCurveTo(x + 9.17188, y + 61.137, x + 9.71748, y + 61.6785, x + 10.3905, y + 61.6785);
  ctx.lineTo(x + 13.4287, y + 61.6785);
  ctx.bezierCurveTo(x + 14.1017, y + 61.6785, x + 14.6473, y + 61.137, x + 14.6473, y + 60.469);
  ctx.lineTo(x + 14.6473, y + 45.3072);
  ctx.bezierCurveTo(x + 14.6473, y + 44.6392, x + 14.1017, y + 44.0977, x + 13.4287, y + 44.0977);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 35.2111, y + 32.2617);
  ctx.lineTo(x + 27.8085, y + 32.2617);
  ctx.bezierCurveTo(x + 27.1355, y + 32.2617, x + 26.5898, y + 32.8033, x + 26.5898, y + 33.4713);
  ctx.lineTo(x + 26.5898, y + 35.7216);
  ctx.bezierCurveTo(x + 26.5898, y + 36.3897, x + 27.1355, y + 36.9312, x + 27.8085, y + 36.9312);
  ctx.lineTo(x + 35.2111, y + 36.9312);
  ctx.bezierCurveTo(x + 35.8842, y + 36.9312, x + 36.4298, y + 36.3897, x + 36.4298, y + 35.7216);
  ctx.lineTo(x + 36.4298, y + 33.4713);
  ctx.bezierCurveTo(x + 36.4298, y + 32.8033, x + 35.8842, y + 32.2617, x + 35.2111, y + 32.2617);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementNoteWithPencil(ctx,scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.scale(0.6451612903225806,0.6451612903225806);
  ctx.scale(scale,scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(0.505859,32.5742);
  // ctx.rotate(-0.33252012908995965);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 99.3223, y + 0);
  ctx.bezierCurveTo(x + 99.3223, y + 0, x + 99.3223, y + 0, x + 99.3223, y + 0);
  ctx.lineTo(x + 99.3223, y + 89.1643);
  ctx.bezierCurveTo(x + 99.3223, y + 89.1643, x + 99.3223, y + 89.1643, x + 99.3223, y + 89.1643);
  ctx.lineTo(x + 0, y + 89.1643);
  ctx.bezierCurveTo(x + 0, y + 89.1643, x + 0, y + 89.1643, x + 0, y + 89.1643);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.33252012908995965);
  // ctx.translate(-0.505859,-32.5742);
  // ctx.clip();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 23.2491, y + 24.7179);
  ctx.bezierCurveTo(x + 21.7338, y + 25.2412, x + 20.9218, y + 26.8951, x + 21.4471, y + 28.4161);
  ctx.lineTo(x + 46.0029, y + 99.5214);
  ctx.bezierCurveTo(x + 46.5256, y + 101.035, x + 48.1803, y + 101.845, x + 49.703, y + 101.319);
  ctx.lineTo(x + 97.2139, y + 84.9119);
  ctx.bezierCurveTo(x + 98.7291, y + 84.3886, x + 99.5411, y + 82.7347, x + 99.0159, y + 81.2137);
  ctx.lineTo(x + 77.4366, y + 18.7274);
  ctx.lineTo(x + 62.1243, y + 11.2926);
  ctx.lineTo(x + 23.2491, y + 24.7179);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 68.8016, y + 21.7101);
  ctx.lineTo(x + 77.4373, y + 18.7278);
  ctx.lineTo(x + 62.125, y + 11.293);
  ctx.lineTo(x + 65.1041, y + 19.9194);
  ctx.bezierCurveTo(x + 65.6268, y + 21.433, x + 67.2815, y + 22.2432, x + 68.8042, y + 21.7174);
  ctx.lineTo(x + 68.8016, y + 21.7101);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 29.9208, y + 22.4139);
  ctx.lineTo(x + 23.2491, y + 24.7179);
  ctx.bezierCurveTo(x + 21.7338, y + 25.2412, x + 20.9218, y + 26.8951, x + 21.4471, y + 28.4161);
  ctx.lineTo(x + 46.0029, y + 99.5214);
  ctx.bezierCurveTo(x + 46.5256, y + 101.035, x + 48.1803, y + 101.845, x + 49.703, y + 101.319);
  ctx.lineTo(x + 56.3746, y + 99.0155);
  ctx.lineTo(x + 29.9208, y + 22.4139);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 76.1209, y + 41.9382);
  ctx.lineTo(x + 38.2689, y + 33.567);
  ctx.bezierCurveTo(x + 36.6286, y + 33.2042, x + 35.0036, y + 34.239, x + 34.6394, y + 35.8782);
  ctx.lineTo(x + 21.9088, y + 93.182);
  ctx.bezierCurveTo(x + 21.5447, y + 94.8212, x + 22.5792, y + 96.4441, x + 24.2196, y + 96.8069);
  ctx.lineTo(x + 62.0715, y + 105.178);
  ctx.bezierCurveTo(x + 63.7119, y + 105.541, x + 65.3368, y + 104.506, x + 65.701, y + 102.867);
  ctx.lineTo(x + 78.4316, y + 45.5631);
  ctx.bezierCurveTo(x + 78.7958, y + 43.9239, x + 77.7612, y + 42.301, x + 76.1209, y + 41.9382);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 71.0526, y + 45.7618);
  ctx.lineTo(x + 41.2374, y + 39.168);
  ctx.bezierCurveTo(x + 40.0082, y + 38.8962, x + 38.7905, y + 39.6716, x + 38.5176, y + 40.8999);
  ctx.lineTo(x + 36.0133, y + 52.1724);
  ctx.bezierCurveTo(x + 35.7404, y + 53.4008, x + 36.5157, y + 54.6169, x + 37.7449, y + 54.8888);
  ctx.lineTo(x + 67.56, y + 61.4826);
  ctx.bezierCurveTo(x + 68.7892, y + 61.7544, x + 70.0069, y + 60.979, x + 70.2798, y + 59.7507);
  ctx.lineTo(x + 72.7841, y + 48.4781);
  ctx.bezierCurveTo(x + 73.057, y + 47.2498, x + 72.2818, y + 46.0336, x + 71.0526, y + 45.7618);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.9297, y + 41.082);
  ctx.lineTo(x + 71.0548, y + 45.754);
  ctx.bezierCurveTo(x + 72.2778, y + 46.0244, x + 73.0595, y + 47.2406, x + 72.7863, y + 48.4703);
  ctx.lineTo(x + 70.2804, y + 59.7504);
  ctx.bezierCurveTo(x + 70.0088, y + 60.9726, x + 68.7911, y + 61.7545, x + 67.5605, y + 61.4823);
  ctx.lineTo(x + 46.4355, y + 56.8104);
  ctx.lineTo(x + 49.928, y + 41.0896);
  ctx.lineTo(x + 49.9297, y + 41.082);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 66.8162, y + 64.8008);
  ctx.lineTo(x + 37.0011, y + 58.2071);
  ctx.bezierCurveTo(x + 35.7719, y + 57.9352, x + 34.5542, y + 58.7106, x + 34.2813, y + 59.939);
  ctx.lineTo(x + 27.4362, y + 90.7505);
  ctx.bezierCurveTo(x + 27.1633, y + 91.9789, x + 27.9386, y + 93.195, x + 29.1678, y + 93.4669);
  ctx.lineTo(x + 58.9829, y + 100.061);
  ctx.bezierCurveTo(x + 60.2121, y + 100.332, x + 61.4298, y + 99.5571, x + 61.7027, y + 98.3287);
  ctx.lineTo(x + 68.5478, y + 67.5172);
  ctx.bezierCurveTo(x + 68.8207, y + 66.2889, x + 68.0454, y + 65.0727, x + 66.8162, y + 64.8008);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 50.8478, y + 61.271);
  ctx.lineTo(x + 66.8206, y + 64.8015);
  ctx.bezierCurveTo(x + 68.0514, y + 65.0755, x + 68.8245, y + 66.2889, x + 68.5469, y + 67.5115);
  ctx.lineTo(x + 61.7031, y + 98.3307);
  ctx.bezierCurveTo(x + 61.4281, y + 99.5606, x + 60.2131, y + 100.334, x + 58.9897, y + 100.057);
  ctx.lineTo(x + 43.9443, y + 96.7328);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 90.2827, y + 17.9701);
  ctx.lineTo(x + 97.4105, y + 15.5086);
  ctx.lineTo(x + 117.295, y + 73.0865);
  ctx.bezierCurveTo(x + 117.934, y + 74.9382, x + 116.946, y + 76.9653, x + 115.093, y + 77.6054);
  ctx.lineTo(x + 114.681, y + 77.7477);
  ctx.bezierCurveTo(x + 112.827, y + 78.3878, x + 110.799, y + 77.4023, x + 110.16, y + 75.5506);
  ctx.lineTo(x + 90.2754, y + 17.9727);
  ctx.lineTo(x + 90.2827, y + 17.9701);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 95.7659, y + 9.57754);
  ctx.lineTo(x + 87.9173, y + 12.288);
  ctx.bezierCurveTo(x + 86.9911, y + 12.6079, x + 86.4993, y + 13.6173, x + 86.8188, y + 14.5425);
  ctx.lineTo(x + 91.3179, y + 27.5703);
  ctx.bezierCurveTo(x + 91.6374, y + 28.4955, x + 92.6473, y + 28.9863, x + 93.5735, y + 28.6664);
  ctx.lineTo(x + 101.422, y + 25.956);
  ctx.bezierCurveTo(x + 102.348, y + 25.6361, x + 102.84, y + 24.6267, x + 102.521, y + 23.7015);
  ctx.lineTo(x + 98.0216, y + 10.6737);
  ctx.bezierCurveTo(x + 97.7021, y + 9.74843, x + 96.6922, y + 9.25767, x + 95.7659, y + 9.57754);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 88.6125, y + 2.14525);
  ctx.lineTo(x + 88.9509, y + 2.0284);
  ctx.bezierCurveTo(x + 90.4882, y + 1.49748, x + 92.1676, y + 2.30743, x + 92.6979, y + 3.84313);
  ctx.lineTo(x + 94.7939, y + 9.91247);
  ctx.lineTo(x + 88.8872, y + 11.9523);
  ctx.lineTo(x + 86.7912, y + 5.88296);
  ctx.bezierCurveTo(x + 86.2609, y + 4.34726, x + 87.0752, y + 2.67616, x + 88.6125, y + 2.14525);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}


drawElementCreditCard(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.scale(scale,scale);
  // ctx.scale(0.8080808080808081,0.8080808080808081);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(0,22.793);
  // ctx.rotate(-0.2692257637663853);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 85.6767, y + 0);
  ctx.bezierCurveTo(x + 85.6767, y + 0, x + 85.6767, y + 0, x + 85.6767, y + 0);
  ctx.lineTo(x + 85.6767, y + 58.5453);
  ctx.bezierCurveTo(x + 85.6767, y + 58.5453, x + 85.6767, y + 58.5453, x + 85.6767, y + 58.5453);
  ctx.lineTo(x + 0, y + 58.5453);
  ctx.bezierCurveTo(x + 0, y + 58.5453, x + 0, y + 58.5453, x + 0, y + 58.5453);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.2692257637663853);
  // ctx.translate(0,-22.793);
  // ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 59.5887, y + 6.35095);
  ctx.lineTo(x + 3.73922, y + 21.7612);
  ctx.bezierCurveTo(x + 1.67411, y + 22.331, x + 0.461928, y + 24.4671, x + 1.03174, y + 26.5322);
  ctx.lineTo(x + 10.6526, y + 61.3997);
  ctx.bezierCurveTo(x + 11.2224, y + 63.4649, x + 13.3584, y + 64.677, x + 15.4235, y + 64.1072);
  ctx.lineTo(x + 71.273, y + 48.6969);
  ctx.bezierCurveTo(x + 73.3381, y + 48.1271, x + 74.5503, y + 45.9911, x + 73.9805, y + 43.926);
  ctx.lineTo(x + 64.3597, y + 9.05843);
  ctx.bezierCurveTo(x + 63.7899, y + 6.99331, x + 61.6538, y + 5.78113, x + 59.5887, y + 6.35095);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 82.7391, y + 15.1244);
  ctx.lineTo(x + 26.8896, y + 30.5347);
  ctx.bezierCurveTo(x + 24.8245, y + 31.1045, x + 23.6123, y + 33.2405, x + 24.1821, y + 35.3056);
  ctx.lineTo(x + 33.803, y + 70.1732);
  ctx.bezierCurveTo(x + 34.3728, y + 72.2383, x + 36.5088, y + 73.4505, x + 38.5739, y + 72.8806);
  ctx.lineTo(x + 94.4234, y + 57.4704);
  ctx.bezierCurveTo(x + 96.4885, y + 56.9006, x + 97.7007, y + 54.7645, x + 97.1309, y + 52.6994);
  ctx.lineTo(x + 87.5101, y + 17.8319);
  ctx.bezierCurveTo(x + 86.9402, y + 15.7667, x + 84.8042, y + 14.5546, x + 82.7391, y + 15.1244);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 14.9014, y + 24.4021);
  ctx.lineTo(x + 7.85963, y + 26.3451);
  ctx.bezierCurveTo(x + 7.05695, y + 26.5665, x + 6.58579, y + 27.3968, x + 6.80727, y + 28.1995);
  ctx.lineTo(x + 8.75027, y + 35.2412);
  ctx.bezierCurveTo(x + 8.97175, y + 36.0439, x + 9.802, y + 36.5151, x + 10.6047, y + 36.2936);
  ctx.lineTo(x + 17.6464, y + 34.3506);
  ctx.bezierCurveTo(x + 18.4491, y + 34.1291, x + 18.9203, y + 33.2989, x + 18.6988, y + 32.4962);
  ctx.lineTo(x + 16.7558, y + 25.4544);
  ctx.bezierCurveTo(x + 16.5343, y + 24.6518, x + 15.7041, y + 24.1806, x + 14.9014, y + 24.4021);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 87.7861, y + 44.1404);
  ctx.lineTo(x + 80.7444, y + 46.0834);
  ctx.bezierCurveTo(x + 79.9417, y + 46.3048, x + 79.4706, y + 47.1351, x + 79.692, y + 47.9378);
  ctx.lineTo(x + 81.635, y + 54.9795);
  ctx.bezierCurveTo(x + 81.8565, y + 55.7822, x + 82.6868, y + 56.2533, x + 83.4894, y + 56.0319);
  ctx.lineTo(x + 90.5312, y + 54.0889);
  ctx.bezierCurveTo(x + 91.3339, y + 53.8674, x + 91.805, y + 53.0371, x + 91.5835, y + 52.2345);
  ctx.lineTo(x + 89.6406, y + 45.1927);
  ctx.bezierCurveTo(x + 89.4191, y + 44.39, x + 88.5888, y + 43.9189, x + 87.7861, y + 44.1404);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 51.6006, y + 57.6657);
  ctx.lineTo(x + 36.9098, y + 61.7192);
  ctx.bezierCurveTo(x + 35.6285, y + 62.0728, x + 34.8764, y + 63.3981, x + 35.23, y + 64.6793);
  ctx.lineTo(x + 35.3222, y + 65.0137);
  ctx.bezierCurveTo(x + 35.6758, y + 66.295, x + 37.001, y + 67.047, x + 38.2823, y + 66.6935);
  ctx.lineTo(x + 52.9731, y + 62.6399);
  ctx.bezierCurveTo(x + 54.2544, y + 62.2864, x + 55.0064, y + 60.9611, x + 54.6529, y + 59.6799);
  ctx.lineTo(x + 54.5607, y + 59.3455);
  ctx.bezierCurveTo(x + 54.2071, y + 58.0642, x + 52.8818, y + 57.3122, x + 51.6006, y + 57.6657);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 89.0076, y + 23.2567);
  ctx.lineTo(x + 25.6797, y + 40.7305);
  ctx.lineTo(x + 28.2082, y + 49.8943);
  ctx.lineTo(x + 91.5361, y + 32.4205);
  ctx.lineTo(x + 89.0076, y + 23.2567);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementMultiplePages(ctx,scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale,scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.translate(0,0.00390625);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 70, y + 0);
  ctx.bezierCurveTo(x + 70, y + 0, x + 70, y + 0, x + 70, y + 0);
  ctx.lineTo(x + 70, y + 71);
  ctx.bezierCurveTo(x + 70, y + 71, x + 70, y + 71, x + 70, y + 71);
  ctx.lineTo(x + 0, y + 71);
  ctx.bezierCurveTo(x + 0, y + 71, x + 0, y + 71, x + 0, y + 71);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.translate(0,-0.00390625);
  ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 32.5499, y + 2.74605);
  ctx.lineTo(x + 1.30917, y + 19.0627);
  ctx.bezierCurveTo(x + 0.111513, y + 19.6882, x + -0.34705, y + 21.1563, x + 0.284935, y + 22.3417);
  ctx.lineTo(x + 25.5413, y + 69.7144);
  ctx.bezierCurveTo(x + 26.1733, y + 70.8998, x + 27.6565, y + 71.3537, x + 28.8541, y + 70.7282);
  ctx.lineTo(x + 60.0948, y + 54.4115);
  ctx.bezierCurveTo(x + 61.2925, y + 53.786, x + 61.7511, y + 52.318, x + 61.1191, y + 51.1326);
  ctx.lineTo(x + 35.8627, y + 3.7598);
  ctx.bezierCurveTo(x + 35.2308, y + 2.5744, x + 33.7475, y + 2.12053, x + 32.5499, y + 2.74605);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 50.4031, y + 0.417041);
  ctx.lineTo(x + 15.762, y + 7.25577);
  ctx.bezierCurveTo(x + 14.434, y + 7.51794, x + 13.5722, y + 8.79602, x + 13.837, y + 10.1104);
  ctx.lineTo(x + 24.4226, y + 62.6395);
  ctx.bezierCurveTo(x + 24.6875, y + 63.9539, x + 25.9788, y + 64.807, x + 27.3068, y + 64.5448);
  ctx.lineTo(x + 61.9479, y + 57.7061);
  ctx.bezierCurveTo(x + 63.2759, y + 57.4439, x + 64.1378, y + 56.1658, x + 63.8729, y + 54.8514);
  ctx.lineTo(x + 53.2873, y + 2.32232);
  ctx.bezierCurveTo(x + 53.0224, y + 1.00789, x + 51.7311, y + 0.15487, x + 50.4031, y + 0.417041);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 31.3245, y + 0.00390625);
  ctx.bezierCurveTo(x + 30.1535, y + 0.00390625, x + 29.209, y + 0.938791, x + 29.209, y + 2.09779);
  ctx.lineTo(x + 29.209, y + 56.3211);
  ctx.bezierCurveTo(x + 29.209, y + 57.4801, x + 30.1535, y + 58.415, x + 31.3245, y + 58.415);
  ctx.lineTo(x + 67.8901, y + 58.415);
  ctx.bezierCurveTo(x + 69.0611, y + 58.415, x + 70.0057, y + 57.4801, x + 70.0057, y + 56.3211);
  ctx.lineTo(x + 70.0057, y + 8.674);
  ctx.lineTo(x + 61.2395, y + 0.00390625);
  ctx.lineTo(x + 31.318, y + 0.00390625);
  ctx.lineTo(x + 31.3245, y + 0.00390625);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 63.3558, y + 8.674);
  ctx.lineTo(x + 69.9999, y + 8.674);
  ctx.lineTo(x + 61.2402, y + 0.00390625);
  ctx.lineTo(x + 61.2402, y + 6.58011);
  ctx.bezierCurveTo(x + 61.2402, y + 7.73912, x + 62.1848, y + 8.674, x + 63.3558, y + 8.674);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 52.8044, y + 7.32812);
  ctx.lineTo(x + 36.0743, y + 7.32812);
  ctx.bezierCurveTo(x + 35.056, y + 7.32812, x + 34.2305, y + 8.14518, x + 34.2305, y + 9.15307);
  ctx.bezierCurveTo(x + 34.2305, y + 10.161, x + 35.056, y + 10.978, x + 36.0743, y + 10.978);
  ctx.lineTo(x + 52.8044, y + 10.978);
  ctx.bezierCurveTo(x + 53.8227, y + 10.978, x + 54.6482, y + 10.161, x + 54.6482, y + 9.15307);
  ctx.bezierCurveTo(x + 54.6482, y + 8.14518, x + 53.8227, y + 7.32812, x + 52.8044, y + 7.32812);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.8968, y + 15.3516);
  ctx.lineTo(x + 36.0743, y + 15.3516);
  ctx.bezierCurveTo(x + 35.056, y + 15.3516, x + 34.2305, y + 16.1686, x + 34.2305, y + 17.1765);
  ctx.bezierCurveTo(x + 34.2305, y + 18.1844, x + 35.056, y + 19.0015, x + 36.0743, y + 19.0015);
  ctx.lineTo(x + 62.8968, y + 19.0015);
  ctx.bezierCurveTo(x + 63.9151, y + 19.0015, x + 64.7406, y + 18.1844, x + 64.7406, y + 17.1765);
  ctx.bezierCurveTo(x + 64.7406, y + 16.1686, x + 63.9151, y + 15.3516, x + 62.8968, y + 15.3516);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.8968, y + 23.375);
  ctx.lineTo(x + 36.0743, y + 23.375);
  ctx.bezierCurveTo(x + 35.056, y + 23.375, x + 34.2305, y + 24.1921, x + 34.2305, y + 25.1999);
  ctx.bezierCurveTo(x + 34.2305, y + 26.2078, x + 35.056, y + 27.0249, x + 36.0743, y + 27.0249);
  ctx.lineTo(x + 62.8968, y + 27.0249);
  ctx.bezierCurveTo(x + 63.9151, y + 27.0249, x + 64.7406, y + 26.2078, x + 64.7406, y + 25.1999);
  ctx.bezierCurveTo(x + 64.7406, y + 24.1921, x + 63.9151, y + 23.375, x + 62.8968, y + 23.375);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.8968, y + 31.3984);
  ctx.lineTo(x + 36.0743, y + 31.3984);
  ctx.bezierCurveTo(x + 35.056, y + 31.3984, x + 34.2305, y + 32.2155, x + 34.2305, y + 33.2234);
  ctx.bezierCurveTo(x + 34.2305, y + 34.2313, x + 35.056, y + 35.0483, x + 36.0743, y + 35.0483);
  ctx.lineTo(x + 62.8968, y + 35.0483);
  ctx.bezierCurveTo(x + 63.9151, y + 35.0483, x + 64.7406, y + 34.2313, x + 64.7406, y + 33.2234);
  ctx.bezierCurveTo(x + 64.7406, y + 32.2155, x + 63.9151, y + 31.3984, x + 62.8968, y + 31.3984);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.8968, y + 39.4219);
  ctx.lineTo(x + 36.0743, y + 39.4219);
  ctx.bezierCurveTo(x + 35.056, y + 39.4219, x + 34.2305, y + 40.2389, x + 34.2305, y + 41.2468);
  ctx.bezierCurveTo(x + 34.2305, y + 42.2547, x + 35.056, y + 43.0718, x + 36.0743, y + 43.0718);
  ctx.lineTo(x + 62.8968, y + 43.0718);
  ctx.bezierCurveTo(x + 63.9151, y + 43.0718, x + 64.7406, y + 42.2547, x + 64.7406, y + 41.2468);
  ctx.bezierCurveTo(x + 64.7406, y + 40.2389, x + 63.9151, y + 39.4219, x + 62.8968, y + 39.4219);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.8968, y + 47.4453);
  ctx.lineTo(x + 36.0743, y + 47.4453);
  ctx.bezierCurveTo(x + 35.056, y + 47.4453, x + 34.2305, y + 48.2624, x + 34.2305, y + 49.2703);
  ctx.bezierCurveTo(x + 34.2305, y + 50.2782, x + 35.056, y + 51.0952, x + 36.0743, y + 51.0952);
  ctx.lineTo(x + 62.8968, y + 51.0952);
  ctx.bezierCurveTo(x + 63.9151, y + 51.0952, x + 64.7406, y + 50.2782, x + 64.7406, y + 49.2703);
  ctx.bezierCurveTo(x + 64.7406, y + 48.2624, x + 63.9151, y + 47.4453, x + 62.8968, y + 47.4453);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementCoin(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale,scale);

  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.translate(0,0.00390625);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 42, y + 0);
  ctx.bezierCurveTo(x + 42, y + 0, x + 42, y + 0, x + 42, y + 0);
  ctx.lineTo(x + 42, y + 42);
  ctx.bezierCurveTo(x + 42, y + 42, x + 42, y + 42, x + 42, y + 42);
  ctx.lineTo(x + 0, y + 42);
  ctx.bezierCurveTo(x + 0, y + 42, x + 0, y + 42, x + 0, y + 42);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.translate(0,-0.00390625);
  ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 21, y + 42.0039);
  ctx.bezierCurveTo(x + 32.598, y + 42.0039, x + 42, y + 32.6019, x + 42, y + 21.0039);
  ctx.bezierCurveTo(x + 42, y + 9.40593, x + 32.598, y + 0.00390625, x + 21, y + 0.00390625);
  ctx.bezierCurveTo(x + 9.40202, y + 0.00390625, x + 0, y + 9.40593, x + 0, y + 21.0039);
  ctx.bezierCurveTo(x + 0, y + 32.6019, x + 9.40202, y + 42.0039, x + 21, y + 42.0039);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 20.9993, y + 37.1862);
  ctx.bezierCurveTo(x + 29.9358, y + 37.1862, x + 37.1803, y + 29.9417, x + 37.1803, y + 21.0052);
  ctx.bezierCurveTo(x + 37.1803, y + 12.0687, x + 29.9358, y + 4.82422, x + 20.9993, y + 4.82422);
  ctx.bezierCurveTo(x + 12.0628, y + 4.82422, x + 4.81836, y + 12.0687, x + 4.81836, y + 21.0052);
  ctx.bezierCurveTo(x + 4.81836, y + 29.9417, x + 12.0628, y + 37.1862, x + 20.9993, y + 37.1862);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 21.0001, y + 29.7932);
  ctx.bezierCurveTo(x + 25.8542, y + 29.7932, x + 29.7893, y + 25.8582, x + 29.7893, y + 21.004);
  ctx.bezierCurveTo(x + 29.7893, y + 16.1499, x + 25.8542, y + 12.2148, x + 21.0001, y + 12.2148);
  ctx.bezierCurveTo(x + 16.146, y + 12.2148, x + 12.2109, y + 16.1499, x + 12.2109, y + 21.004);
  ctx.bezierCurveTo(x + 12.2109, y + 25.8582, x + 16.146, y + 29.7932, x + 21.0001, y + 29.7932);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementBills(ctx,scale, x, y) {

  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale,scale);

  // ctx.scale(0.9638554216867469,0.9638554216867469);
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 72.2582, y + 24.4294);
  ctx.lineTo(x + 15.9385, y + 37.6581);
  ctx.bezierCurveTo(x + 14.7902, y + 37.9278, x + 14.078, y + 39.0773, x + 14.3477, y + 40.2256);
  ctx.lineTo(x + 21.8646, y + 72.2278);
  ctx.bezierCurveTo(x + 22.1343, y + 73.376, x + 23.2838, y + 74.0882, x + 24.4321, y + 73.8185);
  ctx.lineTo(x + 80.7518, y + 60.5898);
  ctx.bezierCurveTo(x + 81.9, y + 60.3201, x + 82.6122, y + 59.1706, x + 82.3425, y + 58.0224);
  ctx.lineTo(x + 74.8256, y + 26.0202);
  ctx.bezierCurveTo(x + 74.5559, y + 24.8719, x + 73.4064, y + 24.1597, x + 72.2582, y + 24.4294);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 56.6724, y + 11.6161);
  ctx.lineTo(x + 7.41977, y + 41.9656);
  ctx.bezierCurveTo(x + 6.4156, y + 42.5843, x + 6.10317, y + 43.9, x + 6.72195, y + 44.9041);
  ctx.lineTo(x + 23.9672, y + 72.8906);
  ctx.bezierCurveTo(x + 24.586, y + 73.8948, x + 25.9017, y + 74.2072, x + 26.9058, y + 73.5885);
  ctx.lineTo(x + 76.1584, y + 43.239);
  ctx.bezierCurveTo(x + 77.1626, y + 42.6202, x + 77.475, y + 41.3046, x + 76.8563, y + 40.3004);
  ctx.lineTo(x + 59.611, y + 12.3139);
  ctx.bezierCurveTo(x + 58.9922, y + 11.3097, x + 57.6765, y + 10.9973, x + 56.6724, y + 11.6161);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 33.9046, y + 1.76206);
  ctx.lineTo(x + 1.20706, y + 49.4881);
  ctx.bezierCurveTo(x + 0.540421, y + 50.4612, x + 0.788811, y + 51.7904, x + 1.76186, y + 52.4571);
  ctx.lineTo(x + 28.881, y + 71.0366);
  ctx.bezierCurveTo(x + 29.854, y + 71.7032, x + 31.1832, y + 71.4549, x + 31.8499, y + 70.4818);
  ctx.lineTo(x + 64.5474, y + 22.7557);
  ctx.bezierCurveTo(x + 65.2141, y + 21.7827, x + 64.9657, y + 20.4534, x + 63.9926, y + 19.7868);
  ctx.lineTo(x + 36.8735, y + 1.20727);
  ctx.bezierCurveTo(x + 35.9005, y + 0.540627, x + 34.5713, y + 0.789015, x + 33.9046, y + 1.76206);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 43.7369, y + 20.2678);
  ctx.bezierCurveTo(x + 41.1695, y + 18.5088, x + 40.3257, y + 15.1815, x + 41.6061, y + 12.4542);
  ctx.bezierCurveTo(x + 41.8629, y + 11.9123, x + 41.6493, y + 11.2543, x + 41.1555, y + 10.916);
  ctx.lineTo(x + 38.3958, y + 9.02531);
  ctx.bezierCurveTo(x + 36.8419, y + 7.96067, x + 34.7224, y + 8.35672, x + 33.6578, y + 9.91069);
  ctx.lineTo(x + 8.71538, y + 46.3172);
  ctx.bezierCurveTo(x + 7.65074, y + 47.8712, x + 8.04679, y + 49.9906, x + 9.60076, y + 51.0552);
  ctx.lineTo(x + 12.3605, y + 52.9459);
  ctx.bezierCurveTo(x + 12.8594, y + 53.2878, x + 13.5449, y + 53.2458, x + 13.9576, y + 52.8106);
  ctx.bezierCurveTo(x + 16.0384, y + 50.6317, x + 19.4459, y + 50.217, x + 22.0133, y + 51.976);
  ctx.bezierCurveTo(x + 24.5808, y + 53.735, x + 25.4246, y + 57.0623, x + 24.1442, y + 59.7895);
  ctx.bezierCurveTo(x + 23.8874, y + 60.3315, x + 24.101, y + 60.9895, x + 24.5947, y + 61.3277);
  ctx.lineTo(x + 27.3545, y + 63.2184);
  ctx.bezierCurveTo(x + 28.9084, y + 64.2831, x + 31.0278, y + 63.887, x + 32.0925, y + 62.3331);
  ctx.lineTo(x + 57.0385, y + 25.9213);
  ctx.bezierCurveTo(x + 58.1031, y + 24.3674, x + 57.7071, y + 22.248, x + 56.1531, y + 21.1833);
  ctx.lineTo(x + 53.3934, y + 19.2926);
  ctx.bezierCurveTo(x + 52.8944, y + 18.9508, x + 52.2089, y + 18.9928, x + 51.7962, y + 19.4279);
  ctx.bezierCurveTo(x + 49.7154, y + 21.6068, x + 46.3079, y + 22.0215, x + 43.7405, y + 20.2626);
  ctx.lineTo(x + 43.7369, y + 20.2678);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 40.6089, y + 41.7888);
  ctx.bezierCurveTo(x + 43.5351, y + 37.5178, x + 42.4448, y + 31.6833, x + 38.1737, y + 28.7571);
  ctx.bezierCurveTo(x + 33.9027, y + 25.831, x + 28.0682, y + 26.9212, x + 25.142, y + 31.1923);
  ctx.bezierCurveTo(x + 22.2159, y + 35.4634, x + 23.3061, y + 41.2979, x + 27.5772, y + 44.224);
  ctx.bezierCurveTo(x + 31.8483, y + 47.1502, x + 37.6828, y + 46.0599, x + 40.6089, y + 41.7888);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();

}

drawElementDisplayWindow(ctx, scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.scale(0.9638554216867469,0.9638554216867469);
  ctx.scale(scale,scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(0,13.9727);
  // ctx.rotate(-0.21157753857301262);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 66.5344, y + 0);
  ctx.bezierCurveTo(x + 66.5344, y + 0, x + 66.5344, y + 0, x + 66.5344, y + 0);
  ctx.lineTo(x + 66.5344, y + 84.5414);
  ctx.bezierCurveTo(x + 66.5344, y + 84.5414, x + 66.5344, y + 84.5414, x + 66.5344, y + 84.5414);
  ctx.lineTo(x + 0, y + 84.5414);
  ctx.bezierCurveTo(x + 0, y + 84.5414, x + 0, y + 84.5414, x + 0, y + 84.5414);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.21157753857301262);
  // ctx.translate(0,-13.9727);
  // ctx.clip();
  ctx.translate(x + 0, y + 13.9727); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x -0, -y - 13.9727); // regresar el origen a su posición

  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 9.05197, y + 16.8488);
  ctx.lineTo(x + 24.9939, y + 91.069);
  ctx.bezierCurveTo(x + 25.4481, y + 93.1839, x + 27.5309, y + 94.5302, x + 29.6458, y + 94.0759);
  ctx.lineTo(x + 70.9055, y + 85.2137);
  ctx.bezierCurveTo(x + 73.0205, y + 84.7594, x + 74.3667, y + 82.6766, x + 73.9125, y + 80.5617);
  ctx.lineTo(x + 57.9705, y + 6.34147);
  ctx.bezierCurveTo(x + 57.5163, y + 4.22653, x + 55.4335, y + 2.8803, x + 53.3186, y + 3.33457);
  ctx.lineTo(x + 12.0589, y + 12.1968);
  ctx.bezierCurveTo(x + 9.94393, y + 12.6511, x + 8.5977, y + 14.7338, x + 9.05197, y + 16.8488);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightThirdVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 13.338, y + 19.061);
  ctx.lineTo(x + 27.9914, y + 87.2821);
  ctx.bezierCurveTo(x + 28.3564, y + 88.9817, x + 30.0301, y + 90.0635, x + 31.7296, y + 89.6984);
  ctx.lineTo(x + 67.2016, y + 82.0793);
  ctx.bezierCurveTo(x + 68.9011, y + 81.7143, x + 69.9829, y + 80.0406, x + 69.6179, y + 78.3411);
  ctx.lineTo(x + 54.9645, y + 10.1199);
  ctx.bezierCurveTo(x + 54.5995, y + 8.42043, x + 52.9258, y + 7.33863, x + 51.2263, y + 7.70367);
  ctx.lineTo(x + 15.7543, y + 15.3228);
  ctx.bezierCurveTo(x + 14.0548, y + 15.6878, x + 12.973, y + 17.3615, x + 13.338, y + 19.061);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 58.3058, y + 2.47574);
  ctx.lineTo(x + 68.2269, y + 15.6018);
  ctx.bezierCurveTo(x + 68.9908, y + 16.6148, x + 68.4466, y + 18.0779, x + 67.2033, y + 18.345);
  ctx.lineTo(x + 5.5593, y + 31.5856);
  ctx.bezierCurveTo(x + 4.31597, y + 31.8527, x + 3.21898, y + 30.7421, x + 3.49949, y + 29.5047);
  ctx.lineTo(x + 7.16169, y + 13.4611);
  ctx.bezierCurveTo(x + 7.31325, y + 12.8042, x + 7.82726, y + 12.2906, x + 8.49244, y + 12.1477);
  ctx.lineTo(x + 56.5593, y + 1.82333);
  ctx.bezierCurveTo(x + 57.2183, y + 1.68179, x + 57.9041, y + 1.9377, x + 58.312, y + 2.4744);
  ctx.lineTo(x + 58.3058, y + 2.47574);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 56.6317, y + 17.8764);
  ctx.lineTo(x + 63.2027, y + 16.465);
  ctx.bezierCurveTo(x + 63.93, y + 16.3087, x + 64.3076, y + 15.5838, x + 63.9272, y + 15.0542);
  ctx.lineTo(x + 56.6339, y + 4.92757);
  ctx.bezierCurveTo(x + 56.4037, y + 4.61282, x + 55.9688, y + 4.46559, x + 55.5275, y + 4.5604);
  ctx.lineTo(x + 53.2895, y + 5.0411);
  ctx.bezierCurveTo(x + 52.6927, y + 5.16928, x + 52.311, y + 5.69349, x + 52.444, y + 6.1917);
  ctx.lineTo(x + 55.4044, y + 17.249);
  ctx.bezierCurveTo(x + 55.532, y + 17.7223, x + 56.0722, y + 17.9965, x + 56.6317, y + 17.8764);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 40.1843, y + 7.85138);
  ctx.lineTo(x + 35.0991, y + 8.94364);
  ctx.bezierCurveTo(x + 34.5209, y + 9.06782, x + 34.1401, y + 9.56583, x + 34.2456, y + 10.0569);
  ctx.lineTo(x + 36.6465, y + 21.2344);
  ctx.bezierCurveTo(x + 36.752, y + 21.7255, x + 37.3037, y + 22.0233, x + 37.8818, y + 21.8991);
  ctx.lineTo(x + 43.1908, y + 20.7587);
  ctx.bezierCurveTo(x + 43.7752, y + 20.6332, x + 44.1595, y + 20.1215, x + 44.0416, y + 19.633);
  ctx.lineTo(x + 41.4169, y + 8.5036);
  ctx.bezierCurveTo(x + 41.3004, y + 8.02137, x + 40.7562, y + 7.72854, x + 40.1843, y + 7.85138);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.1615, y + 5.92417);
  ctx.lineTo(x + 44.3064, y + 6.96703);
  ctx.bezierCurveTo(x + 43.722, y + 7.09254, x + 43.3377, y + 7.60432, x + 43.4556, y + 8.09276);
  ctx.lineTo(x + 46.0802, y + 19.2222);
  ctx.bezierCurveTo(x + 46.1968, y + 19.7044, x + 46.7409, y + 19.9972, x + 47.3129, y + 19.8744);
  ctx.lineTo(x + 52.5037, y + 18.7594);
  ctx.bezierCurveTo(x + 53.1005, y + 18.6312, x + 53.4822, y + 18.107, x + 53.3492, y + 17.6088);
  ctx.lineTo(x + 50.3889, y + 6.55152);
  ctx.bezierCurveTo(x + 50.2612, y + 6.07819, x + 49.721, y + 5.804, x + 49.1615, y + 5.92417);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#F9F3F1";

  ctx.beginPath();
  ctx.moveTo(x + 41.006, y + 78.4466);
  ctx.bezierCurveTo(x + 42.036, y + 78.2253, x + 42.6916, y + 77.211, x + 42.4704, y + 76.181);
  ctx.bezierCurveTo(x + 42.2492, y + 75.151, x + 41.2348, y + 74.4954, x + 40.2048, y + 74.7166);
  ctx.bezierCurveTo(x + 39.1748, y + 74.9378, x + 38.5192, y + 75.9522, x + 38.7404, y + 76.9822);
  ctx.bezierCurveTo(x + 38.9617, y + 78.0122, x + 39.976, y + 78.6678, x + 41.006, y + 78.4466);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#F9F3F1";

  ctx.beginPath();
  ctx.moveTo(x + 53.783, y + 71.8055);
  ctx.lineTo(x + 45.7263, y + 73.536);
  ctx.bezierCurveTo(x + 44.7341, y + 73.7491, x + 44.1025, y + 74.7262, x + 44.3156, y + 75.7185);
  ctx.lineTo(x + 44.3436, y + 75.849);
  ctx.bezierCurveTo(x + 44.5567, y + 76.8413, x + 45.5339, y + 77.4729, x + 46.5261, y + 77.2597);
  ctx.lineTo(x + 54.5829, y + 75.5292);
  ctx.bezierCurveTo(x + 55.5751, y + 75.3161, x + 56.2067, y + 74.3389, x + 55.9936, y + 73.3467);
  ctx.lineTo(x + 55.9655, y + 73.2162);
  ctx.bezierCurveTo(x + 55.7524, y + 72.2239, x + 54.7753, y + 71.5923, x + 53.783, y + 71.8055);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.5332, y + 36.9059);
  ctx.lineTo(x + 46.3752, y + 37.5842);
  ctx.bezierCurveTo(x + 45.9151, y + 37.683, x + 45.608, y + 38.1001, x + 45.6185, y + 38.5726);
  ctx.bezierCurveTo(x + 45.6727, y + 41.52, x + 43.6454, y + 44.1927, x + 40.6552, y + 44.8349);
  ctx.bezierCurveTo(x + 37.665, y + 45.4772, x + 34.7191, y + 43.8728, x + 33.5582, y + 41.1631);
  ctx.bezierCurveTo(x + 33.3737, y + 40.728, x + 32.9224, y + 40.4737, x + 32.4624, y + 40.5725);
  ctx.lineTo(x + 24.0637, y + 42.3765);
  ctx.bezierCurveTo(x + 23.5477, y + 42.4873, x + 23.2158, y + 43.0009, x + 23.3266, y + 43.5168);
  ctx.lineTo(x + 24.9036, y + 50.8587);
  ctx.bezierCurveTo(x + 25.0144, y + 51.3746, x + 25.5279, y + 51.7066, x + 26.0439, y + 51.5957);
  ctx.lineTo(x + 30.3458, y + 50.6717);
  ctx.bezierCurveTo(x + 30.8618, y + 50.5609, x + 31.3753, y + 50.8928, x + 31.4862, y + 51.4088);
  ctx.lineTo(x + 34.9138, y + 67.3669);
  ctx.bezierCurveTo(x + 35.0247, y + 67.8828, x + 35.5382, y + 68.2148, x + 36.0542, y + 68.104);
  ctx.lineTo(x + 54.4056, y + 64.1622);
  ctx.bezierCurveTo(x + 54.9216, y + 64.0514, x + 55.2535, y + 63.5378, x + 55.1427, y + 63.0219);
  ctx.lineTo(x + 51.715, y + 47.0638);
  ctx.bezierCurveTo(x + 51.6042, y + 46.5478, x + 51.9361, y + 46.0343, x + 52.4521, y + 45.9235);
  ctx.lineTo(x + 56.754, y + 44.9995);
  ctx.bezierCurveTo(x + 57.27, y + 44.8886, x + 57.6019, y + 44.3751, x + 57.4911, y + 43.8591);
  ctx.lineTo(x + 55.9141, y + 36.5173);
  ctx.bezierCurveTo(x + 55.8033, y + 36.0013, x + 55.2898, y + 35.6694, x + 54.7738, y + 35.7802);
  ctx.lineTo(x + 49.5332, y + 36.9059);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 54.7731, y + 35.782);
  ctx.lineTo(x + 46.3744, y + 37.5859);
  ctx.bezierCurveTo(x + 45.9144, y + 37.6847, x + 45.6073, y + 38.1019, x + 45.6177, y + 38.5744);
  ctx.bezierCurveTo(x + 45.6715, y + 41.3984, x + 43.8098, y + 43.9639, x + 41.0235, y + 44.7379);
  ctx.lineTo(x + 45.6022, y + 66.0547);
  ctx.lineTo(x + 54.4049, y + 64.164);
  ctx.bezierCurveTo(x + 54.9209, y + 64.0531, x + 55.2528, y + 63.5396, x + 55.142, y + 63.0236);
  ctx.lineTo(x + 51.7143, y + 47.0656);
  ctx.bezierCurveTo(x + 51.6035, y + 46.5496, x + 51.9354, y + 46.0361, x + 52.4514, y + 45.9252);
  ctx.lineTo(x + 56.7533, y + 45.0012);
  ctx.bezierCurveTo(x + 57.2693, y + 44.8904, x + 57.6012, y + 44.3769, x + 57.4904, y + 43.8609);
  ctx.lineTo(x + 55.9134, y + 36.5191);
  ctx.bezierCurveTo(x + 55.8026, y + 36.0031, x + 55.289, y + 35.6711, x + 54.7731, y + 35.782);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 44.9135, y + 49.3432);
  ctx.lineTo(x + 48.0591, y + 48.6675);
  ctx.bezierCurveTo(x + 48.5191, y + 48.5687, x + 48.9611, y + 48.7794, x + 49.0385, y + 49.14);
  ctx.lineTo(x + 49.63, y + 51.894);
  ctx.bezierCurveTo(x + 49.6674, y + 52.068, x + 49.6165, y + 52.2546, x + 49.4877, y + 52.4123);
  ctx.lineTo(x + 48.3032, y + 53.8308);
  ctx.bezierCurveTo(x + 48.0444, y + 54.1401, x + 47.556, y + 54.258, x + 47.1735, y + 54.1125);
  ctx.lineTo(x + 45.2058, y + 53.3385);
  ctx.bezierCurveTo(x + 44.9926, y + 53.2542, x + 44.842, y + 53.098, x + 44.8006, y + 52.9053);
  ctx.lineTo(x + 44.2158, y + 50.1824);
  ctx.bezierCurveTo(x + 44.1383, y + 49.8218, x + 44.4486, y + 49.4495, x + 44.9148, y + 49.3494);
  ctx.lineTo(x + 44.9135, y + 49.3432);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementShoppingApp(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(0,16.5078);
  // ctx.rotate(-0.28549746838272844);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 58.6195, y + 0);
  ctx.bezierCurveTo(x + 58.6195, y + 0, x + 58.6195, y + 0, x + 58.6195, y + 0);
  ctx.lineTo(x + 58.6195, y + 60.6297);
  ctx.bezierCurveTo(x + 58.6195, y + 60.6297, x + 58.6195, y + 60.6297, x + 58.6195, y + 60.6297);
  ctx.lineTo(x + 0, y + 60.6297);
  ctx.bezierCurveTo(x + 0, y + 60.6297, x + 0, y + 60.6297, x + 0, y + 60.6297);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.28549746838272844);
  // ctx.translate(0,-16.5078);
  // ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 59.8571, y + 25.6218);
  ctx.lineTo(x + 10.8115, y + 40.0174);
  ctx.bezierCurveTo(x + 8.82295, y + 40.6011, x + 7.68409, y + 42.6862, x + 8.26775, y + 44.6748);
  ctx.lineTo(x + 16.0171, y + 71.0767);
  ctx.bezierCurveTo(x + 16.6008, y + 73.0653, x + 18.686, y + 74.2041, x + 20.6745, y + 73.6205);
  ctx.lineTo(x + 69.7201, y + 59.2248);
  ctx.bezierCurveTo(x + 71.7086, y + 58.6412, x + 72.8475, y + 56.556, x + 72.2638, y + 54.5675);
  ctx.lineTo(x + 64.5144, y + 28.1655);
  ctx.bezierCurveTo(x + 63.9308, y + 26.177, x + 61.8456, y + 25.0381, x + 59.8571, y + 25.6218);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 58.7215, y + 28.6773);
  ctx.lineTo(x + 13.4167, y + 41.975);
  ctx.bezierCurveTo(x + 11.8188, y + 42.444, x + 10.9036, y + 44.1196, x + 11.3727, y + 45.7175);
  ctx.lineTo(x + 18.0687, y + 68.5306);
  ctx.bezierCurveTo(x + 18.5377, y + 70.1286, x + 20.2133, y + 71.0437, x + 21.8112, y + 70.5747);
  ctx.lineTo(x + 67.116, y + 57.2771);
  ctx.bezierCurveTo(x + 68.7139, y + 56.808, x + 69.629, y + 55.1325, x + 69.16, y + 53.5345);
  ctx.lineTo(x + 62.464, y + 30.7214);
  ctx.bezierCurveTo(x + 61.995, y + 29.1235, x + 60.3194, y + 28.2083, x + 58.7215, y + 28.6773);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  // ctx.fillStyle=`white`;
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.9189, y + 31.4271);
  ctx.lineTo(x + 60.7796, y + 32.055);
  ctx.bezierCurveTo(x + 59.9791, y + 32.29, x + 59.5206, y + 33.1295, x + 59.7555, y + 33.93);
  ctx.lineTo(x + 64.9676, y + 51.6872);
  ctx.bezierCurveTo(x + 65.2025, y + 52.4878, x + 66.042, y + 52.9463, x + 66.8426, y + 52.7113);
  ctx.lineTo(x + 68.9819, y + 52.0834);
  ctx.bezierCurveTo(x + 69.7824, y + 51.8484, x + 70.2409, y + 51.0089, x + 70.006, y + 50.2084);
  ctx.lineTo(x + 64.794, y + 32.4512);
  ctx.bezierCurveTo(x + 64.559, y + 31.6506, x + 63.7195, y + 31.1921, x + 62.9189, y + 31.4271);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;
  // ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 26.213, y + 47.7817);
  ctx.lineTo(x + 31.7523, y + 61.2464);
  ctx.bezierCurveTo(x + 32.0128, y + 61.8746, x + 32.7005, y + 62.2061, x + 33.3552, y + 62.0139);
  ctx.lineTo(x + 53.3218, y + 56.1534);
  ctx.bezierCurveTo(x + 53.9764, y + 55.9613, x + 54.3759, y + 55.3107, x + 54.2556, y + 54.6414);
  ctx.lineTo(x + 51.6389, y + 40.3188);
  ctx.bezierCurveTo(x + 51.4994, y + 39.5408, x + 50.7169, y + 39.0594, x + 49.9571, y + 39.2825);
  ctx.lineTo(x + 27.0679, y + 46.0008);
  ctx.bezierCurveTo(x + 26.3139, y + 46.2221, x + 25.9157, y + 47.05, x + 26.213, y + 47.7817);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 50.6199, y + 36.2225);
  ctx.lineTo(x + 24.855, y + 43.7849);
  ctx.bezierCurveTo(x + 23.8769, y + 44.072, x + 23.3167, y + 45.0976, x + 23.6038, y + 46.0757);
  ctx.lineTo(x + 24.1511, y + 47.9403);
  ctx.bezierCurveTo(x + 24.4382, y + 48.9184, x + 25.4638, y + 49.4786, x + 26.442, y + 49.1915);
  ctx.lineTo(x + 52.2068, y + 41.6291);
  ctx.bezierCurveTo(x + 53.1849, y + 41.342, x + 53.7451, y + 40.3164, x + 53.458, y + 39.3383);
  ctx.lineTo(x + 52.9108, y + 37.4737);
  ctx.bezierCurveTo(x + 52.6237, y + 36.4956, x + 51.598, y + 35.9354, x + 50.6199, y + 36.2225);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 33.2683, y + 51.1023);
  ctx.lineTo(x + 34.7938, y + 50.6545);
  ctx.bezierCurveTo(x + 35.5186, y + 50.4418, x + 36.2822, y + 50.8588, x + 36.4949, y + 51.5836);
  ctx.lineTo(x + 39.0649, y + 60.3395);
  ctx.lineTo(x + 34.9091, y + 61.5593);
  ctx.lineTo(x + 32.3391, y + 52.8034);
  ctx.bezierCurveTo(x + 32.1264, y + 52.0786, x + 32.5435, y + 51.315, x + 33.2683, y + 51.1023);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 39.78, y + 49.1882);
  ctx.lineTo(x + 41.3055, y + 48.7404);
  ctx.bezierCurveTo(x + 42.0303, y + 48.5277, x + 42.7939, y + 48.9448, x + 43.0067, y + 49.6696);
  ctx.lineTo(x + 45.5766, y + 58.4254);
  ctx.lineTo(x + 41.4208, y + 59.6452);
  ctx.lineTo(x + 38.8509, y + 50.8893);
  ctx.bezierCurveTo(x + 38.6381, y + 50.1646, x + 39.0552, y + 49.4009, x + 39.78, y + 49.1882);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 46.2917, y + 47.2781);
  ctx.lineTo(x + 47.8172, y + 46.8303);
  ctx.bezierCurveTo(x + 48.542, y + 46.6175, x + 49.3057, y + 47.0346, x + 49.5184, y + 47.7594);
  ctx.lineTo(x + 52.0884, y + 56.5152);
  ctx.lineTo(x + 47.9325, y + 57.735);
  ctx.lineTo(x + 45.3626, y + 48.9792);
  ctx.bezierCurveTo(x + 45.1498, y + 48.2544, x + 45.5669, y + 47.4908, x + 46.2917, y + 47.2781);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 57.5736, y + 43.0184);
  ctx.lineTo(x + 47.8839, y + 39.0004);
  ctx.bezierCurveTo(x + 46.8273, y + 38.5622, x + 45.6155, y + 39.0636, x + 45.1774, y + 40.1202);
  ctx.lineTo(x + 45.0677, y + 40.3847);
  ctx.bezierCurveTo(x + 44.6296, y + 41.4413, x + 45.1309, y + 42.6531, x + 46.1875, y + 43.0912);
  ctx.lineTo(x + 55.8772, y + 47.1092);
  ctx.bezierCurveTo(x + 56.9339, y + 47.5474, x + 58.1456, y + 47.046, x + 58.5838, y + 45.9894);
  ctx.lineTo(x + 58.6934, y + 45.7249);
  ctx.bezierCurveTo(x + 59.1316, y + 44.6683, x + 58.6302, y + 43.4565, x + 57.5736, y + 43.0184);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 28.0985, y + 44.3346);
  ctx.lineTo(x + 24.0804, y + 54.0243);
  ctx.bezierCurveTo(x + 23.6423, y + 55.0809, x + 24.1437, y + 56.2927, x + 25.2003, y + 56.7308);
  ctx.lineTo(x + 25.4648, y + 56.8405);
  ctx.bezierCurveTo(x + 26.5214, y + 57.2786, x + 27.7331, y + 56.7773, x + 28.1713, y + 55.7207);
  ctx.lineTo(x + 32.1893, y + 46.031);
  ctx.bezierCurveTo(x + 32.6274, y + 44.9743, x + 32.1261, y + 43.7626, x + 31.0694, y + 43.3244);
  ctx.lineTo(x + 30.805, y + 43.2148);
  ctx.bezierCurveTo(x + 29.7483, y + 42.7766, x + 28.5366, y + 43.278, x + 28.0985, y + 44.3346);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 28.977, y + 10.9721);
  ctx.lineTo(x + 20.3264, y + 13.5112);
  ctx.bezierCurveTo(x + 19.9348, y + 13.6261, x + 19.7118, y + 14.0344, x + 19.8267, y + 14.426);
  ctx.lineTo(x + 26.4198, y + 36.8884);
  ctx.bezierCurveTo(x + 26.5347, y + 37.28, x + 26.943, y + 37.503, x + 27.3346, y + 37.3881);
  ctx.lineTo(x + 32.5601, y + 35.8543);
  ctx.bezierCurveTo(x + 32.8991, y + 35.7548, x + 33.121, y + 35.4294, x + 33.0858, y + 35.0715);
  ctx.lineTo(x + 31.982, y + 23.7839);
  ctx.bezierCurveTo(x + 31.9017, y + 22.9695, x + 33.0064, y + 22.6453, x + 33.379, y + 23.3739);
  ctx.lineTo(x + 38.5509, y + 33.4674);
  ctx.bezierCurveTo(x + 38.713, y + 33.7817, x + 39.0773, y + 33.9414, x + 39.4163, y + 33.8419);
  ctx.lineTo(x + 44.6418, y + 32.3082);
  ctx.bezierCurveTo(x + 45.0334, y + 32.1932, x + 45.2564, y + 31.785, x + 45.1414, y + 31.3933);
  ctx.lineTo(x + 38.5483, y + 8.93092);
  ctx.bezierCurveTo(x + 38.4334, y + 8.5393, x + 38.0251, y + 8.31631, x + 37.6335, y + 8.43126);
  ctx.lineTo(x + 28.9829, y + 10.9704);
  ctx.lineTo(x + 28.977, y + 10.9721);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 37.4614, y + 5.51138);
  ctx.lineTo(x + 18.7924, y + 10.991);
  ctx.bezierCurveTo(x + 18.2242, y + 11.1578, x + 17.8989, y + 11.7535, x + 18.0656, y + 12.3217);
  ctx.lineTo(x + 18.6352, y + 14.2622);
  ctx.bezierCurveTo(x + 18.802, y + 14.8304, x + 19.3977, y + 15.1558, x + 19.9659, y + 14.989);
  ctx.lineTo(x + 38.6349, y + 9.50938);
  ctx.bezierCurveTo(x + 39.203, y + 9.34262, x + 39.5284, y + 8.74686, x + 39.3617, y + 8.17871);
  ctx.lineTo(x + 38.7921, y + 6.23816);
  ctx.bezierCurveTo(x + 38.6253, y + 5.67001, x + 38.0296, y + 5.34462, x + 37.4614, y + 5.51138);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 32.6955, y + 33.0714);
  ctx.lineTo(x + 25.8627, y + 35.077);
  ctx.bezierCurveTo(x + 25.2946, y + 35.2437, x + 24.9692, y + 35.8395, x + 25.1359, y + 36.4076);
  ctx.lineTo(x + 25.582, y + 37.9273);
  ctx.bezierCurveTo(x + 25.7487, y + 38.4955, x + 26.3445, y + 38.8209, x + 26.9127, y + 38.6541);
  ctx.lineTo(x + 33.7455, y + 36.6486);
  ctx.bezierCurveTo(x + 34.3136, y + 36.4818, x + 34.639, y + 35.8861, x + 34.4723, y + 35.3179);
  ctx.lineTo(x + 34.0262, y + 33.7982);
  ctx.bezierCurveTo(x + 33.8595, y + 33.23, x + 33.2637, y + 32.9047, x + 32.6955, y + 33.0714);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 44.5256, y + 29.5988);
  ctx.lineTo(x + 37.6928, y + 31.6043);
  ctx.bezierCurveTo(x + 37.1246, y + 31.7711, x + 36.7992, y + 32.3668, x + 36.966, y + 32.935);
  ctx.lineTo(x + 37.4121, y + 34.4547);
  ctx.bezierCurveTo(x + 37.5788, y + 35.0228, x + 38.1746, y + 35.3482, x + 38.7427, y + 35.1815);
  ctx.lineTo(x + 45.5756, y + 33.1759);
  ctx.bezierCurveTo(x + 46.1437, y + 33.0092, x + 46.4691, y + 32.4134, x + 46.3023, y + 31.8452);
  ctx.lineTo(x + 45.8563, y + 30.3255);
  ctx.bezierCurveTo(x + 45.6895, y + 29.7574, x + 45.0938, y + 29.432, x + 44.5256, y + 29.5988);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 23.4392, y + 15.7927);
  ctx.lineTo(x + 26.8351, y + 14.7959);
  ctx.bezierCurveTo(x + 27.3378, y + 14.6484, x + 27.8338, y + 14.8456, x + 27.9487, y + 15.2372);
  ctx.lineTo(x + 28.822, y + 18.2124);
  ctx.bezierCurveTo(x + 28.8769, y + 18.3994, x + 28.8365, y + 18.608, x + 28.7065, y + 18.7922);
  ctx.lineTo(x + 27.5209, y + 20.4417);
  ctx.bezierCurveTo(x + 27.265, y + 20.8025, x + 26.7382, y + 20.9761, x + 26.3055, y + 20.8429);
  ctx.lineTo(x + 24.0848, y + 20.1551);
  ctx.bezierCurveTo(x + 23.8407, y + 20.0807, x + 23.6655, y + 19.9163, x + 23.6055, y + 19.7117);
  ctx.lineTo(x + 22.7425, y + 16.7717);
  ctx.bezierCurveTo(x + 22.6276, y + 16.3801, x + 22.9382, y + 15.9461, x + 23.4409, y + 15.7985);
  ctx.lineTo(x + 23.4392, y + 15.7927);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 32.4822, y + 13.1364);
  ctx.lineTo(x + 35.8781, y + 12.1397);
  ctx.bezierCurveTo(x + 36.3808, y + 11.9921, x + 36.8767, y + 12.1894, x + 36.9917, y + 12.581);
  ctx.lineTo(x + 37.8649, y + 15.5561);
  ctx.bezierCurveTo(x + 37.9198, y + 15.7431, x + 37.8795, y + 15.9518, x + 37.7494, y + 16.136);
  ctx.lineTo(x + 36.5639, y + 17.7854);
  ctx.bezierCurveTo(x + 36.3079, y + 18.1462, x + 35.7812, y + 18.3199, x + 35.3484, y + 18.1866);
  ctx.lineTo(x + 33.1277, y + 17.4989);
  ctx.bezierCurveTo(x + 32.8837, y + 17.4245, x + 32.7085, y + 17.2601, x + 32.6484, y + 17.0555);
  ctx.lineTo(x + 31.7855, y + 14.1154);
  ctx.bezierCurveTo(x + 31.6705, y + 13.7238, x + 31.9812, y + 13.2898, x + 32.4839, y + 13.1423);
  ctx.lineTo(x + 32.4822, y + 13.1364);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementClothes(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);

  // ctx.scale(0.6060606060606061,0.6060606060606061);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x, y + 23.543);
  // ctx.rotate(-0.20870123596572596);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 113.624, y + 0);
  ctx.bezierCurveTo(x + 113.624, y + 0, x + 113.624, y + 0, x + 113.624, y + 0);
  ctx.lineTo(x + 113.624, y + 99.5846);
  ctx.bezierCurveTo(x + 113.624, y + 99.5846, x + 113.624, y + 99.5846, x + 113.624, y + 99.5846);
  ctx.lineTo(x + 0, y + 99.5846);
  ctx.bezierCurveTo(x + 0, y + 99.5846, x + 0, y + 99.5846, x + 0, y + 99.5846);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.20870123596572596);
  // ctx.translate(x, y - 23.543);
  // ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 55.5078, y + 78.3477);
  ctx.lineTo(x + 121.391, y + 64.3945);
  ctx.bezierCurveTo(x + 123.073, y + 64.0383, x + 124.734, y + 65.1185, x + 125.09, y + 66.8004);
  ctx.lineTo(x + 128.463, y + 82.7262);
  ctx.bezierCurveTo(x + 128.777, y + 84.2108, x + 127.987, y + 85.7017, x + 126.578, y + 86.2712);
  ctx.lineTo(x + 62.6493, y + 112.068);
  ctx.lineTo(x + 55.5078, y + 78.3477);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 91.1771, y + 15.5157);
  ctx.bezierCurveTo(x + 83.372, y + 8.52315, x + 75.9277, y + 8.7655, x + 75.9277, y + 8.7655);
  ctx.bezierCurveTo(x + 75.9277, y + 8.7655, x + 72.0894, y + 21.63, x + 55.475, y + 25.0294);
  ctx.bezierCurveTo(x + 38.9089, y + 28.6572, x + 30.1851, y + 18.4531, x + 30.1851, y + 18.4531);
  ctx.bezierCurveTo(x + 30.1851, y + 18.4531, x + 23.2816, y + 21.2494, x + 18.9814, y + 30.8056);
  ctx.bezierCurveTo(x + 14.6811, y + 40.3618, x + 15.8119, y + 71.1572, x + 15.8119, y + 71.1572);
  ctx.lineTo(x + 31.9843, y + 72.8413);
  ctx.lineTo(x + 38.1275, y + 101.848);
  ctx.bezierCurveTo(x + 38.1275, y + 101.848, x + 39.027, y + 104.457, x + 50.989, y + 102.292);
  ctx.bezierCurveTo(x + 62.9511, y + 100.127, x + 70.943, y + 98.066, x + 70.943, y + 98.066);
  ctx.bezierCurveTo(x + 70.943, y + 98.066, x + 79.0845, y + 96.7106, x + 90.897, y + 93.8401);
  ctx.bezierCurveTo(x + 102.71, y + 90.9695, x + 102.475, y + 88.2206, x + 102.475, y + 88.2206);
  ctx.lineTo(x + 96.3313, y + 59.2136);
  ctx.lineTo(x + 110.432, y + 51.118);
  ctx.bezierCurveTo(x + 110.432, y + 51.118, x + 98.9799, y + 22.4978, x + 91.1771, y + 15.5157);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 110.431, y + 51.1178);
  ctx.lineTo(x + 96.3297, y + 59.2134);
  ctx.lineTo(x + 91.0242, y + 34.1619);
  ctx.bezierCurveTo(x + 91.0242, y + 34.1619, x + 93.3229, y + 23.1963, x + 91.5919, y + 15.8937);
  ctx.bezierCurveTo(x + 91.5919, y + 15.8937, x + 96.6771, y + 20.9022, x + 102.2, y + 32.793);
  ctx.bezierCurveTo(x + 107.721, y + 44.6733, x + 110.431, y + 51.1178, x + 110.431, y + 51.1178);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 56.7022, y + 30.0602);
  ctx.bezierCurveTo(x + 56.4738, y + 30.1086, x + 56.2558, y + 30.1548, x + 56.0274, y + 30.2032);
  ctx.bezierCurveTo(x + 44.6753, y + 32.3795, x + 33.3455, y + 28.9756, x + 26.4633, y + 21.3212);
  ctx.lineTo(x + 31.3836, y + 16.8947);
  ctx.bezierCurveTo(x + 36.7207, y + 22.837, x + 45.6918, y + 25.4496, x + 54.7925, y + 23.7066);
  ctx.bezierCurveTo(x + 64.1758, y + 21.9147, x + 71.8605, y + 15.6878, x + 74.3155, y + 7.86747);
  ctx.lineTo(x + 80.6257, y + 9.85042);
  ctx.bezierCurveTo(x + 77.5075, y + 19.7638, x + 68.1638, y + 27.6328, x + 56.7126, y + 30.058);
  ctx.lineTo(x + 56.7022, y + 30.0602);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 96.9493, y + 62.1306);
  ctx.lineTo(x + 50.127, y + 72.0469);
  ctx.lineTo(x + 53.0886, y + 86.0313);
  ctx.lineTo(x + 99.911, y + 76.115);
  ctx.lineTo(x + 96.9493, y + 62.1306);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 14.8406, y + 93.5152);
  ctx.bezierCurveTo(x + 14.8461, y + 94.0022, x + 15.1237, y + 94.4424, x + 15.559, y + 94.6539);
  ctx.lineTo(x + 55.0899, y + 113.412);
  ctx.bezierCurveTo(x + 55.3431, y + 113.532, x + 55.6202, y + 113.56, x + 55.8901, y + 113.502);
  ctx.lineTo(x + 62.6384, y + 112.073);
  ctx.lineTo(x + 50.9543, y + 56.9039);
  ctx.lineTo(x + 14.7111, y + 64.5797);
  ctx.lineTo(x + 14.8406, y + 93.5152);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 47.2793, y + 45.5977);
  ctx.bezierCurveTo(x + 47.9645, y + 45.4525, x + 48.6313, y + 45.8862, x + 48.7764, y + 46.5715);
  ctx.lineTo(x + 48.5147, y + 45.336);
  ctx.lineTo(x + 47.2793, y + 45.5977);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 47.2798, y + 45.5954);
  ctx.lineTo(x + 15.667, y + 52.2905);
  ctx.bezierCurveTo(x + 15.0856, y + 52.4137, x + 14.6648, y + 52.9367, x + 14.6612, y + 53.5341);
  ctx.lineTo(x + 14.7111, y + 64.5772);
  ctx.lineTo(x + 50.9647, y + 56.8992);
  ctx.lineTo(x + 48.7769, y + 46.5692);
  ctx.bezierCurveTo(x + 48.6318, y + 45.884, x + 47.9651, y + 45.4503, x + 47.2798, y + 45.5954);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 22.2584, y + 50.8923);
  ctx.lineTo(x + 39.6896, y + 47.2007);
  ctx.lineTo(x + 42.0862, y + 58.5169);
  ctx.bezierCurveTo(x + 42.3149, y + 59.5966, x + 41.6275, y + 60.6534, x + 40.5478, y + 60.8821);
  ctx.lineTo(x + 27.0098, y + 63.7492);
  ctx.bezierCurveTo(x + 25.9301, y + 63.9779, x + 24.8733, y + 63.2905, x + 24.6447, y + 62.2108);
  ctx.lineTo(x + 22.248, y + 50.8945);
  ctx.lineTo(x + 22.2584, y + 50.8923);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 113.594, y + 66.0455);
  ctx.lineTo(x + 121.567, y + 64.3569);
  ctx.bezierCurveTo(x + 123.28, y + 63.9941, x + 124.954, y + 65.0825, x + 125.316, y + 66.7955);
  ctx.lineTo(x + 128.65, y + 82.5345);
  ctx.bezierCurveTo(x + 128.968, y + 84.0398, x + 128.159, y + 85.5455, x + 126.729, y + 86.1194);
  ctx.lineTo(x + 118.541, y + 89.4048);
  ctx.lineTo(x + 113.592, y + 66.0352);
  ctx.lineTo(x + 113.594, y + 66.0455);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 27.853, y + 69.1138);
  ctx.lineTo(x + 40.7993, y + 66.372);
  ctx.bezierCurveTo(x + 42.7095, y + 65.9674, x + 44.5011, y + 66.8463, x + 44.8177, y + 68.3413);
  ctx.lineTo(x + 47.2187, y + 79.6783);
  ctx.bezierCurveTo(x + 47.3704, y + 80.3947, x + 47.1522, y + 81.1568, x + 46.6163, y + 81.8018);
  ctx.lineTo(x + 41.7305, y + 87.6203);
  ctx.bezierCurveTo(x + 40.675, y + 88.8853, x + 38.6533, y + 89.3785, x + 37.0894, y + 88.7551);
  ctx.lineTo(x + 29.0026, y + 85.5539);
  ctx.bezierCurveTo(x + 28.1266, y + 85.2078, x + 27.5196, y + 84.5445, x + 27.3525, y + 83.7555);
  ctx.lineTo(x + 24.9779, y + 72.543);
  ctx.bezierCurveTo(x + 24.6613, y + 71.048, x + 25.9532, y + 69.5161, x + 27.853, y + 69.1138);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementShoppingWeb(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);

  // ctx.scale(0.7766990291262136,0.7766990291262136);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 103, y + 0);
  ctx.bezierCurveTo(x + 103, y + 0, x + 103, y + 0, x + 103, y + 0);
  ctx.lineTo(x + 103, y + 88);
  ctx.bezierCurveTo(x + 103, y + 88, x + 103, y + 88, x + 103, y + 88);
  ctx.lineTo(x + 0, y + 88);
  ctx.bezierCurveTo(x + 0, y + 88, x + 0, y + 88, x + 0, y + 88);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle="#F9F3F1";

  ctx.beginPath();
  ctx.moveTo(x + 97.3779, y + 0);
  ctx.lineTo(x + 5.62211, y + 0);
  ctx.bezierCurveTo(x + 2.51711, y + 0, x + 0, y + 2.55412, x + 0, y + 5.70479);
  ctx.lineTo(x + 0, y + 82.2952);
  ctx.bezierCurveTo(x + 0, y + 85.4459, x + 2.51711, y + 88, x + 5.62211, y + 88);
  ctx.lineTo(x + 97.3779, y + 88);
  ctx.bezierCurveTo(x + 100.483, y + 88, x + 103, y + 85.4459, x + 103, y + 82.2952);
  ctx.lineTo(x + 103, y + 5.70479);
  ctx.bezierCurveTo(x + 103, y + 2.55412, x + 100.483, y + 0, x + 97.3779, y + 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 5.06711, y + 0);
  ctx.lineTo(x + 97.9401, y + 0);
  ctx.bezierCurveTo(x + 100.737, y + 0, x + 103.007, y + 2.30386, x + 103.007, y + 5.14162);
  ctx.lineTo(x + 103.007, y + 13.5379);
  ctx.lineTo(x + 0, y + 13.5379);
  ctx.lineTo(x + 0, y + 5.14162);
  ctx.bezierCurveTo(x + 0, y + 2.30386, x + 2.27047, y + 0, x + 5.06711, y + 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 96.232, y + 8.75607);
  ctx.bezierCurveTo(x + 97.3148, y + 8.75607, x + 98.1925, y + 7.8654, x + 98.1925, y + 6.76671);
  ctx.bezierCurveTo(x + 98.1925, y + 5.66801, x + 97.3148, y + 4.77734, x + 96.232, y + 4.77734);
  ctx.bezierCurveTo(x + 95.1492, y + 4.77734, x + 94.2715, y + 5.66801, x + 94.2715, y + 6.76671);
  ctx.bezierCurveTo(x + 94.2715, y + 7.8654, x + 95.1492, y + 8.75607, x + 96.232, y + 8.75607);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 90.566, y + 8.75607);
  ctx.bezierCurveTo(x + 91.6488, y + 8.75607, x + 92.5265, y + 7.8654, x + 92.5265, y + 6.76671);
  ctx.bezierCurveTo(x + 92.5265, y + 5.66801, x + 91.6488, y + 4.77734, x + 90.566, y + 4.77734);
  ctx.bezierCurveTo(x + 89.4832, y + 4.77734, x + 88.6055, y + 5.66801, x + 88.6055, y + 6.76671);
  ctx.bezierCurveTo(x + 88.6055, y + 7.8654, x + 89.4832, y + 8.75607, x + 90.566, y + 8.75607);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 84.9019, y + 8.75607);
  ctx.bezierCurveTo(x + 85.9847, y + 8.75607, x + 86.8625, y + 7.8654, x + 86.8625, y + 6.76671);
  ctx.bezierCurveTo(x + 86.8625, y + 5.66801, x + 85.9847, y + 4.77734, x + 84.9019, y + 4.77734);
  ctx.bezierCurveTo(x + 83.8192, y + 4.77734, x + 82.9414, y + 5.66801, x + 82.9414, y + 6.76671);
  ctx.bezierCurveTo(x + 82.9414, y + 7.8654, x + 83.8192, y + 8.75607, x + 84.9019, y + 8.75607);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 66.3702, y + 25.2617);
  ctx.lineTo(x + 61.7284, y + 25.2617);
  ctx.bezierCurveTo(x + 61.0509, y + 25.2617, x + 60.4959, y + 25.7664, x + 60.3661, y + 26.4392);
  ctx.bezierCurveTo(x + 59.5516, y + 30.6593, x + 55.8973, y + 33.8482, x + 51.5005, y + 33.8482);
  ctx.bezierCurveTo(x + 47.1037, y + 33.8482, x + 43.4421, y + 30.6593, x + 42.6348, y + 26.4392);
  ctx.bezierCurveTo(x + 42.5051, y + 25.7664, x + 41.9501, y + 25.2617, x + 41.2725, y + 25.2617);
  ctx.lineTo(x + 28.9255, y + 25.2617);
  ctx.bezierCurveTo(x + 28.1615, y + 25.2617, x + 27.5488, y + 25.8907, x + 27.5488, y + 26.6587);
  ctx.lineTo(x + 27.5488, y + 37.6148);
  ctx.bezierCurveTo(x + 27.5488, y + 38.39, x + 28.1687, y + 39.0117, x + 28.9255, y + 39.0117);
  ctx.lineTo(x + 35.254, y + 39.0117);
  ctx.bezierCurveTo(x + 36.018, y + 39.0117, x + 36.6307, y + 39.6407, x + 36.6307, y + 40.4087);
  ctx.lineTo(x + 36.6307, y + 64.2225);
  ctx.bezierCurveTo(x + 36.6307, y + 64.9978, x + 37.2506, y + 65.6194, x + 38.0074, y + 65.6194);
  ctx.lineTo(x + 64.9935, y + 65.6194);
  ctx.bezierCurveTo(x + 65.7576, y + 65.6194, x + 66.3702, y + 64.9904, x + 66.3702, y + 64.2225);
  ctx.lineTo(x + 66.3702, y + 40.4087);
  ctx.bezierCurveTo(x + 66.3702, y + 39.6334, x + 66.9901, y + 39.0117, x + 67.7469, y + 39.0117);
  ctx.lineTo(x + 74.0754, y + 39.0117);
  ctx.bezierCurveTo(x + 74.8394, y + 39.0117, x + 75.4521, y + 38.3827, x + 75.4521, y + 37.6148);
  ctx.lineTo(x + 75.4521, y + 26.6587);
  ctx.bezierCurveTo(x + 75.4521, y + 25.8834, x + 74.8322, y + 25.2617, x + 74.0754, y + 25.2617);
  ctx.lineTo(x + 66.3702, y + 25.2617);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 73.023, y + 76.9492);
  ctx.lineTo(x + 29.9706, y + 76.9492);
  ctx.bezierCurveTo(x + 28.3623, y + 76.9492, x + 27.0586, y + 78.2705, x + 27.0586, y + 79.9004);
  ctx.bezierCurveTo(x + 27.0586, y + 81.5302, x + 28.3623, y + 82.8515, x + 29.9706, y + 82.8515);
  ctx.lineTo(x + 73.023, y + 82.8515);
  ctx.bezierCurveTo(x + 74.6312, y + 82.8515, x + 75.9349, y + 81.5302, x + 75.9349, y + 79.9004);
  ctx.bezierCurveTo(x + 75.9349, y + 78.2705, x + 74.6312, y + 76.9492, x + 73.023, y + 76.9492);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.184, y + 73.5328);
  ctx.bezierCurveTo(x + 49.0041, y + 73.5328, x + 49.6689, y + 72.8583, x + 49.6689, y + 72.0262);
  ctx.bezierCurveTo(x + 49.6689, y + 71.1941, x + 49.0041, y + 70.5195, x + 48.184, y + 70.5195);
  ctx.bezierCurveTo(x + 47.364, y + 70.5195, x + 46.6992, y + 71.1941, x + 46.6992, y + 72.0262);
  ctx.bezierCurveTo(x + 46.6992, y + 72.8583, x + 47.364, y + 73.5328, x + 48.184, y + 73.5328);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 44.0473, y + 73.5328);
  ctx.bezierCurveTo(x + 44.8674, y + 73.5328, x + 45.5321, y + 72.8583, x + 45.5321, y + 72.0262);
  ctx.bezierCurveTo(x + 45.5321, y + 71.1941, x + 44.8674, y + 70.5195, x + 44.0473, y + 70.5195);
  ctx.bezierCurveTo(x + 43.2273, y + 70.5195, x + 42.5625, y + 71.1941, x + 42.5625, y + 72.0262);
  ctx.bezierCurveTo(x + 42.5625, y + 72.8583, x + 43.2273, y + 73.5328, x + 44.0473, y + 73.5328);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 58.9604, y + 70.5195);
  ctx.lineTo(x + 52.6391, y + 70.5195);
  ctx.bezierCurveTo(x + 51.8191, y + 70.5195, x + 51.1543, y + 71.1941, x + 51.1543, y + 72.0262);
  ctx.bezierCurveTo(x + 51.1543, y + 72.8583, x + 51.8191, y + 73.5328, x + 52.6391, y + 73.5328);
  ctx.lineTo(x + 58.9604, y + 73.5328);
  ctx.bezierCurveTo(x + 59.7804, y + 73.5328, x + 60.4452, y + 72.8583, x + 60.4452, y + 72.0262);
  ctx.bezierCurveTo(x + 60.4452, y + 71.1941, x + 59.7804, y + 70.5195, x + 58.9604, y + 70.5195);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 22.265, y + 25.2617);
  ctx.lineTo(x + 9.91799, y + 25.2617);
  ctx.bezierCurveTo(x + 9.24045, y + 25.2617, x + 8.68544, y + 25.7664, x + 8.5557, y + 26.4392);
  ctx.bezierCurveTo(x + 7.76284, y + 30.5642, x + 4.25983, y + 33.6872, x + 0, y + 33.8335);
  ctx.lineTo(x + 0, y + 65.6268);
  ctx.lineTo(x + 13.1831, y + 65.6268);
  ctx.bezierCurveTo(x + 13.9472, y + 65.6268, x + 14.5598, y + 64.9978, x + 14.5598, y + 64.2298);
  ctx.lineTo(x + 14.5598, y + 40.416);
  ctx.bezierCurveTo(x + 14.5598, y + 39.6407, x + 15.1797, y + 39.019, x + 15.9365, y + 39.019);
  ctx.lineTo(x + 22.265, y + 39.019);
  ctx.bezierCurveTo(x + 23.029, y + 39.019, x + 23.6417, y + 38.39, x + 23.6417, y + 37.6221);
  ctx.lineTo(x + 23.6417, y + 26.666);
  ctx.bezierCurveTo(x + 23.6417, y + 25.8907, x + 23.0218, y + 25.269, x + 22.265, y + 25.269);
  ctx.lineTo(x + 22.265, y + 25.2617);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 102.999, y + 33.8335);
  ctx.bezierCurveTo(x + 98.7393, y + 33.6872, x + 95.2363, y + 30.5569, x + 94.4434, y + 26.4392);
  ctx.bezierCurveTo(x + 94.3137, y + 25.7664, x + 93.7587, y + 25.2617, x + 93.0811, y + 25.2617);
  ctx.lineTo(x + 80.7341, y + 25.2617);
  ctx.bezierCurveTo(x + 79.9701, y + 25.2617, x + 79.3574, y + 25.8907, x + 79.3574, y + 26.6587);
  ctx.lineTo(x + 79.3574, y + 37.6148);
  ctx.bezierCurveTo(x + 79.3574, y + 38.39, x + 79.9773, y + 39.0117, x + 80.7341, y + 39.0117);
  ctx.lineTo(x + 87.0626, y + 39.0117);
  ctx.bezierCurveTo(x + 87.8266, y + 39.0117, x + 88.4393, y + 39.6407, x + 88.4393, y + 40.4087);
  ctx.lineTo(x + 88.4393, y + 64.2225);
  ctx.bezierCurveTo(x + 88.4393, y + 64.9978, x + 89.0592, y + 65.6194, x + 89.816, y + 65.6194);
  ctx.lineTo(x + 102.999, y + 65.6194);
  ctx.lineTo(x + 102.999, y + 33.8262);
  ctx.lineTo(x + 102.999, y + 33.8335);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementClothesRack(ctx,scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  // ctx.scale(0.8247422680412372,0.8247422680412372);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x, y + 19.082);
  // ctx.rotate(-0.24126384382018415);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 79.8662, y + 0);
  ctx.bezierCurveTo(x + 79.8662, y + 0, x + 79.8662, y + 0, x + 79.8662, y + 0);
  ctx.lineTo(x + 79.8662, y + 77.5912);
  ctx.bezierCurveTo(x + 79.8662, y + 77.5912, x + 79.8662, y + 77.5912, x + 79.8662, y + 77.5912);
  ctx.lineTo(x + 0, y + 77.5912);
  ctx.bezierCurveTo(x + 0, y + 77.5912, x + 0, y + 77.5912, x + 0, y + 77.5912);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.24126384382018415);
  // ctx.translate(x, y - 19.082);
  // ctx.clip();

  ctx.translate(x + 0, y + 19.082); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x -0, -y - 19.082); // regresar el origen a su posición

  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 78.3772, y + 60.5553);
  ctx.lineTo(x + 27.0454, y + 73.1859);
  ctx.bezierCurveTo(x + 26.1563, y + 73.4047, x + 25.6129, y + 74.3028, x + 25.8317, y + 75.1918);
  ctx.lineTo(x + 29.2246, y + 88.981);
  ctx.bezierCurveTo(x + 29.4433, y + 89.8701, x + 30.3414, y + 90.4135, x + 31.2305, y + 90.1947);
  ctx.lineTo(x + 82.5623, y + 77.5641);
  ctx.bezierCurveTo(x + 83.4514, y + 77.3453, x + 83.9948, y + 76.4473, x + 83.776, y + 75.5582);
  ctx.lineTo(x + 80.3831, y + 61.769);
  ctx.bezierCurveTo(x + 80.1644, y + 60.88, x + 79.2663, y + 60.3366, x + 78.3772, y + 60.5553);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.8538, y + 62.185);
  ctx.lineTo(x + 28.9262, y + 67.3344);
  ctx.bezierCurveTo(x + 28.0371, y + 67.5531, x + 27.4937, y + 68.4512, x + 27.7125, y + 69.3403);
  ctx.lineTo(x + 28.1708, y + 71.2027);
  ctx.bezierCurveTo(x + 28.3895, y + 72.0918, x + 29.2876, y + 72.6352, x + 30.1767, y + 72.4164);
  ctx.lineTo(x + 51.1043, y + 67.2671);
  ctx.bezierCurveTo(x + 51.9933, y + 67.0483, x + 52.5367, y + 66.1502, x + 52.318, y + 65.2611);
  ctx.lineTo(x + 51.8597, y + 63.3987);
  ctx.bezierCurveTo(x + 51.6409, y + 62.5096, x + 50.7428, y + 61.9662, x + 49.8538, y + 62.185);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.6018, y + 57.0951);
  ctx.lineTo(x + 27.6743, y + 62.2445);
  ctx.bezierCurveTo(x + 26.7852, y + 62.4633, x + 26.2418, y + 63.3614, x + 26.4606, y + 64.2504);
  ctx.lineTo(x + 26.9188, y + 66.1129);
  ctx.bezierCurveTo(x + 27.1376, y + 67.002, x + 28.0357, y + 67.5454, x + 28.9247, y + 67.3266);
  ctx.lineTo(x + 49.8523, y + 62.1772);
  ctx.bezierCurveTo(x + 50.7414, y + 61.9584, x + 51.2848, y + 61.0604, x + 51.066, y + 60.1713);
  ctx.lineTo(x + 50.6077, y + 58.3088);
  ctx.bezierCurveTo(x + 50.389, y + 57.4198, x + 49.4909, y + 56.8764, x + 48.6018, y + 57.0951);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 53.0681, y + 61.3931);
  ctx.lineTo(x + 73.9957, y + 56.2438);
  ctx.bezierCurveTo(x + 74.8847, y + 56.025, x + 75.4281, y + 55.1269, x + 75.2094, y + 54.2378);
  ctx.lineTo(x + 74.7511, y + 52.3754);
  ctx.bezierCurveTo(x + 74.5323, y + 51.4863, x + 73.6343, y + 50.9429, x + 72.7452, y + 51.1617);
  ctx.lineTo(x + 51.8176, y + 56.3111);
  ctx.bezierCurveTo(x + 50.9286, y + 56.5298, x + 50.3852, y + 57.4279, x + 50.6039, y + 58.317);
  ctx.lineTo(x + 51.0622, y + 60.1794);
  ctx.bezierCurveTo(x + 51.281, y + 61.0685, x + 52.179, y + 61.6119, x + 53.0681, y + 61.3931);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 54.3162, y + 66.4674);
  ctx.lineTo(x + 75.2437, y + 61.318);
  ctx.bezierCurveTo(x + 76.1328, y + 61.0992, x + 76.6762, y + 60.2011, x + 76.4574, y + 59.3121);
  ctx.lineTo(x + 75.9991, y + 57.4496);
  ctx.bezierCurveTo(x + 75.7804, y + 56.5605, x + 74.8823, y + 56.0171, x + 73.9932, y + 56.2359);
  ctx.lineTo(x + 53.0657, y + 61.3853);
  ctx.bezierCurveTo(x + 52.1766, y + 61.6041, x + 51.6332, y + 62.5021, x + 51.852, y + 63.3912);
  ctx.lineTo(x + 52.3102, y + 65.2537);
  ctx.bezierCurveTo(x + 52.529, y + 66.1427, x + 53.4271, y + 66.6861, x + 54.3162, y + 66.4674);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#F9F3F1";

  ctx.beginPath();
  ctx.moveTo(x + 75.5241, y + 5.199);
  ctx.lineTo(x + 5.06836, y + 22.5352);
  ctx.lineTo(x + 5.77383, y + 25.4023);
  ctx.lineTo(x + 76.2295, y + 8.06611);
  ctx.lineTo(x + 75.5241, y + 5.199);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 5.42283, y + 17.7477);
  ctx.lineTo(x + 2.52635, y + 18.4604);
  ctx.bezierCurveTo(x + 1.13108, y + 18.8037, x + 0.278312, y + 20.2131, x + 0.621627, y + 21.6084);
  ctx.lineTo(x + 17.9173, y + 91.8996);
  ctx.bezierCurveTo(x + 18.2606, y + 93.2948, x + 19.67, y + 94.1476, x + 21.0653, y + 93.8043);
  ctx.lineTo(x + 23.9618, y + 93.0916);
  ctx.bezierCurveTo(x + 25.357, y + 92.7483, x + 26.2098, y + 91.3389, x + 25.8665, y + 89.9436);
  ctx.lineTo(x + 8.57081, y + 19.6524);
  ctx.bezierCurveTo(x + 8.22749, y + 18.2572, x + 6.8181, y + 17.4044, x + 5.42283, y + 17.7477);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 75.0263, y + 0.622703);
  ctx.lineTo(x + 72.1299, y + 1.33541);
  ctx.bezierCurveTo(x + 70.7346, y + 1.67872, x + 69.8818, y + 3.08812, x + 70.2251, y + 4.48338);
  ctx.lineTo(x + 87.5208, y + 74.7746);
  ctx.bezierCurveTo(x + 87.8641, y + 76.1698, x + 89.2735, y + 77.0226, x + 90.6688, y + 76.6793);
  ctx.lineTo(x + 93.5653, y + 75.9666);
  ctx.bezierCurveTo(x + 94.9605, y + 75.6233, x + 95.8133, y + 74.2139, x + 95.47, y + 72.8186);
  ctx.lineTo(x + 78.1743, y + 2.52742);
  ctx.bezierCurveTo(x + 77.831, y + 1.13216, x + 76.4216, y + 0.279388, x + 75.0263, y + 0.622703);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#0290CF";

  ctx.beginPath();
  ctx.moveTo(x + 44.1016, y + 24.3672);
  ctx.lineTo(x + 59.4888, y + 20.581);
  ctx.lineTo(x + 53.4867, y + 30.5008);
  ctx.lineTo(x + 44.1016, y + 24.3672);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.7734, y + 19.7045);
  ctx.lineTo(x + 59.1425, y + 20.5979);
  ctx.bezierCurveTo(x + 58.9016, y + 20.6572, x + 58.6942, y + 20.8017, x + 58.5584, y + 21.0096);
  ctx.lineTo(x + 53.8785, y + 28.149);
  ctx.bezierCurveTo(x + 53.5981, y + 28.5794, x + 53.0341, y + 28.7182, x + 52.5801, y + 28.4685);
  ctx.lineTo(x + 45.1217, y + 24.3158);
  ctx.bezierCurveTo(x + 44.905, y + 24.1946, x + 44.6542, y + 24.1629, x + 44.4133, y + 24.2222);
  ctx.lineTo(x + 40.7824, y + 25.1156);
  ctx.bezierCurveTo(x + 40.5298, y + 25.1777, x + 40.3135, y + 25.3369, x + 40.1747, y + 25.5579);
  ctx.lineTo(x + 34.4251, y + 35.0604);
  ctx.bezierCurveTo(x + 34.1461, y + 35.5216, x + 34.295, y + 36.1268, x + 34.7562, y + 36.4059);
  ctx.lineTo(x + 40.9643, y + 40.1621);
  ctx.bezierCurveTo(x + 41.4255, y + 40.4412, x + 42.0306, y + 40.2923, x + 42.3097, y + 39.8311);
  ctx.bezierCurveTo(x + 42.7615, y + 39.0844, x + 43.8879, y + 39.2558, x + 44.0975, y + 40.1077);
  ctx.lineTo(x + 48.0615, y + 56.2176);
  ctx.bezierCurveTo(x + 48.1901, y + 56.7405, x + 48.7245, y + 57.0639, x + 49.2474, y + 56.9352);
  ctx.lineTo(x + 70.0222, y + 51.8234);
  ctx.bezierCurveTo(x + 70.5451, y + 51.6947, x + 70.8685, y + 51.1603, x + 70.7398, y + 50.6374);
  ctx.lineTo(x + 66.7759, y + 34.5276);
  ctx.bezierCurveTo(x + 66.5677, y + 33.6815, x + 67.4845, y + 33.0011, x + 68.2312, y + 33.4529);
  ctx.bezierCurveTo(x + 68.6924, y + 33.732, x + 69.2976, y + 33.5831, x + 69.5766, y + 33.1219);
  ctx.lineTo(x + 73.3329, y + 26.9138);
  ctx.bezierCurveTo(x + 73.612, y + 26.4525, x + 73.4631, y + 25.8474, x + 73.0018, y + 25.5683);
  ctx.lineTo(x + 63.4994, y + 19.8187);
  ctx.bezierCurveTo(x + 63.2797, y + 19.6859, x + 63.0084, y + 19.6467, x + 62.7558, y + 19.7089);
  ctx.lineTo(x + 62.7734, y + 19.7045);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#0290CF";

  ctx.beginPath();
  ctx.moveTo(x + 26.9629, y + 28.5859);
  ctx.lineTo(x + 42.356, y + 24.7984);
  ctx.lineTo(x + 36.348, y + 34.7196);
  ctx.lineTo(x + 26.9629, y + 28.5859);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#F9F3F1";

  ctx.beginPath();
  ctx.moveTo(x + 45.6367, y + 23.9233);
  ctx.lineTo(x + 42.0058, y + 24.8167);
  ctx.bezierCurveTo(x + 41.7649, y + 24.8759, x + 41.5574, y + 25.0205, x + 41.4217, y + 25.2283);
  ctx.lineTo(x + 36.7418, y + 32.3678);
  ctx.bezierCurveTo(x + 36.4614, y + 32.7982, x + 35.8974, y + 32.9369, x + 35.4434, y + 32.6873);
  ctx.lineTo(x + 27.985, y + 28.5345);
  ctx.bezierCurveTo(x + 27.7683, y + 28.4134, x + 27.5175, y + 28.3816, x + 27.2766, y + 28.4409);
  ctx.lineTo(x + 23.6457, y + 29.3343);
  ctx.bezierCurveTo(x + 23.3931, y + 29.3965, x + 23.1768, y + 29.5556, x + 23.038, y + 29.7767);
  ctx.lineTo(x + 17.2884, y + 39.2792);
  ctx.bezierCurveTo(x + 17.0093, y + 39.7404, x + 17.1582, y + 40.3455, x + 17.6195, y + 40.6246);
  ctx.lineTo(x + 23.8275, y + 44.3809);
  ctx.bezierCurveTo(x + 24.2888, y + 44.66, x + 24.8939, y + 44.5111, x + 25.173, y + 44.0498);
  ctx.bezierCurveTo(x + 25.6248, y + 43.3031, x + 26.7512, y + 43.4746, x + 26.9608, y + 44.3265);
  ctx.lineTo(x + 30.9247, y + 60.4364);
  ctx.bezierCurveTo(x + 31.0534, y + 60.9593, x + 31.5878, y + 61.2826, x + 32.1107, y + 61.154);
  ctx.lineTo(x + 52.8855, y + 56.0422);
  ctx.bezierCurveTo(x + 53.4084, y + 55.9135, x + 53.7318, y + 55.3791, x + 53.6031, y + 54.8562);
  ctx.lineTo(x + 49.6392, y + 38.7463);
  ctx.bezierCurveTo(x + 49.431, y + 37.9003, x + 50.3477, y + 37.2198, x + 51.0945, y + 37.6717);
  ctx.bezierCurveTo(x + 51.5557, y + 37.9507, x + 52.1608, y + 37.8018, x + 52.4399, y + 37.3406);
  ctx.lineTo(x + 56.1962, y + 31.1325);
  ctx.bezierCurveTo(x + 56.4752, y + 30.6713, x + 56.3263, y + 30.0662, x + 55.8651, y + 29.7871);
  ctx.lineTo(x + 46.3626, y + 24.0375);
  ctx.bezierCurveTo(x + 46.143, y + 23.9046, x + 45.8717, y + 23.8654, x + 45.619, y + 23.9276);
  ctx.lineTo(x + 45.6367, y + 23.9233);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#0290CF";

  ctx.beginPath();
  ctx.moveTo(x + 49.7889, y + 10.3589);
  ctx.lineTo(x + 47.9441, y + 10.8128);
  ctx.bezierCurveTo(x + 47.3925, y + 10.9486, x + 47.0553, y + 11.5058, x + 47.1911, y + 12.0574);
  ctx.lineTo(x + 49.8915, y + 23.0323);
  ctx.bezierCurveTo(x + 50.0273, y + 23.5839, x + 50.5845, y + 23.9211, x + 51.1361, y + 23.7853);
  ctx.lineTo(x + 52.9809, y + 23.3314);
  ctx.bezierCurveTo(x + 53.5325, y + 23.1957, x + 53.8697, y + 22.6385, x + 53.7339, y + 22.0869);
  ctx.lineTo(x + 51.0335, y + 11.1119);
  ctx.bezierCurveTo(x + 50.8977, y + 10.5603, x + 50.3405, y + 10.2232, x + 49.7889, y + 10.3589);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#0290CF";

  ctx.beginPath();
  ctx.moveTo(x + 32.6561, y + 14.5737);
  ctx.lineTo(x + 30.8113, y + 15.0277);
  ctx.bezierCurveTo(x + 30.2597, y + 15.1634, x + 29.9225, y + 15.7206, x + 30.0583, y + 16.2722);
  ctx.lineTo(x + 32.7587, y + 27.2471);
  ctx.bezierCurveTo(x + 32.8945, y + 27.7988, x + 33.4517, y + 28.1359, x + 34.0033, y + 28.0002);
  ctx.lineTo(x + 35.8481, y + 27.5462);
  ctx.bezierCurveTo(x + 36.3997, y + 27.4105, x + 36.7368, y + 26.8533, x + 36.6011, y + 26.3017);
  ctx.lineTo(x + 33.9007, y + 15.3268);
  ctx.bezierCurveTo(x + 33.7649, y + 14.7752, x + 33.2077, y + 14.438, x + 32.6561, y + 14.5737);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementShirts(ctx, scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.scale(0.8888888888888888,0.8888888888888888);
  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x, y + 23.7656);
  // ctx.rotate(-0.3452156540689664);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 70.227, y + 0);
  ctx.bezierCurveTo(x + 70.227, y + 0, x + 70.227, y + 0, x + 70.227, y + 0);
  ctx.lineTo(x + 70.227, y + 70.1542);
  ctx.bezierCurveTo(x + 70.227, y + 70.1542, x + 70.227, y + 70.1542, x + 70.227, y + 70.1542);
  ctx.lineTo(x + 0, y + 70.1542);
  ctx.bezierCurveTo(x + 0, y + 70.1542, x + 0, y + 70.1542, x + 0, y + 70.1542);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(0.3452156540689664);
  // ctx.translate(x, y - 23.7656);
  // ctx.clip();
  ctx.translate(x + 0, y + 23.7656); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x - 0, -y - 23.7656); // regresar el origen a su posición

  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 64.6378, y + 25.4896);
  ctx.bezierCurveTo(x + 64.2951, y + 25.5045, x + 63.9535, y + 25.5655, x + 63.6315, y + 25.6812);
  ctx.lineTo(x + 40.0625, y + 34.157);
  ctx.bezierCurveTo(x + 39.7405, y + 34.2728, x + 39.4358, y + 34.4365, x + 39.1646, y + 34.6501);
  ctx.lineTo(x + 32.7797, y + 39.5453);
  ctx.bezierCurveTo(x + 31.6285, y + 40.4311, x + 31.1785, y + 41.9543, x + 31.6687, y + 43.3174);
  ctx.lineTo(x + 44.4279, y + 78.7977);
  ctx.bezierCurveTo(x + 45.0511, y + 80.5306, x + 46.9602, y + 81.4298, x + 48.6931, y + 80.8066);
  ctx.lineTo(x + 86.6872, y + 67.1434);
  ctx.bezierCurveTo(x + 88.4201, y + 66.5202, x + 89.3193, y + 64.6111, x + 88.6961, y + 62.8782);
  ctx.lineTo(x + 75.9369, y + 27.3979);
  ctx.bezierCurveTo(x + 75.4467, y + 26.0348, x + 74.1271, y + 25.1402, x + 72.6779, y + 25.1972);
  ctx.lineTo(x + 64.6378, y + 25.4896);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 50.9259, y + 21.6043);
  ctx.bezierCurveTo(x + 50.5832, y + 21.6192, x + 50.2415, y + 21.6802, x + 49.9196, y + 21.796);
  ctx.lineTo(x + 26.3437, y + 30.2742);
  ctx.bezierCurveTo(x + 26.0218, y + 30.39, x + 25.7171, y + 30.5537, x + 25.4458, y + 30.7673);
  ctx.lineTo(x + 19.0609, y + 35.6625);
  ctx.bezierCurveTo(x + 17.9098, y + 36.5483, x + 17.4597, y + 38.0715, x + 17.9499, y + 39.4346);
  ctx.lineTo(x + 30.7092, y + 74.9149);
  ctx.bezierCurveTo(x + 31.3324, y + 76.6478, x + 33.2415, y + 77.547, x + 34.9744, y + 76.9238);
  ctx.lineTo(x + 72.9684, y + 63.2606);
  ctx.bezierCurveTo(x + 74.7014, y + 62.6374, x + 75.6006, y + 60.7283, x + 74.9774, y + 58.9954);
  ctx.lineTo(x + 62.2181, y + 23.5151);
  ctx.bezierCurveTo(x + 61.7279, y + 22.152, x + 60.4084, y + 21.2574, x + 58.9592, y + 21.3144);
  ctx.lineTo(x + 50.919, y + 21.6067);
  ctx.lineTo(x + 50.9259, y + 21.6043);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 37.2042, y + 17.7122);
  ctx.bezierCurveTo(x + 36.8615, y + 17.7272, x + 36.5199, y + 17.7881, x + 36.1979, y + 17.9039);
  ctx.lineTo(x + 12.6289, y + 26.3797);
  ctx.bezierCurveTo(x + 12.3069, y + 26.4955, x + 12.0023, y + 26.6592, x + 11.731, y + 26.8728);
  ctx.lineTo(x + 5.3461, y + 31.7679);
  ctx.bezierCurveTo(x + 4.19495, y + 32.6537, x + 3.74489, y + 34.177, x + 4.23506, y + 35.5401);
  ctx.lineTo(x + 16.9943, y + 71.0204);
  ctx.bezierCurveTo(x + 17.6175, y + 72.7533, x + 19.5266, y + 73.6525, x + 21.2595, y + 73.0293);
  ctx.lineTo(x + 59.2536, y + 59.366);
  ctx.bezierCurveTo(x + 60.9865, y + 58.7429, x + 61.8857, y + 56.8338, x + 61.2625, y + 55.1008);
  ctx.lineTo(x + 48.5033, y + 19.6205);
  ctx.bezierCurveTo(x + 48.0131, y + 18.2575, x + 46.6935, y + 17.3629, x + 45.2443, y + 17.4199);
  ctx.lineTo(x + 37.2042, y + 17.7122);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#F9F3F1";

  ctx.beginPath();
  ctx.moveTo(x + 34.2059, y + 11.6118);
  ctx.lineTo(x + 24.8439, y + 21.6076);
  ctx.bezierCurveTo(x + 24.5586, y + 21.9113, x + 24.1477, y + 22.0591, x + 23.7412, y + 22.0042);
  ctx.lineTo(x + 10.1505, y + 20.2625);
  ctx.bezierCurveTo(x + 9.53385, y + 20.1825, x + 8.95543, y + 20.553, x + 8.75744, y + 21.1424);
  ctx.lineTo(x + 6.79394, y + 27.1472);
  ctx.bezierCurveTo(x + 6.54686, y + 27.9013, x + 7.0353, y + 28.7003, x + 7.81409, y + 28.8225);
  ctx.lineTo(x + 20.9825, y + 30.9172);
  ctx.bezierCurveTo(x + 21.6457, y + 31.019, x + 22.2784, y + 30.6059, x + 22.438, y + 29.9528);
  ctx.lineTo(x + 23.2709, y + 26.5901);
  ctx.lineTo(x + 23.4014, y + 26.0713);
  ctx.lineTo(x + 28.0682, y + 38.8117);
  ctx.bezierCurveTo(x + 28.3071, y + 39.4761, x + 29.0496, y + 39.8201, x + 29.714, y + 39.5812);
  ctx.lineTo(x + 31.4059, y + 38.9728);
  ctx.bezierCurveTo(x + 32.0771, y + 38.7314, x + 32.4236, y + 37.9957, x + 32.1822, y + 37.3245);
  ctx.lineTo(x + 27.549, y + 24.4406);
  ctx.lineTo(x + 28.1066, y + 24.8511);
  ctx.lineTo(x + 30.8903, y + 26.9133);
  ctx.bezierCurveTo(x + 31.4293, y + 27.315, x + 32.1802, y + 27.2307, x + 32.6266, y + 26.7298);
  ctx.lineTo(x + 41.445, y + 16.7283);
  ctx.bezierCurveTo(x + 41.972, y + 16.1288, x + 41.8354, y + 15.211, x + 41.1646, y + 14.787);
  ctx.lineTo(x + 35.8266, y + 11.408);
  ctx.bezierCurveTo(x + 35.3054, y + 11.0771, x + 34.6167, y + 11.1624, x + 34.1922, y + 11.6167);
  ctx.lineTo(x + 34.2059, y + 11.6118);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 37.9874, y + 38.9479);
  ctx.lineTo(x + 46.4602, y + 35.901);
  ctx.bezierCurveTo(x + 47.7068, y + 35.4527, x + 49.0038, y + 35.8758, x + 49.356, y + 36.8553);
  ctx.lineTo(x + 52.0261, y + 44.2801);
  ctx.bezierCurveTo(x + 52.1936, y + 44.7459, x + 52.1165, y + 45.2842, x + 51.81, y + 45.7656);
  ctx.lineTo(x + 49.0576, y + 50.1358);
  ctx.bezierCurveTo(x + 48.4628, y + 51.0845, x + 47.1419, y + 51.6059, x + 46.0303, y + 51.3327);
  ctx.lineTo(x + 40.278, y + 49.9205);
  ctx.bezierCurveTo(x + 39.6504, y + 49.7671, x + 39.1854, y + 49.3774, x + 38.9982, y + 48.8569);
  ctx.lineTo(x + 36.3601, y + 41.5211);
  ctx.bezierCurveTo(x + 36.0103, y + 40.5484, x + 36.7383, y + 39.3894, x + 37.9849, y + 38.9411);
  ctx.lineTo(x + 37.9874, y + 38.9479);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementBowl(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  // ctx.scale(0.9302325581395349,0.9302325581395349);
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 76.4688, y + 22.0965);
  ctx.bezierCurveTo(x + 75.9698, y + 19.5661, x + 73.5063, y + 17.9227, x + 70.9639, y + 18.4241);
  ctx.bezierCurveTo(x + 70.0494, y + 18.6045, x + 69.2512, y + 19.0415, x + 68.6282, y + 19.6402);
  ctx.bezierCurveTo(x + 67.1326, y + 16.7532, x + 63.8543, y + 15.0683, x + 60.4894, y + 15.7319);
  ctx.bezierCurveTo(x + 57.1245, y + 16.3956, x + 54.5374, y + 19.4276, x + 54.4206, y + 22.8349);
  ctx.bezierCurveTo(x + 53.7598, y + 22.7868, x + 53.0849, y + 22.8188, x + 52.4004, y + 22.9538);
  ctx.bezierCurveTo(x + 48.3222, y + 23.7581, x + 45.6584, y + 27.7034, x + 46.46, y + 31.7681);
  ctx.bezierCurveTo(x + 46.4612, y + 31.7739, x + 46.4623, y + 31.7796, x + 46.4634, y + 31.7853);
  ctx.bezierCurveTo(x + 46.3358, y + 31.8045, x + 46.2127, y + 31.8169, x + 46.0862, y + 31.8419);
  ctx.bezierCurveTo(x + 42.008, y + 32.6462, x + 39.3441, y + 36.5915, x + 40.1458, y + 40.6562);
  ctx.bezierCurveTo(x + 40.9475, y + 44.7209, x + 44.9041, y + 47.3604, x + 48.988, y + 46.5549);
  ctx.bezierCurveTo(x + 50.3455, y + 46.2872, x + 51.5433, y + 45.6644, x + 52.5108, y + 44.8134);
  ctx.bezierCurveTo(x + 54.2002, y + 46.2585, x + 56.5107, y + 46.9447, x + 58.869, y + 46.4796);
  ctx.bezierCurveTo(x + 61.9233, y + 45.8772, x + 64.1815, y + 43.5107, x + 64.7906, y + 40.6606);
  ctx.bezierCurveTo(x + 66.056, y + 41.0475, x + 67.4306, y + 41.1391, x + 68.8226, y + 40.8646);
  ctx.bezierCurveTo(x + 73.2861, y + 39.9843, x + 76.1996, y + 35.6686, x + 75.3223, y + 31.2203);
  ctx.bezierCurveTo(x + 75.0321, y + 29.749, x + 74.3585, y + 28.4544, x + 73.4345, y + 27.4055);
  ctx.bezierCurveTo(x + 75.5904, y + 26.6413, x + 76.925, y + 24.4094, x + 76.4711, y + 22.1079);
  ctx.lineTo(x + 76.4688, y + 22.0965);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 55.376, y + 27.1289);
  ctx.bezierCurveTo(x + 55.6657, y + 24.5678, x + 53.8116, y + 22.257, x + 51.2398, y + 21.9732);
  ctx.bezierCurveTo(x + 50.3113, y + 21.8709, x + 49.4198, y + 22.0467, x + 48.647, y + 22.4311);
  ctx.bezierCurveTo(x + 48.0929, y + 19.2275, x + 45.4788, y + 16.6385, x + 42.0705, y + 16.2639);
  ctx.bezierCurveTo(x + 38.6621, y + 15.8894, x + 35.2794, y + 18.0078, x + 34.1379, y + 21.2186);
  ctx.bezierCurveTo(x + 33.5226, y + 20.9772, x + 32.8668, y + 20.8032, x + 32.1819, y + 20.7241);
  ctx.bezierCurveTo(x + 28.0462, y + 20.267, x + 24.3236, y + 23.2376, x + 23.8559, y + 27.3504);
  ctx.bezierCurveTo(x + 23.8571, y + 27.3561, x + 23.8582, y + 27.3619, x + 23.8593, y + 27.3676);
  ctx.bezierCurveTo(x + 23.7352, y + 27.3445, x + 23.6112, y + 27.3214, x + 23.4825, y + 27.3051);
  ctx.bezierCurveTo(x + 19.3468, y + 26.848, x + 15.6241, y + 29.8185, x + 15.1565, y + 33.9314);
  ctx.bezierCurveTo(x + 14.6889, y + 38.0442, x + 17.6669, y + 41.751, x + 21.7968, y + 42.2093);
  ctx.bezierCurveTo(x + 23.1712, y + 42.3605, x + 24.4998, y + 42.1282, x + 25.6812, y + 41.6038);
  ctx.bezierCurveTo(x + 26.8539, y + 43.4898, x + 28.8528, y + 44.8382, x + 31.2349, y + 45.0999);
  ctx.bezierCurveTo(x + 34.3319, y + 45.4408, x + 37.1942, y + 43.8651, x + 38.6345, y + 41.3209);
  ctx.bezierCurveTo(x + 39.7205, y + 42.0703, x + 41.0028, y + 42.5727, x + 42.4139, y + 42.7286);
  ctx.bezierCurveTo(x + 46.9346, y + 43.2287, x + 51.0146, y + 39.9795, x + 51.525, y + 35.4776);
  ctx.bezierCurveTo(x + 51.6912, y + 33.9876, x + 51.4435, y + 32.5496, x + 50.8802, y + 31.2689);
  ctx.bezierCurveTo(x + 53.1647, y + 31.1871, x + 55.1171, y + 29.4519, x + 55.3806, y + 27.122);
  ctx.lineTo(x + 55.376, y + 27.1289);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 6.68458, y + 41.0917);
  ctx.bezierCurveTo(x + 6.22276, y + 38.7502, x + 7.75292, y + 36.4798, x + 10.1055, y + 36.0158);
  ctx.bezierCurveTo(x + 10.951, y + 35.849, x + 11.7876, y + 35.9397, x + 12.5326, y + 36.2329);
  ctx.bezierCurveTo(x + 12.7962, y + 33.2369, x + 15.0104, y + 30.6471, x + 18.1279, y + 30.0322);
  ctx.bezierCurveTo(x + 21.2455, y + 29.4174, x + 24.5181, y + 31.1034, x + 25.8138, y + 33.9763);
  ctx.bezierCurveTo(x + 26.3631, y + 33.7014, x + 26.949, y + 33.4907, x + 27.5817, y + 33.3659);
  ctx.bezierCurveTo(x + 31.355, y + 32.6217, x + 35.0158, y + 35.0638, x + 35.7565, y + 38.8194);
  ctx.bezierCurveTo(x + 35.7576, y + 38.8251, x + 35.7587, y + 38.8308, x + 35.7599, y + 38.8365);
  ctx.bezierCurveTo(x + 35.8749, y + 38.8138, x + 35.9865, y + 38.774, x + 36.1016, y + 38.7513);
  ctx.bezierCurveTo(x + 39.8749, y + 38.0071, x + 43.5356, y + 40.4492, x + 44.2763, y + 44.2048);
  ctx.bezierCurveTo(x + 45.0171, y + 47.9603, x + 42.5577, y + 51.6095, x + 38.7844, y + 52.3537);
  ctx.bezierCurveTo(x + 37.5247, y + 52.6022, x + 36.2836, y + 52.4901, x + 35.1549, y + 52.1001);
  ctx.bezierCurveTo(x + 34.2126, y + 53.9275, x + 32.4821, y + 55.3334, x + 30.2964, y + 55.7645);
  ctx.bezierCurveTo(x + 27.4664, y + 56.3227, x + 24.7083, y + 55.0943, x + 23.1862, y + 52.8608);
  ctx.bezierCurveTo(x + 22.2396, y + 53.6363, x + 21.0961, y + 54.2009, x + 19.8076, y + 54.455);
  ctx.bezierCurveTo(x + 15.6777, y + 55.2695, x + 11.6672, y + 52.599, x + 10.8553, y + 48.4828);
  ctx.bezierCurveTo(x + 10.5866, y + 47.1203, x + 10.7098, y + 45.7756, x + 11.1315, y + 44.5505);
  ctx.bezierCurveTo(x + 9.01929, y + 44.6578, x + 7.09086, y + 43.2122, x + 6.67082, y + 41.0825);
  ctx.lineTo(x + 6.68458, y + 41.0917);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 52.9592, y + 13.7596);
  ctx.bezierCurveTo(x + 55.5991, y + 13.2389, x + 57.319, y + 10.6869, x + 56.8008, y + 8.05943);
  ctx.bezierCurveTo(x + 56.2826, y + 5.43198, x + 53.7225, y + 3.72409, x + 51.0826, y + 4.24476);
  ctx.bezierCurveTo(x + 48.4428, y + 4.76542, x + 46.7228, y + 7.31747, x + 47.241, y + 9.94492);
  ctx.bezierCurveTo(x + 47.7593, y + 12.5724, x + 50.3194, y + 14.2803, x + 52.9592, y + 13.7596);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 19.6388, y + 26.1249);
  ctx.bezierCurveTo(x + 21.3988, y + 25.7778, x + 22.5454, y + 24.0764, x + 22.1999, y + 22.3248);
  ctx.bezierCurveTo(x + 21.8544, y + 20.5731, x + 20.1477, y + 19.4346, x + 18.3878, y + 19.7817);
  ctx.bezierCurveTo(x + 16.6279, y + 20.1288, x + 15.4812, y + 21.8301, x + 15.8267, y + 23.5818);
  ctx.bezierCurveTo(x + 16.1722, y + 25.3334, x + 17.8789, y + 26.472, x + 19.6388, y + 26.1249);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 47.6359, y + 27.3348);
  ctx.bezierCurveTo(x + 52.8785, y + 30.0716, x + 59.065, y + 28.6254, x + 61.4528, y + 24.0982);
  ctx.lineTo(x + 42.4718, y + 14.1802);
  ctx.bezierCurveTo(x + 40.084, y + 18.7074, x + 42.3921, y + 24.5923, x + 47.6359, y + 27.3348);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.1712, y + 26.3266);
  ctx.bezierCurveTo(x + 52.4546, y + 28.5627, x + 57.3828, y + 27.6145, x + 59.1895, y + 24.2011);
  ctx.lineTo(x + 43.6864, y + 16.101);
  ctx.bezierCurveTo(x + 41.8854, y + 19.5133, x + 43.8946, y + 24.0952, x + 48.1723, y + 26.3324);
  ctx.lineTo(x + 48.1712, y + 26.3266);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 38.7475, y + 52.1408);
  ctx.bezierCurveTo(x + 46.1747, y + 50.676, x + 51.0137, y + 43.4958, x + 49.5557, y + 36.1035);
  ctx.bezierCurveTo(x + 48.0977, y + 28.7113, x + 40.8948, y + 23.9062, x + 33.4676, y + 25.371);
  ctx.bezierCurveTo(x + 26.0405, y + 26.8359, x + 21.2015, y + 34.0161, x + 22.6595, y + 41.4083);
  ctx.bezierCurveTo(x + 24.1174, y + 48.8006, x + 31.3203, y + 53.6057, x + 38.7475, y + 52.1408);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 38.056, y + 48.6339);
  ctx.bezierCurveTo(x + 43.5358, y + 47.5531, x + 47.1061, y + 42.2555, x + 46.0303, y + 36.8014);
  ctx.bezierCurveTo(x + 44.9546, y + 31.3473, x + 39.6403, y + 27.8021, x + 34.1604, y + 28.8829);
  ctx.bezierCurveTo(x + 28.6806, y + 29.9637, x + 25.1103, y + 35.2613, x + 26.186, y + 40.7154);
  ctx.bezierCurveTo(x + 27.2618, y + 46.1694, x + 32.5761, y + 49.7147, x + 38.056, y + 48.6339);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.6385, y + 72.8202);
  ctx.lineTo(x + 34.6143, y + 75.7835);
  ctx.bezierCurveTo(x + 33.0728, y + 76.0875, x + 31.5155, y + 75.3419, x + 30.7985, y + 73.9488);
  ctx.lineTo(x + 28.9798, y + 70.4237);
  ctx.bezierCurveTo(x + 28.5448, y + 69.5817, x + 27.7872, y + 68.952, x + 26.8788, y + 68.6791);
  ctx.bezierCurveTo(x + 17.8, y + 65.9436, x + 10.6687, y + 60.2665, x + 7.62526, y + 52.6828);
  ctx.bezierCurveTo(x + 6.81398, y + 50.66, x + 8.09578, y + 48.4029, x + 10.2413, y + 47.9797);
  ctx.lineTo(x + 43.4416, y + 41.4316);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.8903, y + 72.9679);
  ctx.lineTo(x + 63.9144, y + 70.0047);
  ctx.bezierCurveTo(x + 65.456, y + 69.7007, x + 66.6136, y + 68.4196, x + 66.7479, y + 66.8586);
  ctx.lineTo(x + 67.0919, y + 62.907);
  ctx.bezierCurveTo(x + 67.1746, y + 61.9628, x + 67.6364, y + 61.0926, x + 68.3731, y + 60.4953);
  ctx.bezierCurveTo(x + 75.7334, y + 54.5174, x + 80.1751, y + 46.5578, x + 80.1112, y + 38.3864);
  ctx.bezierCurveTo(x + 80.0937, y + 36.2071, x + 78.0508, y + 34.6057, x + 75.9053, y + 35.0288);
  ctx.lineTo(x + 42.705, y + 41.577);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();

}

drawElementRestaurant(ctx,scale, x, y) {
  x = x / scale;
  y = y / scale;
  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 68, y + 0);
  ctx.bezierCurveTo(x + 68, y + 0, x + 68, y + 0, x + 68, y + 0);
  ctx.lineTo(x + 68, y + 61);
  ctx.bezierCurveTo(x + 68, y + 61, x + 68, y + 61, x + 68, y + 61);
  ctx.lineTo(x + 0, y + 61);
  ctx.bezierCurveTo(x + 0, y + 61, x + 0, y + 61, x + 0, y + 61);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 5.62, y + 61.0039);
  ctx.lineTo(x + 62.3809, y + 61.0039);
  ctx.lineTo(x + 62.3809, y + 15.1908);
  ctx.lineTo(x + 5.62, y + 15.1908);
  ctx.lineTo(x + 5.62, y + 61.0039);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 3.43357, y + 61.0039);
  ctx.lineTo(x + 64.5621, y + 61.0039);
  ctx.bezierCurveTo(x + 65.0574, y + 61.0039, x + 65.459, y + 60.6021, x + 65.459, y + 60.1064);
  ctx.lineTo(x + 65.459, y + 56.208);
  ctx.bezierCurveTo(x + 65.459, y + 55.7124, x + 65.0574, y + 55.3106, x + 64.5621, y + 55.3106);
  ctx.lineTo(x + 3.43357, y + 55.3106);
  ctx.bezierCurveTo(x + 2.93823, y + 55.3106, x + 2.53668, y + 55.7124, x + 2.53668, y + 56.208);
  ctx.lineTo(x + 2.53668, y + 60.1064);
  ctx.bezierCurveTo(x + 2.53668, y + 60.6021, x + 2.93823, y + 61.0039, x + 3.43357, y + 61.0039);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 7.26962, y + 1.02835);
  ctx.lineTo(x + 0.169252, y + 16.2807);
  ctx.bezierCurveTo(x + -0.37729, y + 17.4586, x + 0.482228, y + 18.8048, x + 1.77618, y + 18.8048);
  ctx.lineTo(x + 66.2213, y + 18.8048);
  ctx.bezierCurveTo(x + 67.5199, y + 18.8048, x + 68.3795, y + 17.4586, x + 67.8282, y + 16.2807);
  ctx.lineTo(x + 60.7279, y + 1.02835);
  ctx.bezierCurveTo(x + 60.4429, y + 0.401992, x + 59.817, y + 0, x + 59.1256, y + 0);
  ctx.lineTo(x + 8.87654, y + 0);
  ctx.bezierCurveTo(x + 8.18519, y + 0, x + 7.55924, y + 0.401992, x + 7.26962, y + 1.02835);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 31.7179, y + 41.0273);
  ctx.lineTo(x + 55.4061, y + 41.0273);
  ctx.bezierCurveTo(x + 56.8689, y + 41.0273, x + 58.0547, y + 39.8407, x + 58.0547, y + 38.377);
  ctx.lineTo(x + 58.0547, y + 25.2094);
  ctx.bezierCurveTo(x + 58.0547, y + 23.7457, x + 56.8689, y + 22.5591, x + 55.4061, y + 22.5591);
  ctx.lineTo(x + 31.7179, y + 22.5591);
  ctx.bezierCurveTo(x + 30.2551, y + 22.5591, x + 29.0693, y + 23.7457, x + 29.0693, y + 25.2094);
  ctx.lineTo(x + 29.0693, y + 38.377);
  ctx.bezierCurveTo(x + 29.0693, y + 39.8407, x + 30.2551, y + 41.0273, x + 31.7179, y + 41.0273);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 31.7179, y + 54.0117);
  ctx.lineTo(x + 55.4061, y + 54.0117);
  ctx.bezierCurveTo(x + 56.8689, y + 54.0117, x + 58.0547, y + 52.8251, x + 58.0547, y + 51.3614);
  ctx.lineTo(x + 58.0547, y + 45.6914);
  ctx.bezierCurveTo(x + 58.0547, y + 44.2277, x + 56.8689, y + 43.0411, x + 55.4061, y + 43.0411);
  ctx.lineTo(x + 31.7179, y + 43.0411);
  ctx.bezierCurveTo(x + 30.2551, y + 43.0411, x + 29.0693, y + 44.2277, x + 29.0693, y + 45.6914);
  ctx.lineTo(x + 29.0693, y + 51.3614);
  ctx.bezierCurveTo(x + 29.0693, y + 52.8251, x + 30.2551, y + 54.0117, x + 31.7179, y + 54.0117);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 25.207, y + 55.3086);
  ctx.lineTo(x + 10.4784, y + 55.3086);
  ctx.lineTo(x + 10.4784, y + 33.8815);
  ctx.bezierCurveTo(x + 10.4784, y + 33.0681, x + 11.1418, y + 32.4044, x + 11.9546, y + 32.4044);
  ctx.lineTo(x + 23.7309, y + 32.4044);
  ctx.bezierCurveTo(x + 24.5437, y + 32.4044, x + 25.207, y + 33.0681, x + 25.207, y + 33.8815);
  ctx.lineTo(x + 25.207, y + 55.3086);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 17.8393, y + 55.3066);
  ctx.lineTo(x + 10.4727, y + 55.3066);
  ctx.lineTo(x + 10.4727, y + 34.0243);
  ctx.bezierCurveTo(x + 10.4727, y + 33.1269, x + 11.2014, y + 32.4023, x + 12.0936, y + 32.4023);
  ctx.lineTo(x + 17.8346, y + 32.4023);
  ctx.lineTo(x + 17.8346, y + 55.3112);
  ctx.lineTo(x + 17.8393, y + 55.3066);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 12.4451, y + 28.3164);
  ctx.lineTo(x + 23.2404, y + 28.3164);
  ctx.bezierCurveTo(x + 24.3265, y + 28.3164, x + 25.207, y + 27.4354, x + 25.207, y + 26.3485);
  ctx.lineTo(x + 25.207, y + 23.586);
  ctx.bezierCurveTo(x + 25.207, y + 22.4991, x + 24.3265, y + 21.6181, x + 23.2404, y + 21.6181);
  ctx.lineTo(x + 12.4451, y + 21.6181);
  ctx.bezierCurveTo(x + 11.3589, y + 21.6181, x + 10.4784, y + 22.4991, x + 10.4784, y + 23.586);
  ctx.lineTo(x + 10.4784, y + 26.3485);
  ctx.bezierCurveTo(x + 10.4784, y + 27.4354, x + 11.3589, y + 28.3164, x + 12.4451, y + 28.3164);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 44.6766, y + 11.9755);
  ctx.lineTo(x + 44.6766, y + 11.5174);
  ctx.bezierCurveTo(x + 44.6766, y + 11.1528, x + 44.3823, y + 10.8584, x + 44.018, y + 10.8584);
  ctx.lineTo(x + 37.7258, y + 10.8584);
  ctx.lineTo(x + 37.7258, y + 9.82066);
  ctx.lineTo(x + 44.018, y + 9.82066);
  ctx.bezierCurveTo(x + 44.3823, y + 9.82066, x + 44.6766, y + 9.52618, x + 44.6766, y + 9.16158);
  ctx.lineTo(x + 44.6766, y + 8.7035);
  ctx.bezierCurveTo(x + 44.6766, y + 8.3389, x + 44.3823, y + 8.04441, x + 44.018, y + 8.04441);
  ctx.lineTo(x + 37.7258, y + 8.04441);
  ctx.lineTo(x + 37.7258, y + 7.00671);
  ctx.lineTo(x + 44.018, y + 7.00671);
  ctx.bezierCurveTo(x + 44.3823, y + 7.00671, x + 44.6766, y + 6.71223, x + 44.6766, y + 6.34763);
  ctx.lineTo(x + 44.6766, y + 5.88955);
  ctx.bezierCurveTo(x + 44.6766, y + 5.52495, x + 44.3823, y + 5.23047, x + 44.018, y + 5.23047);
  ctx.lineTo(x + 37.7258, y + 5.23047);
  ctx.lineTo(x + 37.3334, y + 5.23047);
  ctx.lineTo(x + 36.3244, y + 5.23047);
  ctx.bezierCurveTo(x + 34.8996, y + 5.23047, x + 33.7178, y + 6.27752, x + 33.5076, y + 7.64242);
  ctx.lineTo(x + 20.8857, y + 7.64242);
  ctx.bezierCurveTo(x + 20.171, y + 7.64242, x + 19.5918, y + 8.22204, x + 19.5918, y + 8.93721);
  ctx.bezierCurveTo(x + 19.5918, y + 9.65238, x + 20.171, y + 10.232, x + 20.8857, y + 10.232);
  ctx.lineTo(x + 33.5076, y + 10.232);
  ctx.bezierCurveTo(x + 33.7225, y + 11.5969, x + 34.8996, y + 12.644, x + 36.3244, y + 12.644);
  ctx.lineTo(x + 37.3334, y + 12.644);
  ctx.lineTo(x + 44.018, y + 12.644);
  ctx.bezierCurveTo(x + 44.3823, y + 12.644, x + 44.6766, y + 12.3495, x + 44.6766, y + 11.9849);
  ctx.lineTo(x + 44.6766, y + 11.9755);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementPot(ctx,scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;
  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  // ctx.scale(0.5228758169934641,0.5228758169934641);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x + 32.8008, y + 0);
  // ctx.rotate(0.2544463156604973);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 123.47, y + 0);
  ctx.bezierCurveTo(x + 123.47, y + 0, x + 123.47, y + 0, x + 123.47, y + 0);
  ctx.lineTo(x + 123.47, y + 130.31);
  ctx.bezierCurveTo(x + 123.47, y + 130.31, x + 123.47, y + 130.31, x + 123.47, y + 130.31);
  ctx.lineTo(x + 0, y + 130.31);
  ctx.bezierCurveTo(x + 0, y + 130.31, x + 0, y + 130.31, x + 0, y + 130.31);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(-0.2544463156604973);
  // ctx.translate(x - 32.8008, y + 0);
  // ctx.clip();
  ctx.translate(x + 32.8008, y + 0); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x - 32.8008, -y - 0); // regresar el origen a su posición

  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 98.4432, y + 69.6853);
  ctx.lineTo(x + 93.9002, y + 63.5544);
  ctx.lineTo(x + 102.72, y + 54.751);
  ctx.lineTo(x + 109.442, y + 63.8252);
  ctx.lineTo(x + 98.4432, y + 69.6853);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 100.266, y + 55.8488);
  ctx.bezierCurveTo(x + 103.639, y + 56.726, x + 107.084, y + 54.703, x + 107.961, y + 51.3303);
  ctx.bezierCurveTo(x + 108.839, y + 47.9576, x + 106.815, y + 44.5123, x + 103.443, y + 43.6352);
  ctx.bezierCurveTo(x + 100.07, y + 42.758, x + 96.6248, y + 44.781, x + 95.7477, y + 48.1537);
  ctx.bezierCurveTo(x + 94.8705, y + 51.5264, x + 96.8935, y + 54.9716, x + 100.266, y + 55.8488);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 112.618, y + 72.5168);
  ctx.bezierCurveTo(x + 115.99, y + 73.394, x + 119.436, y + 71.371, x + 120.313, y + 67.9982);
  ctx.bezierCurveTo(x + 121.19, y + 64.6255, x + 119.167, y + 61.1803, x + 115.794, y + 60.3031);
  ctx.bezierCurveTo(x + 112.422, y + 59.4259, x + 108.976, y + 61.449, x + 108.099, y + 64.8217);
  ctx.bezierCurveTo(x + 107.222, y + 68.1944, x + 109.245, y + 71.6396, x + 112.618, y + 72.5168);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 107.217, y + 67.062);
  ctx.bezierCurveTo(x + 113.118, y + 68.5967, x + 119.145, y + 65.0573, x + 120.68, y + 59.1563);
  ctx.bezierCurveTo(x + 122.215, y + 53.2554, x + 118.675, y + 47.2277, x + 112.774, y + 45.6929);
  ctx.bezierCurveTo(x + 106.873, y + 44.1582, x + 100.846, y + 47.6977, x + 99.311, y + 53.5986);
  ctx.bezierCurveTo(x + 97.7763, y + 59.4995, x + 101.316, y + 65.5273, x + 107.217, y + 67.062);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="white";

  ctx.beginPath();
  ctx.moveTo(x + 49.367, y + 73.2066);
  ctx.bezierCurveTo(x + 59.1572, y + 86.4162, x + 76.3588, y + 90.2391, x + 87.7999, y + 81.7661);
  ctx.lineTo(x + 52.3533, y + 33.9544);
  ctx.bezierCurveTo(x + 40.9122, y + 42.4274, x + 39.5742, y + 60.0067, x + 49.3645, y + 73.2163);
  ctx.lineTo(x + 49.367, y + 73.2066);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;
  // ctx.fillStyle="rgb(3, 63, 133, 1.2)";
  // ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 51.9128, y + 71.3285);
  ctx.bezierCurveTo(x + 59.9044, y + 82.1175, x + 73.383, y + 85.6747, x + 82.0103, y + 79.2804);
  ctx.lineTo(x + 53.0585, y + 40.2255);
  ctx.bezierCurveTo(x + 44.4313, y + 46.6198, x + 43.9211, y + 60.5396, x + 51.9128, y + 71.3285);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 79.5774, y + 25.5036);
  ctx.bezierCurveTo(x + 74.5601, y + 18.7327, x + 66.3712, y + 15.9726, x + 59.711, y + 18.2599);
  ctx.bezierCurveTo(x + 57.6451, y + 18.9728, x + 56.8813, y + 21.5123, x + 58.1914, y + 23.2686);
  ctx.lineTo(x + 75.5016, y + 46.6175);
  ctx.bezierCurveTo(x + 76.802, y + 48.3713, x + 79.4529, y + 48.3891, x + 80.7406, y + 46.6162);
  ctx.bezierCurveTo(x + 84.8694, y + 40.9118, x + 84.6068, y + 32.2673, x + 79.5896, y + 25.4964);
  ctx.lineTo(x + 79.5774, y + 25.5036);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 77.5785, y + 26.9908);
  ctx.bezierCurveTo(x + 73.9214, y + 22.0616, x + 68.4589, y + 19.5456, x + 63.7091, y + 20.1288);
  ctx.bezierCurveTo(x + 61.2129, y + 20.4302, x + 59.9634, y + 23.2877, x + 61.4529, y + 25.3077);
  ctx.lineTo(x + 74.5027, y + 42.9091);
  ctx.bezierCurveTo(x + 75.9922, y + 44.9291, x + 79.0979, y + 44.5486, x + 80.1165, y + 42.2613);
  ctx.bezierCurveTo(x + 82.0497, y + 37.8871, x + 81.2355, y + 31.92, x + 77.5881, y + 26.9933);
  ctx.lineTo(x + 77.5785, y + 26.9908);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 131.145, y + 92.0584);
  ctx.lineTo(x + 118.67, y + 88.8139);
  ctx.bezierCurveTo(x + 118.128, y + 88.673, x + 117.58, y + 88.6338, x + 117.051, y + 88.6822);
  ctx.lineTo(x + 117.031, y + 88.6771);
  ctx.bezierCurveTo(x + 116.367, y + 88.3289, x + 115.65, y + 88.0286, x + 114.885, y + 87.8297);
  ctx.bezierCurveTo(x + 109.833, y + 86.5158, x + 104.723, y + 89.361, x + 103.467, y + 94.1903);
  ctx.bezierCurveTo(x + 102.211, y + 99.0197, x + 105.288, y + 103.994, x + 110.34, y + 105.308);
  ctx.bezierCurveTo(x + 114.191, y + 106.31, x + 118.073, y + 104.892, x + 120.257, y + 102.019);
  ctx.bezierCurveTo(x + 121.291, y + 100.665, x + 122.978, y + 99.9779, x + 124.633, y + 100.408);
  ctx.lineTo(x + 128.95, y + 101.531);
  ctx.bezierCurveTo(x + 131.688, y + 102.243, x + 134.494, y + 100.473, x + 134.977, y + 97.6224);
  ctx.bezierCurveTo(x + 135.409, y + 95.0894, x + 133.644, y + 92.6982, x + 131.157, y + 92.0513);
  ctx.lineTo(x + 131.145, y + 92.0584);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 21.5608, y + 63.5585);
  ctx.lineTo(x + 34.0358, y + 66.803);
  ctx.bezierCurveTo(x + 34.5778, y + 66.9439, x + 35.0752, y + 67.1767, x + 35.5138, y + 67.4767);
  ctx.lineTo(x + 35.5331, y + 67.4817);
  ctx.bezierCurveTo(x + 36.2824, y + 67.501, x + 37.0553, y + 67.5883, x + 37.8199, y + 67.7872);
  ctx.bezierCurveTo(x + 42.8718, y + 69.1011, x + 45.9487, y + 74.0757, x + 44.6927, y + 78.9051);
  ctx.bezierCurveTo(x + 43.4367, y + 83.7344, x + 38.326, y + 86.5796, x + 33.274, y + 85.2657);
  ctx.bezierCurveTo(x + 29.4222, y + 84.2639, x + 26.7232, y + 81.1337, x + 26.2161, y + 77.5611);
  ctx.bezierCurveTo(x + 25.9725, y + 75.8755, x + 24.8339, y + 74.4531, x + 23.179, y + 74.0227);
  ctx.lineTo(x + 18.8626, y + 72.9001);
  ctx.bezierCurveTo(x + 16.1237, y + 72.1877, x + 14.5359, y + 69.2743, x + 15.5028, y + 66.5499);
  ctx.bezierCurveTo(x + 16.3601, y + 64.1277, x + 19.0664, y + 62.8994, x + 21.5536, y + 63.5463);
  ctx.lineTo(x + 21.5608, y + 63.5585);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 41.1031, y + 63.6281);
  ctx.lineTo(x + 114.221, y + 82.6447);
  ctx.bezierCurveTo(x + 116.988, y + 83.3646, x + 118.65, y + 86.1938, x + 117.93, y + 88.9617);
  ctx.lineTo(x + 103.676, y + 143.768);
  ctx.bezierCurveTo(x + 102.425, y + 148.578, x + 97.51, y + 151.464, x + 92.7001, y + 150.213);
  ctx.lineTo(x + 26.9766, y + 133.12);
  ctx.bezierCurveTo(x + 22.1666, y + 131.869, x + 19.2807, y + 126.954, x + 20.5317, y + 122.144);
  ctx.lineTo(x + 34.786, y + 67.3374);
  ctx.bezierCurveTo(x + 35.5059, y + 64.5695, x + 38.3351, y + 62.9082, x + 41.1031, y + 63.6281);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 21.5332, y + 118.293);
  ctx.lineTo(x + 104.687, y + 139.92);
  ctx.lineTo(x + 103.683, y + 143.781);
  ctx.bezierCurveTo(x + 102.432, y + 148.591, x + 97.5169, y + 151.477, x + 92.7069, y + 150.226);
  ctx.lineTo(x + 26.9737, y + 133.13);
  ctx.bezierCurveTo(x + 22.1638, y + 131.879, x + 19.2779, y + 126.964, x + 20.5289, y + 122.155);
  ctx.lineTo(x + 21.5332, y + 118.293);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementKnifeAndFork(ctx, scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  // ctx.scale(0.6956521739130435,0.6956521739130435);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 115, y + 0);
  ctx.bezierCurveTo(x + 115, y + 0, x + 115, y + 0, x + 115, y + 0);
  ctx.lineTo(x + 115, y + 115);
  ctx.bezierCurveTo(x + 115, y + 115, x + 115, y + 115, x + 115, y + 115);
  ctx.lineTo(x + 0, y + 115);
  ctx.bezierCurveTo(x + 0, y + 115, x + 0, y + 115, x + 0, y + 115);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 57.5, y + 115);
  ctx.bezierCurveTo(x + 89.2564, y + 115, x + 115, y + 89.2564, x + 115, y + 57.5);
  ctx.bezierCurveTo(x + 115, y + 25.7436, x + 89.2564, y + 0, x + 57.5, y + 0);
  ctx.bezierCurveTo(x + 25.7436, y + 0, x + 0, y + 25.7436, x + 0, y + 57.5);
  ctx.bezierCurveTo(x + 0, y + 89.2564, x + 25.7436, y + 115, x + 57.5, y + 115);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 57.4998, y + 100.261);
  ctx.bezierCurveTo(x + 81.1164, y + 100.261, x + 100.261, y + 81.1164, x + 100.261, y + 57.4998);
  ctx.bezierCurveTo(x + 100.261, y + 33.8833, x + 81.1164, y + 14.7383, x + 57.4998, y + 14.7383);
  ctx.bezierCurveTo(x + 33.8833, y + 14.7383, x + 14.7383, y + 33.8833, x + 14.7383, y + 57.4998);
  ctx.bezierCurveTo(x + 14.7383, y + 81.1164, x + 33.8833, y + 100.261, x + 57.4998, y + 100.261);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 56.874, y + 24.1289);
  ctx.lineTo(x + 55.5809, y + 24.1289);
  ctx.bezierCurveTo(x + 54.5528, y + 24.1289, x + 53.7175, y + 24.9642, x + 53.7175, y + 25.9923);
  ctx.lineTo(x + 53.7175, y + 43.7748);
  ctx.lineTo(x + 50.7859, y + 43.7748);
  ctx.lineTo(x + 50.7859, y + 25.9923);
  ctx.bezierCurveTo(x + 50.7859, y + 24.9642, x + 49.9506, y + 24.1289, x + 48.9225, y + 24.1289);
  ctx.lineTo(x + 47.6294, y + 24.1289);
  ctx.bezierCurveTo(x + 46.6013, y + 24.1289, x + 45.766, y + 24.9642, x + 45.766, y + 25.9923);
  ctx.lineTo(x + 45.766, y + 43.7748);
  ctx.lineTo(x + 42.8344, y + 43.7748);
  ctx.lineTo(x + 42.8344, y + 25.9923);
  ctx.bezierCurveTo(x + 42.8344, y + 24.9642, x + 41.999, y + 24.1289, x + 40.971, y + 24.1289);
  ctx.lineTo(x + 39.6778, y + 24.1289);
  ctx.bezierCurveTo(x + 38.6498, y + 24.1289, x + 37.8145, y + 24.9642, x + 37.8145, y + 25.9923);
  ctx.lineTo(x + 37.8145, y + 47.7345);
  ctx.bezierCurveTo(x + 37.8145, y + 51.7665, x + 40.7702, y + 55.0917, x + 44.6335, y + 55.7021);
  ctx.lineTo(x + 44.6335, y + 91.3796);
  ctx.bezierCurveTo(x + 44.6335, y + 93.3956, x + 46.272, y + 95.0341, x + 48.288, y + 95.0341);
  ctx.bezierCurveTo(x + 50.304, y + 95.0341, x + 51.9425, y + 93.3956, x + 51.9425, y + 91.3796);
  ctx.lineTo(x + 51.9425, y + 55.7021);
  ctx.bezierCurveTo(x + 55.8058, y + 55.0997, x + 58.7615, y + 51.7665, x + 58.7615, y + 47.7345);
  ctx.lineTo(x + 58.7615, y + 25.9923);
  ctx.bezierCurveTo(x + 58.7615, y + 24.9642, x + 57.9262, y + 24.1289, x + 56.8981, y + 24.1289);
  ctx.lineTo(x + 56.874, y + 24.1289);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 74.8658, y + 62.1491);
  ctx.lineTo(x + 67.8942, y + 62.1491);
  ctx.bezierCurveTo(x + 67.8942, y + 62.1491, x + 64.8099, y + 62.1491, x + 63.2678, y + 59.1774);
  ctx.bezierCurveTo(x + 61.7257, y + 56.2056, x + 60.0149, y + 27.9897, x + 74.8658, y + 25.3633);
  ctx.lineTo(x + 74.8658, y + 62.1491);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 67.957, y + 61.9258);
  ctx.lineTo(x + 74.8725, y + 61.9258);
  ctx.lineTo(x + 74.8725, y + 92.0532);
  ctx.bezierCurveTo(x + 74.8725, y + 93.6998, x + 73.5392, y + 95.0331, x + 71.8926, y + 95.0331);
  ctx.lineTo(x + 70.9449, y + 95.0331);
  ctx.bezierCurveTo(x + 69.2984, y + 95.0331, x + 67.9651, y + 93.6998, x + 67.9651, y + 92.0532);
  ctx.lineTo(x + 67.9651, y + 61.9258);
  ctx.lineTo(x + 67.957, y + 61.9258);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementDish(ctx,scale, x, y) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 64, y + 0);
  ctx.bezierCurveTo(x + 64, y + 0, x + 64, y + 0, x + 64, y + 0);
  ctx.lineTo(x + 64, y + 82);
  ctx.bezierCurveTo(x + 64, y + 82, x + 64, y + 82, x + 64, y + 82);
  ctx.lineTo(x + 0, y + 82);
  ctx.bezierCurveTo(x + 0, y + 82, x + 0, y + 82, x + 0, y + 82);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 48.7585, y + 35.0409);
  ctx.bezierCurveTo(x + 48.7585, y + 35.0409, x + 47.2641, y + 32.1265, x + 45.4327, y + 32.6435);
  ctx.bezierCurveTo(x + 43.6012, y + 33.1605, x + 29.7248, y + 50.9137, x + 27.9046, y + 51.0444);
  ctx.bezierCurveTo(x + 26.0843, y + 51.175, x + 25.8652, y + 54.9529, x + 28.5057, y + 55.396);
  ctx.bezierCurveTo(x + 31.1461, y + 55.8391, x + 51.3484, y + 37.8814, x + 48.7585, y + 35.0409);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 11.4381, y + 61.7712);
  ctx.bezierCurveTo(x + 11.4381, y + 61.7712, x + 10.2639, y + 55.3232, x + 14.0785, y + 51.1761);
  ctx.bezierCurveTo(x + 17.8931, y + 47.0289, x + 17.9605, y + 40.5809, x + 17.8145, y + 39.0243);
  ctx.bezierCurveTo(x + 17.6684, y + 37.4677, x + 16.6403, y + 32.7241, x + 18.9886, y + 32.6502);
  ctx.bezierCurveTo(x + 21.3369, y + 32.5764, x + 23.8987, y + 34.7977, x + 24.1909, y + 37.1723);
  ctx.bezierCurveTo(x + 24.483, y + 39.5413, x + 25.0729, y + 48.6594, x + 27.3426, y + 50.6591);
  ctx.bezierCurveTo(x + 29.6122, y + 52.6588, x + 35.1852, y + 51.1022, x + 38.3369, y + 47.5459);
  ctx.bezierCurveTo(x + 41.4886, y + 43.9896, x + 48.5954, y + 35.1726, x + 49.5504, y + 34.133);
  ctx.bezierCurveTo(x + 50.5055, y + 33.0933, x + 52.556, y + 32.2071, x + 53.8763, y + 33.2411);
  ctx.bezierCurveTo(x + 55.1965, y + 34.275, x + 56.3145, y + 35.4794, x + 53.219, y + 39.6152);
  ctx.bezierCurveTo(x + 52.1122, y + 41.0922, x + 39.0055, y + 60.0669, x + 33.9493, y + 63.9925);
  ctx.bezierCurveTo(x + 28.8931, y + 67.9181, x + 21.5673, y + 71.9971, x + 21.5673, y + 71.9971);
  ctx.lineTo(x + 11.4549, y + 61.7712);
  ctx.lineTo(x + 11.4381, y + 61.7712);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 34.4325, y + 8.08594);
  ctx.bezierCurveTo(x + 20.764, y + 8.08594, x + 9.67969, y + 17.0506, x + 9.67969, y + 28.1059);
  ctx.lineTo(x + 59.1853, y + 28.1059);
  ctx.bezierCurveTo(x + 59.1853, y + 17.0506, x + 48.101, y + 8.08594, x + 34.4325, y + 8.08594);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 34.6791, y + 8.85105);
  ctx.bezierCurveTo(x + 37.0962, y + 8.85105, x + 39.0555, y + 6.86967, x + 39.0555, y + 4.42552);
  ctx.bezierCurveTo(x + 39.0555, y + 1.98137, x + 37.0962, y + 0, x + 34.6791, y + 0);
  ctx.bezierCurveTo(x + 32.2621, y + 0, x + 30.3027, y + 1.98137, x + 30.3027, y + 4.42552);
  ctx.bezierCurveTo(x + 30.3027, y + 6.86967, x + 32.2621, y + 8.85105, x + 34.6791, y + 8.85105);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 62.5225, y + 25.9961);
  ctx.lineTo(x + 6.34276, y + 25.9961);
  ctx.bezierCurveTo(x + 5.52675, y + 25.9961, x + 4.86523, y + 26.665, x + 4.86523, y + 27.4902);
  ctx.lineTo(x + 4.86523, y + 32.1998);
  ctx.bezierCurveTo(x + 4.86523, y + 33.025, x + 5.52675, y + 33.6939, x + 6.34276, y + 33.6939);
  ctx.lineTo(x + 62.5225, y + 33.6939);
  ctx.bezierCurveTo(x + 63.3386, y + 33.6939, x + 64.0001, y + 33.025, x + 64.0001, y + 32.1998);
  ctx.lineTo(x + 64.0001, y + 27.4902);
  ctx.bezierCurveTo(x + 64.0001, y + 26.665, x + 63.3386, y + 25.9961, x + 62.5225, y + 25.9961);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 25.3082, y + 72.9778);
  ctx.lineTo(x + 8.92957, y + 56.4153);
  ctx.bezierCurveTo(x + 8.28674, y + 55.7652, x + 7.24451, y + 55.7652, x + 6.60168, y + 56.4153);
  ctx.lineTo(x + 0.484016, y + 62.6016);
  ctx.bezierCurveTo(x + -0.158813, y + 63.2517, x + -0.158816, y + 64.3056, x + 0.484014, y + 64.9556);
  ctx.lineTo(x + 16.8627, y + 81.5181);
  ctx.bezierCurveTo(x + 17.5055, y + 82.1682, x + 18.5477, y + 82.1682, x + 19.1906, y + 81.5181);
  ctx.lineTo(x + 25.3082, y + 75.3318);
  ctx.bezierCurveTo(x + 25.9511, y + 74.6817, x + 25.9511, y + 73.6278, x + 25.3082, y + 72.9778);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 17.2974, y + 79.3886);
  ctx.bezierCurveTo(x + 18.4703, y + 79.3886, x + 19.421, y + 78.4272, x + 19.421, y + 77.2412);
  ctx.bezierCurveTo(x + 19.421, y + 76.0552, x + 18.4703, y + 75.0938, x + 17.2974, y + 75.0938);
  ctx.bezierCurveTo(x + 16.1246, y + 75.0938, x + 15.1738, y + 76.0552, x + 15.1738, y + 77.2412);
  ctx.bezierCurveTo(x + 15.1738, y + 78.4272, x + 16.1246, y + 79.3886, x + 17.2974, y + 79.3886);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}

drawElementRecipe(ctx,scale, x, y, rotationAngle) {
  x = x / scale;
  y = y / scale;

  ctx.save();
  ctx.strokeStyle="rgba(0,0,0,0)";
  ctx.miterLimit=4;

  ctx.fillStyle="rgba(0,0,0,0)";
  ctx.scale(scale, scale);
  // ctx.scale(0.9090909090909091,0.9090909090909091);
  ctx.save();
  ctx.fillStyle="rgba(0,0,0,0)";

  // ctx.translate(x + 19.1777, y + 0.777344);
  // ctx.rotate(0.26408402379001);
  ctx.beginPath();
  ctx.moveTo(x + 0, y + 0);
  ctx.lineTo(x + 70.4894, y + 0);
  ctx.bezierCurveTo(x + 70.4894, y + 0, x + 70.4894, y + 0, x + 70.4894, y + 0);
  ctx.lineTo(x + 70.4894, y + 70.4894);
  ctx.bezierCurveTo(x + 70.4894, y + 70.4894, x + 70.4894, y + 70.4894, x + 70.4894, y + 70.4894);
  ctx.lineTo(x + 0, y + 70.4894);
  ctx.bezierCurveTo(x + 0, y + 70.4894, x + 0, y + 70.4894, x + 0, y + 70.4894);
  ctx.lineTo(x + 0, y + 0);
  ctx.bezierCurveTo(x + 0, y + 0, x + 0, y + 0, x + 0, y + 0);
  ctx.closePath();
  // ctx.rotate(-0.26408402379001);
  // ctx.translate(-x - 19.1777, -y - 0.777344);
  // ctx.clip();

  ctx.translate(x + 19.1777, y + 0.777344); // trasladar el origen al centro del dibujo

  ctx.rotate(rotationAngle * Math.PI / 180); // rotar 15 grados hacia la izquierda

  ctx.translate(-x - 19.1777, -y - 0.777344); // regresar el origen a su posición

  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 67.3909, y + 13.8141);
  ctx.lineTo(x + 22.089, y + 1.56454);
  ctx.bezierCurveTo(x + 20.4811, y + 1.12978, x + 18.8259, y + 2.07838, x + 18.392, y + 3.6833);
  ctx.lineTo(x + 1.56402, y + 65.9171);
  ctx.bezierCurveTo(x + 1.13006, y + 67.522, x + 2.08166, y + 69.1755, x + 3.68949, y + 69.6102);
  ctx.lineTo(x + 48.9914, y + 81.8598);
  ctx.bezierCurveTo(x + 50.5993, y + 82.2946, x + 52.2545, y + 81.346, x + 52.6885, y + 79.7411);
  ctx.lineTo(x + 69.5164, y + 17.5073);
  ctx.bezierCurveTo(x + 69.9504, y + 15.9024, x + 68.9988, y + 14.2489, x + 67.3909, y + 13.8141);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle="#EDEBED";

  ctx.beginPath();
  ctx.moveTo(x + 39.8838, y + 7.06063);
  ctx.lineTo(x + 32.6921, y + 10.6519);
  ctx.bezierCurveTo(x + 32.1171, y + 10.938, x + 31.4582, y + 11.0027, x + 30.8348, y + 10.8341);
  ctx.lineTo(x + 19.9679, y + 7.89569);
  ctx.bezierCurveTo(x + 18.5701, y + 7.52326, x + 17.1306, y + 8.34826, x + 16.7537, y + 9.74209);
  ctx.lineTo(x + 1.46111, y + 66.2977);
  ctx.bezierCurveTo(x + 1.08422, y + 67.6915, x + 1.91184, y + 69.1295, x + 3.3082, y + 69.5071);
  ctx.lineTo(x + 49.3779, y + 81.9643);
  ctx.bezierCurveTo(x + 50.7743, y + 82.3419, x + 52.2138, y + 81.5169, x + 52.5907, y + 80.1231);
  ctx.lineTo(x + 69.6245, y + 17.1281);
  ctx.bezierCurveTo(x + 70.0014, y + 15.7343, x + 69.1737, y + 14.2962, x + 67.7774, y + 13.9186);
  ctx.lineTo(x + 41.7463, y + 6.87985);
  ctx.bezierCurveTo(x + 41.128, y + 6.71266, x + 40.4639, y + 6.77595, x + 39.8889, y + 7.06202);
  ctx.lineTo(x + 39.8838, y + 7.06063);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 64.145, y + 15.5957);
  ctx.lineTo(x + 47.3371, y + 11.0508);
  ctx.bezierCurveTo(x + 46.563, y + 10.8415, x + 45.7662, y + 11.2982, x + 45.5573, y + 12.0708);
  ctx.bezierCurveTo(x + 45.3483, y + 12.8435, x + 45.8065, y + 13.6395, x + 46.5805, y + 13.8488);
  ctx.lineTo(x + 63.3884, y + 18.3936);
  ctx.bezierCurveTo(x + 64.1624, y + 18.6029, x + 64.9593, y + 18.1463, x + 65.1682, y + 17.3736);
  ctx.bezierCurveTo(x + 65.3771, y + 16.601, x + 64.919, y + 15.805, x + 64.145, y + 15.5957);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.2419, y + 72.0189);
  ctx.lineTo(x + 7.84051, y + 60.824);
  ctx.bezierCurveTo(x + 7.44211, y + 60.7162, x + 7.03197, y + 60.9513, x + 6.92444, y + 61.349);
  ctx.lineTo(x + 6.32642, y + 63.5606);
  ctx.bezierCurveTo(x + 6.21889, y + 63.9583, x + 6.45468, y + 64.368, x + 6.85308, y + 64.4757);
  ctx.lineTo(x + 48.2545, y + 75.6706);
  ctx.bezierCurveTo(x + 48.6529, y + 75.7783, x + 49.063, y + 75.5433, x + 49.1706, y + 75.1456);
  ctx.lineTo(x + 49.7686, y + 72.934);
  ctx.bezierCurveTo(x + 49.8761, y + 72.5363, x + 49.6403, y + 72.1266, x + 49.2419, y + 72.0189);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 51.0525, y + 65.3235);
  ctx.lineTo(x + 9.65106, y + 54.1287);
  ctx.bezierCurveTo(x + 9.25266, y + 54.0209, x + 8.84252, y + 54.256, x + 8.73498, y + 54.6537);
  ctx.lineTo(x + 8.13697, y + 56.8653);
  ctx.bezierCurveTo(x + 8.02943, y + 57.2629, x + 8.26523, y + 57.6727, x + 8.66363, y + 57.7804);
  ctx.lineTo(x + 50.065, y + 68.9753);
  ctx.bezierCurveTo(x + 50.4634, y + 69.083, x + 50.8736, y + 68.848, x + 50.9811, y + 68.4503);
  ctx.lineTo(x + 51.5791, y + 66.2387);
  ctx.bezierCurveTo(x + 51.6867, y + 65.841, x + 51.4509, y + 65.4313, x + 51.0525, y + 65.3235);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 52.865, y + 58.6204);
  ctx.lineTo(x + 11.4636, y + 47.4255);
  ctx.bezierCurveTo(x + 11.0652, y + 47.3178, x + 10.655, y + 47.5528, x + 10.5475, y + 47.9505);
  ctx.lineTo(x + 9.94947, y + 50.1621);
  ctx.bezierCurveTo(x + 9.84193, y + 50.5598, x + 10.0777, y + 50.9695, x + 10.4761, y + 51.0773);
  ctx.lineTo(x + 51.8775, y + 62.2722);
  ctx.bezierCurveTo(x + 52.2759, y + 62.3799, x + 52.6861, y + 62.1448, x + 52.7936, y + 61.7472);
  ctx.lineTo(x + 53.3916, y + 59.5355);
  ctx.bezierCurveTo(x + 53.4992, y + 59.1379, x + 53.2634, y + 58.7282, x + 52.865, y + 58.6204);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 54.6755, y + 51.9251);
  ctx.lineTo(x + 13.2741, y + 40.7302);
  ctx.bezierCurveTo(x + 12.8757, y + 40.6225, x + 12.4656, y + 40.8575, x + 12.358, y + 41.2552);
  ctx.lineTo(x + 11.76, y + 43.4668);
  ctx.bezierCurveTo(x + 11.6525, y + 43.8645, x + 11.8883, y + 44.2742, x + 12.2867, y + 44.3819);
  ctx.lineTo(x + 53.6881, y + 55.5768);
  ctx.bezierCurveTo(x + 54.0865, y + 55.6846, x + 54.4966, y + 55.4495, x + 54.6042, y + 55.0518);
  ctx.lineTo(x + 55.2022, y + 52.8402);
  ctx.bezierCurveTo(x + 55.3097, y + 52.4426, x + 55.0739, y + 52.0328, x + 54.6755, y + 51.9251);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 71.1328, y + 47.875);
  ctx.lineTo(x + 73.9306, y + 51.3886);
  ctx.lineTo(x + 45.6098, y + 73.8591);
  ctx.bezierCurveTo(x + 44.6409, y + 74.6279, x + 43.2235, y + 74.4655, x + 42.4526, y + 73.4974);
  ctx.bezierCurveTo(x + 41.6818, y + 72.5292, x + 41.8431, y + 71.1142, x + 42.812, y + 70.3454);
  ctx.lineTo(x + 71.1328, y + 47.875);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 76.8304, y + 49.1668);
  ctx.lineTo(x + 73.9529, y + 45.553);
  ctx.bezierCurveTo(x + 73.5345, y + 45.0275, x + 72.769, y + 44.9398, x + 72.2431, y + 45.3571);
  ctx.lineTo(x + 66.0619, y + 50.2614);
  ctx.bezierCurveTo(x + 65.536, y + 50.6787, x + 65.4488, y + 51.4429, x + 65.8672, y + 51.9684);
  ctx.lineTo(x + 68.7447, y + 55.5822);
  ctx.bezierCurveTo(x + 69.1631, y + 56.1077, x + 69.9287, y + 56.1954, x + 70.4546, y + 55.7781);
  ctx.lineTo(x + 76.6358, y + 50.8738);
  ctx.bezierCurveTo(x + 77.1617, y + 50.4565, x + 77.2488, y + 49.6923, x + 76.8304, y + 49.1668);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.primaryLightSecondVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 79.7637, y + 43.8936);
  ctx.bezierCurveTo(x + 80.4017, y + 44.6948, x + 80.2719, y + 45.8669, x + 79.4658, y + 46.5065);
  ctx.lineTo(x + 76.5548, y + 48.8161);
  ctx.lineTo(x + 74.2388, y + 45.9076);
  ctx.lineTo(x + 77.1498, y + 43.5979);
  ctx.bezierCurveTo(x + 77.9517, y + 42.9617, x + 79.1258, y + 43.0924, x + 79.7671, y + 43.8978);
  ctx.lineTo(x + 79.7637, y + 43.8936);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryColor}`;

  ctx.beginPath();
  ctx.moveTo(x + 38.6215, y + 27.5366);
  ctx.bezierCurveTo(x + 31.7891, y + 25.6892, x + 25.0554, y + 28.6095, x + 23.5798, y + 34.0665);
  ctx.lineTo(x + 48.3176, y + 40.7556);
  ctx.bezierCurveTo(x + 49.7918, y + 35.3037, x + 45.4487, y + 29.3827, x + 38.6163, y + 27.5352);
  ctx.lineTo(x + 38.6215, y + 27.5366);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 38.6382, y + 27.9444);
  ctx.bezierCurveTo(x + 39.8448, y + 28.2707, x + 41.0869, y + 27.5588, x + 41.4126, y + 26.3544);
  ctx.bezierCurveTo(x + 41.7382, y + 25.15, x + 41.0241, y + 23.9092, x + 39.8175, y + 23.5829);
  ctx.bezierCurveTo(x + 38.6109, y + 23.2567, x + 37.3688, y + 23.9685, x + 37.0431, y + 25.1729);
  ctx.bezierCurveTo(x + 36.7175, y + 26.3773, x + 37.4316, y + 27.6182, x + 38.6382, y + 27.9444);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle=`${this.brandColors.secondaryLightFirstVariant}`;

  ctx.beginPath();
  ctx.moveTo(x + 49.6542, y + 40.0029);
  ctx.lineTo(x + 22.8141, y + 32.7453);
  ctx.bezierCurveTo(x + 22.0657, y + 32.543, x + 21.2952, y + 32.9845, x + 21.0932, y + 33.7316);
  ctx.lineTo(x + 20.7984, y + 34.822);
  ctx.bezierCurveTo(x + 20.5964, y + 35.569, x + 21.0393, y + 36.3387, x + 21.7878, y + 36.5411);
  ctx.lineTo(x + 48.6278, y + 43.7986);
  ctx.bezierCurveTo(x + 49.3763, y + 44.001, x + 50.1467, y + 43.5594, x + 50.3487, y + 42.8124);
  ctx.lineTo(x + 50.6436, y + 41.722);
  ctx.bezierCurveTo(x + 50.8456, y + 40.9749, x + 50.4026, y + 40.2052, x + 49.6542, y + 40.0029);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();

}


  // * tematicas
  drawElementsByIndustryTheme(theme, roadType, mapSection, ctx, midWidth, startHeight, additional,heigthElement, isLargeRoad? ) {
    switch(theme) {
      case "default":
        if(roadType === "road1") {
          mapSection === 0 ? this.drawForestIconsFirstSection(ctx, midWidth, startHeight,additional) : this.drawForestIconsSecondSection(ctx, midWidth, startHeight,heigthElement);
        } else {
          this.drawForestIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad);
        };
      break;

      case "medical_pharma":
        if(roadType === "road1") {
          mapSection === 0 ? this.drawFarmacyIconsFirstSection(ctx, midWidth, startHeight,additional) : this.drawFarmacyIconsSecondSection(ctx, midWidth, startHeight,heigthElement);
        } else {
          this.drawFarmacyIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad);
        };
      break;

      case "retail":
        if(roadType === "road1") {
          mapSection === 0 ? this.drawRetailIconsFirstSection(ctx, midWidth, startHeight,additional) : this.drawRetailIconsSecondSection(ctx, midWidth, startHeight, heigthElement);
        } else {
          this.drawRetailIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad);
        };
      break;

      case "banking":
        if(roadType === "road1") {
          mapSection === 0 ? this.drawBankingIconsFirstSection(ctx, midWidth, startHeight,additional) : this.drawBankingIconsSecondSection(ctx, midWidth, startHeight, heigthElement);
        } else {
          this.drawBankingIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad);
        };
      break;

      case "clothing_store":
        if(roadType === "road1") {
          mapSection === 0 ? this.drawClothingStoreIconsFirstSection(ctx, midWidth, startHeight,additional) : this.drawClothingStoreIconsSecondSection(ctx, midWidth, startHeight, heigthElement);
        } else {
          this.drawClothingIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad);
        };
      break;

      case "restaurants":
        if(roadType === "road1") {
          mapSection === 0 ? this.drawRestaurantIconsFirstSection(ctx, midWidth, startHeight,additional) : this.drawRestaurantIconsSecondSection(ctx, midWidth, startHeight, heigthElement);
        } else {
          this.drawRestaurantIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad);
        };
      break;
    };
  }

  drawRoadBlockByIndustryTheme(theme, ctx, midWidth, startHeight) {
    switch(theme) {
      case "default":
        this.drawElementTree(ctx,1,midWidth+60,startHeight-130);
        this.drawElementTree(ctx,1,midWidth+80,startHeight-135);
        this.drawElementTree(ctx,1,midWidth+100,startHeight-128);
        this.drawElementTree(ctx,1,midWidth+50,startHeight-108);
        this.drawElementTree(ctx,1,midWidth+71,startHeight-114);
        this.drawElementTree(ctx,1,midWidth+90,startHeight-120);
        this.drawElementTree(ctx,1,midWidth+110,startHeight-112);
        this.drawElementTree(ctx,1,midWidth+61,startHeight-96);
        this.drawElementTree(ctx,1,midWidth+96,startHeight-100);
        this.drawElementTree(ctx,1,midWidth+80,startHeight-98);
      break;

      case "medical_pharma":
        this.drawElementFolder(ctx, 1.2, midWidth + 58, startHeight - 117, 5);
      break;

      case "retail":
      this.drawElementMarket(ctx, 0.9, midWidth + 61, startHeight - 117);
      break;

      case "banking":
        this.drawElementBinder(ctx, 1.1, midWidth+60 ,startHeight - 117);
      break;

      case "clothing_store":
        this.drawElementDisplayWindow(ctx,0.89, midWidth+78 ,startHeight - 135, 25);
      break;

      case "restaurants":
        this.drawElementRestaurant(ctx, 0.93, midWidth+63 ,startHeight - 133)
        // this.drawElementRestaurant(ctx, 0.93, midWidth+61 ,startHeight - 126)
      break;
    };
  }

  //* type default
  // road1
  drawForestIconsFirstSection(ctx, midWidth, startHeight,additional ) {

    this.drawElementBush(ctx,0.4,midWidth-100,startHeight-105);
    this.drawElementBush(ctx,0.4,midWidth-122,startHeight-92);

    this.drawElementTree(ctx,1,midWidth+60,startHeight-130);
    this.drawElementTree(ctx,1,midWidth+80,startHeight-135);
    this.drawElementTree(ctx,1,midWidth+100,startHeight-128);
    this.drawElementTree(ctx,1,midWidth+50,startHeight-108);
    this.drawElementTree(ctx,1,midWidth+71,startHeight-114);
    this.drawElementTree(ctx,1,midWidth+90,startHeight-120);
    this.drawElementTree(ctx,1,midWidth+110,startHeight-112);
    this.drawElementTree(ctx,1,midWidth+61,startHeight-96);
    this.drawElementTree(ctx,1,midWidth+96,startHeight-100);
    this.drawElementTree(ctx,1,midWidth+80,startHeight-98);

    this.drawElementRock(ctx,0.42,midWidth+18,startHeight-250);
    this.drawElementRock(ctx,0.42,midWidth-4,startHeight-232);

    this.drawElementRock(ctx,0.8,midWidth-94,startHeight-252);
    this.drawElementRock(ctx,0.42,midWidth-22,startHeight-278);
    this.drawElementRock(ctx,0.42,midWidth-78,startHeight-194);


    this.drawElementMountain(ctx,0.52,midWidth+74,startHeight-450);
    this.drawElementMountain(ctx,1,midWidth-20,startHeight-440);
    this.drawElementMountain(ctx,1,midWidth+36,startHeight-436);
    this.drawElementMountain(ctx,0.6,midWidth+6,startHeight-402);
    this.drawElementMountain(ctx,0.6,midWidth+36,startHeight-394);
    this.drawElementMountain(ctx,0.35,midWidth-6,startHeight-378);

    // Fin de recorrido
    this.drawElementRock(ctx,0.5,midWidth-80,startHeight-550);
    this.drawElementBush(ctx,0.45,midWidth-140,startHeight-540);
    this.drawElementRock(ctx,0.5,midWidth-156,startHeight-526);

    this.drawElementRock(ctx,0.42,midWidth+72+(additional>0?-40:0),startHeight-550);
    this.drawElementRock(ctx,0.42,midWidth+50+(additional>0?-40:0),startHeight-532);
    this.drawElementRock(ctx,0.42,midWidth+78+(additional>0?-40:0),startHeight-512);
    this.drawElementRock(ctx,0.42,midWidth+30+(additional>0?-40:0),startHeight-572);
  }

  drawForestIconsSecondSection(ctx, midWidth, startHeight,heigthElement) {
    this.drawElementBush(ctx,0.4,midWidth-100,startHeight-heigthElement-105);
    this.drawElementBush(ctx,0.4,midWidth-122,startHeight-heigthElement-92);

    this.drawElementTree(ctx,1,midWidth+60,startHeight-heigthElement-130);
    this.drawElementTree(ctx,1,midWidth+80,startHeight-heigthElement-135);
    this.drawElementTree(ctx,1,midWidth+100,startHeight-heigthElement-128);
    this.drawElementTree(ctx,1,midWidth+50,startHeight-heigthElement-108);
    this.drawElementTree(ctx,1,midWidth+71,startHeight-heigthElement-114);
    this.drawElementTree(ctx,1,midWidth+90,startHeight-heigthElement-120);
    this.drawElementTree(ctx,1,midWidth+110,startHeight-heigthElement-112);
    this.drawElementTree(ctx,1,midWidth+61,startHeight-heigthElement-96);
    this.drawElementTree(ctx,1,midWidth+96,startHeight-heigthElement-100);
    this.drawElementTree(ctx,1,midWidth+80,startHeight-heigthElement-98);
  }
  // road2
  drawForestIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad) {
    this.drawElementRock(ctx,0.3,midWidth-108,startHeight-100);
		this.drawElementRock(ctx,0.65,midWidth-84,startHeight-120);
		this.drawElementBush(ctx,0.25,midWidth-98,startHeight-94);
		this.drawElementRock(ctx,0.3,midWidth-84,startHeight-82);
		this.drawElementBush(ctx,0.4,midWidth+62,startHeight-185);
		this.drawElementBush(ctx,0.4,midWidth+40,startHeight-172);
		this.drawElementBush(ctx,0.4,midWidth+50,startHeight-110);

    /*
		// this.drawElementTree(ctx,1,midWidth+60,startHeight-130);
		// this.drawElementTree(ctx,1,midWidth+80,startHeight-135);
		// this.drawElementTree(ctx,1,midWidth+100,startHeight-128);
		// this.drawElementTree(ctx,1,midWidth+50,startHeight-108);
		// this.drawElementTree(ctx,1,midWidth+71,startHeight-114);
		// this.drawElementTree(ctx,1,midWidth+90,startHeight-120);
		// this.drawElementTree(ctx,1,midWidth+110,startHeight-112);
		// this.drawElementTree(ctx,1,midWidth+61,startHeight-96);
		// this.drawElementTree(ctx,1,midWidth+96,startHeight-100);
		// this.drawElementTree(ctx,1,midWidth+80,startHeight-98);
    */

		this.drawElementRock(ctx,0.42,midWidth+18,startHeight-405);
		this.drawElementRock(ctx,0.42,midWidth-4,startHeight-390);

		this.drawElementRock(ctx,0.8,midWidth-94,startHeight-400);
		this.drawElementRock(ctx,0.42,midWidth-22,startHeight-435);
		this.drawElementRock(ctx,0.42,midWidth-78,startHeight-460);

		this.drawElementMountain(ctx,0.52,midWidth+74,startHeight-300);
		this.drawElementMountain(ctx,1,midWidth-20,startHeight-290);
		this.drawElementMountain(ctx,1,midWidth+36,startHeight-286);
		this.drawElementMountain(ctx,0.6,midWidth+6,startHeight-252);
		this.drawElementMountain(ctx,0.5,midWidth+36,startHeight-244);
		this.drawElementMountain(ctx,0.25,midWidth-6,startHeight-228);

		// Fin de recorrido
		this.drawElementRock(ctx,0.5,midWidth-80,startHeight-550);
		this.drawElementBush(ctx,0.45,midWidth-140,startHeight-540);
		this.drawElementRock(ctx,0.5,midWidth-156,startHeight-526);

		this.drawElementRock(ctx,0.42,midWidth+72,startHeight-550);
		this.drawElementRock(ctx,0.42,midWidth+50,startHeight-532);
		this.drawElementRock(ctx,0.42,midWidth+78,startHeight-512);
		this.drawElementRock(ctx,0.42,midWidth+30,startHeight-572);
  }

  //* farmacia
   // road1
  drawFarmacyIconsFirstSection(ctx, midWidth, startHeight,additional ) {
    this.drawElementBandAid(ctx, 0.48, midWidth-5, startHeight-279, -15);
    this.drawElementBandAid(ctx, 0.85, midWidth-110, startHeight-262, -4);
    this.drawElementBandAid(ctx, 0.35, midWidth-68, startHeight-180, 18);

    this.drawElementSprayBottle(ctx, 0.75, midWidth-112, startHeight-90, -5);
    this.drawElementFolder(ctx, 0.98, midWidth + 55, startHeight - 95, 0);
    this.drawElementFirstAidKitScale(ctx, 0.85, midWidth-10, startHeight-425);
    this.drawElementNote(ctx, 0.84, midWidth+50 + (additional > 0 ? -40 : 0), startHeight-550);
    this.drawElementVitalSignsMonitor(ctx, 0.99, midWidth-105, startHeight-550);
    this.drawElementBandAid(ctx, 0.35, midWidth-124, startHeight-537, 5);
  }

  drawFarmacyIconsSecondSection(ctx, midWidth, startHeight,heigthElement) {
    this.drawElementSprayBottle(ctx, 0.7, midWidth-96, startHeight-heigthElement-115, -5);
    this.drawElementFolder(ctx, 1.2, midWidth + 35, startHeight-heigthElement-119, 0);
  }
 // road2
  drawFarmacyIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad) {
    this.drawElementVitalSignsMonitor(ctx, 1, midWidth-105, startHeight-120);
    this.drawElementBandAid(ctx, 0.35, midWidth-124, startHeight-115, 5);
    this.drawElementSprayBottle(ctx, 0.75, midWidth+60, startHeight-130, -5);

    this.drawElementFirstAidKitScale(ctx, 0.9, midWidth, startHeight-270);

    this.drawElementBandAid(ctx, 0.8, midWidth-90, startHeight- (isLargeRoad ? 430 : 450), -4);
    this.drawElementBandAid(ctx, 0.48, midWidth-10, startHeight-400, 20);

    this.drawElementSimpleAidKit(ctx, midWidth - (isLargeRoad ? 80 : 105), startHeight-545);
    this.drawElementBandAid(ctx, 0.5, midWidth+72, startHeight-545, 5);
  }

 // * retail
// road1
 drawRetailIconsFirstSection(ctx, midWidth, startHeight,additional ) {
  this.drawElementMarket(ctx, 0.85,midWidth + 55, startHeight - 115);
  this.drawElementPaperBag(ctx, 0.9, midWidth-112, startHeight-120, 0);
  this.drawElementWetWipes(ctx, 1, midWidth-98, startHeight-262, 0);
  this.drawElementWetWipes(ctx, 0.6, midWidth-18, startHeight-287, 29);
  this.drawElementShoppingCart(ctx, additional > 0 ? 0.88 : 0.9, midWidth + (additional > 0 ? 10 : -10), startHeight- (additional > 0 ? 430 : 438) );
  this.drawElementMoney(ctx, 0.83, midWidth-133, startHeight-560);
  this.drawElementPlasticBag(ctx, 0.7, midWidth + 50 + (additional > 0 ? -40 : 0) , startHeight-560);
 }

 drawRetailIconsSecondSection(ctx, midWidth, startHeight,heigthElement) {
  this.drawElementPaperBag(ctx, 0.9, midWidth-96, startHeight-heigthElement-115, 0);
  this.drawElementWetWipes(ctx, 0.77, midWidth+30, startHeight-heigthElement-125, 0);
  this.drawElementWetWipes(ctx, 0.5, midWidth+90, startHeight-heigthElement-149, 27);
 }
 // road2
 drawRetailIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad) {
  this.drawElementMoney(ctx, 0.83, midWidth-128, startHeight-120);
  this.drawElementPaperBag(ctx, 0.9, midWidth+60, startHeight-125, 0);
  this.drawElementShoppingCart(ctx, 0.88, midWidth, startHeight-274 );

  this.drawElementWetWipes(ctx, 1, midWidth-90, startHeight-(isLargeRoad ? 430 : 447), 0);
  this.drawElementWetWipes(ctx, 0.58, midWidth-15, startHeight-410, -26);

  this.drawElementShoppingCart(ctx, 0.65, midWidth - (isLargeRoad ? 72 : 128), startHeight-548 );

  this.drawElementWetWipes(ctx, 0.65, midWidth+66, startHeight-545, -17);
 }

  // * banking
// road1
drawBankingIconsFirstSection(ctx, midWidth, startHeight,additional ) {
  this.drawElementBinder(ctx, 0.9, midWidth+55 ,startHeight - 115);
  this.drawElementMoney(ctx, 0.83, midWidth-128, startHeight-120);

  this.drawElementNoteWithPencil(ctx, 0.91, midWidth-115, startHeight-269);
  this.drawElementCreditCard(ctx, 0.8, midWidth-133, startHeight-560);

  this.drawElementMultiplePages(ctx, 0.85, midWidth + 50 + (additional > 0 ? -30 : 0) , startHeight-560 );
  this.drawElementBills(ctx, additional > 0 ? 0.88 : 0.9, midWidth + (additional > 0 ? 10 : -10), startHeight- (additional > 0 ? 430 : 438));
 }

 drawBankingIconsSecondSection(ctx, midWidth, startHeight,heigthElement) {
  this.drawElementMoney(ctx, 0.83, midWidth-105, startHeight-heigthElement-120);
  this.drawElementBinder(ctx, 0.85, midWidth+48 , startHeight-heigthElement-120);
 }
 // road2
 drawBankingIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad) {
    this.drawElementCreditCard(ctx, 0.8, midWidth-128, startHeight-120);
    this.drawElementMoney(ctx, 0.8, midWidth+50, startHeight-125);

    this.drawElementBills(ctx, 0.9, midWidth , startHeight-274);

    this.drawElementBinder(ctx, 1, midWidth-80 ,startHeight-(isLargeRoad ? 430 : 447));

    this.drawElementBills(ctx, 0.77,  midWidth - (isLargeRoad ? 72 : 128), startHeight-548 );
    this.drawElementCoin(ctx,1, midWidth+66, startHeight-545)
 }

 // * clothing_store
// road1
drawClothingStoreIconsFirstSection(ctx, midWidth, startHeight,additional ) {
  this.drawElementShoppingApp(ctx, 1, midWidth-128, startHeight-113);
  this.drawElementDisplayWindow(ctx,0.83, midWidth+55 ,startHeight - 115, 0);

  this.drawElementClothes(ctx, 0.8, midWidth-100, startHeight- 272 );

  this.drawElementShoppingWeb(ctx,0.76, midWidth + (additional > 0 ? 10 : -18), startHeight-(additional > 0 ? 424 : 433) );

  this.drawElementClothesRack(ctx,0.79, midWidth + 50 + (additional > 0 ? -40 : 0) ,  startHeight-560 , 0)
  this.drawElementShirts(ctx,0.88, midWidth-133,  startHeight-560, 0)
}

drawClothingStoreIconsSecondSection(ctx, midWidth, startHeight,heigthElement) {
  this.drawElementShoppingApp(ctx, 1, midWidth-105, startHeight-heigthElement-124);
  this.drawElementClothes(ctx, 0.6, midWidth+50, startHeight-heigthElement-125 );
}
// road2
drawClothingIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad) {
  this.drawElementShirts(ctx,0.88, midWidth-115, startHeight-139, 33)
  this.drawElementShoppingApp(ctx, 1.1, midWidth+50, startHeight-136);

  this.drawElementShoppingWeb(ctx,0.85, midWidth , startHeight-282 );

  this.drawElementClothes(ctx, 0.8, midWidth-80, startHeight-(isLargeRoad ? 437 : 447));

  this.drawElementShoppingWeb(ctx, 0.6, midWidth-(isLargeRoad ? 72 : 128) , startHeight-548 );
  this.drawElementClothes(ctx, 0.57, midWidth+79, startHeight-553);
}
 // * restaurants
// road1
drawRestaurantIconsFirstSection(ctx, midWidth, startHeight,additional ) {
  this.drawElementBowl(ctx, 0.9, midWidth-128, startHeight-120)
  this.drawElementRestaurant(ctx, 0.83, midWidth+55 ,startHeight - 115)
  this.drawElementPot(ctx, 0.8, midWidth-120, startHeight-(additional > 0 ? 280 : 288), 0)
  this.drawElementKnifeAndFork(ctx,additional > 0 ? 0.69 : 0.77, midWidth + (additional > 0 ? 10 : -18), startHeight-(additional > 0 ? 430 : 447) )

  this.drawElementRecipe(ctx, 0.9, midWidth-133, startHeight-560, 0)
  this.drawElementDish(ctx, 1, midWidth + 50 + (additional > 0 ? -40 : 0),  startHeight-560)
}

drawRestaurantIconsSecondSection(ctx, midWidth, startHeight,heigthElement) {
  this.drawElementBowl(ctx, 0.9,  midWidth-107, startHeight-heigthElement-127);
  this.drawElementPot(ctx, 0.57, midWidth+50, startHeight-heigthElement-139, 0);
}
// road2
drawRestaurantIconsRoadTwo(ctx, midWidth, startHeight, isLargeRoad) {
  this.drawElementRecipe(ctx, 0.9,midWidth-120, startHeight-136, 0);
  this.drawElementBowl(ctx, 0.9, midWidth+50, startHeight-136);
  this.drawElementKnifeAndFork(ctx,isLargeRoad > 0 ? 0.72 : 0.82, midWidth, startHeight-282 )
  this.drawElementPot(ctx, isLargeRoad ? 0.67 : 0.72,  midWidth-(isLargeRoad ? 80 : 96), startHeight-(isLargeRoad ? 453 : 475), 0)
  this.drawElementKnifeAndFork(ctx, 0.6,midWidth-(isLargeRoad ? 72 : 128) , startHeight-555  )
  this.drawElementPot(ctx, 0.55, midWidth+40, startHeight-561, -27)
}

}

