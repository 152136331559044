import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {

  showOptions:boolean = false;
  chosenOption:any =  '';
  searchTerm:any = '';
  searchResults:any = [];

  @Output() selectInput: EventEmitter<any> = new EventEmitter<any>();

  @Input() value: String = '';
  @Input() placeholder: String = "Enter text here.";
  @Input() data: any = [];
  @Input() inputClass: String = "border border-gray-300 py-2 px-3 rounded-md focus:outline-none focus:shadow-outline";
  @Input() dropdownClass: String = "-mt-1 w-full bg-white border border-t-0 mh-48 overflow-hidden overflow-y-scroll rounded-b-md shadow-md";
  @Input() code_api: String;
  @Input() data_type: String;
  @Input() can_be_created_by_user: Number;
  @Input() invalidClass:string = "";

  get computedClasses(): string {
    return `${this.inputClass} ${this.invalidClass}`;
  }

  constructor() { }

  ngOnInit() {
    this.changeSearchResults();
  }

  async ionViewWillEnter() {
  }


  changeSearchResults(){
    this.searchResults = this.data.filter((item) => {
      if(this.searchTerm == ''){return item};
      return item.value_text.toLowerCase().includes(this.searchTerm.toLowerCase());
    });
  }

  changeshowOptions(){
    this.showOptions = !this.showOptions;
  }

  reset() {
    this.value = '';
    this.chosenOption = '';
    this.selectInput.emit("");
  }

  handleInput(evt) {
    this.searchTerm = evt.target.value;
    this.showOptions = true;
    this.changeSearchResults();
  }

  handleClick(item) {
    this.selectInput.emit(item)
    this.chosenOption = item.value_text;
    this.showOptions = false;
    this.value = item.value_text;
  }

  handleInputDefault(evt) {
    this.searchTerm = evt.target.value;
    this.showOptions = true;
    this.changeSearchResults();
    this.selectInput.emit(evt.target.value)
    // if(evt.target.value == '') this.selectInput.emit(evt.target.value)

  }

  handleInputDefaultCreate(evt) {
    this.searchTerm = evt.target.value;
    this.showOptions = true;
    this.changeSearchResults();
    this.selectInput.emit(evt.target.value)
  }

  handleInput2(evt) {
    this.selectInput.emit(evt.target.value)
  }

  clickedOutside() {
    this.showOptions = false;
  }

  filterKeyNumber(e){
    const key = e.charCode;
      if (key < 48 || key > 57){
        return e.preventDefault();
      }
  }

}
