import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-home-detail-card',
  templateUrl: './home-detail-card.component.html',
  styleUrls: ['./home-detail-card.component.scss'],
})
export class HomeDetailCardComponent  implements OnInit {
   @Input() customClass:string = "";
   @Output() cardClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    // console.log(this.customClass)
  }

  onCardClick() {
    this.cardClick.emit();
  }

}
