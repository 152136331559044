import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { FuncionesServiceProvider, MenuServiceProvider } from "src/app/service/index.providers";
import { UsuarioServiceProvider } from "src/app/service/usuario-service/usuario-service";
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";
import { environment } from "../../../environments/environment";
import { Platform, PopoverController } from "@ionic/angular";
import { ConexionServiceProvider, ConnectionStatus } from '../../service/conexion-service/conexion-service';
import { PopoverComponent } from "../popover/popover.component";

@Component({
	selector: "app-my-header",
	templateUrl: "./my-header.component.html",
	styleUrls: ["./my-header.component.scss"],
})
export class MyHeaderComponent implements OnInit, OnDestroy {
	@Input() btn_back: number;
	logo_workspace = environment.LOGO_APP;
	activeLink: String;
	new_notification: boolean = false;
	usuario;
	SideBarActive: boolean = false;
	array_menu3$: Observable<any[]>;
	array_menu3: any;
	iniciales: String;
	data_empresa;
	notification: boolean = false;
  showNotificationFrom: string = "";
	showMenuLateral:boolean = true;
	networkIsActive: boolean = true;
	isCordovaApp: boolean = false;
	private networkObservable: Subscription;
	hasOfflineModeAvailable:boolean = false;
  private userDataSub: Subscription;

	constructor(
		private router: Router,
		private mp: MenuServiceProvider,
		private ns: NotificacionService,
		private up: UsuarioServiceProvider,
		private fp: FuncionesServiceProvider,
		private platform: Platform,
		private networkService: ConexionServiceProvider,
		public popoverController: PopoverController,
	) {
		if (this.platform.is("cordova")) {
			const configData = JSON.parse(localStorage.getItem("config_data"));
			if(configData && configData.is_offline){
				this.hasOfflineModeAvailable = true;
			};
			this.isCordovaApp = true;
			this.checkNewtworkStatus();

		}

		// this.array_menu3$ = this.fp.getPaginas_main();
		// this.array_menu3$.subscribe((paginas) => {
    //   this.array_menu3 = paginas;
    //   console.log("array_menu3-1: ",this.array_menu3)
    //   if ( !(this.platform.is("cordova") || this.platform.is("mobileweb")) ) {
    //     if(this.array_menu3) {
    //       this.array_menu3.forEach((element,index) => {
    //         if(element.hideInWeb)
    //           this.array_menu3.splice(index, 1);
    //       });
    //     }
    //   }
    //   console.log("array_menu3-2: ",this.array_menu3)
    // });


    this.fp.headerPages$.subscribe((paginas) => {
      this.array_menu3 = paginas;
      // console.log("headerPages / array_menu3-1: ",this.array_menu3)
      if ( !(this.platform.is("cordova") || this.platform.is("mobileweb")) ) {
        if(this.array_menu3) {
          this.array_menu3.forEach((element,index) => {
            if(element.hideInWeb)
              this.array_menu3.splice(index, 1);
          });
        }
      }
      // console.log("headerPages/ array_menu3-2: ",this.array_menu3)
    });

    // if (!this.array_menu3) {
    //   this.array_menu3 = this.fp._pages_main;
    //   console.log("array_menu3-333: ",this.array_menu3)
    // }

  // if ( !(this.platform.is("cordova") || this.platform.is("mobileweb")) ) {
  //   if(this.array_menu3) {
  //     this.array_menu3.forEach((element,index) => {
  //       if(element.hideInWeb)
  //         this.array_menu3.splice(index, 1);
  //     });
  //   }
  // }
  // console.log("array_menu3-2: ",this.array_menu3)



		this.up.configBrand.subscribe((res) => {
			this.data_empresa = res;
		});
	}

	Routes = [
		{ name: "Anuncios", path: "anuncios" },
		{ name: "Cursos", path: "cursos" },
		{ name: "Reuniones", path: "lista-reuniones" },
		{ name: "Progreso", path: "progreso" },
	];
	// ! Al asignar la variable, no es asyncrona, y parece que no funciona por el router de navigacion
	to(routeLink: string) {
		this.mp.select_page.emit(routeLink);
		this.mp.select_group.emit(null);
		this.router.navigate([routeLink]);
	}

	ionViewDidEnter() {
		// if (localStorage.getItem("user_data")) this.usuario = JSON.parse(localStorage.getItem("user_data"));
	}
	ngOnInit() {
		this.mp._showMenu.subscribe((state) => (this.SideBarActive = state));
		let select_page = localStorage.getItem("select_page");
		if (select_page && select_page == "anuncios") {
			this.activeLink = select_page;
			this.mp.select_group.emit(null);
		};
		// this.activeLink = localStorage.getItem('select_page');
		this.mp.selectedPageObservable$.subscribe((page) => {
			this.activeLink = page;
		});

		this.ns.new_notification.subscribe((res) => (this.new_notification = res));

    this.userDataSub = this.up.data_usuario$.subscribe((data) => {
      this.usuario = data;
      if (this.usuario) {
        this.iniciales = this.usuario.nombre.charAt(0).toUpperCase() + this.usuario.apellido.charAt(0).toUpperCase();
      };
    });
		this.ns.currentHideNotification.subscribe((res) =>
      {
        this.notification = res.isActive;
        this.showNotificationFrom = res.from;
      }
    );
		// if(this.up.getPlatformType === 'induction')	this.showMenuLateral = false
	}

	checkNewtworkStatus() {
		console.log("header menu.ts check network status!!!!");
		this.networkObservable = this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				console.log("online header.ts");
				this.networkIsActive = true;
				//   this.offlineManager.checkForEvents().subscribe();
			} else {
				console.log("offline header.ts");
				this.networkIsActive = false;
			}
		});
	}

	async presentSelect(ev: Event) {
		const popover = await this.popoverController.create({
			component: PopoverComponent,
			cssClass: "custom-popover",
			event: ev,
			mode: "ios",
			componentProps: { options: ["Ir a perfil", "Modo sin conexión"] }
		});

		await popover.present();
		const { data } = await popover.onDidDismiss();
		if (data && data.selectedOption) {
			console.log("Opción seleccionada:", data.selectedOption);
			if(data.selectedOption === "Ir a perfil") {
				this.to('usuario/mi-perfil');
			}
			if(data.selectedOption === "Modo sin conexión") {
				if(await this.networkService.getDeviceNetworkType() !== "none") {
					this.networkIsActive = 	!this.networkIsActive;
					this.networkService.updateNetworkStatus(this.networkIsActive ? ConnectionStatus.Online : ConnectionStatus.Offline)
				}
			}
		}
	}


	triggerMenu() {
		this.SideBarActive = !this.SideBarActive;
		this.mp._showMenu.emit(this.SideBarActive);
	}

	showNotification() {
		this.notification = !this.notification;
		this.ns.changeShowNotification( {isActive: this.notification, from: 'header'} );
	}

	toPageInit(){
		let data_usuario = JSON.parse(localStorage.getItem("user_data"));
		if(data_usuario.user_induccion_aprendizaje) {
			this.to("anuncios");
		}
		else if(data_usuario.onboarding) {
			if(data_usuario.supervisor_induccion) {
				this.to("procesos");
			}
			else {
				this.to("home");
			}
		}
		else {
			this.to("anuncios");
		}
	}

	toOfflineCourses() {
		this.router.navigate(['cursos']);
	}

	ngOnDestroy() {
		if(this.networkObservable) {
			this.networkObservable.unsubscribe();
		}
    if(this.userDataSub) this.userDataSub.unsubscribe();
	}

}
