import { Component, Input, OnInit } from "@angular/core";
import { ModalController, Platform } from '@ionic/angular';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-platform-upgrade-available",
  templateUrl: "./platform-upgrade-available.component.html",
  styleUrls: ["./platform-upgrade-available.component.scss"],
})
export class PlatformUpgradeAvailableComponent implements OnInit {
  @Input() isForcedToUpdate: boolean;
  @Input() dbVersion: string = "";
  userVersion:string = "";
  constructor(
    private modalCtrl: ModalController,
    private platform: Platform) {
  }

  ngOnInit() {
    if (this.platform.is("ios")) {
      this.userVersion = environment.APP_VERSION_IOS;
    }
    if (this.platform.is("android")) {
      this.userVersion = environment.APP_VERSION_ANDROID;
    }
  }

  updateAndroid() {
    this.modalCtrl.dismiss({
      confirm: true
    });
  }
  cancelar() {
    this.modalCtrl.dismiss()
  }
}
