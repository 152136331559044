import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { CheckLoginGuard } from './guards/login/check-login.guard';
import { CheckPagesGuard } from './guards/page/check-pages.guard';
import { OfflineMsgGuard } from './guards/offline/offline-msg.guard';
import { AppPreloadService } from "./service/app-preload.service"

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/anuncios/anuncios.module').then(
        (m) => m.AnunciosPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'anuncios',
    },
  },
  {
    path: 'splash-screen',
    loadChildren: () => import('./pages/splash-screen/splash-screen.module').then( m => m.SplashScreenPageModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: mapToCanActivate([CheckLoginGuard]),
    data: {
      title: 'login',
      preload: true,
    },
  },
  {
    path: 'auth/login/external',
    data: {
      title: 'login-external'
    },
    loadChildren: () => import('./pages/login-external/login-external.module').then( m => m.LoginExternalPageModule)
  },
  {
    path: 'anuncios',
    loadChildren: () =>
      import('./pages/anuncios/anuncios.module').then(
        (m) => m.AnunciosPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'anuncios',
      preload: true,
    },
  },
  {
    path: 'recuperar-contrasenia',
    data: {
      title: 'recuperar-contrasenia',
    },
    loadChildren: () =>
      import('./pages/recuperar-contra/recuperar-contra.module').then(
        (m) => m.RecuperarContraPageModule
      ),
  },
  {
    path: 'ayuda-coordinador',
    data: {
      title: 'ayuda-coordinador',
    },
    loadChildren: () =>
      import('./pages/ayuda-coordinador/ayuda-coordinador.module').then(
        (m) => m.AyudaCoordinadorPageModule
      ),
  },
  {
    path: 'solicitud-enviada',
    data: {
      title: 'solicitud-enviada',
    },
    loadChildren: () =>
      import('./pages/solicitud-enviada/solicitud-enviada.module').then(
        (m) => m.SolicitudEnviadaPageModule
      ),
  },
  {
    path: 'validacion-quiz',
    data: {
      title: 'validacion-quiz',
    },
    loadChildren: () =>
      import('./pages/validacion-quiz/validacion-quiz.module').then(
        (m) => m.ValidacionQuizPageModule
      ),
  },
  {
    path: 'error-validacion',
    data: {
      title: 'error-validacion',
    },
    loadChildren: () =>
      import(
        './pages/validacion-quiz/error-validacion/error-validacion/error-validacion.module'
      ).then((m) => m.ErrorValidacionPageModule),
  },
  {
    path: 'cambiar-contrasenia/:token',
    data: {
      title: 'cambiar-contrasenia',
    },
    loadChildren: () =>
      import('./pages/cambiar-contra/cambiar-contra.module').then(
        (m) => m.CambiarContraPageModule
      ),
  },
  {
    path: 'validacion-usuario',
    data: {
      title: 'validacion-usuario',
    },
    loadChildren: () =>
      import('./pages/validacion-usuario/validacion-usuario.module').then(
        (m) => m.ValidacionUsuarioPageModule
      ),
  },
  {
    path: 'login-loading',
    loadChildren: () =>
      import('./pages/login-loading/login-loading.module').then(
        (m) => m.LoginLoadingPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'login-loading',
      preload: true,
    },
  },
  {
    path: 'cursos',
    loadChildren: () =>
      import('./pages/cursos/cursos.module').then((m) => m.CursosPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'cursos',
      preload: true,
    },
  },
  {
    path: 'offline-message',
    data: {
      title: 'offline-status',
    },
    canActivate: mapToCanActivate([CheckPagesGuard, OfflineMsgGuard]),
    // canActivate: [CheckPagesGuard, OfflineMsgGuard],
    loadChildren: () =>
      import('./pages/offline-message/offline-message.module').then(
        (m) => m.OfflineMessagePageModule
      ),
  },
  {
		path: "reuniones-pasantia",
		loadChildren: () =>
			import("./pages/induccion/reuniones-pasantia/reuniones-pasantia.module").then(
				(m) => m.ReunionesPasantiaPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'reuniones-pasantia'
        }
	},
	{
		path: "reunion-pasantia/:proceso",
		loadChildren: () =>
			import("./pages/induccion/reunion-pasantia/reunion-pasantia.module").then(
				(m) => m.ReunionProcesoPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'reunion-pasantia'
        }
	},
	{
		path: "usuario-pasantia/:colaborador/pasantia/:pasantia",
		loadChildren: () =>
			import("./pages/induccion/usuario-pasantia/usuario-pasantia.module").then(
				(m) => m.UsuarioPasantiaPageModule
			),
		canActivate: [CheckPagesGuard],
		data: {
            title: 'usuario-pasantia'
        }
	},
  {
    path: 'progreso',
    loadChildren: () =>
      import('./pages/progreso/progreso.module').then(
        (m) => m.ProgresoPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'progreso',
      preload: true,
    },
  },
  {
    path: 'progreso-detalle',
    loadChildren: () =>
      import('./pages/progreso/progreso-detalle/progreso-detalle.module').then(
        (m) => m.ProgresoDetallePageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'progreso-detalle',
    },
  },
  {
    path: 'escuela/:escuela/cursos/:curso/tema/:tema',
    loadChildren: () =>
      import('./pages/tema/tema.module').then((m) => m.TemaPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'escuela-tema',
      preload: true,
    },
  },
  {
    path: 'escuela/:escuela/cursos/:curso/tema',
    loadChildren: () =>
      import('./pages/tema/tema.module').then((m) => m.TemaPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'escuela-tema',
      preload: true,
    },
  },
  {
    path: 'evaluacion-curso/:tema_id',
    loadChildren: () =>
      import('./pages/evaluacion/evaluacion.module').then(
        (m) => m.EvaluacionPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'evaluacion',
    },
  },
  {
    path: 'evaluacion-resultado',
    loadChildren: () =>
      import('./pages/evaluacion-resultado/evaluacion-resultado.module').then(
        (m) => m.EvaluacionResultadoPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'evaluacion-resultado',
      preload: true,
    },
  },
  {
    path: 'encuesta-preguntas',
    loadChildren: () =>
      import('./pages/encuesta-preguntas/encuesta-preguntas.module').then(
        (m) => m.EncuestaPreguntasPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'encuesta-preguntas',
    },
  },
  {
    path: 'certificados',
    loadChildren: () =>
      import('./pages/diploma/diploma.module').then((m) => m.DiplomaPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'certificados',
    },
  },
  {
    path: 'encuesta-resultado/:curso_id',
    loadChildren: () =>
      import('./pages/encuesta-resultado/encuesta-resultado.module').then(
        (m) => m.EncuestaResultadoPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'encuesta-resultado',
    },
  },
  {
    path: 'encuesta-libre',
    loadChildren: () =>
      import('./pages/encuesta-libre/encuesta-libre.module').then(
        (m) => m.EncuestaLibrePageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'encuesta-libre',
    },
  },
  {
    path: 'lista-reuniones',
    loadChildren: () =>
      import('./pages/lista-reuniones/lista-reuniones.module').then(
        (m) => m.ListaReunionesPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'lista-reuniones',
    },
  },
  {
    path: 'conferencias/:tipo/:id',
    loadChildren: () => import('./pages/conferencias/conferencias.module').then((m) => m.ConferenciasPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'conferencias',
    },
  },
  {
    path: 'conferencias/:tipo',
    loadChildren: () =>
      import('./pages/conferencias/conferencias.module').then(
        (m) => m.ConferenciasPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'conferencias',
    },
  },
  {
    path: 'conferencias-detalles',
    loadChildren: () =>
      import('./pages/conferencias-detalles/conferencias-detalles.module').then(
        (m) => m.ConferenciasDetallesPageModule
      ),
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'conferencias-detalles',
    },
  },
  {
		path: "reportes",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'reportes'
        },
		loadChildren: () =>
			import("./pages/reportes/reportes.module").then((m) => m.ReportesPageModule),
	},
  {
    path: 'modulo-reconocimiento',
	  canActivate: mapToCanActivate([CheckPagesGuard]),
	  data: {
		        title: 'reconocimiento'
	        },
    loadChildren: () => import('./pages/modulo-reconocimiento/modulo-reconocimiento.module').then( m => m.ModuloReconocimientoPageModule)
  },
  {
		path: "beneficios",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'beneficios'
        },
		loadChildren: () =>
			import("./pages/beneficio/lista-beneficios/lista-beneficios.module").then(
				(m) => m.ListaBeneficiosPageModule
			),
	},
  {
		path: "beneficio",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'beneficio'
        },
		loadChildren: () =>
			import("./pages/beneficio/beneficio/beneficio.module").then((m) => m.BeneficioPageModule),
	},
  {
		path: "beneficio/speaker",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'speaker'
        },
		loadChildren: () =>
			import("./pages/beneficio/speaker/speaker.module").then((m) => m.SpeakerPageModule),
	},
  {
		path: "introduccion",
		loadChildren: () =>
			import("./pages/induccion/introduccion/introduccion.module").then(
				(m) => m.IntroduccionPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'introduccion'
        }
	},
  {
		path: "home",
		loadChildren: () =>
			import("./pages/induccion/home/home.module").then(
				(m) => m.HomePageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'home'
        }
	},
  {
		path: "induccion/:induccion/tema/:tema",
		loadChildren: () => import("./pages/tema-ind/tema-ind.module").then((m) => m.TemaIndPageModule),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'induccion-tema',
			preload: true,
        }
	},
  {
		path: "certificado",
		loadChildren: () => import("./pages/diploma/ver-diploma/ver-diploma.module").then((m) => m.VerDiplomaPageModule),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'certificado'
        }
	},
  {
		path: "procesos",
		loadChildren: () =>
			import("./pages/induccion/procesos/procesos.module").then(
				(m) => m.ProcesosPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'procesos'
        }
	},
  {
		path: "proceso/:proceso",
		loadChildren: () =>
			import("./pages/induccion/proceso/proceso.module").then(
				(m) => m.ProcesoPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'proceso'
        }
	},
  {
		path: "proceso-detalle/:proceso",
		loadChildren: () =>
			import("./pages/induccion/proceso-detalle/proceso-detalle.module").then(
				(m) => m.ProcesoDetallePageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'proceso-detalle'
        }
	},
  {
		path: "colaborador/:colaborador",
		loadChildren: () =>
			import("./pages/induccion/colaborador/colaborador.module").then(
				(m) => m.ColaboradorPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'colaborador'
        }
	},
  {
		path: "lista-colaboradores",
		loadChildren: () =>
			import("./pages/induccion/lista-colaboradores/lista-colaboradores.module").then(
				(m) => m.ListaColaboradoresPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'lista-colaboradores'
        }
	},
  {
		path: "induccion/encuesta-preguntas",
		loadChildren: () =>
			import("./pages/encuesta-preguntas/encuesta-preguntas.module").then(
				(m) => m.EncuestaPreguntasPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'induccion-encuesta-preguntas'
        }
	},
  // {
	// 	path: 'cursos-presenciales',
	// 	data: {
	// 		title: 'presenciales'
	// 	},
	// 	canActivate: mapToCanActivate([CheckPagesGuard]),
	// 	loadChildren: () => import('./pages/cursos-presenciales/cursos-presenciales.module').then( m => m.CursosPresencialesPageModule)
	// },
  {
		path: "faq",
		loadChildren: () => import("./pages/faq/faq.module").then((m) => m.FaqPageModule),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'faq'
        }
	},
  {
		path: 'register',
		data: {
			title: 'register'
		},
		loadChildren: () => import('./pages/register-user/register-user.module').then( m => m.RegisterUserPageModule)
	},
  {
		path: "alumno",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'alumno'
        },
		loadChildren: () =>
			import("./pages/checklist/alumno/alumno.module").then((m) => m.AlumnoPageModule),
	},
  {
		path: "checklist",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'checklist'
        },
		loadChildren: () =>
			import("./pages/checklist/checklist/checklist.module").then((m) => m.ChecklistPageModule),
	},
	{
		path: "lista-entidades/:checklist",
		canActivate: [CheckPagesGuard],
		data: {
            title: 'lista-entidades-checklist'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-entidades-checklist/lista-entidades-checklists.module").then(
				(m) => m.ListaEntidadesChecklistsPageModule
			),
	},
  {
		path: "checklist-actividades/:checklist",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'checklist-actividades'
        },
		loadChildren: () =>
			import("./pages/checklist/checklist-actividades/checklist-actividades.module").then(
				(m) => m.ChecklistActividadesPageModule
			),
	},
  {
		path: "lista-alumnos",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'lista-alumnos'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-alumnos/lista-alumnos.module").then(
				(m) => m.ListaAlumnosPageModule
			),
	},
  {
		path: "lista-alumnos-checklist",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'lista-alumnos-checklist'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-alumnos-checklist/lista-alumnos-checklist.module").then(
				(m) => m.ListaAlumnosChecklistPageModule
			),
	},
  {
		path: "lista-asignados/:checklist",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'lista-asignados-checklist'
        },
		loadChildren: () =>
			import("./pages/checklist/lista-asignados-checklist/lista-asignados-checklists.module").then(
				(m) => m.ListaAsignadosChecklistsPageModule
			),
	},
  {
		path: "lista-checklists",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
        title: 'lista-checklists'
    },
		loadChildren: () =>
			import("./pages/checklist/lista-checklists/lista-checklists.module").then(
				(m) => m.ListaChecklistsPageModule
			),
	},
  {
    path: 'usuario/mi-perfil',
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'perfil'
    },
    loadChildren: () => import('./pages/usuario-perfil/usuario-perfil.module').then( m => m.UsuarioPerfilPageModule)
  },
  {
    path: 'usuario/cambiar-contrasena',
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'cambiar-contrasena-perfil'
    },
    loadChildren: () => import('./pages/actualizar-contrasenia/actualizar-contrasenia.module').then( m => m.ActualizarContraseniaPageModule)
  },
  {
		path: "ayuda",
		loadChildren: () => import("./pages/ayuda/ayuda.module").then((m) => m.AyudaPageModule),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
        title: 'ayuda'
    }
	},
  {
    path: 'tareas',
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'tareas'
    },
    loadChildren: () => import('./pages/tareas/tareas.module').then( m => m.TareasPageModule)
  },
  {
		path: "adjuntar-archivo",
		loadChildren: () =>
			import("./pages/adjuntar-archivo/adjuntar-archivo.module").then(
				(m) => m.AdjuntarArchivoPageModule
			),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'adjuntar-archivo'
        }
	},
  {
		path: "glosario",
		loadChildren: () =>
			import("./pages/glosario/glosario.module").then((m) => m.GlosarioPageModule),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'glosario'
    }
	},
  {
		path: "ranking",
		loadChildren: () => import("./pages/ranking/ranking.module").then((m) => m.RankingPageModule),
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'ranking'
        }
	},
  {
    path: 'historial-de-cursos',
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'historial'
    },
    loadChildren: () => import('./pages/historico/historico.module').then( m => m.HistoricoPageModule)
  },
  {
		path: "vademecum",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'vademecum'
        },
		loadChildren: () =>
			import("./pages/vademecun/vademecun.module").then((m) => m.VademecunPageModule),
	},
	{
		path: "documentos-y-protocolos",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'documentos-y-protocolos'
        },
		loadChildren: () =>
			import("./pages/vademecun/vademecun.module").then((m) => m.VademecunPageModule),

	},
  {
		path: "videoteca",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'videoteca'
        },
		loadChildren: () =>
			import("./pages/videoteca/videoteca.module").then((m) => m.VideotecaPageModule),
	},
  {
		path: "multimedia/:id",
		canActivate: mapToCanActivate([CheckPagesGuard]),
		data: {
            title: 'multimedia'
        },
		loadChildren: () =>
			import("./pages/videoteca/multimedia/multimedia.module").then((m) => m.MultimediaPageModule),
	},
  {
    path: 'validador-evaluacion',
    data: {
      title: 'validador-evaluacion'
    },
    loadChildren: () => import('./pages/validador-evaluacion/validador-evaluacion.module').then( m => m.ValidadorEvaluacionPageModule)
  },
  {
    path: 'scorm-player',
    data: {
      title: 'scorm-mobile-player'
    },
    loadChildren: () => import('./pages/scorm-player/scorm-player.module').then( m => m.ScormPlayerPageModule)
  },
  {
    path: 'resultados-resumen',
    canActivate: mapToCanActivate([CheckPagesGuard]),
    data: {
      title: 'resultados-checklist'
    },
    loadChildren: () => import('./pages/checklist/resultados-resumen/resultados-resumen.module').then( m => m.ResultadosResumenPageModule)
  },
  {
    path: 'firma-documento',
    loadChildren: () => import('./pages/ver-firmar-doc/ver-firmar-doc.module').then( m => m.VerFirmarDocPageModule)
  },

  {
    path: 'encuestas',
    data: {
      title: 'encuestas-externos'
    },
    loadChildren: () => import('./pages/encuestas-externos/encuestas-externos.module').then( m => m.EncuestasExternosPageModule)
  },
  {
    path: 'historial-monedas',
    data: {
      title: 'historial-monedas',
    },
    canActivate: mapToCanActivate([CheckPagesGuard]),
    loadChildren: () => import('./pages/historial-monedas/historial-monedas.module').then( m => m.HistorialMonedasPageModule)
  },
  {
    path: 'tema-beneficio/escuela/:escuela/cursos/:curso/tema/:tema',
    data: {
      title: 'curso-beneficio',
    },
    loadChildren: () => import('./pages/tema-beneficio/tema-beneficio.module').then( m => m.TemaBeneficioPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
  },
  {
    path: 'tema-beneficio/escuela/:escuela/cursos/:curso/tema',
    data: {
      title: 'curso-beneficio',
    },
    loadChildren: () => import('./pages/tema-beneficio/tema-beneficio.module').then( m => m.TemaBeneficioPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
  },
  {
    path: 'login-workspace',
    data: {
      title: 'login-workspace',
    },
    loadChildren: () => import('./pages/login-multi-modulos/login-multi-modulos.module').then( m => m.LoginMultiModulosPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
  },
  {
    path: 'process-not-found',
    data: {
      title: 'Induccion',
    },
    loadChildren: () => import('./pages/induccion-sin-proceso/induccion-sin-proceso.module').then( m => m.InduccionSinProcesoPageModule),
    canActivate: mapToCanActivate([CheckPagesGuard]),
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadService })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
