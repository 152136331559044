<div class="flex flex-col gap-3 q-valoration">
  <ng-content select="[questionLabel]"></ng-content>

  <div class="">
    <ng-container *ngIf="valorationType === 'emoji'">
      <ion-radio-group
        (ionChange)="handleChange($event)"
        [(ngModel)]="question.answer"
        class="question-radio flex flex-col"
      >
        <div class="flex flex-col items-center relative" *ngFor="let option of question.opciones">
          <ion-radio
            class="opacity-0"
            [value]="option.value"
            color="primary"
            mode="md"
            labelPlacement="stacked" alignment="center"
          >{{option.text}}
          </ion-radio>

          <div class=" absolute top-0 left-0 right-0 bottom-0  flex flex-col items-center gap-2 lg:gap-3 hover:bg-gray-100">
            <ion-icon *ngIf="valorationSelected != option.value" name="ellipse-outline" class="text-2xl lg:text-3xl leading-6 lg:leading-[30px] text-gray-500 min-w-[24px]  min-h-[24px] lg:min-w-[30px]  lg:min-h-[30px]"></ion-icon>
            <span *ngIf="valorationSelected == option.value"  class="text-2xl lg:text-3xl leading-6 lg:leading-[30px]" >{{option.emoji}} </span>
            <span class="text-xs lg:text-sm  leading-[14px] lg:leading-4 text-center"><span [outerHTML]="option.text"></span></span>
          </div>
        </div>
      </ion-radio-group>
    </ng-container>

    <ng-container *ngIf="valorationType === 'stars'">
      <ion-radio-group
      (ionChange)="handleChange($event)"
      [(ngModel)]="question.answer"
      class="question-radio flex flex-col"
    >
      <div class="flex flex-col items-center relative" *ngFor="let option of question.opciones">
        <ion-radio
          class="opacity-0"
          [value]="option.value"
          color="primary"
          mode="md"
          labelPlacement="stacked" alignment="center"
        >{{option.text}}
        </ion-radio>

        <div class="absolute top-0 left-0 right-0 bottom-0 w-[60px] flex flex-col items-center gap-2 lg:gap-3 hover:bg-gray-100">
          <img *ngIf="valorationSelected >= option.value; else outlineStar" src="assets/icon/star-filled.svg" class="w-6 h-6  lg:w-[30px] lg:h-[30px]">
          <span class="text-xs lg:text-sm leading-[14px] lg:leading-4 text-center"><span [outerHTML]="option.text"></span></span>
        </div>
      </div>

      <ng-template #outlineStar>
        <img src="assets/icon/star-outline.svg" class="w-6 h-6  lg:w-[30px] lg:h-[30px]">
      </ng-template>
    </ion-radio-group>

    </ng-container>
  </div>
</div>
