<div class="hidden lg:flex  lg:h-18 xxxl:h-20 ">
	<div class="w-full h-full flex header-container bg-white">
		<!-- Logo -->
		<div class="ml-4 py-1 w-11/12 max-w-xxs flex items-center">
			<img [src]="usuario.workspace.logo" class="cursor-pointer h-full w-auto object-scale-down"
				(click)="toPageInit()" />
		</div>

		<div class="pt-2 pr-1.5 pb-1 pl-0 h-12 left-0 bottom-0 absolute z-10 bg-white rounded-tr-lg opacity-50 hidden lg:block"
			*ngIf="data_empresa">
			<img [src]="data_empresa.logo_app || '../../../assets/img/logo-cursalab.svg' "
				class="cursor-pointer h-full w-auto" (click)="toPageInit()" />
		</div>

		<div class="flex w-full justify-end items-center pr-8">
			<!-- Routes -->
			<ng-container *ngIf="(networkIsActive || !hasOfflineModeAvailable) else offlineActiveHeader">
				<div *ngFor="let route of array_menu3; let i = index" class="nav-item flex justify-center items-center cursor-pointer h-full"
        (click)="to(route.path)"
				[ngClass]="activeLink === route.path ? 'active' : ''">
					{{ route.name }}
				</div>
			</ng-container>
			<ng-template #offlineActiveHeader>
				<div *ngFor="let route of array_menu3; let i = index" class="nav-item flex justify-center items-center cursor-pointer h-full"
					(click)="route.path === 'cursos' ? toOfflineCourses() : ''"
					[ngClass]="'cursos' === route.path ? 'active' : 'opacity-50'">
					{{ route.name }}
				</div>
			</ng-template>
		</div>
		<div class="px-3 cursor-pointer" (click)="networkIsActive ? showNotification() : ''"
			[ngClass]="{'opacity-50': !networkIsActive}"
			[eventTracker]='{"identifier":"Notificación","category":"button_click","action":"Ver Notificaciones - web"}'>
			<div class="grid content-center lg:h-18 xxxl:h-20">
					<mat-icon class="mdi mdi-bell text-2xl text-primary px-4 py-2.5 rounded-lg hover:bg-gray-200"
						[ngClass]="{'bg-gray-200' : notification && showNotificationFrom === 'header'}"></mat-icon>
			</div>
		</div>
		<ng-container *ngIf="(!isCordovaApp || !hasOfflineModeAvailable) else offlineActiveProfileBtn">
			<div class="px-7 cursor-pointer " (click)="to('usuario/mi-perfil')"
				[eventTracker]='{"identifier":"Ver_perfil","category":"button_click","action":"Ver perfil"}'>
				<div class="grid content-center lg:h-18 xxxl:h-20">
						<div class="user-icon bg-secondary flex justify-center items-center text-white font-black ">
							{{iniciales}}
						</div>
				</div>
			</div>
		</ng-container>
		<ng-template #offlineActiveProfileBtn>
			<div class="px-7 cursor-pointer " (click)="presentSelect($event)"
				[eventTracker]='{"identifier":"Ver_perfil","category":"button_click","action":"Ver perfil"}'>

				<div class="grid content-center lg:h-18 xxxl:h-20">

						<div class="user-icon bg-secondary flex justify-center items-center text-white font-black ">
							{{iniciales}}
						</div>

				</div>
			</div>
		</ng-template>
	</div>
	<div class="grid place-content-center lg:w-20 xxl:w-22 xxxl:w-24  text-white px-8 cursor-pointer bg-primary"
		(click)="triggerMenu()" *ngIf="showMenuLateral">
		<div class="flex">
			<ion-icon [name]="SideBarActive ? 'close' : 'add'" size="large"
				class="noHover sidebar-controler"></ion-icon>
		</div>
	</div>
</div>
