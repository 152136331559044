import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UsuarioServiceProvider } from '../index.providers';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';
import { NativeMarket } from "@capacitor-community/native-market";
import { PlatformUpgradeAvailableComponent } from 'src/app/components/platform-modal/platform-upgrade-available/platform-upgrade-available.component';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class LoginMultiModuloService {
  modulesOptions = [];
  public workspacesList = new BehaviorSubject<any>(null);
	public workspacesList$ = this.workspacesList.asObservable();
  data_empresa;

  setWorkspacesList(data){
    this.modulesOptions = data;
		this.workspacesList.next(this.modulesOptions);
	}

  constructor(
    private router: Router,
    private up: UsuarioServiceProvider,
    private navCtrl: NavController,
    private titleService: Title,
    @Inject(DOCUMENT) private document: any,
    private api: ApiService,
		private alertCtrl: AlertController,
    private platform: Platform,
    private modalController: ModalController,
  ) {

  }

  goTo(route) {
    this.router.navigate([`${route}`]);
  }

  routeUserToPlatform(data_usuario, isRefreshRequired = false) {
    // if(data_usuario.user_induccion_aprendizaje) { //usuario con acceso a aprendizaje e inducción
    //   this.up.setPlatformType = 'capacitacion';
    //   this.navCtrl.navigateRoot("anuncios");
    //   localStorage.setItem("select_page", "anuncios");
    // }
    if(data_usuario.onboarding) { // usuario con acceso a inducción
      this.up.setPlatformType = 'induction';
      if(data_usuario.supervisor_induccion) {
        this.navCtrl.navigateRoot("procesos");
        localStorage.setItem("select_page", "procesos");
      }
      else {
        if(data_usuario.processes?.length) {
          this.navCtrl.navigateRoot("home");
          localStorage.setItem("select_page", "home");
        } else {
          this.navCtrl.navigateRoot("process-not-found");
        }
      };
    }
    else {
      this.up.setPlatformType = 'capacitacion';
      // this.navCtrl.navigateRoot("anuncios");
      // localStorage.setItem("select_page", "anuncios");
      const checkDeepLink = 	localStorage.getItem("pendingDeepLink");
      if(checkDeepLink) {
        this.router.navigateByUrl(checkDeepLink);
        localStorage.removeItem("pendingDeepLink");
      } else {
        this.navCtrl.navigateRoot("anuncios");
        localStorage.setItem("select_page", "anuncios");
        if(isRefreshRequired) {
          setTimeout(() => {
            this.refreshPage();
          }, 40);
        }
      }
    }
  }

  refreshPage() {
    const currentUrl = this.router.url;
    // console.log("refreshPage:  ", currentUrl)
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  // routeUserToPlatform(data_usuario) {
  //   if(data_usuario.user_induccion_aprendizaje) {
  //     this.up.setPlatformType = 'capacitacion';
  //     this.navCtrl.navigateRoot("anuncios");
  //     localStorage.setItem("select_page", "anuncios");
  //   }
  //   else if(data_usuario.onboarding) {
  //     this.up.setPlatformType = 'induction';
  //     if(data_usuario.supervisor_induccion) {
  //       this.navCtrl.navigateRoot("procesos");
  //       localStorage.setItem("select_page", "procesos");
  //     }
  //     else {
  //       this.navCtrl.navigateRoot("home");
  //       localStorage.setItem("select_page", "home");
  //     }
  //   }
  //   else {
  //     this.up.setPlatformType = 'capacitacion';
  //     // this.navCtrl.navigateRoot("anuncios");
  //     // localStorage.setItem("select_page", "anuncios");
  //     const checkDeepLink = 	localStorage.getItem("pendingDeepLink");
  //     if(checkDeepLink) {
  //       this.router.navigateByUrl(checkDeepLink);
  //       localStorage.removeItem("pendingDeepLink");
  //     } else {
  //       this.navCtrl.navigateRoot("anuncios");
  //       localStorage.setItem("select_page", "anuncios");
  //     }
  //   }
  // }

  setCustomBrandConfig(custom_ambiente, data_empresa, isSingleBrand) {
    localStorage.setItem("loggedCustomConfig", JSON.stringify(custom_ambiente));
    // let formatedData = {
    //   ...data_empresa,
    //   completed_courses_logo : custom_ambiente.completed_courses_logo ? custom_ambiente.completed_courses_logo : data_empresa.completed_courses_logo,
    //   enrolled_courses_logo : custom_ambiente.enrolled_courses_logo ? custom_ambiente.enrolled_courses_logo : data_empresa.enrolled_courses_logo,
    //   diplomas_logo : custom_ambiente.diplomas_logo ? custom_ambiente.diplomas_logo : data_empresa.diplomas_logo,
    //   color_primario_app: custom_ambiente.color_primario_app ? custom_ambiente.color_primario_app : data_empresa.color_primario_app,
    //   color_secundario_app: custom_ambiente.color_secundario_app ? custom_ambiente.color_secundario_app : data_empresa.color_secundario_app,
    //   color_terciario_app: custom_ambiente.color_terciario_app ? custom_ambiente.color_terciario_app : data_empresa.color_terciario_app,
    // }
    const formatedData = this.generateAppThemeConfigObj(custom_ambiente, data_empresa);

    this.setBrandConfigMultimarca(formatedData , isSingleBrand);
  }

  generateAppThemeConfigObj(custom_ambiente, data_empresa) {
    let themeObject = {
      ...data_empresa,
      completed_courses_logo : custom_ambiente.completed_courses_logo ? custom_ambiente.completed_courses_logo : data_empresa.completed_courses_logo,
      enrolled_courses_logo : custom_ambiente.enrolled_courses_logo ? custom_ambiente.enrolled_courses_logo : data_empresa.enrolled_courses_logo,
      diplomas_logo : custom_ambiente.diplomas_logo ? custom_ambiente.diplomas_logo : data_empresa.diplomas_logo,
      color_primario_app: custom_ambiente.color_primario_app ? custom_ambiente.color_primario_app : data_empresa.color_primario_app,
      color_secundario_app: custom_ambiente.color_secundario_app ? custom_ambiente.color_secundario_app : data_empresa.color_secundario_app,
      color_terciario_app: custom_ambiente.color_terciario_app ? custom_ambiente.color_terciario_app : data_empresa.color_terciario_app,
    };
    return themeObject;
  }

  setBrandConfigMultimarca(configObj, marcaPropia = false) {
		let config = configObj;
		// console.log("verificando: ",configObj);

		if(configObj && !configObj.color_primario_app) {
			//en caso lleguen los colores en null
			config.color_primario_app = "#5458e9";
			config.color_secundario_app = "#58c1e5";
		}

		this.up.setConfig(config);
		this.up.configBrand.subscribe((res) => {
			if(res){
				// console.log("config actualizada: ", res);

				this.data_empresa = res;
				if(!marcaPropia) {
					localStorage.setItem("config_ambiente", JSON.stringify(this.data_empresa));
				}
				let title = this.getTitle(this.router.routerState, this.router.routerState.root);

				title.length
				? this.titleService.setTitle(this.data_empresa.titulo ? this.data_empresa.titulo : "Cursalab" + " | " + title[0])
				: this.titleService.setTitle(this.data_empresa.titulo ? this.data_empresa.titulo : "Cursalab");
				if(this.data_empresa && this.data_empresa.icono) {
					const favicon = document.getElementById("favicon") as HTMLLinkElement;
					favicon.href = this.data_empresa.icono;
				};

				let result_pri;
				let result_sec;
				let rgb;

				try {
					result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
						this.data_empresa.color_primario_app
					);
					result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
						this.data_empresa.color_secundario_app
					);

					//red,green,blue(primary & secondary)
					rgb = {
						rp: parseInt(result_pri[1], 16),
						gp: parseInt(result_pri[2], 16),
						bp: parseInt(result_pri[3], 16),
						rs: parseInt(result_sec[1], 16),
						gs: parseInt(result_sec[2], 16),
						bs: parseInt(result_sec[3], 16),
					};

				} catch (error) {
					console.log(error);
					result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
						"#5458e9"
					);
					result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
						"#58c1e5"
					);

					//red,green,blue(primary & secondary)
					rgb = {
						rp: parseInt(result_pri[1], 16),
						gp: parseInt(result_pri[2], 16),
						bp: parseInt(result_pri[3], 16),
						rs: parseInt(result_sec[1], 16),
						gs: parseInt(result_sec[2], 16),
						bs: parseInt(result_sec[3], 16),
					};
				}
				let rootelement = this.document.documentElement;
				// colores de config
				rootelement.style.setProperty("--primary", this.data_empresa.color_primario_app);
				rootelement.style.setProperty(
					"--ion-color-primary",
					this.data_empresa.color_primario_app
				);
				//color secundario
				rootelement.style.setProperty("--secondary", this.data_empresa.color_secundario_app);
				rootelement.style.setProperty(
					"--ion-color-secondary",
					this.data_empresa.color_secundario_app
				);
				rootelement.style.setProperty(
					"--tertiary",
					this.data_empresa.color_terciario_app
						? this.data_empresa.color_terciario_app
						: this.data_empresa.color_secundario_app
				);
				rootelement.style.setProperty(
					"--ion-color-tertiary",
					this.data_empresa.color_terciario_app
						? this.data_empresa.color_terciario_app
						: this.data_empresa.color_secundario_app
				);
				if (this.data_empresa.template !== "template-inretail") {
					rootelement.style.setProperty(
						"--quaternary",
						this.data_empresa.color_terciario_app ? this.data_empresa.color_terciario_app : this.data_empresa.color_secundario_app
					);
				}

				// variantes
				rootelement.style.setProperty(
					"--primary-focused",
					`rgb(${5.4*rgb.rp/10}, ${6.4*rgb.gp/10}, ${8.8*rgb.bp/10})`
					//`rgb(${6*rgb.rp/10}, ${6*rgb.gp/10}, ${6*rgb.bp/10})`   original
				);
				rootelement.style.setProperty(
					"--primary-light",
					`rgb(${18*rgb.rp/10}, ${17.5*rgb.gp/10}, ${12.2*rgb.bp/10})`
				);
        rootelement.style.setProperty(
          "--primary-semi-light",
            `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.5)`
        );
				rootelement.style.setProperty(
					"--primary-light-variant",
					`rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.3)`
				);
        rootelement.style.setProperty(
          '--primary-medium-light-variant',
          `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.2)`
        );
				rootelement.style.setProperty(
					"--primary-ultra-light-variant",
					`rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.12)`
				);
				//color secundario
				rootelement.style.setProperty(
					"--secondary-focused",
					`rgb(${8.6*rgb.rs/10}, ${9.6*rgb.gs/10}, ${9.4*rgb.bs/10})`
				);

				rootelement.style.setProperty(
					"--secondary-blur",
					`rgba(${0*rgb.rs/10}, ${9*rgb.gs/10}, ${11.5*rgb.bs/10}, 0.65)`
				);
        rootelement.style.setProperty(
          '--secondary-light-variant',
          `rgb(${rgb.rs}, ${rgb.gs}, ${rgb.bs},0.4)`
        );
			}
		});

	}

  getTitle(state, parent) {
		var data = [];

		if (parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(parent.snapshot.data.title);
		}

		if (state && parent) {
			data.push(...this.getTitle(state, state.firstChild(parent)));
		}
		return data;
	}

  setUserProviderConfig(res) {
    this.up.setIdUsuario = res.usuario.id;
    this.up.setConfigData = JSON.stringify(res.config_data);
    this.up.setBucketBaseUrl = res.bucket_base_url;
    this.up.token = res.access_token;
    localStorage.setItem("user_data", JSON.stringify(res.usuario));
    this.up.data_usuario.next(res.usuario);
    this.up.save_storage();
  }

  async logoutPreviousSession() {
    try {
      await this.api.logout_token();
      this.up.clearStorage(true, false);
    } catch (error) {
			console.log(error)
    };
  }

  async confirmLogout() {
		let alert = await this.alertCtrl.create({
			header: "Cerrar sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.logout();
					},
				},
			],
		});
		await alert.present();
	}

	async logout() {
    try {
      await this.api.logout_token();
      this.up.clearStorage();
			this.router.navigate(["login"]);
    } catch (e) {
      console.log(e);
    };
	}

  // multimarca
  saveMultibrandApiUrls(resMaster) {
    const newApiUrl = {
      serviceBase: resMaster.url_workspace.api_url,
      serviceBaseGestor: resMaster.url_workspace.gestor_url,
      serviceBaseRest: resMaster.url_workspace.api_url + "rest/",
      serviceBaseAuth: resMaster.url_workspace.api_url + "auth/",
      urlBase: resMaster.url_workspace.app_url,
      urlReporte: resMaster.url_workspace.reportes_url,
      urlAndroid: resMaster.url_workspace.url_android,
      urliOS: resMaster.url_workspace.url_ios,
    };
    localStorage.setItem("api_urls", JSON.stringify(newApiUrl));
    this.api.setApiUrls = newApiUrl;
  }
  /*
  upgradeMultibrandApp(resMaster) {
    if (Capacitor.isNativePlatform()) {
      if (this.platform.is("android"))
        this.multibrandAppVersionControl(
          environment.APP_VERSION_ANDROID,
          resMaster.url_workspace.android_version,
          resMaster.url_workspace.update_modal,
          resMaster.url_workspace.force_update
        );
      if (this.platform.is("ios"))
        this.multibrandAppVersionControl(
          environment.APP_VERSION_IOS,
          resMaster.url_workspace.ios_version,
          resMaster.url_workspace.update_modal,
          resMaster.url_workspace.force_update
        );
    }
  }

  multibrandAppVersionControl(userAppVersion, dataBaseVersion, showUpdateModal, forceAppUpdate) {
		//solo android, app iOS por publicar
		if (this.platform.is("ios")) return;

		const version_store = dataBaseVersion;
		const version_usu = userAppVersion;
		let ver_number_base_datos = parseInt(version_store.toString().split(".").join(""));
		let ver_number_usu = parseInt(version_usu.toString().split(".").join(""));
		console.log("v_BD", ver_number_base_datos);
		console.log("v_usu", ver_number_usu);
		if (ver_number_base_datos > ver_number_usu && showUpdateModal) {
			this.modalPlatformsUpgradeAvailable(forceAppUpdate, ver_number_base_datos);
		}
	}
    */

  async modalPlatformsUpgradeAvailable(isForcedToUpdate: boolean, dbVersion) {
		const modalUpgradeAvailable = await this.modalController.create({
			component: PlatformUpgradeAvailableComponent,
			backdropDismiss: false,
			cssClass: "platform-modal",
			componentProps: {
         isForcedToUpdate,
         dbVersion,
      },
		});
		await modalUpgradeAvailable.present();
		const { data } = await modalUpgradeAvailable.onWillDismiss();
		if (data?.confirm) {
      console.log("onWillDismiss", data)
      let appId = environment.APP_ID_IOS;
			try {
				if (this.platform.is("android")) {
          appId = environment.APP_ID_PLAYSTORE;
        };
        await NativeMarket.openStoreListing({
          appId: appId,
        });
        if(isForcedToUpdate) {
          setTimeout(() => {
            this.listenToAppStateChanges(dbVersion);
          }, 1000);
        }
			} catch (error) {
				console.log("error al abrir tienda::", error);
			};
		};
	}

  listenToAppStateChanges(dbVersion) {
    App.addListener('appStateChange', (state) => {
      console.log("*** appStateChange::", state)
      let userVersion = environment.APP_VERSION_ANDROID;
      if (this.platform.is("ios")){
        userVersion = environment.APP_VERSION_IOS;
      }
      let numberDbVersion = parseInt(dbVersion.toString().split(".").join(""));
			let numberUserVersion = parseInt(userVersion.toString().split(".").join(""));
      console.log("*** appStateChange number ver::", numberDbVersion, numberUserVersion )

      if (state.isActive ) {
        if(numberDbVersion > numberUserVersion) {
          this.modalPlatformsUpgradeAvailable(true, dbVersion);
          App.removeAllListeners();
        } else {
          App.removeAllListeners();
        }
      }
    });
  }

}
