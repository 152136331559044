import { Component, Inject, OnInit, ApplicationRef, NgZone } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { concat, interval } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import * as moment from 'moment';
import { register } from 'swiper/element/bundle';
register();
import { Storage } from '@ionic/storage-angular';
// import { Storage } from '@ionic/storage';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
// import { Keyboard } from '@ionic-native/keyboard/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { Network } from '@ionic-native/network/ngx';

import { ApiService } from '../app/api.service';
// import { OfflineService } from './service/offline-service/offline.service';
// import { ConexionServiceProvider, ConnectionStatus,} from './service/conexion-service/conexion-service';
import { UsuarioServiceProvider, FuncionesServiceProvider,} from './service/index.providers';
import { MenuServiceProvider } from 'src/app/service/index.providers';
import { NotificacionService } from 'src/app/service/notificaciones-service/notificacion.service';
import { MultipleOptionModalComponent } from './components/multiple-option-modal/multiple-option-modal.component';
import { environment } from '../environments/environment';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { OfflineService } from './service/offline-service/offline.service';
import { ConexionServiceProvider } from './service/conexion-service/conexion-service';
import { StorageService } from './service/storage-service/storage-service.service';
// import { NotificationsFCMService } from './service/fcm-service/notifications-fcm.service';
import { PushNotificationsService } from './service/fcm-service/push-notifications-service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Browser } from '@capacitor/browser';
import { DownloadFileService } from './service/download-files-service/download-file.service';
declare var cordova: any;
declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public rootPage: any;
  keyboardHidden = true;
  SDK_KEY = '8da6o4WWAQrFYQNrkK4d5xXaaEHjY0L1BzW3';
  SDK_SECRET = 'Nf1EVzndUCF0JGIjtpNZVcpsOabERp7vB4BF';
  fullscreen: boolean = false;
  userID: any = null;
  domain: any = null;
  platformIsIOS: boolean | null = null;
  showImpersonationBar: boolean = false;
  impersonationBarTitle: string = "";

  config_empresa: any = null;
  showNotification: boolean = false;
  showNotificationFrom: string = "";
  data_usuario: any;
  initials:any;
  userFullName: string = '';
  checkUpdateInterval;
  webCurrentVersion:any;
  webAvailableVersion:any;
  userIsLogged = false;

  //**config Cursalab del espacio multimarca**
  defaultConfigData = {
    color_primario_app: '#5458e9',
    color_secundario_app: '#58c1e5',
    color_terciario_app: '#372db4',
    titulo_login_app: 'Bienvenido a',
    subtitulo_login_app: 'Cursalab',
    logo_cursalab_position: 'top-left',
    form_login_position: 'center',
    form_login_transparency: 100,
    show_blog_btn: 1,
    female_logo: 'assets/img/avatar_mujer.svg',
    male_logo: 'assets/img/avatar_hombre.svg',
    // fondo_app: 'assets/img/bg-cursalab.png',
    enrolled_courses_logo: 'assets/icon/cursos_inscritos.svg',
    completed_courses_logo: 'assets/icon/cursos_completados.svg',
    diplomas_logo: 'assets/icon/diplomas_obtenidas.svg',
    icono: 'assets/icon/favicon.png',
    id: 1,
    link_pbi: null,
    logo_cursalab: 'assets/img/logo_by_cursalab.svg',
    logo_app: 'assets/img/logo-cursalab.svg',
    titulo: 'Cursalab',
    titulo_login: 'Cursalab',
    url_app_web: null,
    template: 'template-default',
  };

  constructor(
    public platform: Platform,
    private titleService: Title,
    // private splashScreen: SplashScreen,
    private up: UsuarioServiceProvider,
    private fp: FuncionesServiceProvider,
    public router: Router,
    private zone: NgZone,
    // private fcm: FCM,
    private Storage: Storage,
    public navCtrl: NavController,
    // private iab: InAppBrowser,
    // private LocalNotifications: LocalNotifications,
    private mp: MenuServiceProvider,
    // private keyboard: Keyboard,
    private api: ApiService,
    // private statusBar: StatusBar,
    private ns: NotificacionService,
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private alertCtrl: AlertController,
    @Inject(DOCUMENT) private document: any,
    private modalCtrl: ModalController,
    private networkService: ConexionServiceProvider,
    private offlineHttpService: OfflineService,
    private storageService: StorageService,
    // private notificationsFCMService: NotificationsFCMService,
    private pushNotificationsService : PushNotificationsService,
    private downloadFileService: DownloadFileService,
  )
  {
    if(environment.APP_MULTIMARCA && Capacitor.isNativePlatform()) {
      SplashScreen.hide();
      this.router.navigate(['/splash-screen']);
    }

    this.up.configBrand.subscribe((res:any) => {
      this.config_empresa = res;
    });
    console.log('Ver. web: ', environment.WEB_VERSION);
    this.domain = this.document.location.hostname;
    this.addGoogleAnalyticsScript();

    if (
      swUpdate.isEnabled &&
      (this.platform.is('desktop') || !this.platform.is('cordova'))
    ) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if(event.type === "VERSION_READY") {
          console.log('current version::: ', event.currentVersion.hash);
          console.log('available version::: ', event.latestVersion.hash);
          if (
            this.webCurrentVersion &&
            this.webCurrentVersion === event.currentVersion.hash &&
            this.webAvailableVersion &&
            this.webAvailableVersion === event.latestVersion.hash
          ) {
            console.log('already informed');
          } else {
            this.webCurrentVersion = event.currentVersion.hash;
            this.webAvailableVersion = event.latestVersion.hash;
            console.log('reload message');
            this.reloadMessage();
          }

        }
      });

      const appIsStable$ = appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );
      const everyTwoHour$ = interval(7200000);

      const everyTwoHourOnceAppIsStable$ = concat(appIsStable$, everyTwoHour$);

      this.checkUpdateInterval = everyTwoHourOnceAppIsStable$.subscribe(
        (intervalNumber) => {
          if (intervalNumber !== true) {
            try {
              swUpdate.checkForUpdate().then((updateFound) => {
                console.log('checking for updates');
              });
            } catch (err) {
              console.error('Failed to check for updates', err);
            }
          }
        }
      );
    }

    this.mp.currentHideFooter.subscribe((state:any) => (this.fullscreen = state));

    this.ns.currentHideNotification.subscribe(
      (state:any) => {
        this.showNotification = state.isActive;
        this.showNotificationFrom = state.from;
      }
    );

    if(Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidHide', () => {
        this.keyboardHidden = true;
      });

      Keyboard.addListener('keyboardWillShow', info => {
        this.keyboardHidden = false;
      });
    }

    this.up.isLogged.subscribe((res:any) => {
      this.userIsLogged = res;
      if (this.userIsLogged) {
        this.data_usuario = JSON.parse(localStorage.getItem('user_data')!);
        if (this.data_usuario) {
          this.initials =
            this.data_usuario.nombre.charAt(0).toUpperCase() +
            this.data_usuario.apellido.charAt(0).toUpperCase();
          this.userFullName = this.data_usuario.full_name;
        }

        this.fp.cargarFunciones();
        this.userID = this.up.getIdUsuario;
        //validate impersonation session
        const configData = JSON.parse(localStorage.getItem('config_data') || "{}");
        if (configData.impersonation) {
          setTimeout(() => {
            this.showImpersonationBar = configData.impersonation.show_bar;
            this.impersonationBarTitle = configData.impersonation.show_title;
          }, 1000);
        }

        this.platform.ready().then(() => {
          if (this.platform.is('cordova')) {
            console.log('USUARIO LOGUEADO INIT HTTP OFFLINE SERVICE');
            this.offlineHttpService.checkNewtworkStatus();
          }
        });
      } else {
        this.initials = '';
        this.userFullName = '';

        this.showImpersonationBar = false;
        this.impersonationBarTitle = '';

        if (environment.APP_MULTIMARCA) {
          // console.log("logout multimarca**********************")
          this.getMultibrandLoginConfig()
          // this.setCustomConfigForBrand(this.defaultConfig Data);
        } else {
          this.getConfig();
        }
      }
    });

    //Add dynamic title for selected pages
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let title: string | any = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        );
        if (this.config_empresa) {
          title.length > 0
            ? titleService.setTitle(
                this.config_empresa.titulo
                  ? this.config_empresa.titulo
                  : 'Cursalab' + ' | ' + title[0]
              )
            : titleService.setTitle(
                this.config_empresa.titulo
                  ? this.config_empresa.titulo
                  : 'Cursalab'
              );
        }
        if (title.length > 0 && title[0] !== 'login') {
          const element = document.getElementsByClassName(
            'grecaptcha-badge'
          )[0] as HTMLElement;
          if (element) {
            element.style.visibility = 'hidden';
          }
        }
        const userJson = localStorage.getItem('user_data');
        const user = userJson !== null ? JSON.parse(userJson) : null;
        if (user) {
          const nps = localStorage.getItem('nps')
            ? JSON.parse(localStorage.getItem('nps')!)
            : null;
          const date = localStorage.getItem('date_nps')
            ? JSON.parse(localStorage.getItem('date_nps')!)
            : { query: null, sent: null };
          const date_now = moment(new Date()).format('L');
          if (!nps || (nps && date.query !== date_now)) {
            const dni = user.dni;
            this.api
              .listNPS('app', '2', dni)
              .then((res: any) => {
                const date_nps = { query: date_now, sent: null };
                if (date.sent === date_now) {
                  date_nps.sent = date.sent;
                }
                localStorage.setItem('date_nps', JSON.stringify(date_nps));
                localStorage.setItem('nps', JSON.stringify(res.data));
              })
              .catch((err:any) => {
                console.log(err);
                localStorage.setItem('nps', JSON.stringify([]));
              });
          }
        }
        this.ns.changeShowNotification({isActive: false, from: ''});
      }
    });

    //Add analytics
    if (this.domain != 'localhost') {
      const navEndEvents$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      );

      navEndEvents$.subscribe((event: NavigationEnd) => {
        if (this.userID) {
          gtag('config', environment.GA_TRACKING_ID, {
            user_id: this.userID,
            page_path: event.urlAfterRedirects,
          });
        } else {
          gtag('config', environment.GA_TRACKING_ID);
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }

    this.platform.ready().then(async () => {
      console.log('platform is ready');
      if (this.platform.is('ios')) {
        this.platformIsIOS = true;
          //! todo
        // // let status bar overlay webview
        // this.statusBar.overlaysWebView(false);
        // // set status bar to white
        // this.statusBar.backgroundColorByHexString('#000000');
      }
      if (Capacitor.isNativePlatform()) {
        this.Storage.get('token_fcm').then( async (val:any) => {
          console.log("check fcm token storage: ",val);
          if (val != null) {
            if (val.status === 'true') {
              let data = {
                status: 'false',
                token: '',
              };
              this.Storage.set('token_fcm', data);
            } else {
              this.pushNotificationsService.requestPermissions();
            }
          } else {
            this.pushNotificationsService.requestPermissions();
          }
        });

        this.checkNewtworkStatus();
        if(!environment.APP_MULTIMARCA && Capacitor.isNativePlatform()){
          await SplashScreen.hide();
        }
      }

      // this.splashScreen.hide();

    });

    if (Capacitor.isNativePlatform()){
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          try {
            const excludedRoutes: string[] = ["/validador-evaluacion", "/register", "/encuestas"];
            const slug = event.url;
            console.log("slug:: ",slug);
            const url = new URL(slug);
            const path = url.pathname;
            const query = url.search;

            if (excludedRoutes.includes(path)) {
              let modifiedUrl = url.href;
              if(path === "/validador-evaluacion") {
                modifiedUrl = this.encodeUrl(url);
              };
              this.downloadFileService.downloadMediaWithBrowser(modifiedUrl);
              return;
            }

            if (this.platform.is('ios')) {
              if (slug.indexOf(environment.CUSTOM_SCHEMA) > -1) {
                Browser.close();
                return;
              };
            };

            if (slug && path !== "//") {
              if(!this.userIsLogged) {
                console.log("---no logueado!!!");
                localStorage.setItem("pendingDeepLink", path + query);
              }
              this.mp.select_group.emit(null);
              this.router.navigateByUrl(path + query);
            };
          } catch (error) {
            console.log("error deeplink::", error);

          };
        });
      });
    };

  }

  ngOnInit() {
    //configuracion de ambiente por marca
    if (environment.APP_MULTIMARCA) {
      const configAmbienteJson = localStorage.getItem('config_ambiente');
      const configAmbiente = configAmbienteJson !== null ? JSON.parse(configAmbienteJson) : null;

      //* config de ambiente especifica x workspace */
      if (configAmbiente && this.userIsLogged) {
        this.setCustomConfigForBrand(configAmbiente);
      }
      // else {
      //   console.log("oninit no logueado @@@@@@@@@@@@@@@@@")
      //   this.setCustomConfigForBrand(this.defaultConfigData);
      // }

      let apiConnectors = this.api.getApiUrls;
      const apiUrlForBrand = localStorage.getItem('api_urls');
      if (apiConnectors.serviceBase === '' || !apiConnectors.serviceBase) {
        if (apiUrlForBrand) this.api.setApiUrls = JSON.parse(apiUrlForBrand);
      }

      const certificateUrlBase = this.up.getRutaBase;
      if (!certificateUrlBase && apiUrlForBrand) {
        this.up.genera_rutas();
      }
    } else {
      if (this.userIsLogged) {
        this.getConfig();
      }
    }
  }

  checkNewtworkStatus() {
    console.log('*app.ts - initializeNetworkEvents!!!*');
    this.networkService.initializeNetworkEvents();
  }

  async reloadMessage() {
    // todo probar service worker
    const modalAvailable = await this.modalCtrl.create({
      component: MultipleOptionModalComponent,
      backdropDismiss: false,
      cssClass: 'reload-modal',
      componentProps: {
        firstButtonText: 'Actualizar ahora',
        secondButtonText: 'Actualizar más tarde',
        customStyle: 'w-10/12 sm:w-88 md:w-96 custom-reload-modal-width',
      },
    });
    await modalAvailable.present();

    const { data } = await modalAvailable.onWillDismiss();

    if (data.option === 'first') {
      console.log('reload');
      this.swUpdate
        .activateUpdate()
        .then(() => window.location.reload())
        .catch((error:any) => window.location.reload());
    }
  }

  setCustomConfigForBrand(configData:any) {
    this.config_empresa = configData;
    if (configData && !configData.color_primario_app) {
      //en caso lleguen los colores en null
      this.config_empresa.color_primario_app = '#5458e9';
      this.config_empresa.color_secundario_app = '#58c1e5';
    }
    this.up.setConfig(this.config_empresa);
    let title = this.getTitle(
      this.router.routerState,
      this.router.routerState.root
    );
    title.length
      ? this.titleService.setTitle(
          this.config_empresa.titulo
            ? this.config_empresa.titulo
            : 'Cursalab' + ' | ' + title[0]
        )
      : this.titleService.setTitle(
          this.config_empresa.titulo ? this.config_empresa.titulo : 'Cursalab'
        );
    if (this.config_empresa.icono) {
      const favicon = document.getElementById('favicon') as HTMLLinkElement;
      favicon.href = this.config_empresa.icono;
    }

    let result_pri;
    let result_sec;
    let rgb;

    try {
      result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        this.config_empresa.color_primario_app
      );
      result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        this.config_empresa.color_secundario_app
      );

      //red,green,blue(primary & secondary)
      rgb = {
        rp: parseInt(result_pri![1], 16),
        gp: parseInt(result_pri![2], 16),
        bp: parseInt(result_pri![3], 16),
        rs: parseInt(result_sec![1], 16),
        gs: parseInt(result_sec![2], 16),
        bs: parseInt(result_sec![3], 16),
      };
    } catch (error) {
      console.log(error);
      result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec('#5458e9');
      result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec('#58c1e5');

      //red,green,blue(primary & secondary)
      rgb = {
        rp: parseInt(result_pri![1], 16),
        gp: parseInt(result_pri![2], 16),
        bp: parseInt(result_pri![3], 16),
        rs: parseInt(result_sec![1], 16),
        gs: parseInt(result_sec![2], 16),
        bs: parseInt(result_sec![3], 16),
      };
    }

    let rootelement = this.document.documentElement;
    // colores de config
    rootelement.style.setProperty(
      '--primary',
      this.config_empresa.color_primario_app
    );
    rootelement.style.setProperty(
      '--ion-color-primary',
      this.config_empresa.color_primario_app
    );
    //color secundario
    rootelement.style.setProperty(
      '--secondary',
      this.config_empresa.color_secundario_app
    );
    rootelement.style.setProperty(
      '--ion-color-secondary',
      this.config_empresa.color_secundario_app
    );
    rootelement.style.setProperty(
      '--tertiary',
      this.config_empresa.color_terciario_app
        ? this.config_empresa.color_terciario_app
        : this.config_empresa.color_secundario_app
    );
    rootelement.style.setProperty(
      '--ion-color-tertiary',
      this.config_empresa.color_terciario_app
        ? this.config_empresa.color_terciario_app
        : this.config_empresa.color_secundario_app
    );
    if (this.config_empresa.template !== 'template-inretail') {
      rootelement.style.setProperty(
        '--quaternary',
        this.config_empresa.color_terciario_app
          ? this.config_empresa.color_terciario_app
          : this.config_empresa.color_secundario_app
      );
    }

    // variantes
    rootelement.style.setProperty(
      '--primary-focused',
      `rgb(${(5.4 * rgb.rp) / 10}, ${(6.4 * rgb.gp) / 10}, ${
        (8.8 * rgb.bp) / 10
      })`
      //`rgb(${6*rgb.rp/10}, ${6*rgb.gp/10}, ${6*rgb.bp/10})`   original
    );
    rootelement.style.setProperty(
      '--primary-light',
      `rgb(${(18 * rgb.rp) / 10}, ${(17.5 * rgb.gp) / 10}, ${
        (12.2 * rgb.bp) / 10
      })`
    );
    rootelement.style.setProperty(
      "--primary-semi-light",
        `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.5)`
    );
    rootelement.style.setProperty(
      '--primary-light-variant',
      `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.3)`
    );
    rootelement.style.setProperty(
      '--primary-medium-light-variant',
      `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.2)`
    );
    rootelement.style.setProperty(
      '--primary-ultra-light-variant',
      `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.12)`
    );
    rootelement.style.setProperty(
      '--secondary-focused',
      `rgb(${(8.6 * rgb.rs) / 10}, ${(9.6 * rgb.gs) / 10}, ${
        (9.4 * rgb.bs) / 10
      })`
    );
    rootelement.style.setProperty(
      '--secondary-blur',
      `rgba(${(0 * rgb.rs) / 10}, ${(9 * rgb.gs) / 10}, ${
        (11.5 * rgb.bs) / 10
      }, 0.65)`
    );
    rootelement.style.setProperty(
      '--secondary-light-variant',
      `rgb(${rgb.rs}, ${rgb.gs}, ${rgb.bs},0.4)`
    );
  }

  async getConfig() {
    const loggedCustomConfigInStorageJSON =  localStorage.getItem('loggedCustomConfig');
    const loggedCustomConfigInStorage = loggedCustomConfigInStorageJSON !== null ? JSON.parse(loggedCustomConfigInStorageJSON) : null;
    let response;
    try {
      response = await this.api.getConfig();

      //***config ambiente */
      if (loggedCustomConfigInStorage) {
        let formatedData = {
          ...response,
          completed_courses_logo:
            loggedCustomConfigInStorage.completed_courses_logo
              ? loggedCustomConfigInStorage.completed_courses_logo
              : response.completed_courses_logo,
          enrolled_courses_logo:
            loggedCustomConfigInStorage.enrolled_courses_logo
              ? loggedCustomConfigInStorage.enrolled_courses_logo
              : response.enrolled_courses_logo,
          diplomas_logo: loggedCustomConfigInStorage.diplomas_logo
            ? loggedCustomConfigInStorage.diplomas_logo
            : response.diplomas_logo,
          color_primario_app: loggedCustomConfigInStorage.color_primario_app
            ? loggedCustomConfigInStorage.color_primario_app
            : response.color_primario_app,
          color_secundario_app: loggedCustomConfigInStorage.color_secundario_app
            ? loggedCustomConfigInStorage.color_secundario_app
            : response.color_secundario_app,
          color_terciario_app: loggedCustomConfigInStorage.color_terciario_app
            ? loggedCustomConfigInStorage.color_terciario_app
            : response.color_terciario_app,
        };

        this.setCustomConfigForBrand(formatedData);
      } else {
        this.setCustomConfigForBrand(response);
      }
    } catch (error) {
      console.log('ERROR - default config: ', error);
      if (loggedCustomConfigInStorage) {
        let formatedData = {
          ...this.defaultConfigData,
          completed_courses_logo:
            loggedCustomConfigInStorage.completed_courses_logo,
          enrolled_courses_logo:
            loggedCustomConfigInStorage.enrolled_courses_logo,
          diplomas_logo: loggedCustomConfigInStorage.diplomas_logo,
          color_primario_app: loggedCustomConfigInStorage.color_primario_app,
          color_secundario_app:
            loggedCustomConfigInStorage.color_secundario_app,
          color_terciario_app: loggedCustomConfigInStorage.color_terciario_app,
        };

        this.setCustomConfigForBrand(formatedData);
      } else {
        this.setCustomConfigForBrand(this.defaultConfigData);
      }
    }
  }



  // collect that title data properties from all child routes
  getTitle(state, parent):any {
    var data = [];

    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  async logoutImpersonation() {
    await this.api
      .logout_token()
      .then((res) => {
        this.up.clearStorage();
        this.showImpersonationBar = false;
        this.impersonationBarTitle = '';
        this.router.navigate(['login']);
      })
      .catch((e) => {
        console.log(e.status, e.url);
      });
  }
  addGoogleAnalyticsScript() {
    if (this.domain != 'localhost') {
      let gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src =
        'https://www.googletagmanager.com/gtag/js?id=' +
        environment.GA_TRACKING_ID;
      document.head.prepend(gtagScript);

      /** Disable automatic page view hit to fix duplicate page view count  **/
      gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
    }
  }

  encodeUrl(parsedUrl) {
    const apiUrl = parsedUrl.searchParams.get('api_url');
    const identificator = parsedUrl.searchParams.get('identificator');
    const course = parsedUrl.searchParams.get('course_id');
    const topic = parsedUrl.searchParams.get('topic_id');
    return parsedUrl.origin + parsedUrl.pathname + `?api_url=${encodeURIComponent(apiUrl)}&identificator=${identificator}&course_id=${course}&topic_id=${topic}`;
  }

  getMultibrandLoginConfig() {
    // console.log("get api master config*****************")
    const origin =  this.fp.getLocationOrigin();
    try {
      this.api.getMultibrandLoginConfig(origin).subscribe(
        {
          next: ({data}) => {
            if(data?.color_primario_app || data?.logo_app) {
              this.setCustomConfigForBrand(data);
            } else {
              this.setCustomConfigForBrand(this.defaultConfigData);
            };
          },
          error: (e) => {
            console.log("e::",e);
            this.setCustomConfigForBrand(this.defaultConfigData);
          }
        }
      );

    } catch (error) {
      console.log("error::",error);
      this.setCustomConfigForBrand(this.defaultConfigData);
    };
  }

  ngOnDestroy() {
    if (this.checkUpdateInterval) {
      this.checkUpdateInterval.unsubscribe();
      console.log('OnDestroy unsubscribe interval');
    }
  }
}
