import { Injectable } from "@angular/core";
import { UsuarioServiceProvider } from "../usuario-service/usuario-service";
//! todo
// import { NativePageTransitions,	NativeTransitionOptions,} from "@ionic-native/native-page-transitions/ngx";
import { Observable, Subject, BehaviorSubject  } from "rxjs";
// export interface Paginas {
// 	name: string;
// 	path: string;
// 	icon?: string;
// }
@Injectable({ providedIn: "root" })
export class FuncionesServiceProvider {
	private ruta_imagen: string;
	public mod_evaluaciones: any = [];
	cargar_al_iniciar: number = 0;
	renovar_token: boolean = false;
	private timeCod: any;

	private pages: any[];
	private groupedPages: any[];
	finalMenu: any= [];
	private pages$ = new Subject<any[]>();

	private pages_main = [];
	private pages_main$ = new Subject<any[]>();

  menuGradientActive:boolean = true;

  private alternativeStyleSubject = new BehaviorSubject<boolean | null>(null);
  alternativeStyleSubject$ = this.alternativeStyleSubject.asObservable();
  brandColors = {
    primary: "",
    secondary: "",
    tertiary: "",
  };

  private headerPages = new BehaviorSubject([]);
  headerPages$ = this.headerPages.asObservable();


	constructor(
		public up: UsuarioServiceProvider,
		// private nativePageTransitions: NativePageTransitions
	) {
    const alternativeStyle = localStorage.getItem("appAlternativeStyle");
    if (alternativeStyle) {
      this.alternativeStyleSubject.next(JSON.parse(alternativeStyle));
    }
  }

	public transitionsPush(direccion) {
    //! todo
		// let options: NativeTransitionOptions = {
		// 	direction: direccion,
		// 	duration: 800,
		// 	slowdownfactor: 3,
		// 	slidePixels: 20,
		// 	iosdelay: 100,
		// 	androiddelay: 100,
		// };
		// this.nativePageTransitions.slide(null);
	}

  setAlternativeStyleState(state: boolean): void {
    this.alternativeStyleSubject.next(state);
    if(state) {
      localStorage.setItem("appAlternativeStyle", JSON.stringify(state));
    }
  }

  getAlternativeStyleState(): boolean | null {
    return this.alternativeStyleSubject.getValue();
  }

	public get rutaImagen(): string {
		return this.ruta_imagen;
	}
	public set rutaImagen(v: string) {
		this.ruta_imagen = v;
	}

	public get mod_eval(): any {
		return this.mod_evaluaciones;
	}

	public get timeC(): any {
		return this.timeCod;
	}

	public get _pages(): any {
		return this.finalMenu;
	}

	getPaginas(): Observable<any[]> {
		return this.pages$.asObservable();
	}

	public get _pages_main(): any {
		return this.pages_main;
	}

	getPaginas_main(): Observable<any[]> {
		return this.pages_main$.asObservable();
	}

	async cargarFunciones() {
		this.timeCod = 2000;

		this.up.load_storage();

		let config_data = JSON.parse(this.up.getConfigData);
		this.pages = [];
		this.pages_main = [];

		let data_usuario = JSON.parse(localStorage.getItem("user_data"));
		let sidemenu;
		if(data_usuario.user_induccion_aprendizaje && data_usuario.onboarding)  sidemenu = this.up.getPlatformType === 'induction' ? config_data.full_app_side_menu_induccion : config_data.full_app_side_menu;
		else sidemenu = config_data.full_app_side_menu;

		// if (sidemenu.perfil) {
		// 	this.pages.push({ name: "Anuncios", path: "anuncios", icon: "assets/icon/anuncios.svg" });
		// }
		if (sidemenu.cursos) {
			this.pages.push({
				name: "Cursos",
				path: "cursos",
				icon: "assets/icon/book-open-variant.svg",
				group: "courses",
			});
		}
		if (sidemenu.cursosextra) {
			this.pages.push({
				name: "Extracurriculares",
				path: "extracurricular",
				icon: "assets/icon/book-account.svg",
				group: "courses"
			});
		}
		if (sidemenu.cursoslibres) {
			this.pages.push({
				name: "Libres",
				path: "libres",
				icon: "assets/icon/book-heart.svg",
				group: "courses"
			});
		}
		// if (sidemenu.progreso) {
		// 	this.pages.push({
		// 		name: "Progreso",
		// 		path: "progreso",
		// 		icon: "assets/icon/progreso.svg",
		// 		group: "",
		// 	});
		// }
		if (sidemenu.videoteca) {
			this.pages.push({
				name: "Videoteca",
				path: "videoteca",
				icon: "assets/icon/file-video.svg",
				group: "content",
			});
		}
		if (sidemenu.glosario) {
			this.pages.push({
				name: "Glosario",
				path: "glosario",
				icon: "assets/icon/alphabetical-variant.svg",
				group: "content",
			});
		}
		if (sidemenu.vademecun) {
			if (JSON.parse(localStorage.getItem("user_data")).workspace.slug === "farmacias-peruanas") {
				this.pages.push({
					name: "Vademécum",
					path: "vademecum",
					icon: "assets/icon/book-education.svg",
					group: "content",
				});
			} else {
				this.pages.push({
					name: "Documentos y protocolos",
					path: "documentos-y-protocolos",
					icon: "assets/icon/book-education.svg",
					group: "content",
				});
			}
		}

		if (sidemenu.beneficios) {
			this.pages.push({
				name: "Mis beneficios",
				path: "beneficios",
				icon: "assets/icon/gift-open.svg",
				group: "benefits",
			});
		}
		if (sidemenu.progreso) {
			this.pages.push({
				name: "Progreso",
				path: "progreso",
				icon: "assets/icon/chart-areaspline-variant.svg",
				group: "progress",
			});
		}
		if (sidemenu.diplomas) {
			this.pages.push({
				name: "Certificados",
				path: "certificados",
				icon: "assets/icon/certificate.svg",
				group: "progress",
			});
		}
		if (sidemenu.ranking) {
			this.pages.push({
				name: "Ranking",
				path: "ranking",
				icon: "assets/icon/podium-gold.svg",
				group: "progress",
			});
		}
		if (sidemenu.historico) {
			this.pages.push({
				name: "Historial",
				path: "historial-de-cursos",
				icon: "assets/icon/clock-star-four-points.svg",
				group: "progress",
			});
		}

		/* if (sidemenu.temas_libres) {
			this.pages.push({ name: "Temas Libres", path: "temas_libres" });
		} */
		if (sidemenu.tareas) {
			this.pages.push({
				name: "Tareas",
				path: "tareas",
				icon: "assets/icon/book-arrow-down.svg",
				group: "activities",
			});
		}
		if (sidemenu.aulas_virtuales) {
			this.pages.push({
				name: "Sesiones live",
				path: "lista-reuniones",
				icon: "assets/icon/video.svg",
				group: "activities",
			});
		}
		if (sidemenu.encuestas_libres) {
			this.pages.push({
				name: "Encuestas",
				path: "encuesta-libre",
				icon: "assets/icon/text-box-check.svg",
				group: "activities",
			});
		}
		if (sidemenu.subir_link) {
			this.pages.push({
				name: "Adjuntar archivo",
				path: "adjuntar-archivo",
				icon: "assets/icon/clipboard-arrow-up.svg",
				group: "activities",
			});
		}

		if (sidemenu.checklist) {
			this.pages.push({
				name: "Checklist",
				path: "lista-checklists",
				icon: "assets/icon/checkbox-multiple-marked.svg",
				group: "supervision",
			});
		}

		// if (sidemenu.checklist && JSON.parse(localStorage.getItem("user_data")).rol_entrenamiento) {
		// 	if (JSON.parse(localStorage.getItem("user_data")).rol_entrenamiento == "trainer") {
		// 		this.pages.push({
		// 			name: "Mi equipo",
		// 			path: "lista-alumnos",
		// 			icon: "assets/icon/account-group.svg",
		// 			group: "supervision",
		// 		});
		// 	} else {
		// 		this.pages.push({
		// 			name: "Checklist",
		// 			path: "alumno",
		// 			icon: "assets/icon/checkbox-multiple-marked.svg",
		// 			group: "supervision",
		// 		});
		// 	}
		// }

		if (sidemenu.reportes) {
			if (JSON.parse(localStorage.getItem("user_data")).supervisor)
				this.pages.push({
					name: "Reportes",
					path: "reportes",
					icon: "assets/icon/account-badge.svg",
					group: "supervision",
				});
		}

		if (sidemenu.reconocimiento) {
			this.pages.push({
				name: "Reconocimiento",
				path: "modulo-reconocimiento",
				icon: "assets/icon/trophy.svg",
				group: "recognition",
			});
		}
		if (sidemenu.preguntas) {
			this.pages.push({
				name: "Preguntas frecuentes",
				path: "faq",
				icon: "assets/icon/frequently-asked-questions.svg",
				group: "help",
			});
		}
		if (sidemenu.ayuda) {
			this.pages.push({
				name: "Ayuda",
				path: "ayuda",
				icon: "assets/icon/handshake.svg",
				group: "help",
			});
		}
		// Inducción
		if (sidemenu.ind_avance) {
			this.pages.push({
				name: "Avance",
				path: "home",
				icon: "assets/icon/ind-home.svg",
				group: "",
			});
		}
		if (sidemenu.ind_ruta) {
			this.pages.push({
				name: "Ruta",
				path: "home",
				icon: "assets/icon/ind_ruta.svg",
				group: "",
			});
		}
		// if (sidemenu.ind_certificado) {
		// 	this.pages.push({
		// 		name: "Certificado",
		// 		path: "certificados",
		// 		icon: "assets/icon/ind_certificado.svg",
		// 		group: "",
		// 	});
		// }
		if (sidemenu.ind_asistencia) {
			this.pages.push({
				name: "Inasistencia",
				path: "lista-colaboradores",
				icon: "assets/icon/ind_inasistencia.svg",
				group: "",
			});
		}
		if (sidemenu.ind_procesos) {
			this.pages.push({
				name: "Procesos",
				path: "procesos",
				icon: "assets/icon/ind_procesos.svg",
				group: "",
			});
		}
		if (sidemenu.ind_faq) {
			this.pages.push({
				name: "FAQ",
				path: "faq",
				icon: "assets/icon/ind_faq.svg",
				group: "",
			});
		}

    // Si es lider (mentor) en inducción
    if (sidemenu.is_mentor_onboarding) {
      this.pages.push({
        name: "Solicitudes",
        path: "reuniones-pasantia",
        icon: "assets/icon/ind_induccion.svg",
        group: "",
      });
    }

		// Inducción y Aprendizaje
		if (sidemenu.ind_aprendizaje) {
			this.pages.push({
				name: "Regresar a capacitación",
				path: "aprendizaje",
				icon: "assets/icon/ind_capacitacion.svg",
				group: "",
			});
		}

		if (sidemenu.ind_induccion) {
			this.pages.push({
				name: "Supervisar inducción",
				path: "induccion",
				icon: "assets/icon/ind_induccion.svg",
				group: "",
			});
		}

		this.groupedPages = [
			{
				groupName: "Mis cursos",
				groupIcon: "assets/icon/book-open-page-variant.svg",
				groupedItems : [],
				isGrouped: true
			},
			{
				groupName: "Mi avance",
				groupIcon: "assets/icon/chart-timeline-variant.svg",
				groupedItems : [],
				isGrouped: true
			},

			{
				groupName: "Mis actividades",
				groupIcon: "assets/icon/clipboard-account.svg",
				groupedItems : [],
				isGrouped: true
			},
			{
				groupName: "Mis beneficios",
				groupIcon: "assets/icon/gift-open.svg",
				groupedItems : [],
				isGrouped: false
			},
			{
				groupName: "Supervisión",
				groupIcon: "assets/icon/book-edit.svg",
				groupedItems : [],
				isGrouped: true
			},

			{
				groupName: "Reconocimiento",
				groupIcon: "assets/icon/trophy.svg",
				groupedItems : [],
				isGrouped: false
			},
			{
				groupName: "Más recursos",
				groupIcon: "assets/icon/multimedia.svg",
				groupedItems : [],
				isGrouped: true
			},
			{
				groupName: "Centro de ayuda",
				groupIcon: "assets/icon/help-circle.svg",
				groupedItems : [],
				isGrouped: true
			}

		];
		for(let page of this.pages ) {
			if(page.group === "courses"){
				this.groupedPages[0].groupedItems.push(page)
			}
			else if(page.group === "progress"){
				this.groupedPages[1].groupedItems.push(page)
			}

			else if(page.group === "activities"){
				this.groupedPages[2].groupedItems.push(page)
			}
			else if(page.group === "benefits"){
				this.groupedPages[3].groupedItems.push(page)
			}
			else if(page.group === "supervision"){
				this.groupedPages[4].groupedItems.push(page)
			}

			else if(page.group === "recognition"){
				this.groupedPages[5].groupedItems.push(page)
			}
			else if(page.group === "content"){
				//más recursos
				this.groupedPages[6].groupedItems.push(page)
			}
			else if(page.group === "help"){
				this.groupedPages[7].groupedItems.push(page)
			}
			else {
				this.groupedPages.push({'groupedItems': [page]})
			}
		}

		for( let group of this.groupedPages ) {
			if(group.groupedItems.length === 1){
				group.groupName = group.groupedItems[0].name;
				group.groupIcon = group.groupedItems[0].icon;
				group.isGrouped = false;
			}

		}
		this.finalMenu = [];
		for( let group of this.groupedPages ) {
			if(group.groupedItems.length > 0){
				this.finalMenu.push(group);
			}
		}
		// console.log("groupedMenu::",this.finalMenu);


		this.pages$.next(this.finalMenu);
		// this.pages$.next(this.pages);

		let main_menu
		if(data_usuario.user_induccion_aprendizaje && data_usuario.onboarding) main_menu = this.up.getPlatformType === 'induction' ? config_data.full_app_main_menu_induccion : config_data.full_app_main_menu;
		else main_menu = config_data.full_app_main_menu;

		// if (main_menu.anuncios) {
		// 	this.pages_main.push({
		// 		name: "Anuncios",
		// 		path: "anuncios",
		// 		icon: "assets/icon/bullhorn-outline.svg",
		// 		selectedIcon: "assets/icon/bullhorn.svg",
		// 	});
		// }
		if (main_menu.anuncios) {
			this.pages_main.push({
				name: "Inicio",
				path: "anuncios",
				icon: "assets/icon/home-outline.svg",
				selectedIcon: "assets/icon/home.svg",
			});
		}
		if (main_menu.cursos) {
			this.pages_main.push({
				name: "Cursos",
				path: "cursos",
				icon: "assets/icon/book-open-page-variant-outline.svg",
				selectedIcon: "assets/icon/book-open-page-variant.svg",
			});
		}
		if (main_menu.aulas_virtuales) {
			this.pages_main.push({
				name: "Sesiones",
				path: "lista-reuniones",
				icon: "assets/icon/video-outline.svg",
				selectedIcon: "assets/icon/video.svg",
			});
		}
		if (main_menu.progreso) {
			this.pages_main.push({
				name: "Progreso",
				path: "progreso",
				icon: "assets/icon/chart-line.svg",
				selectedIcon: "assets/icon/chart-areaspline-variant.svg",
			});
		}
		if(main_menu.ind_home_sup) {
			this.pages_main.push({
				name: "Inicio",
				path: "procesos",
				icon: "assets/icon/ind-home.svg",
				selectedIcon: "assets/icon/ind-home.svg",
			});
		}
		if(main_menu.ind_procesos) {
			this.pages_main.push({
				name: "Inasistencia",
				path: "lista-colaboradores",
				icon: "assets/icon/ind_nav_inasistencia.svg",
				selectedIcon: "assets/icon/ind_nav_inasistencia_selected.svg",
			});
		}
		if(main_menu.ind_asistencia) {
			this.pages_main.push({
				name: "Procesos",
				path: "procesos",
				icon: "assets/icon/ind_nav_procesos.svg",
				selectedIcon: "assets/icon/ind_nav_procesos_selected.svg",
			});
		}
		if(main_menu.ind_home) {
			this.pages_main.push({
				name: "Inicio",
				path: "home",
				icon: "assets/icon/ind-home.svg",
				selectedIcon: "assets/icon/ind-home.svg",
			});
		}
		if(main_menu.ind_ruta) {
			this.pages_main.push({
				name: "Ruta",
				path: "map",
				icon: "assets/icon/ind_nav_ruta.svg",
				selectedIcon: "assets/icon/ind_nav_ruta_selected.svg",
				hideInWeb: true
			});
		}
		if(main_menu.ind_faq) {
			this.pages_main.push({
				name: "FAQ",
				path: "faq",
				icon: "assets/icon/ind-faq.svg",
				selectedIcon: "assets/icon/ind_faq.svg",
			});
		}
		if(main_menu.ind_aprendizaje) {
			this.pages_main.push({
				name: "Regresar a capacitación",
				path: "aprendizaje",
				icon: "assets/icon/ind_capacitacion.svg",
				selectedIcon: "assets/icon/ind_capacitacion.svg",
			});
		}
		if(main_menu.ind_induccion) {
			this.pages_main.push({
				name: "Supervisar inducción",
				path: "induccion",
				icon: "assets/icon/ind_induccion.svg",
				selectedIcon: "assets/icon/ind_induccion.svg",
			});
		}
    if (main_menu.checklist) {
			this.pages_main.push({
				name: "Checklist",
				path: "lista-checklists",
				icon: "assets/icon/checkbox-multiple-marked-outline.svg",
				selectedIcon: "assets/icon/checkbox-multiple-marked.svg",
			});
		}
//icon: "assets/icon/account-group.svg",
		this.pages_main$.next(this.pages_main);

    this.headerPages.next(this.pages_main);
	}

  sanitizeHtmlString(htmlString) {
    let textSanitized = "";
    if(!htmlString) {
      return textSanitized;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    textSanitized = doc.body.textContent;
    return textSanitized;
  }

  getLocationOrigin() {
    const origin = window.location.origin;
    return origin;
  }

  getMultibrandBgSvgImg(brandConfig) {
    this.getBrandColors(brandConfig);

    const primaryColorFormated = this.brandColors.primary
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/,/g, "%2C");
    const secondaryColorFormated = this.brandColors.secondary
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/,/g, "%2C");
    const tertiaryColorFormated = this.brandColors.tertiary
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/,/g, "%2C");

   const bgSvg = `
    "data:image/svg+xml,%3Csvg viewBox='0 0 1440 433' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_481_1404)'%3E%3Cpath d='M1637.04 624.741L1343.44 116.339C1334.2 100.336 1313.74 94.855 1297.73 104.097L789.331 397.698C773.328 406.94 767.847 427.404 777.088 443.407L1070.69 951.809C1079.93 967.811 1100.4 973.292 1116.4 964.051L1624.8 670.449C1640.8 661.208 1646.28 640.743 1637.04 624.741Z' fill='${primaryColorFormated}'/%3E%3C/g%3E%3Cpath d='M659.181 8.73664L-29.3154 172.76C-48.5977 177.353 -60.5051 196.709 -55.9114 215.991L108.112 904.487C112.705 923.769 132.06 935.677 151.343 931.083L839.839 767.06C859.121 762.466 871.029 743.111 866.435 723.829L702.412 35.3327C697.818 16.0504 678.463 4.14295 659.181 8.73664Z' fill='${tertiaryColorFormated}'/%3E%3Cpath d='M885.285 501.239L456.202 55.3565C442.696 41.3217 420.37 40.8931 406.335 54.399L-39.5475 483.482C-53.5822 496.988 -54.011 519.314 -40.505 533.349L388.578 979.231C402.084 993.266 424.41 993.695 438.445 980.189L884.327 551.106C898.362 537.6 898.791 515.274 885.285 501.239Z' fill='${secondaryColorFormated}'/%3E%3Cdefs%3E%3CclipPath id='clip0_481_1404'%3E%3Crect width='748.92' height='748.92' fill='white' transform='translate(727.999 308.407) rotate(-19.7764)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"
   `;
   return bgSvg;
  }

  getBrandColors(brandConfig) {
    // let primaryColor = "#dead5d" //orange
    // let secondaryColor = "#a83261" //pink
    // let tertiaryColor = "#a69c8b"; // brown

    let primaryColor = brandConfig.color_primario_app;
    let secondaryColor = brandConfig.color_secundario_app;
    let tertiaryColor = brandConfig.color_terciario_app;
    if(!tertiaryColor) {
      tertiaryColor = brandConfig.color_primario_app;
    }

    let rgb;
    let result_pri;
    let result_sec;
    let result_ter;
    try {
      result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(primaryColor);
      result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(secondaryColor);
      result_ter = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(tertiaryColor);
      rgb = {
        rp: parseInt(result_pri[1], 16),
        gp: parseInt(result_pri[2], 16),
        bp: parseInt(result_pri[3], 16),
        rs: parseInt(result_sec![1], 16),
        gs: parseInt(result_sec![2], 16),
        bs: parseInt(result_sec![3], 16),
        rt: parseInt(result_ter![1], 16),
        gt: parseInt(result_ter![2], 16),
        bt: parseInt(result_ter![3], 16),
      };
    } catch (error) {
      result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#5458e9");
      result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec('#58c1e5');
      result_ter = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#5458e9");
      rgb = {
        rp: parseInt(result_pri[1], 16),
        gp: parseInt(result_pri[2], 16),
        bp: parseInt(result_pri[3], 16),
        rs: parseInt(result_sec![1], 16),
        gs: parseInt(result_sec![2], 16),
        bs: parseInt(result_sec![3], 16),
        rt: parseInt(result_ter![1], 16),
        gt: parseInt(result_ter![2], 16),
        bt: parseInt(result_ter![3], 16),
      };
    };

    primaryColor = `rgba(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 1)`;
    secondaryColor = `rgba(${rgb.rs}, ${rgb.gs}, ${rgb.bs}, 1)`;
    tertiaryColor = brandConfig.color_terciario_app ? `rgba(${rgb.rt}, ${rgb.gt}, ${rgb.bt}, 1)` :  `rgba(${rgb.rt}, ${rgb.gt}, ${rgb.bt}, 0.7)`;

    this.brandColors = {
      primary: primaryColor,
      secondary: secondaryColor,
      tertiary: tertiaryColor
    };
  }

}
