import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
// import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Platform, AlertController, MenuController, ModalController, NavController } from "@ionic/angular";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import {
	FuncionesServiceProvider,
	LoaderProvider,
	MenuServiceProvider,
	UsuarioServiceProvider,
} from "src/app/service/index.providers";
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";
import { ApiService } from "src/app/api.service";
import { ConexionServiceProvider, ConnectionStatus } from "src/app/service/conexion-service/conexion-service";
import { LoginMultiModuloService } from "src/app/service/login-multi-modulo/login-multi-modulo.service";
import { RewardsService } from "src/app/service/rewards/rewards.service";
import { Storage } from '@ionic/storage-angular';

@Component({
	selector: "app-side-menu-mobile",
	templateUrl: "./side-menu-mobile.component.html",
	styleUrls: ["./side-menu-mobile.component.scss"],
})
export class SideMenuMobileComponent implements OnInit, OnDestroy {
	array_menu2$: Observable<any[]>;
	array_menu2: any;
	data_usuario;
	select_page: String = "anuncios";
	new_notification: boolean = false;
	initials: string;
	swipe: boolean = false;
	displayAccordionSection: number | null = null;
	mobile: boolean = false;
	isIos = false;
	groupSelected:number | null = null;
	subGroupSelected:number | null = null;
	notification: boolean = false;
  showNotificationFrom: string = "";
	newNotification: boolean = false;
	@Input()dataUsuarioProp;
	@Input()initialsProp;

	networkIsActive: boolean = true;
	isCordovaApp: boolean = false;
	lockNetworkToggle: boolean = false;
	private networkObservable: Subscription;
	hasOfflineModeAvailable:boolean = false;
  platformTypeInduction:boolean = false;
  modulesOptions = [];
  modulesOptionsIsOpen:boolean = false;
  multiWorkspaceSubs: Subscription;
  IDselectedPlatform;
  IDselectedWorkspace;
  version_usu: string | null = this.platform.is("ios") ? environment.APP_VERSION_IOS : environment.APP_VERSION_ANDROID;
	device_os: string = this.platform.is("ios") ? "ios" : this.platform.is("android") ? "android" : "web";
  data_empresa;
  configBrandSub: Subscription;

	constructor(
		private router: Router,
		private mp: MenuServiceProvider,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private up: UsuarioServiceProvider,
		private alertCtrl: AlertController,
		private fp: FuncionesServiceProvider,
		private ns: NotificacionService,
		private api: ApiService,
		private menuCtrl: MenuController,
		private platform: Platform,
		private networkService: ConexionServiceProvider,
    private loginMultiModuloService: LoginMultiModuloService,
    private loader: LoaderProvider,
    private Storage: Storage,
    private rewardsService: RewardsService,
	) {
		if (this.platform.is("cordova") || this.platform.is("mobileweb")) this.mobile = true;
		if (this.platform.is("ios")) this.isIos = true;
		if (this.platform.is("cordova")) {
			this.isCordovaApp = true;
			this.checkNewtworkStatus();
		}

		this.up.isLogged.subscribe((res) => {
			if (res) {
				const user = JSON.parse(localStorage.getItem("user_data"));
				if (user) {
					// console.log("side.ts LOGGED ", this.data_usuario);
					this.data_usuario = user;
					this.initials = this.data_usuario.nombre.charAt(0).toUpperCase() + this.data_usuario.apellido.charAt(0).toUpperCase();
				};

			} else {
				this.data_usuario = null;
				this.initials = "";
			};

			const configData = JSON.parse(localStorage.getItem("config_data"));
			if(configData && configData.is_offline) this.hasOfflineModeAvailable = true;
			// console.log(configData);
      if(this.up.getPlatformType && this.up.getPlatformType === 'induction') {
        this.platformTypeInduction = true;
      }

		});

	}

	ngOnInit() {
		this.array_menu2$ = this.fp.getPaginas();
		this.array_menu2$.subscribe((paginas) => {
			this.array_menu2 = paginas;
			//console.log("this.array_menu2:::",this.array_menu2);
		});

		if (!this.array_menu2) this.array_menu2 = this.fp._pages;

		// if (this.array_menu2 && this.networkIsActive && this.isCordovaApp) {
		//  localStorage.setItem("offlineMenu", JSON.stringify(this.array_menu2))
		// } else if(this.isCordovaApp && !this.array_menu2 && !this.networkIsActive && localStorage.getItem("offlineMenu")) {
		// 	this.array_menu2 =  JSON.parse(localStorage.getItem("offlineMenu"));
		// }
		this.ns.new_notification.subscribe((res) => (this.new_notification = res));
		this.select_page = localStorage.getItem("select_page") || "anuncios";
		if (localStorage.getItem("select_group")) this.groupSelected = parseInt(localStorage.getItem("select_group"));
		if (localStorage.getItem("select_sub_group")) this.subGroupSelected = parseInt(localStorage.getItem("select_sub_group"));
		this.mp.select_group.subscribe((index) => (this.groupSelected = index));
		this.mp.select_sub_group.subscribe((index) => (this.subGroupSelected = index));

		this.ns.currentHideNotification.subscribe((res) => {
      this.notification = res.isActive;
      this.showNotificationFrom = res.from;
    });
		this.ns.newNotification.subscribe((res) => (this.newNotification = res));
    this.multiWorkspaceSubs = this.loginMultiModuloService.workspacesList$.subscribe((res) => {
      this.modulesOptions = res;
      if(this.modulesOptions && this.modulesOptions.length) {
        this.getSelectedWorkspace();
      }
    });
    setTimeout(() => {
      if(!this.modulesOptions || this.modulesOptions.length === 0) {
        this.modulesOptions =  JSON.parse(localStorage.getItem("multiWorkspace"));
        this.loginMultiModuloService.setWorkspacesList(this.modulesOptions);
      };
    }, 70);

    this.configBrandSub = this.up.configBrand.subscribe((res) => {
			this.data_empresa = res;
		});
	}

	checkNewtworkStatus() {
		console.log("sidemenu.ts check network status!!!!");
		this.networkObservable = this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				console.log("online sidemenu.ts");
				this.networkIsActive = true;
				//   this.offlineManager.checkForEvents().subscribe();
			} else {
				console.log("offline sidemenu.ts");
				this.networkIsActive = false;
			}
		});
	}

	async toggleNetwork() {
		if(await this.networkService.getDeviceNetworkType() !== "none") {
			this.lockNetworkToggle = false;
			this.networkIsActive = 	!this.networkIsActive;
			console.log("toggle network: ", this.networkIsActive);
			if(!this.networkIsActive) {
				localStorage.setItem("chooseOffline", "true");
			} else {
				localStorage.removeItem("chooseOffline");
			};
			this.networkService.updateNetworkStatus(this.networkIsActive ? ConnectionStatus.Online : ConnectionStatus.Offline)
		} else {
			this.lockNetworkToggle = true;
			localStorage.removeItem("chooseOffline");
		};
	}

	toOfflineCourses() {
		this.router.navigate(['cursos']);
		this.cerrarMenu();
	}

	to(routeLink, groupIndex = null, subGroupIndex = null) {

		groupIndex ? this.groupSelected = groupIndex : this.groupSelected = this.displayAccordionSection;
		this.subGroupSelected = subGroupIndex;

		// if (routeLink == "adjuntar-archivo-from-gestor") {
		// 	let token = localStorage.getItem("token");
		// 	let url = environment.SERVICE_BASE_GESTOR + "adjuntar_archivo?" + "token=" + token;
		// 	console.log(url);
		// 	this.iab.create(url, "_system"); //_blank
		// } else {

			if(routeLink === "induccion"){
				let data_usuario = JSON.parse(localStorage.getItem("user_data"));
				routeLink = data_usuario.supervisor_induccion ? "procesos" : 'home';
				localStorage.setItem("platform_type", 'induction');
				this.fp.cargarFunciones();
			}
			if(routeLink === "aprendizaje"){
				routeLink = "anuncios";
				localStorage.setItem("platform_type", 'capacitacion');
				this.fp.cargarFunciones();
			}

			this.mp.select_page.emit(routeLink);
			this.mp.select_group.emit(this.groupSelected);
			this.mp.select_sub_group.emit(this.subGroupSelected);
			// this.modalCtrl.dismiss();
			//this.displayAccordionSection = null;
			this.cerrarMenu();
			if (routeLink == "extracurricular" || routeLink == "libres") {
				let extras: NavigationExtras = {
					queryParams: {
						categoria: routeLink,
					},
				};
				this.router.navigate(["progreso"], extras);
			} else {
				this.router.navigate([routeLink]);
			}
		// }
	}

	accordeonActive(index) {
		if(index === this.displayAccordionSection){
			this.displayAccordionSection = null;
		} else{
			this.displayAccordionSection = index;
		}
	}

	async confirmarSalir() {
		let alert = await this.alertCtrl.create({
			header: "Cerrar sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();

		try {
			this.modalCtrl.dismiss();
		} catch (error) {
			console.log(error);
		};
	}

	async cerrarSesion() {
		this.cerrarMenu();
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.navCtrl.navigateRoot("login");
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});

	}

	showNotification(){
		this.notification = !this.notification;
		this.ns.changeShowNotification({isActive: this.notification, from: ''});
		this.cerrarMenu();
	}

	cerrarMenu() {
		this.menuCtrl.close();
    this.modulesOptionsIsOpen = false;
	}

  showMultimoduleOptions() {
    this.modulesOptionsIsOpen = true;
    this.getSelectedWorkspace();
  }

  selectWorkspace(selectionData) {
    console.log("selectModule...", selectionData)
    this.modulesOptionsIsOpen = false;
    localStorage.setItem("IDselectedPlatform", JSON.stringify(selectionData.platform?.id));
    localStorage.setItem("IDselectedWorkspace", JSON.stringify(selectionData.subworkspace?.id));
    this.cerrarMenu();
    if(selectionData.is_neccesary_login) this.loginWorkspace(selectionData.subworkspace?.id, selectionData.platform?.id);
  }


  loginWorkspace(subworkspaceId, platformId) {
    this.loader.show();
    this.api.loginSelectedWorkspace(subworkspaceId, platformId, this.device_os, this.version_usu).subscribe(
      {
        next: async (data) => {
          await this.loginMultiModuloService.logoutPreviousSession();
          try {
            console.log(data);
            if(environment.APP_MULTIMARCA) {
              //** seteando url
              this.loginMultiModuloService.saveMultibrandApiUrls(data);
              //** seteando colores
              const resBrandConfig = await this.api.getConfig();
              if(data.custom_ambiente) {
                const formatedData = this.loginMultiModuloService.generateAppThemeConfigObj(data.custom_ambiente, resBrandConfig);
                this.loginMultiModuloService.setBrandConfigMultimarca(formatedData);
              } else {
                this.loginMultiModuloService.setBrandConfigMultimarca(resBrandConfig);
              };

              const certificateUrlBase = this.up.getRutaBase;
              if (!certificateUrlBase) { this.up.genera_rutas()	};

              if(data.usuario && !data.recovery) {
                this.loginMultiModuloService.setUserProviderConfig(data);
                // FCM token
                if (this.platform.is("cordova")) {
                  this.Storage.get("token_fcm").then((val) => {
                    console.log("login - check storage for token ", val);
                    if (val.status === "true") {
                      // Enviar el token generado por FCM (Firebase) a la BD
                      this.api.guardar_token_fcm(val.token);
                    }
                  });
                }

                this.fp.cargarFunciones();

                if(data.usuario.platform_relations && data.usuario.platform_relations.length) {
                    this.loginMultiModuloService.setWorkspacesList(data.usuario.platform_relations);
                    localStorage.setItem("multiWorkspace", JSON.stringify(data.usuario.platform_relations));
                };

                this.loginMultiModuloService.routeUserToPlatform(data.usuario, true);
                if(data.usuario.rewards) {
                  this.rewardsService.rewardReceivedConfirmation(data.usuario.rewards.coins_gained,
                    data.usuario.rewards.coin_name,
                    data.usuario.rewards.path_coins_image,
                    data.usuario.rewards.messages, true
                  );
                };
              };

            }
            else {
                if(data.custom_ambiente) {
                  this.loginMultiModuloService.setCustomBrandConfig(data.custom_ambiente, this.data_empresa, true);
                } else {
                  const resBrandConfig = await this.api.getConfig();
                  this.loginMultiModuloService.setBrandConfigMultimarca(resBrandConfig);
                };
                if(data.usuario && !data.recovery){
                  this.loginMultiModuloService.setUserProviderConfig(data);
                  if (this.platform.is("cordova")) {
                    this.Storage.get("token_fcm").then((val) => {
                      console.log("login - check storage for token ", val);
                      if (val.status === "true") {
                        // Enviar el token generado por FCM (Firebase) a la BD
                        this.api.guardar_token_fcm(val.token);
                        // this.api.sub_token(result2.data.config_data.push_code);
                      }
                    });
                  };

                  this.fp.cargarFunciones();
                  if(data.usuario.platform_relations && data.usuario.platform_relations.length) {
                    this.loginMultiModuloService.setWorkspacesList(data.usuario.platform_relations);
                    localStorage.setItem("multiWorkspace", JSON.stringify(data.usuario.platform_relations));
                  };
                  this.loginMultiModuloService.routeUserToPlatform(data.usuario, true);
                  if(data.usuario.rewards) {
                    this.rewardsService.rewardReceivedConfirmation(data.usuario.rewards.coins_gained,
                      data.usuario.rewards.coin_name,
                      data.usuario.rewards.path_coins_image,
                      data.usuario.rewards.messages, true
                    );
                  };
                  // this.rewardsService.refreshRewardConfiguration();
                };
            };
            this.loader.hide();
          } catch (error) {
            this.loader.hide();
            console.log(error);
          }
        },
        error: (e) => {
          this.loader.hide();
          console.log(e);
        }
      });
  }

  getSelectedWorkspace() {
    this.IDselectedPlatform = JSON.parse(localStorage.getItem("IDselectedPlatform"));
    this.IDselectedWorkspace = JSON.parse(localStorage.getItem("IDselectedWorkspace"));
  }

  handleClickedOutside() {
    this.modulesOptionsIsOpen = false;
    console.log("click opusdiee mobile", this.modulesOptionsIsOpen);
  }

	ngOnDestroy() {
		if(this.networkObservable) this.networkObservable.unsubscribe();
    if(this.multiWorkspaceSubs)this.multiWorkspaceSubs.unsubscribe();
	}
}
