<ion-menu side="end" menuId="mobileMenu" contentId="main" class="my-custom-menu" [swipeGesture]="swipe" maxEdgeStart="0" type="overlay">

	<ion-content class=""  [ngClass]="menuGradientActive ? 'side-menu-mobile-gradiant-container' : 'side-menu-mobile-container'">
		<section class=" text-white pb-4">
			<!-- User - avatar -->
			<div class="flex items-center justify-between pl-6 pr-5 py-3 mb-3">
				<div class="w-auto flex items-center" (click)="to('usuario/mi-perfil')">
					<div class="flex items-center justify-center mr-5">
						<div
							class="user-icon bg-secondary flex justify-center items-center font-black text-3xl p-6 text-white">
							{{initials ? initials : initialsProp }}
						</div>
					</div>
					<div  class="w-full font-bold px-2 py-2 items-center text-base">
						{{ ( (data_usuario && data_usuario.full_name) ? data_usuario.full_name : dataUsuarioProp)| titlecase }}
					</div>
				</div>
				<div class="h-full flex justify-end self-start ml-2 cursor-pointer" (click)="cerrarMenu()">
					<ion-icon name="close-circle" class="text-3xl text-white"></ion-icon>
				</div>
			</div>
			<nav class="pl-6">
				<div *ngIf="isCordovaApp && hasOfflineModeAvailable && !platformTypeInduction" class="item menu-toggle-wrapper mb-4 flex items-center justify-between px-2 mr-5 ">
					<div class="flex items-center justify-start gap-3" (click)="toggleNetwork()" >
						<ion-toggle class="toggle-filter " [checked]="!networkIsActive" [disabled]="true"></ion-toggle>
						<mat-icon class="mdi  text-white text-base mdi-cloud-off-outline" [ngClass]="networkIsActive ? 'opacity-50' : 'opacity-100' "></mat-icon>
						<ion-label class="text-sm" [ngClass]="networkIsActive ? 'opacity-50' : 'opacity-100' ">Modo sin conexión</ion-label>
					</div>
				</div>

          <div *ngIf="networkIsActive" (click)="showNotification()" class="item mb-4 flex items-center justify-between px-2 mr-5">
            <div class="flex items-center justify-start">
              <div class="relative mr-4">
                <mat-icon class="mdi mdi-bell text-white text-xl"></mat-icon>
                <mat-icon class="mdi mdi-circle-medium text-2xl icon-alert-notification" *ngIf="newNotification"></mat-icon>
              </div>
              <ion-label class="text-sm">Notificaciones</ion-label>
            </div>
            <ion-icon name="chevron-forward-outline" class="text-base text-white"></ion-icon>
          </div>
          <p class="text-base font-bold mb-3">Selección de accesos</p>


          <ng-container *ngIf="networkIsActive else offlineActive">
            <div class="item mb-4" *ngFor="let group of array_menu2; index as i"  [ngClass]="{ active: displayAccordionSection === i && group.isGrouped }">
              <header (click)="group.isGrouped ? accordeonActive(i) : to(group.groupedItems[0].path, i)"
                class="flex items-center justify-between px-2 mr-5"
                [ngClass]="{
                  'bg-secondary rounded-default': groupSelected === i
                }"
              >
                <div class="flex items-center justify-start">
                  <ion-icon [src]="group.groupIcon" slot="start" class="text-white text-xl mr-4"></ion-icon>
                  <span class="text-white text-base font-normal">{{group.groupName}}</span>
                </div>
                <ion-icon *ngIf="!group.isGrouped" name="chevron-forward-outline" class="text-base text-white"></ion-icon>
                <ion-icon *ngIf="group.isGrouped" name="chevron-down-outline"
                  [ngClass]="{ 'rotate-180': displayAccordionSection == i, 'rotate-0': displayAccordionSection !== i }"
                  class="text-base text-white transition-transform duration-200 transform">
                </ion-icon>
              </header>
              <ng-container *ngIf="group.isGrouped">
                <div
                  class="mt-2 ml-9 px-2  mr-5 flex items-center justify-start leading-10"
                  *ngFor="let item of group.groupedItems; index as subindex"
                  (click)="to(item.path, null, subindex)"
                  [ngClass]="{
                    'bg-secondary rounded-default': groupSelected === i && subGroupSelected === subindex
                  }"
                >

                  <ion-icon [src]="item.icon" slot="start" class="text-white text-xl mr-4"></ion-icon>
                  <span class="text-white text-base font-normal">	{{item.name}}</span>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-template #offlineActive>
            <div class="item mb-4" *ngFor="let group of array_menu2; index as i" >
              <header (click)="group.groupName === 'Mis cursos' ? toOfflineCourses() : '' "
                class="flex items-center justify-between px-2 mr-5"
                [ngClass]="{'opacity-50': group.groupName !== 'Mis cursos'}"
              >
                <div class="flex items-center justify-start">
                  <ion-icon [src]="group.groupIcon" slot="start" class="text-white text-xl mr-4"></ion-icon>
                  <span class="text-white text-base font-normal">{{group.groupName}}</span>
                </div>
                <ion-icon *ngIf="!group.isGrouped || group.groupName === 'Mis cursos' " name="chevron-forward-outline" class="text-base text-white"></ion-icon>
                <ion-icon *ngIf="group.isGrouped && group.groupName !== 'Mis cursos' " name="chevron-down-outline"
                  class="text-base text-white transition-transform duration-200 transform">
                </ion-icon>
              </header>
            </div>
          </ng-template>

          <div class="h-10 flex items-center justify-start px-2   w-full" (click)="confirmarSalir()">
            <ion-icon src="assets/icon/logout.svg"  slot="start" class="text-white text-xl mr-4"></ion-icon>
            <span class="text-white text-base font-medium">Cerrar sesión</span>
          </div>
        </nav>
    </section>
      <!-- multi workspace -->
      <div *ngIf="networkIsActive && modulesOptions && modulesOptions.length" class="px-6 pb-4">
        <div appClickedOutside (clickedOutside)="handleClickedOutside()" class="relative">
          <div *ngIf="modulesOptionsIsOpen" class=" absolute bottom-[58px] left-0 right-0 z-[51] bg-white transition duration-300 p-2 rounded-t-default" [ngClass]="modulesOptionsIsOpen ? 'block' : 'hidden'">
            <div (click)="selectWorkspace(module)" *ngFor="let module of modulesOptions" class="side-nav-modules-opt grid items-center py-3 px-3 hover:bg-gray-100 cursor-pointer"
            [ngClass]="{'rounded-default border-1 border-primary': IDselectedPlatform === module.platform?.id && IDselectedWorkspace === module.subworkspace?.id}"
            >
              <div class="flex gap-1 items-center">
                <!-- <ion-icon *ngIf="IDselectedPlatform === module.platform?.id && IDselectedWorkspace === module.subworkspace?.id" name="ellipse" class="text-primary text-[8px]"></ion-icon> -->
                <mat-icon *ngIf="module.platform?.code === 'training'" class="mdi mdi-human-male-board text-xl leading-[18px] text-primary"></mat-icon>
                <mat-icon *ngIf="module.platform?.code === 'onboarding'" class="mdi mdi-card-account-details text-xl leading-[18px] text-primary"></mat-icon>
              </div>
              <span class="pl-4 justify-self-start"  [ngClass]="{'text-primary font-bold': IDselectedPlatform === module.platform?.id && IDselectedWorkspace === module.subworkspace?.id}">{{module.platform?.name + " - " + module.subworkspace?.name}} </span>
              <img [src]="module.subworkspace?.logo" class="w-5 h-5 rounded-full border-1 border-secondary object-contain" />
            </div>
          </div>

          <button (click)="showMultimoduleOptions()" class="nav-modules-btn  w-full bg-primary border-1 border-white rounded-default px-4 py-4 grid grid-cols-[auto_1fr_auto] items-center text-white">
            <mat-icon class="mdi mdi-swap-horizontal text-xl leading-[20px] text-white"></mat-icon>
            <span class="pl-4 justify-self-start">Cambiar a:</span>
            <mat-icon class="mdi text-xl leading-[20px] text-white" [ngClass]="modulesOptionsIsOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' "></mat-icon>
          </button>
        </div>

      </div>
	</ion-content>
</ion-menu>


