// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	APP_MULTIMARCA: false,
	URL_MASTER: "https://master.cursalab.co",

	//**MARCA PROPIA
	SERVICE_BASE: "https://gestiona.test1.cursalab.co/api/",
	SERVICE_BASE_GESTOR: "https://gestiona.test1.cursalab.co/",
	SERVICE_BASE_REST: "https://gestiona.test1.cursalab.co/api/rest/",
	SERVICE_BASE_AUTH: "https://gestiona.test1.cursalab.co/api/auth/",
	URL_BASE: "https://test1.cursalab.co/",
	URL_REPORTE: "https://reportes.main.cursalab.co/",

	SERVICE_BASE_REST_MASTER: "https://test.master.cursalab.io/api/",

	// //**url de tiendas
	APP_PLAYSTORE: "",
	APP_ID_PLAYSTORE: "io.cursalab.app",
	APP_ID_IOS: "id6454143761",

	// //**versiones
	APP_VERSION_IOS: "0.0",
	APP_VERSION_ANDROID: "0.0.1",
	WEB_VERSION: "8.1.10",

	//*****google analytics ID test
	GA_TRACKING_ID:"G-FVFVVW99KV",

	recaptcha:{
		siteKey: "6LeLUBAkAAAAAGIuPtfdvgzcpgAPHboYlvp1GdUf"
	},

	RUTA_ARCHIVOS: "archivos/",
	RUTA_DIPLOMA: "dnc/",
	RUTA_DESCARGAS: "dnx/",

	LOGO_APP: "assets/img/logo_color.png",
	LOGO_BY_CURSALAB: "assets/img/logo_by_cursalab.svg",
	LOGO_CURSALAB: "assets/img/logo-cursalab.svg",

	RUTA_TEMPLATE_INVITADOS:
		"https://d1rhexnbhr0x6m.cloudfront.net/inretail/plantillas/Plantilla-Importar-Invitados.xlsx",

  CUSTOM_SCHEMA: "io.cursalab.app://",
  TYPE_CLIENT: "multimarca"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
