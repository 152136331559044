import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FuncionesServiceProvider, LoaderProvider, MenuServiceProvider, UsuarioServiceProvider } from "src/app/service/index.providers";
import { AlertController, MenuController, NavController, Platform } from "@ionic/angular";
import { NavigationExtras, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "src/app/api.service";
import { ConexionServiceProvider, ConnectionStatus } from '../../service/conexion-service/conexion-service';
import { LoginMultiModuloService } from "src/app/service/login-multi-modulo/login-multi-modulo.service";
import { RewardsService } from "src/app/service/rewards/rewards.service";
import { Storage } from '@ionic/storage-angular';
@Component({
	selector: "app-side-menu",
	templateUrl: "./side-menu.component.html",
	styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit, OnDestroy {
	@Input() navControl: any;
	array_menu2$: Observable<any[]>;
	array_menu2: any;
	swipe: boolean = false;
	categorias: any;
	url;
	data_usuario;
	page_select: String = "anuncios";
	SideBarActive: boolean = false;
	displayAccordionSection: number | null = null;
	groupSelected:number | null = null;
	subGroupSelected:number | null = null;
	showMenuLateral:boolean = true;
	networkIsActive: boolean = true;
	isCordovaApp: boolean = false;
	private networkObservable: Subscription;
  menuGradientActive:boolean;
  modulesOptions = [];
  modulesOptionsIsOpen:boolean = false;
	multiWorkspaceSubs: Subscription;
  version_usu: string | null = this.platform.is("ios") ? environment.APP_VERSION_IOS : this.platform.is("android") ? environment.APP_VERSION_ANDROID : null;
	device_os: string = this.platform.is("ios") ? "ios" : this.platform.is("android") ? "android" : "web";
  configBrandSub: Subscription;
  data_empresa;
  IDselectedPlatform;
  IDselectedWorkspace;

	constructor(
		private fp: FuncionesServiceProvider,
		private alertCtrl: AlertController,
		private menu: MenuController,
		public router: Router,
		private navCtrl: NavController,
		private mp: MenuServiceProvider,
		private api: ApiService,
		private up: UsuarioServiceProvider,
		private platform: Platform,
		private networkService: ConexionServiceProvider,
    private loginMultiModuloService: LoginMultiModuloService,
    private loader: LoaderProvider,
    private Storage: Storage,
    private rewardsService: RewardsService,

	) {
		if (this.platform.is("cordova")) {
			this.isCordovaApp = true;
			this.checkNewtworkStatus();
		}
	}

	ngOnInit() {
		//obtniene el array de paginas
		this.array_menu2$ = this.fp.getPaginas();
		this.array_menu2$.subscribe((paginas) => {
			this.array_menu2 = paginas;
		});
		if (!this.array_menu2) {
			this.array_menu2 = this.fp._pages;
		}
		if (localStorage.getItem("select_page")) this.page_select = localStorage.getItem("select_page");
		if (localStorage.getItem("select_group")) this.groupSelected = parseInt(localStorage.getItem("select_group"));
		if (localStorage.getItem("select_sub_group")) this.subGroupSelected = parseInt(localStorage.getItem("select_sub_group"));
		this.mp._showMenu.subscribe((state) => {
      this.SideBarActive = state;
      if(!this.SideBarActive) this.modulesOptionsIsOpen = false;
    });
		this.mp.select_page.subscribe((page) => (this.page_select = page));
		this.mp.select_group.subscribe((index) => (this.groupSelected = index));
		this.mp.select_sub_group.subscribe((index) => (this.subGroupSelected = index));
		// if(this.up.getPlatformType === 'induction')	this.showMenuLateral = false;
    this.alternativeStyleIsActive();

    this.multiWorkspaceSubs = this.loginMultiModuloService.workspacesList$.subscribe((res) => {
      this.modulesOptions = res;
      if(this.modulesOptions && this.modulesOptions.length) {
        this.getSelectedWorkspace();
      }
    });
    setTimeout(() => {
      if(!this.modulesOptions || this.modulesOptions.length === 0) {
        this.modulesOptions =  JSON.parse(localStorage.getItem("multiWorkspace"));
        this.loginMultiModuloService.setWorkspacesList(this.modulesOptions);
      };
    }, 70);

    // console.log("wk options list:::",this.modulesOptions)

    this.configBrandSub = this.up.configBrand.subscribe((res) => {
			this.data_empresa = res;
		});

	}

  getSelectedWorkspace() {
    this.IDselectedPlatform = JSON.parse(localStorage.getItem("IDselectedPlatform"));
    this.IDselectedWorkspace = JSON.parse(localStorage.getItem("IDselectedWorkspace"));
  }


  alternativeStyleIsActive() {
    this.fp.alternativeStyleSubject$.subscribe(state => {
      this.menuGradientActive = state;
    });
  }

  checkNewtworkStatus() {
		console.log("sidemenu.ts check network status!!!!");
		this.networkObservable = this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				console.log("online sidemenu.ts");
				this.networkIsActive = true;
			} else {
				console.log("offline sidemenu.ts");
				this.networkIsActive = false;
			}
		});
	}

	/* Routes = [
    { name: 'Anuncios', path: 'anuncios', icon: 'assets/icon/anuncios.svg' },
    { name: 'Cursos', path: 'cursos', icon: 'assets/icon/cursos.svg' },
    { name: 'Reuniones', path: 'lista-reuniones', icon: 'assets/icon/reunion.svg' },
    { name: 'Progreso', path: 'progreso', icon: 'assets/icon/progreso.svg' },
    { name: 'Cursos Extracurriculas', path: 'cursos-extra', icon: 'assets/icon/cursos_extra.svg' },
    { name: 'Glosario', path: 'glosario', icon: 'assets/icon/glosario.svg' },
    { name: 'Adjuntar Archivo', path: 'adjuntar-archivo', icon: 'assets/icon/clip.svg' },
    { name: 'Ranking', path: 'ranking', icon: 'assets/icon/ranking.svg' },
    { name: 'Diploma', path: 'diploma', icon: 'assets/icon/diploma.svg' },
    { name: 'Ayuda', path: 'ayuda', icon: 'assets/icon/ayuda.svg' },
    { name: 'Preguntas Frecuentes', path: 'faq', icon: 'assets/icon/faq.svg' },
  ] */
	to(routeLink, groupIndex = null, subGroupIndex = null) {
		groupIndex ? this.groupSelected = groupIndex : this.groupSelected = this.displayAccordionSection;
		this.subGroupSelected = subGroupIndex;

		this.SideBarActive = false;
		this.mp._showMenu.emit(this.SideBarActive);
    console.log("to routeLink:",routeLink);


			if(routeLink === "induccion"){
				let data_usuario = JSON.parse(localStorage.getItem("user_data"));
				routeLink = data_usuario.supervisor_induccion ? "procesos" : 'home';
				localStorage.setItem("platform_type", 'induction');
				this.fp.cargarFunciones();
			}
			if(routeLink === "aprendizaje"){
				routeLink = "anuncios";
				localStorage.setItem("platform_type", 'capacitacion');
				this.fp.cargarFunciones();
			}

			this.mp.select_page.emit(routeLink);
			this.mp.select_group.emit(this.groupSelected);
			this.mp.select_sub_group.emit(this.subGroupSelected);
			if (routeLink == "extracurricular" || routeLink == "libres") {
				let extras: NavigationExtras = {
					queryParams: {
						categoria: routeLink,
					},
				};
				this.router.navigate(["progreso"], extras);
			} else {
				this.router.navigate([routeLink]);
			}
	}

	accordeonActive(index) {
		this.SideBarActive = true;
		this.mp._showMenu.emit(this.SideBarActive);
		if(index === this.displayAccordionSection){
			this.displayAccordionSection = null;
		} else{
			this.displayAccordionSection = index;
		}
	}

	async confirmarSalir() {
		this.SideBarActive = false;
		this.mp._showMenu.emit(this.SideBarActive);
		let alert = await this.alertCtrl.create({
			header: "Cerrar sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();
	}

	async cerrarSesion() {
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.menu.swipeGesture(false);
			// this.navCtrl.navigateRoot("login");
			this.router.navigate(["login"]);
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
	}

  showSideBar() {
    this.SideBarActive = true
    this.mp._showMenu.emit(this.SideBarActive);
  }

  showMultimoduleOptions() {
    this.modulesOptionsIsOpen = !this.modulesOptionsIsOpen;
    // console.log("***click showMultimoduleOptions",this.modulesOptionsIsOpen)
    if(this.modulesOptionsIsOpen) {
      this.getSelectedWorkspace();
    }
  }

  selectWorkspace(selectionData) {
    // console.log("selectWorkspace...", selectionData)
    this.modulesOptionsIsOpen = false;
    this.SideBarActive = false;
    this.mp._showMenu.emit(this.SideBarActive);
    // this.IDselectedPlatform = selectionData.platform?.id;
    // this.IDselectedWorkspace = selectionData.subworkspace?.id;
    localStorage.setItem("IDselectedPlatform", JSON.stringify(selectionData.platform?.id));
    localStorage.setItem("IDselectedWorkspace", JSON.stringify(selectionData.subworkspace?.id));
    if(selectionData.is_neccesary_login) this.loginWorkspace(selectionData.subworkspace?.id, selectionData.platform?.id);
  }



  loginWorkspace(subworkspaceId, platformId) {
    this.loader.show();
    this.api.loginSelectedWorkspace(subworkspaceId, platformId, this.device_os, this.version_usu).subscribe(
      {
        next: async (data) => {
          await this.loginMultiModuloService.logoutPreviousSession();
          try {
            // console.log(data);
            if(environment.APP_MULTIMARCA) {
              //** seteando url
              this.loginMultiModuloService.saveMultibrandApiUrls(data);
              //** seteando colores
              const resBrandConfig = await this.api.getConfig();
              if(data.custom_ambiente) {
                const formatedData = this.loginMultiModuloService.generateAppThemeConfigObj(data.custom_ambiente, resBrandConfig);
                this.loginMultiModuloService.setBrandConfigMultimarca(formatedData);
              } else {
                this.loginMultiModuloService.setBrandConfigMultimarca(resBrandConfig);
              };

              const certificateUrlBase = this.up.getRutaBase;
              if (!certificateUrlBase) { this.up.genera_rutas()	};

              if(data.usuario && !data.recovery) {
                this.fp.setAlternativeStyleState(data.config_data?.has_simple_login);
                this.loginMultiModuloService.setUserProviderConfig(data);
                // FCM token
                if (this.platform.is("cordova")) {
                  this.Storage.get("token_fcm").then((val) => {
                    console.log("login - check storage for token ", val);
                    if (val.status === "true") {
                      // Enviar el token generado por FCM (Firebase) a la BD
                      this.api.guardar_token_fcm(val.token);
                    }
                  });
                }

                this.fp.cargarFunciones();

                if(data.usuario.platform_relations && data.usuario.platform_relations.length) {
                    this.loginMultiModuloService.setWorkspacesList(data.usuario.platform_relations);
                    localStorage.setItem("multiWorkspace", JSON.stringify(data.usuario.platform_relations));
                };

                this.loginMultiModuloService.routeUserToPlatform(data.usuario, true);
                if(data.usuario.rewards) {
                  this.rewardsService.rewardReceivedConfirmation(data.usuario.rewards.coins_gained,
                    data.usuario.rewards.coin_name,
                    data.usuario.rewards.path_coins_image,
                    data.usuario.rewards.messages, true
                  );
                };
              };

            }
            else {
                if(data.custom_ambiente) {
                  this.loginMultiModuloService.setCustomBrandConfig(data.custom_ambiente, this.data_empresa, true);
                } else {
                  const resBrandConfig = await this.api.getConfig();
                  this.loginMultiModuloService.setBrandConfigMultimarca(resBrandConfig, true);
                };
                if(data.usuario && !data.recovery){
                  this.fp.setAlternativeStyleState(data.config_data?.has_simple_login);
                  this.loginMultiModuloService.setUserProviderConfig(data);
                  if (this.platform.is("cordova")) {
                    this.Storage.get("token_fcm").then((val) => {
                      console.log("login - check storage for token ", val);
                      if (val.status === "true") {
                        // Enviar el token generado por FCM (Firebase) a la BD
                        this.api.guardar_token_fcm(val.token);
                        // this.api.sub_token(result2.data.config_data.push_code);
                      }
                    });
                  };

                  this.fp.cargarFunciones();
                  if(data.usuario.platform_relations && data.usuario.platform_relations.length) {
                    this.loginMultiModuloService.setWorkspacesList(data.usuario.platform_relations);
                    localStorage.setItem("multiWorkspace", JSON.stringify(data.usuario.platform_relations));
                  };
                  this.loginMultiModuloService.routeUserToPlatform(data.usuario, true);
                  if(data.usuario.rewards) {
                    this.rewardsService.rewardReceivedConfirmation(data.usuario.rewards.coins_gained,
                      data.usuario.rewards.coin_name,
                      data.usuario.rewards.path_coins_image,
                      data.usuario.rewards.messages, true
                    );
                  };
                  // this.rewardsService.refreshRewardConfiguration();
                };
            };
            this.loader.hide();
          } catch (error) {
            this.loader.hide();
            console.log(error);
          }
        },
        error: (e) => {
          this.loader.hide();
          console.log(e);
        }
      });
  }

  handleClickedOutside() {
    this.modulesOptionsIsOpen = false;
  }

	ngOnDestroy() {
		if(this.networkObservable) this.networkObservable.unsubscribe();
    if(this.multiWorkspaceSubs)this.multiWorkspaceSubs.unsubscribe();
    if(this.configBrandSub)this.configBrandSub.unsubscribe();
	}
}
