<div (click)="onClickCard()" class="w-full step-card-container  shadow-lg px-5 pt-5 pb-4 md:px-6 md:py-6 lg:py-5 xxl:py-6 rounded-lg bg-white items-center relative"
[ngClass]="disabledCard ? 'cursor-not-allowed' :  'hover:bg-gray-100 cursor-pointer' "
>
    <!-- card icon lg:mt-[23%] xl:mt-[27%] xxl:mt-[29%] -->
    <div class="rounded-full flex justify-center items-center lg:self-end  " [ngClass]="iconContaninerClass ? iconContaninerClass : 'h-18 w-18 md:h-24 md:w-24 bg-gray-450 opacity-75 '" >
      <mat-icon id="stepCardIcon" [class]="'mdi mdi-' + iconName +  ' text-3xl md:text-4xl text-white'" ></mat-icon>
    </div>
    <!-- card text -->
    <div class="w-full lg:h-full lg:overflow-hidden" [ngClass]="{'text-gray-450': disabledCard}">
      <ng-content select="[cardText]"></ng-content>

    </div>

    <!-- chip status -->
    <div *ngIf="haveCardChip" class="absolute top-2 right-1">
      <ng-content select="[cardChip]"></ng-content>
    </div>


  <div *ngIf="!isLast && haveMiddleArrow"  class="absolute next-step-arrow hidden md:flex">
    <mat-icon class="mdi mdi-chevron-right text-3xl leading-8"></mat-icon>
  </div>

</div>


