<div class="platform-modal mx-auto text-center bg-white my-auto w-72 md:w-80 lg:w-106">
	<div class="bg-primary header-modal py-3 lg:py-3.5 xxl:py-4 font-semibold text-base lg:text-lg xxl:text-xl text-white text-center relative">
		 ¡Actualiza tu plataforma!
		<button  *ngIf="!isForcedToUpdate" class="btn-close absolute right-[-0.75rem] top-[-0.75rem] lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full"
    (click)="cancelar()">
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5"></ion-icon>
		</button>
	</div>
	<div class="flex flex-col px-7 lg:px-10  py-4  justify-center  text-base font-normal leading-5 ">
    <!-- <app-image-svg name="achievement-img" customClass="w-56 text-secondary mx-auto"/> -->
    <p class="font-normal text-base text-center mb-4 max-w-[300px] mx-auto">Se encuentra disponible una <b>nueva versión de la aplicación.</b> </p>

		<!-- <div class="flex flex-col  justify-center mb-6 text-gray-1000">
			<p class="text-left mb-2.5 message">Se encuentra disponible una nueva versión de la aplicación.</p>
			<p class="text-left mb-2.5 message custom-spacing">Actualiza para disfrutar de las nuevas funcionalidades.</p>
			<p class="text-left text-sm font-semibold">*Se recomienda estar conectado a una red wi-fi.</p>
		</div> -->
		<div class="flex items-center justify-center">
			<button
				(click)="updateAndroid()"
				class="flex items-center rounded-lg py-2 px-4 bg-primary text-base text-white font-normal">
				Actualizar ahora
			</button>
		</div>
    <div class="flex justify-end items-end text-[10px] text-gray-500 -mr-3 mt-1 -mb-2">
      {{ userVersion }} -  {{ dbVersion }}
    </div>
	</div>
</div>
