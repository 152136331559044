import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable({
	providedIn: "root",
})
export class InduccionService {

  lastStageSelected;
  lastStageSelectedIdx;

	private _confetti: boolean = false;
	public showConfetti = new BehaviorSubject<boolean>(this._confetti);
	currentHideConfetti = this.showConfetti.asObservable();


	constructor() {
	}

  public get getLastStageDataSaved() {
		return this.lastStageSelected;
	}
  public get getLastStageIndexSaved() {
		return this.lastStageSelectedIdx;
	}
	public set setLastStageSaved({stage, index}){
		this.lastStageSelected = stage;
		this.lastStageSelectedIdx = index;
	}

	changeShowConfetti(state:boolean) {
    this.showConfetti.next(state);
	}

  findPendingActivity(stages) {
    const firstPendingActivityStage = stages.find( (stage) =>
      stage.activities.some(activity => (activity.status === "disapproved" && activity.attempts) || activity.status === "pending")
    );

    if (firstPendingActivityStage) {
      const pendingActivity = firstPendingActivityStage.activities.find(activity => (activity.status === "disapproved" && activity.attempts) || activity.status === "pending");
      // console.log(pendingActivity);
      return {
        pendigActivityID: pendingActivity.id,
        pendigStageData: firstPendingActivityStage,
      };
    };
    return {
      pendigActivityID: null,
      pendigStageData: null,
    };
  }
}
