import { AfterViewInit, Component, Input, OnInit, OnDestroy } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
	selector: "app-get-signature",
	templateUrl: "./get-signature.component.html",
	styleUrls: ["./get-signature.component.scss"],
})
export class GetSignatureComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() title: string | null = "";
	@Input() courseParagraph: boolean = false;
	@Input() courseText: string | null = "";
	@Input() customParagraph: string | null = null;
	@Input() subtitle: string | null = "";
	@Input() buttonText: string | null = "";
	@Input() customStyle: string | null = null;
	@Input() customTitleSize: string | null = null;
	@Input() notClose: boolean | null = true;

	mainCanvas;
	context: any;
	initialX;
	initialY;
	correccionX = 0;
	correccionY = 0;

	mode = "draw";
	activeEvents = {
		mousedown: this.startDraw,
		mouseup: this.endDraw,
		mousemove: this.draw,
	};
	sizes = {
		small: 5,
		medium: 10,
		big: 15,
	};
	drawing = false;
	// width = 695;
	// height = 405;

	canvaIsTouched: boolean = false;
	imageData;

	constructor(private modalCtrl: ModalController, private navParams: NavParams) {}

	ngOnInit() {}
	ngAfterViewInit(): void {
		this.handleCanvas();
	}

	closeModal(okStatus) {
      this.sendSignature();
      this.removeListeners();
      this.modalCtrl.dismiss({
        aceptar: okStatus,
        imageData: this.imageData
      });
	}

	 sendSignature() {
		// Obtener la imagen del canvas en formato de datos (base64)
		this.imageData = this.mainCanvas.toDataURL("image/png");
	  }

	removeListeners() {
		//this.mainCanvas.removeEventListener("mousemove",(e) => { this.mouseMoving(e) });
		for (const event in this.activeEvents) {
			window.removeEventListener(event, () => {
				this.activeEvents[event];
			});
			this.activeEvents[event] = undefined;
		}
	}

	initializeCanvaSettings() {
		// set default settings
		this.context.lineWidth = 2;
		this.context.imageSmoothingEnabled = true;
		this.context.lineCap = "round";
		this.context.lineJoin = "round";

		this.context.strokeStyle = "#000";
	}

	handleCanvas() {
		this.mainCanvas = document.getElementById("main-canvas") as HTMLCanvasElement;
		// context of the canvas
		this.context = this.mainCanvas.getContext("2d");
		this.initializeCanvaSettings();
	}

	clearCanva() {
      this.context.clearRect(0,0,this.mainCanvas.width,this.mainCanvas.height);
      this.canvaIsTouched = false;
    }

	startDraw(e) {
		this.initializeCanvaSettings();
		this.canvaIsTouched = true;
		this.drawing = true;
		this.context.beginPath();
		this.draw(e);
	}

	endDraw(e) {
		this.drawing = false;
	}

	draw(e,  eventId = "no") {
		if (!this.drawing) return;
		if(eventId === "touchmove") e.preventDefault();

		let { x, y } = this.getMousePos(this.mainCanvas, e, eventId);

		this.context.lineTo(x, y);
		this.context.stroke();

		// for smoother drawing
		this.context.beginPath();
		this.context.moveTo(x, y);
	}



	getMousePos(mainCanvas, evt, eventId) {
		let customEvent = evt;
		var rect = mainCanvas.getBoundingClientRect(), // abs. size of element
			scaleX = this.mainCanvas.width / rect.width, // relationship bitmap vs. element for x
			scaleY = this.mainCanvas.height / rect.height; // relationship bitmap vs. element for y

		if(eventId === "touchmove") {
			// Obtener las coordenadas del primer toque
			var touch = evt.touches[0];

			// Coordenadas X e Y del primer toque
			var x = touch.clientX;
			var y = touch.clientY;
			customEvent = touch;
		}

		return {
			x: (customEvent.clientX - rect.left) * scaleX,
			y: (customEvent.clientY - rect.top) * scaleY,
		};
	}

	ngOnDestroy() {
		this.removeListeners();
	}

}
