<ng-container *ngIf="showMenuLateral">
  <div class="side-bar-custom absolute mt-20 top-0 right-0 hidden lg:grid  bg-primary  h-[calc(100vh-5rem)] z-5  "
    [ngClass]="SideBarActive ? 'sideBar-full-state  overflow-y-auto  sidebar-container-shadow' : 'lg:w-20 xl:w-24'"
  >
    <div class="lg:w-20 xl:w-24 mr-0 ml-auto"
      [ngClass]="SideBarActive ? 'sidebar-active' : 'sidebar-off'">
      <div class="menu mt-4 ">
        <!-- Icons -->
        <!-- <ng-container *ngIf="false">
          <div class="relative h-11 z-9" *ngFor="let route of array_menu2">
            <div
              class="group side-item  transition duration-300 flex items-center  bg-primary absolute top-0 right-0 w-full h-full"
              (click)="to(route.path)"
              [ngClass]="SideBarActive ? 'justify-start' : 'justify-center hover:w-80'"
            >
              <div
                class="transition delay-100 duration-200 bg-secondary absolute w-11/12 h-full right-0 top-0 left-0 w-full group-hover:w-full group-hover:rounded-l-none"
                *ngIf="page_select == route.path"
              ></div>
              <ion-icon
                [src]="route.icon"
                [ngClass]="SideBarActive ? 'mr-2' : 'group-hover:mr-2'"
              ></ion-icon>
              <span
                class="whitespace-nowrap z-2"
                [ngClass]="SideBarActive ? 'block' : 'hidden group-hover:block'"
                >{{ route.name }}
              </span>
            </div>
          </div>
        </ng-container> -->


        <ng-container *ngIf="networkIsActive else offlineActiveMenu">
          <div class="item mb-4 relative z-50 text-white  " *ngFor="let group of array_menu2; index as i"
            [ngClass]="{
              active: displayAccordionSection === i && group.isGrouped  && SideBarActive,
              'overflow-hidden': SideBarActive,
              'overflow-visible': !SideBarActive,
              'bg-secondary w-4/5 mx-auto rounded-lg': groupSelected === i && !SideBarActive
            }"
          >
            <div (click)="group.isGrouped ? accordeonActive(i) : to(group.groupedItems[0].path, i)"
              class="select-header group  flex items-center  cursor-pointer " [ngClass]="{
                'justify-between mx-5 px-5 ': SideBarActive,
                'justify-center  hover:w-84  absolute top-0 right-0 w-full h-full transition ': !SideBarActive,
                'bg-primary':  groupSelected !== i,
                'hover:bg-secondary': groupSelected === i && !SideBarActive,
                'bg-secondary rounded-sm': groupSelected === i && SideBarActive,
                'hover-path': groupSelected !== i && SideBarActive
              }">
              <div class="flex items-center " [ngClass]="SideBarActive ? 'justify-start' : 'justify-center'">
                <ion-icon [src]="group.groupIcon" slot="start" class=" text-xl"

                  [ngClass]="SideBarActive ? 'mr-4' : 'group-hover:mr-4'"
                ></ion-icon>
                <span class=" text-base font-normal"
                  [ngClass]="SideBarActive ? 'block' : 'hidden group-hover:block'">{{group.groupName}}</span>
              </div>
              <ion-icon *ngIf="!group.isGrouped && SideBarActive" name="chevron-forward-outline"
                class="text-base noHover "></ion-icon>
              <ion-icon *ngIf="group.isGrouped && SideBarActive" name="chevron-down-outline"
                [ngClass]="{ 'rotate-180': displayAccordionSection == i, 'rotate-0': displayAccordionSection !== i }"
                class="text-base  transition-transform duration-200 transform noHover">
              </ion-icon>
            </div>
            <ng-container *ngIf="group.isGrouped && SideBarActive">
              <div class="mt-1 ml-14 px-5 mr-5  py-2 flex items-center justify-start leading-9 cursor-pointer"
                *ngFor="let item of group.groupedItems; index as subindex" (click)="to(item.path, null, subindex)" [ngClass]="{
                  'bg-secondary rounded-sm': groupSelected === i && subGroupSelected === subindex  && SideBarActive,
                  'hover-path': (groupSelected !== i &&  SideBarActive ) || (groupSelected === i && subGroupSelected !== subindex && SideBarActive)
                }"
              >

                <ion-icon [src]="item.icon" slot="start" class=" text-xl mr-4"></ion-icon>
                <span class="text-base font-normal max-w-xxs whitespace-nowrap overflow-hidden overflow-ellipsis">{{item.name}}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #offlineActiveMenu>
          <div class="item mb-4 relative z-50 text-white  " *ngFor="let group of array_menu2; index as i" [ngClass]="{'overflow-visible': !SideBarActive}">
            <div class="select-header group  flex items-center  cursor-pointer opacity-50 " [ngClass]="{
                'justify-center absolute top-0 right-0 w-full h-full transition ': !SideBarActive,
                'hover:bg-secondary': groupSelected === i && !SideBarActive
              }"
            >
              <div class="flex items-center " [ngClass]="SideBarActive ? 'justify-start' : 'justify-center'">
                <ion-icon [src]="group.groupIcon" slot="start" class=" text-xl"
                  [ngClass]="SideBarActive ? 'mr-4' : ''"
                ></ion-icon>
              </div>
              <!-- <ion-icon *ngIf="!group.isGrouped && SideBarActive" name="chevron-forward-outline"
                class="text-base noHover "></ion-icon>
              <ion-icon *ngIf="group.isGrouped && SideBarActive" name="chevron-down-outline"
                [ngClass]="{ 'rotate-180': displayAccordionSection == i, 'rotate-0': displayAccordionSection !== i }"
                class="text-base  transition-transform duration-200 transform noHover">
              </ion-icon> -->
            </div>
          </div>
        </ng-template>

      </div>
      <!-- Cerrar sesion -->
      <div class="h-14 cursor-pointer z-50">
        <div class="relative h-14 z-9">
          <span class="division self-center mx-auto"
            [ngClass]="SideBarActive ? 'division-nav-inactive' : 'division-nav-active'"></span>
          <div class="mt-4  group transition duration-300 flex items-center side-item bg-primary absolute top-0 right-0 w-full h-full text-white"
            [ngClass]="SideBarActive ? 'justify-start ' : 'justify-center hover:w-84'"
            (click)="confirmarSalir()">
            <div class="flex h-full  items-center" [ngClass]="SideBarActive ? 'hover-path mx-5 px-6 w-full' : ''">
              <ion-icon class="text-xl" src="assets/icon/logout.svg"
                [ngClass]="SideBarActive ? 'mr-4' : 'group-hover:mr-2'"></ion-icon>
              <span class="whitespace-nowrap z-auto  text-base font-medium"
                [ngClass]="SideBarActive ? 'block' : 'hidden group-hover:block'">Cerrar sesión</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- cambio de worspace -->
    <div *ngIf="networkIsActive && modulesOptions && modulesOptions.length" class="px-6 mb-4 mt-8" [ngClass]="{'h-14': !SideBarActive}">
      <div *ngIf="!SideBarActive" (click)="showSideBar()" class="h-full cursor-pointer flex items-center justify-center">
        <mat-icon class="mdi mdi-swap-horizontal text-xl leading-[20px] text-white"></mat-icon>
      </div>

      <div appClickedOutside 	(clickedOutside)="handleClickedOutside()"  *ngIf="SideBarActive" class="relative">
        <div *ngIf="modulesOptionsIsOpen" class=" absolute bottom-[58px] left-0 right-0 z-[51] bg-white transition duration-300 p-2 rounded-t-default" [ngClass]="modulesOptionsIsOpen ? 'block' : 'hidden'">
          <div (click)="selectWorkspace(module)" *ngFor="let module of modulesOptions" class="side-nav-modules-opt grid items-center py-3 px-3 hover:bg-gray-100 cursor-pointer"
          [ngClass]="{'rounded-default border-1 border-primary': IDselectedPlatform === module.platform?.id && IDselectedWorkspace === module.subworkspace?.id}"
          >
            <div class="flex gap-1 items-center">
              <mat-icon *ngIf="module.platform?.code === 'training'" class="mdi mdi-human-male-board text-xl leading-[18px] text-primary"></mat-icon>
              <mat-icon *ngIf="module.platform?.code === 'onboarding'" class="mdi mdi-card-account-details text-xl leading-[18px] text-primary"></mat-icon>
            </div>
            <span class="pl-4 justify-self-start " [ngClass]="{'text-primary font-bold': IDselectedPlatform === module.platform?.id && IDselectedWorkspace === module.subworkspace?.id}">{{module.platform?.name + " - " + module.subworkspace?.name}} </span>
            <img [src]="module.subworkspace?.logo" class="w-5 h-5 rounded-full border-1 border-secondary" />
          </div>
        </div>

        <button (click)="showMultimoduleOptions()" class="nav-modules-btn w-full bg-primary border-1 border-white rounded-default px-4 py-4 grid items-center text-white">
          <mat-icon class="mdi mdi-swap-horizontal text-xl leading-[20px] text-white"></mat-icon>
          <span class="pl-4 justify-self-start">Cambiar a:</span>
          <mat-icon class="mdi text-xl leading-[20px] text-white" [ngClass]="modulesOptionsIsOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' "></mat-icon>
        </button>
      </div>
    </div>

  </div>

</ng-container>
