import { Injectable } from '@angular/core';
import { UsuarioServiceProvider } from '../index.providers';

@Injectable({
  providedIn: 'root'
})
export class ThemeColorsService {
  brandConfig;

	primaryColor: string | null = null;
	secondaryColor: string | null = null;
	tertiaryColor: string | null = null;

	primaryFocusedColor: string | null = null;
	primaryUltraFocusedColor: string | null = null;

	primaryLightFirstVariant: string | null = null;
	primaryLightSecondVariant: string | null = null;
	primaryLightThirdVariant: string | null = null;
	primaryLightFourthVariant: string | null = null;
	primaryLightFifthVariant: string | null = null;

	secondaryUltraFocusedColor: string | null = null;
  secondaryLightFirstVariant: string | null = null;

  constructor(private up: UsuarioServiceProvider) {
    this.up.configBrand.subscribe((res) => {
			//console.log("data empresa en IMAGEN SVG::", res);
			this.brandConfig = res;
			if (this.brandConfig) {
        this.generateBrandColorsVariant(this.brandConfig);
			}
		});
  }

  generateBrandColorsVariant(brandConfig) {
      this.primaryColor = this.brandConfig.color_primario_app;
      this.secondaryColor = this.brandConfig.color_secundario_app;
      this.tertiaryColor = this.brandConfig.color_terciario_app
        ? this.brandConfig.color_terciario_app
        : this.brandConfig.color_secundario_app;
      //  this.primaryColor = "#fac60c";
      // this.secondaryColor = "#f53b11";
      // this.tertiaryColor = "#9a29e6";

      //red,green,blue(primary)
      let rgb;
      let result_pri;
      let result_sec;
      try {
        result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.primaryColor);
        result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.secondaryColor);
        rgb = {
          rp: parseInt(result_pri[1], 16),
          gp: parseInt(result_pri[2], 16),
          bp: parseInt(result_pri[3], 16),
          rs: parseInt(result_sec![1], 16),
          gs: parseInt(result_sec![2], 16),
          bs: parseInt(result_sec![3], 16),
        };
      } catch (error) {
        result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#5458e9");
        result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec('#58c1e5');
        rgb = {
          rp: parseInt(result_pri[1], 16),
          gp: parseInt(result_pri[2], 16),
          bp: parseInt(result_pri[3], 16),
          rs: parseInt(result_sec![1], 16),
          gs: parseInt(result_sec![2], 16),
          bs: parseInt(result_sec![3], 16),
        };
      }

      this.primaryFocusedColor = `rgb(${rgb.rp * 0.3}, ${rgb.gp * 0.3}, ${rgb.bp * 0.3})`;
      this.primaryUltraFocusedColor = `rgb(${rgb.rp * 0.6}, ${rgb.gp * 0.6}, ${rgb.bp * 0.6})`; // 0.6 para oscurecer el color, a menor nro es más oscuro y viceversa (1.2)

      this.primaryLightFirstVariant = this.convertRgbaToSolidRgb(rgb.rp, rgb.gp, rgb.bp, 0.8);
      this.primaryLightSecondVariant = this.convertRgbaToSolidRgb(rgb.rp, rgb.gp, rgb.bp, 0.5);
      this.primaryLightThirdVariant = this.convertRgbaToSolidRgb(rgb.rp, rgb.gp, rgb.bp, 0.3);
      this.primaryLightFourthVariant = this.convertRgbaToSolidRgb(rgb.rp, rgb.gp, rgb.bp, 0.2);
      this.primaryLightFifthVariant = this.convertRgbaToSolidRgb(rgb.rp, rgb.gp, rgb.bp, 0.12);

      this.secondaryUltraFocusedColor = `rgb(${rgb.rs * 0.6}, ${rgb.gs * 0.6}, ${rgb.bs * 0.6})`; // 0.6 para oscurecer el color, a menor nro es más oscuro y viceversa (1.2)
      this.secondaryLightFirstVariant = this.convertRgbaToSolidRgb(rgb.rs, rgb.gs, rgb.bs, 0.8);

      // this.primaryFocusedColor = `rgb(${rgb.rp * 0.3}, ${rgb.gp * 0.3}, ${rgb.bp * 0.3})`;
      // this.primaryUltraFocusedColor = `rgb(${rgb.rp * 0.6}, ${rgb.gp * 0.6}, ${rgb.bp * 0.6})`; // 0.6 para oscurecer el color, a menor nro es más oscuro y viceversa (1.2)

      // this.primaryLightFirstVariant = `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.8)`;
      // this.primaryLightSecondVariant = `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.5)`;
      // this.primaryLightThirdVariant = `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.3)`;
      // this.primaryLightFourthVariant = `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.2)`;
      // this.primaryLightFifthVariant = `rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp}, 0.12)`;

      // this.secondaryUltraFocusedColor = `rgb(${rgb.rs * 0.6}, ${rgb.gs * 0.6}, ${rgb.bs * 0.6})`; // 0.6 para oscurecer el color, a menor nro es más oscuro y viceversa (1.2)
      // this.secondaryLightFirstVariant = `rgb(${rgb.rs}, ${rgb.gs}, ${rgb.bs}, 0.8)`;

  }

  getBrandColors() {
    return {
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      tertiaryColor: this.tertiaryColor,

      primaryFocusedColor: this.primaryFocusedColor,
      primaryUltraFocusedColor: this.primaryUltraFocusedColor,

      primaryLightFirstVariant:  this.primaryLightFirstVariant,
      primaryLightSecondVariant: this.primaryLightSecondVariant,
      primaryLightThirdVariant: this.primaryLightThirdVariant,
      primaryLightFourthVariant: this.primaryLightFourthVariant,
      primaryLightFifthVariant: this.primaryLightFifthVariant,

      secondaryUltraFocusedColor: this.secondaryUltraFocusedColor,
      secondaryLightFirstVariant: this.secondaryLightFirstVariant,
    }
  }

  convertRgbaToSolidRgb(red,green,blue,alphaValue) {
    const blendedColor = this.blendColorWithWhite(red, green, blue, alphaValue);
    return `rgb(${blendedColor.r}, ${blendedColor.g}, ${blendedColor.b})`;
  }

  blendColorWithWhite(r, g, b, alpha) {
    // Fórmula para mezclar un color con blanco
    const blend = (value) => Math.round((1 - alpha) * 255 + alpha * value);
    return {
      r: blend(r),
      g: blend(g),
      b: blend(b)
    };
  }

  rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  }

}
